import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import { SYSTEM_SUMMARY } from '@ctw/shared/api/fhir/system-urls';
import { fqsRequest, MAX_OBJECTS_PER_REQUEST } from '@ctw/shared/api/fqs/client';
import { orderBy, uniqBy } from 'lodash-es';
import { PatientModel } from './models';
import { ImmunizationModel } from './models/immunization';
import {
  ImmunizationGraphqlResponse,
  immunizationsQuery,
  ImmunizationWithBasics,
} from '@ctw/shared/api/fqs/queries/immunizations';
import { QUERY_KEY_PATIENT_IMMUNIZATIONS } from '@ctw/shared/utils/query-keys';
import { sort } from '@ctw/shared/utils/sort';
import { GraphQLClient } from 'graphql-request';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export function usePatientImmunizations(enabled = true) {
  return usePatientQuery({
    queryId: QUERY_KEY_PATIENT_IMMUNIZATIONS,
    queryFn: async ({ requestContext, telemetry, graphqlClient, patient }) => {
      const { data } = await fqsRequest<ImmunizationGraphqlResponse>(
        telemetry,
        graphqlClient,
        immunizationsQuery,
        {
          upid: patient.UPID,
          cursor: '',
          first: 1000,
          sort: {
            lastUpdated: 'DESC',
          },
          filter: {
            tag: { nonematch: [SYSTEM_SUMMARY] },
          },
        },
      );
      const nodes = data.ImmunizationConnection.edges.map((x) => x.node);
      return getFilteredImmunizations(nodes, requestContext.builderId);
    },
    enabled,
  });
}

export async function getImmunizationsFromFQS(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  requestContext: CTWRequestContext,
  patient: PatientModel,
  ids: string[],
) {
  const { data } = await fqsRequest<ImmunizationGraphqlResponse>(
    telemetry,
    graphqlClient,
    immunizationsQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
      sort: {
        lastUpdated: 'DESC',
      },
      filter: {
        ids: { anymatch: ids },
        tag: { nonematch: [SYSTEM_SUMMARY] },
      },
    },
  );
  const nodes = data.ImmunizationConnection.edges.map((x) => x.node);
  return getFilteredImmunizations(nodes, requestContext.builderId);
}

const getFilteredImmunizations = (data: ImmunizationWithBasics[], builderId: string) => {
  const immunizations = data.map(
    (immunization) => new ImmunizationModel(immunization, undefined, immunization.BasicList),
  );

  const sortedByDate = sort(
    immunizations.filter((x) => x.status === 'Completed'),
    'occurrence',
    'desc',
    true,
  );

  // Bump builder owned immunization to the front, so uniqBy favors them!
  const builderOwnedFirst = orderBy(sortedByDate, (a) => a.ownedByBuilder(builderId), 'desc');
  return uniqBy(builderOwnedFirst, 'uniqueKey');
};
