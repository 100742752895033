import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { ContentTypeIcon } from '@ctw/shared/components/icons/content-type-icon';
import { useDownloadBinary } from '@ctw/shared/content/CCDA/use-download-binary';
import { tw } from '@ctw/shared/utils/tailwind';
import { Alert } from '@ctw/shared/components/alert';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { useCCDAModal } from '@ctw/shared/content/CCDA/modal-ccda';
import { Button } from '@ctw/shared/components/button';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { Link } from '@ctw/shared/components/link';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { usePatientContext } from '@ctw/shared/context/patient-provider';

export type ResponsiveDocumentButtonProps = Partial<{
  binaryId: string;
  contentType?: string;
  documentTitle: string;
  forceInModal?: boolean;
  showContentTypeIcon: boolean;
  telemetryTarget: string;
  text?: string;
}>;

const MAX_CCDA_VIEWER_WIDTH = 460;

export const ResponsiveSourceDocumentLink = ({
  binaryId,
  contentType = '',
  documentTitle = 'Source Document',
  forceInModal = false,
  text = 'Source Document',
  telemetryTarget = 'none',
  showContentTypeIcon = true,
}: ResponsiveDocumentButtonProps) => {
  const { patient } = usePatientContext();
  const openCCDAModal = useCCDAModal(patient);
  const { allowSmallBreakpointCCDAViewer, featureFlags } = useCTW();
  const { trackInteraction } = useTelemetry();
  const { downloadBinaryById, error, isDownloading } = useDownloadBinary();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);

  if (!binaryId) {
    return null;
  }

  const isDownloadBtn = !allowSmallBreakpointCCDAViewer && width < MAX_CCDA_VIEWER_WIDTH;
  const downloadText = isDownloadBtn && !isDownloading ? 'Download' : '';
  const showOpenInNewTab = !isDownloadBtn && !forceInModal && featureFlags.openCCDAInNewTab;

  return (
    <>
      {error && <Alert type="error" header={error} />}
      {showOpenInNewTab ?
        <Link
          href={`/ccda/${binaryId}`}
          className={tw`link inline-flex items-center space-x-2 whitespace-nowrap align-top leading-6`}
          target="_blank"
        >
          <span className={tw`normal-case`}>{text}</span>
          <FontAwesomeIcon icon={faExternalLinkAlt} className={tw`h-3`} />
        </Link>
      : <Button
          type="button"
          variant="link"
          className={tw`flex items-center space-x-2`}
          disabled={isDownloading}
          onClick={() => {
            if (isDownloadBtn) {
              downloadBinaryById(binaryId, documentTitle);
              trackInteraction('download_document', {
                target: telemetryTarget,
              });
            } else {
              openCCDAModal(binaryId, documentTitle);
              trackInteraction('open_source_document', {
                target: telemetryTarget,
              });
            }
          }}
        >
          {showContentTypeIcon && <ContentTypeIcon contentType={contentType} />}
          <span className={tw`block min-w-9`}>
            {!isDownloading ?
              downloadText
            : <LoadingSpinner className={tw`h-3`} message="Downloading" />}{' '}
            {text}
          </span>
        </Button>
      }
    </>
  );
};
