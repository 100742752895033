import { tw, twx } from '@ctw/shared/utils/tailwind';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type BadgeVariant = 'primary' | 'info' | 'muted' | 'caution';

export type BadgeProps = {
  className?: string;
  variant: BadgeVariant;
  content: string;
  icon?: IconDefinition;
  testId?: string;
};

export const Badge = ({ className, variant, content, icon, testId }: BadgeProps) => (
  <div
    className={twx(
      'flex max-h-fit max-w-fit items-center justify-center gap-1 whitespace-nowrap rounded-full px-2 py-1 text-xs',
      {
        // Variant styling
        'bg-primary-background-hover text-primary-main': variant === 'primary', // green
        'bg-info-badge text-info-text': variant === 'info', // blue
        'bg-background-disabled text-background-inverse': variant === 'muted', // grey
        'bg-caution-badge text-caution-text': variant === 'caution', // yellow
      },
      className,
    )}
    data-testid={testId}
  >
    {icon && <FontAwesomeIcon icon={icon} />}
    <div className={tw`font-medium uppercase`}>{content}</div>
  </div>
);
