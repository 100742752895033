export const LOINC_ANALYTES = {
  a1c: ['17856-6', '4548-4', '4549-2'],
  ldl: ['18262-6', '13457-7', '2089-1', '39469-2', '46985-8'],
  cholesterol: ['2093-3', '48620-9', '35200-5'],
  hdl: ['2085-9', '2086-7', '49130-8', '9832-7'],
  triglycerides: ['2571-8', '12951-0', '3043-7', '14927-8', '3048-6', '3049-4'],
  gfr: [
    '48643-1',
    '33914-3',
    '77147-7',
    '50044-7',
    '70969-1',
    '48642-3',
    '88294-4',
    '88293-6',
    '62238-1',
    '69405-9',
  ],
};

export const LOINC_TREND_EXCLUSIONS = [
  '56850-1', // lab interpretation
];

export const LOINC_SUMMARY_OF_EPISODE_NOTE = '34133-9';
