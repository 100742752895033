import { EncounterModel, PatientModel } from './models';
import { SYSTEM_SUMMARY } from './system-urls';
import { getDocumentReferences } from '@ctw/shared/api/fhir/document';
import { fqsRequestAll, MAX_OBJECTS_PER_REQUEST } from '@ctw/shared/api/fqs/client';
import { EncounterGraphqlResponse, encountersQuery } from '@ctw/shared/api/fqs/queries/encounters';
import { dedupeAndMergeEncounters } from '@ctw/shared/content/encounters/helpers/filters';
import { GraphQLClient } from 'graphql-request';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export async function getEncountersWithClinicalNotes(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
) {
  const { data } = await fqsRequestAll<EncounterGraphqlResponse>(
    telemetry,
    graphqlClient,
    encountersQuery,
    'EncounterConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
      sort: {},
    },
  );

  const documents = await getDocumentReferences(telemetry, graphqlClient, patient, [
    MAX_OBJECTS_PER_REQUEST,
  ]);

  const results = data.EncounterConnection.edges
    .map((x) => x.node)
    .map((c) => new EncounterModel(c, c.ProvenanceList, undefined, c.BasicList));

  results.forEach((e) => e.setClinicalNotesFromDocumentPool(documents));

  return dedupeAndMergeEncounters(results);
}

export async function getEncountersWithClinicalNotesById(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  ids: string[],
) {
  const { data } = await fqsRequestAll<EncounterGraphqlResponse>(
    telemetry,
    graphqlClient,
    encountersQuery,
    'EncounterConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
      sort: {},
      filter: {
        ids: {
          anymatch: ids,
        },
      },
    },
  );

  const documents = await getDocumentReferences(telemetry, graphqlClient, patient, [
    MAX_OBJECTS_PER_REQUEST,
  ]);

  const results = data.EncounterConnection.edges
    .map((x) => x.node)
    .map((c) => new EncounterModel(c, c.ProvenanceList, undefined, c.BasicList));

  results.forEach((e) => e.setClinicalNotesFromDocumentPool(documents));

  return dedupeAndMergeEncounters(results);
}

// TODO: Remove this function once the Encounter lens is tested.
export function getNonLensEncountersFQS(limit: number, ids?: string[]) {
  return async (
    telemetry: ReturnType<typeof useTelemetry>,
    graphqlClient: GraphQLClient,
    patient: PatientModel,
  ) => {
    // Skip querying for an empty set of ids.
    // This fixes an issue with FQS where it is an error to do an anymatch with an empty array.
    if (ids !== undefined && ids.length === 0) {
      return [];
    }
    const { data } = await fqsRequestAll<EncounterGraphqlResponse>(
      telemetry,
      graphqlClient,
      encountersQuery,
      'EncounterConnection',
      {
        upid: patient.UPID,
        cursor: '',
        first: limit,
        // Optionally filter down to a specific set of encounters.
        filter: {
          ...(ids ?
            {
              ids: { anymatch: ids },
            }
          : {}),
          tag: { nonematch: [SYSTEM_SUMMARY] },
        },
      },
    );
    const results = data.EncounterConnection.edges
      .map((x) => x.node)
      .map((c) => new EncounterModel(c, c.ProvenanceList, undefined, c.BasicList));
    return dedupeAndMergeEncounters(results);
  };
}
