import { faInbox, faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RefreshIcon } from '../components/icons/refresh';
import { RequestRecordsButton } from '@ctw/shared/content/patient-history/request-records-button';
import { usePatientHistory } from '@ctw/shared/content/patient-history/use-patient-history';
import { tw } from '@ctw/shared/utils/tailwind';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import { formatFHIRDate } from '@ctw/shared/utils/dates';
import { useFeatureFlag } from '@ctw/shared/context/feature-flag-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export type EmptyTableProps = {
  resourceName: string;
  hasZeroFilteredRecords: boolean;
};

export const EmptyPatientTable = (props: EmptyTableProps) => {
  const patientHistory = usePatientHistory();
  const { patient } = usePatientContext();
  const hasPatientHistoryFormFeature = useFeatureFlag('ctw-patient-history-form');

  if (patientHistory.isLoading) {
    return <LoadingSpinner centered message="Loading..." />;
  }

  const isTestPatient = !!patient.isTestPatient;
  const { latestInProgress, latestCompleted, nextScheduledAt } = patientHistory.details;

  // If there was a previously completed or current in progress job, then we consider a request to have been made
  const requestMade = !!latestCompleted || !!latestInProgress;

  if (latestInProgress && !latestCompleted) {
    return (
      <div className={tw`space-y-3`}>
        <div className={tw`space-y-6`}>
          <div className={tw`flex justify-center`}>
            <LoadingSpinner iconClass={tw`h-12 w-12 text-content-icon`} />
          </div>
          <div className={tw`text-center text-xl font-medium`}>Retrieving records now</div>
        </div>
        <div className={tw`text-center text-base font-normal`}>
          Please re-load the ZAP in a few minutes.
        </div>
      </div>
    );
  }

  if (isTestPatient && !requestMade) {
    return (
      <div className={tw`text-center text-xl font-medium`}>
        This is a test patient with no records requested.
      </div>
    );
  }

  if (!requestMade) {
    return (
      <div className={tw`space-y-3`}>
        <div className={tw`space-y-6`}>
          <div className={tw`flex justify-center`}>
            <RefreshIcon className={tw`h-16 text-content-icon`} />
          </div>
          <div className={tw`text-center text-xl font-medium`}>
            {nextScheduledAt ?
              `Next scheduled request ${formatFHIRDate(nextScheduledAt.toISOString())}`
            : 'Records not requested yet'}
          </div>
        </div>
        {hasPatientHistoryFormFeature && (
          <div className={tw`flex justify-center`}>
            <RequestRecordsButton displayText="Request records now" skipModal />
          </div>
        )}
      </div>
    );
  }

  return <EmptyTableNoneFound {...props} />;
};

export function EmptyTableNoneFound({ resourceName, hasZeroFilteredRecords }: EmptyTableProps) {
  let icon = faInbox;
  let errorText = `No ${resourceName}`;
  let subText = "We didn't find any records for this patient.";
  const { trackInteraction } = useTelemetry();

  trackInteraction('empty_table');
  if (hasZeroFilteredRecords) {
    icon = faMagnifyingGlass;
    errorText = `No matching ${resourceName} found`;
    subText = 'Try changing the filters.';
  }
  return (
    <div className={tw`space-y-3`}>
      <div className={tw`space-y-6`}>
        <div className={tw`flex justify-center`}>
          <FontAwesomeIcon icon={icon} className={tw`h-16 text-content-icon`} />
        </div>
        <div className={tw`text-center text-xl font-medium`}>{errorText}</div>
      </div>
      <div className={tw`text-center text-base font-normal`}>{subText}</div>
    </div>
  );
}
