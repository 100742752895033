import { Reference } from 'fhir/r4';
import { CTWState } from '@ctw/shared/context/ctw-context';

// Use their email address as the display if no practitioner reference available.
export async function getUsersPractitionerReference(ctw: CTWState): Promise<Reference> {
  const { practitionerId, email } = ctw.requestContext.authTokenState;

  if (practitionerId) {
    return {
      reference: `Practitioner/${practitionerId}`,
      type: 'Practitioner',
    };
  }
  return {
    display: email,
  };
}
