import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { Tooltip } from '@ctw/shared/components/tooltip';
import { ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { isReactNode } from '@ctw/shared/utils/types';
import { Button } from '@ctw/shared/components/button';

export interface OverviewCardProps {
  title: string;
  children: ReactNode;
  testId?: string;
  className?: ClassName;
  loading?: boolean;
  empty?: boolean;
  fullbleed?: boolean;
  emptyStateMessage?: string;
  helpText?: string;
  headerIcon?: IconProp | ReactNode;
  footerCTA?:
    | {
        label: string;
        onClick: () => void;
      }
    | undefined;
}

export const OverviewCard = ({
  children,
  className,
  footerCTA,
  headerIcon,
  loading,
  empty = false,
  fullbleed,
  emptyStateMessage,
  title,
  helpText,
  testId,
}: OverviewCardProps) => (
  <section
    data-testid={testId}
    className={twx(
      'flex max-h-[360px] min-h-[175px] min-w-[320px] flex-col divide-y divide-solid divide-border-main rounded-lg border border-solid border-border-main shadow',
      className,
    )}
  >
    <div
      className={tw`flex w-full items-center justify-between space-x-2 rounded-t-lg px-3 py-2.5`}
    >
      {headerIcon && (
        <div>
          {isReactNode(headerIcon) ?
            headerIcon
          : <FontAwesomeIcon icon={headerIcon} className={tw`h-5 w-5 text-content-icon`} />}
        </div>
      )}
      <div
        data-testid="overview-card-header"
        className={tw`flex-auto text-base font-medium text-background-inverse`}
      >
        {title}
      </div>
      {helpText && (
        <div className={tw`self-start`}>
          <Tooltip content={helpText}>
            <FontAwesomeIcon icon={faInfoCircle} className={tw`block h-4 w-4 text-content-icon`} />
          </Tooltip>
        </div>
      )}
    </div>

    <div
      data-testid="overview-card-body"
      className={twx({
        'flex grow items-center justify-center px-3 py-2 text-center text-sm': loading || empty,
        'scrollbar flex w-full grow flex-col overflow-y-auto py-0 text-sm': !loading,
        'px-3 py-2': !fullbleed,
      })}
    >
      {loading ?
        <LoadingSpinner message="Loading..." />
      : empty && (emptyStateMessage ?? 'No data')}
      {!loading && !empty && children}
    </div>

    {footerCTA && !loading && !empty && (
      <Button
        testId="overview-card-footer"
        type="button"
        variant="unstyled"
        className={tw`group flex w-full cursor-pointer content-center justify-between rounded-b-lg bg-white px-3 py-2 text-left text-sm font-medium capitalize text-content-subtle hover:bg-background-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-main`}
        onClick={footerCTA.onClick}
      >
        {footerCTA.label}
        <FontAwesomeIcon
          icon={faAngleRight}
          className={tw`button-arrow h-5 w-5 text-content-icon opacity-0 group-hover:opacity-100`}
        />
      </Button>
    )}
  </section>
);
