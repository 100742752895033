import { fqsRequest, fqsRequestAll, MAX_OBJECTS_PER_REQUEST } from '@ctw/shared/api/fqs/client';
import { ObservationModel, PatientModel } from './models';
import { SYSTEM_LOINC, SYSTEM_SUMMARY } from './system-urls';
import {
  ObservationGraphqlResponse,
  observationQuery,
} from '@ctw/shared/api/fqs/queries/observations';
import { GraphQLClient } from 'graphql-request';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import { QUERY_KEY_PATIENT_OBSERVATIONS } from '@ctw/shared/utils/query-keys';
import { useState, useEffect } from 'react';
import { compact, uniq } from 'lodash-es';
import { LOINC_ANALYTES, LOINC_TREND_EXCLUSIONS } from '@ctw/shared/utils/loinc';

export function usePatientObservationsById(ids: string[]) {
  return usePatientQuery({
    queryId: QUERY_KEY_PATIENT_OBSERVATIONS,
    queryKey: [ids],
    queryFn: async ({ graphqlClient, telemetry, patient }) =>
      fetchObservationsById(telemetry, graphqlClient, patient, ids),
  });
}

export function usePatientObservationsByLoinc(loincCodes: string[], shouldFetch: boolean) {
  return usePatientQuery({
    queryId: QUERY_KEY_PATIENT_OBSERVATIONS,
    queryKey: [loincCodes],
    queryFn: async ({ graphqlClient, telemetry, patient }) => {
      if (loincCodes.length === 0) {
        return [];
      }
      return fetchObservationsByLoinc(telemetry, graphqlClient, patient, loincCodes);
    },
    enabled: shouldFetch,
  });
}

export function usePatientObservationsWithTrends(ids: string[]) {
  const observationsQuery = usePatientObservationsById(ids);
  const [observations, setObservations] = useState<ObservationModel[]>([]);

  // Get LOINC codes from observations and include related codes from LOINC_ANALYTES
  const loincCodes = uniq(
    compact(
      observationsQuery.data?.flatMap((obs) => {
        if (!obs.loincCode) return [];

        // Find all related LOINC codes that map to the same analyte
        const analyteEntry = Object.entries(LOINC_ANALYTES).find(
          ([_, codes]) => obs.loincCode && codes.includes(obs.loincCode),
        );

        if (!analyteEntry) return [obs.loincCode];
        return analyteEntry[1]; // Return all codes for this analyte
      }),
    ),
  );

  const trendObservationsQuery = usePatientObservationsByLoinc(
    loincCodes,
    (observationsQuery.data?.length ?? 0) > 0,
  );

  useEffect(() => {
    if (!observationsQuery.data?.length) {
      return;
    }

    const trendsByAnalyte = (trendObservationsQuery.data ?? []).reduce<
      Record<string, ObservationModel[]>
    >((acc, obs) => {
      if (!obs.loincCode) {
        return acc;
      }

      // Skip if LOINC code is in exclusions list
      if (LOINC_TREND_EXCLUSIONS.includes(obs.loincCode)) {
        return acc;
      }

      // Find which analyte this LOINC code belongs to (eg. A1c, LDL)
      const analyteEntry = Object.entries(LOINC_ANALYTES).find(
        ([_, codes]) => obs.loincCode && codes.includes(obs.loincCode),
      );

      // If no analyte match found, use the LOINC code itself as the key
      const key = analyteEntry ? analyteEntry[0] : obs.loincCode;
      acc[key] ??= [];

      // Check if we already have an observation with same date and value
      const isDuplicate = acc[key].some(
        (existing) =>
          existing.effectiveStartRaw === obs.effectiveStartRaw && existing.value === obs.value,
      );

      if (!isDuplicate) {
        acc[key].push(obs);
      }

      return acc;
    }, {});

    const observationsWithTrends = observationsQuery.data.map((obs) => {
      if (!obs.loincCode) {
        return obs;
      }

      // First try to find analyte match
      const analyteEntry = Object.entries(LOINC_ANALYTES).find(
        ([_, codes]) => obs.loincCode && codes.includes(obs.loincCode),
      );

      if (analyteEntry) {
        obs.trends = trendsByAnalyte[analyteEntry[0]];
      } else {
        // If no analyte match, look for direct LOINC code match
        obs.trends = trendsByAnalyte[obs.loincCode];
      }

      return obs;
    });

    setObservations(observationsWithTrends);
  }, [observationsQuery.data, trendObservationsQuery.data]);

  return {
    data: observations,
    isLoadingObservations: observationsQuery.isLoading,
    isLoadingTrends: trendObservationsQuery.isLoading,
    isError: observationsQuery.isError || trendObservationsQuery.isError,
  };
}

export async function fetchObservationsByLoinc(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  loincCodes: string[],
) {
  const codes = loincCodes.map((loincCode) => `${SYSTEM_LOINC}|${loincCode}`);
  const { data } = await fqsRequest<ObservationGraphqlResponse>(
    telemetry,
    graphqlClient,
    observationQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
      sort: {
        lastUpdated: 'DESC',
      },
      filter: {
        code: {
          anymatch: codes,
        },
      },
    },
  );

  const observationModels = data.ObservationConnection.edges.map(
    ({ node }) => new ObservationModel(node, node.ProvenanceList),
  );

  return observationModels.filter((vital) => vital.value !== '');
}

export async function fetchObservationsById(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  ids: string[],
) {
  const { data } = await fqsRequestAll<ObservationGraphqlResponse>(
    telemetry,
    graphqlClient,
    observationQuery,
    'ObservationConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
      filter: {
        ids: {
          anymatch: ids,
        },
        tag: { nonematch: [SYSTEM_SUMMARY] },
      },
    },
  );

  return data.ObservationConnection.edges.map(({ node }) => new ObservationModel(node));
}
