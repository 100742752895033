import { useRef } from 'react';
import { patientCareTeamColumns } from './helpers/columns';
import { careTeamSortOptions, defaultCareTeamSort } from './helpers/sorts';
import { getDateRangeView } from '../resource/helpers/view-date-range';
import { useResourceDetailsDrawer } from '../resource/resource-details-drawer';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { usePatientCareTeamMembers } from '@ctw/shared/api/fhir/care-team';
import { CareTeamPractitionerModel } from '@ctw/shared/api/fhir/models/careteam-practitioner';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { twx } from '@ctw/shared/utils/tailwind';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';

export interface PatientCareTeamProps {
  className?: string;
}

export const PatientCareTeam = withErrorBoundary({
  boundaryName: 'PatientCareTeam',
  includeTelemetryBoundary: true,
  Component: ({ className }: PatientCareTeamProps) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const patientCareTeamQuery = usePatientCareTeamMembers();
    const { viewOptions, allTime } =
      getDateRangeView<CareTeamPractitionerModel>('effectiveStartDate');

    const { data, setSort, setViewOption, defaultSort, defaultView } = useFilteredSortedData({
      cacheKey: 'patient-careteam',
      defaultSort: defaultCareTeamSort,
      viewOptions,
      records: patientCareTeamQuery.data,
      defaultView: allTime.display,
    });
    const { requestContext } = useCTW();

    const openDetails = useResourceDetailsDrawer({
      header: (m) => m.practitionerName,
      details: careTeamData,
      deepLinkResourceType: 'Practitioner',
    });

    const isEmptyQuery = patientCareTeamQuery.data && patientCareTeamQuery.data.length === 0;
    const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

    return (
      <div ref={containerRef} className={twx(className, 'scrollable-pass-through-height')}>
        <ResourceTableActions
          sortOptions={{
            defaultSort,
            options: careTeamSortOptions,
            onChange: setSort,
          }}
          viewOptions={{
            onChange: setViewOption,
            options: viewOptions,
            defaultView,
          }}
        />
        <ResourceTable
          isLoading={patientCareTeamQuery.isLoading}
          data={data}
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="careteam"
            />
          }
          columns={patientCareTeamColumns(requestContext.builderId)}
          onRowClick={(model) => openDetails({ prefetchedModel: model })}
        />
      </div>
    );
  },
});

export const careTeamData = (careTeamPractitioner: CareTeamPractitionerModel) => [
  { label: 'Organization', value: careTeamPractitioner.managingOrganization },
  { label: 'Care Team', value: careTeamPractitioner.careTeam.categoryDisplay },
  { label: 'Phone', value: careTeamPractitioner.careTeam.phone },
  { label: 'Role', value: careTeamPractitioner.role },
  { label: 'Specialty', value: careTeamPractitioner.specialty },
  { label: 'Member Since', value: careTeamPractitioner.effectiveStartDate },
];
