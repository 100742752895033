import { Env } from '@ctw/shared/context/types';
import { entries } from 'lodash-es';

const envPatternMappings: Record<Env, RegExp[]> = {
  dev: [/^([a-z]+)\.dev\.(zushealth|zusapi)\.com$/i, /localhost/i, /127\.0\.0\.1/i],
  phitest: [/^([a-z]+)\.phitest\.(zushealth|zusapi)\.com$/i],
  sandbox: [/^([a-z]+)\.sandbox\.(zushealth|zusapi)\.com$/i],
  prod: [/^([a-z]+)\.(zushealth|zusapi)\.com$/i],
} as const;

export function getPageEnv(): Env {
  const { hostname } = window.location;

  for (const [env, patterns] of entries(envPatternMappings)) {
    for (const pattern of patterns) {
      if (pattern.test(hostname)) {
        return env as Env;
      }
    }
  }

  return 'dev';
}
