import { ReactJason } from 'react-jason';
import github from 'react-jason/themes/github';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';
import './view-fhir.scss';
import { faFire } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '@ctw/shared/components/modal';
import { Heading } from '@ctw/shared/components/heading';
import { useModal } from '@ctw/shared/context/modal-hooks';

export type ViewFHIRProps = {
  name?: string;
  resource: fhir4.Resource;
};

export function useFHIRDrawer() {
  const { openModal } = useModal();

  return (name: string, resource: fhir4.Resource) => {
    openModal({
      component: (props) => <FHIRModal name={name} resource={resource} {...props} />,
      trackingMetadata: { action: 'view_fhir' },
    });
  };
}

export function ViewFHIR({ name = 'View FHIR', resource }: ViewFHIRProps) {
  const openDrawer = useFHIRDrawer();

  return (
    <Button
      type="button"
      ariaLabel={name}
      variant="secondary"
      onClick={() => {
        openDrawer(name, resource);
      }}
    >
      <span className={tw`relative inline-block w-4 pr-4 align-middle`}>
        <FontAwesomeIcon icon={faFire} className={tw`absolute -top-2.5 left-0 h-4`} />
      </span>
    </Button>
  );
}

interface FHIRModalProps {
  name: string;
  className?: string;
  resource: fhir4.Resource;
  isOpen: boolean;
  onClose: () => void;
}

function FHIRModal({ resource, isOpen, onClose, className, name }: FHIRModalProps) {
  return (
    <Modal className={twx(className, 'view-fhir max-w-5xl')} isOpen={isOpen} onClose={onClose}>
      <Heading level="h2" className={tw`text-lg`}>
        {name}
      </Heading>
      <div className={tw`react-json-pre-wrapper h-full text-sm`}>
        <ReactJason value={resource} theme={github} />
      </div>
    </Modal>
  );
}
