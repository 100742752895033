import { twx } from '@ctw/shared/utils/tailwind';

export type BubbleIconProps = {
  interpretation?: string;
  result: string | number;
  className?: string;
  tooltip?: string;
};

export const BubbleIcon = ({ className, result, interpretation, tooltip }: BubbleIconProps) =>
  interpretation ?
    <div className={twx(className)} title={tooltip}>
      {result} - {interpretation}
    </div>
  : <div className={twx(className)} title={tooltip}>
      {result}
    </div>;
