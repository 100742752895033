import { DefinedQueryObserverResult, QueryObserverLoadingErrorResult } from '@tanstack/react-query';
import { useContext } from 'react';
import { ZAPTabName } from './zus-aggregated-profile/zus-aggregated-profile';
import { PrototypeLabel } from '../components/prototype-label';
import { usePatientContext } from '../context/patient-provider';
import { ZapTabStateContext } from '../context/zap-tab-state-context';
import { useDataIndicators } from '@ctw/shared/api/fhir/data-indicator';
import { DataIndicatorGraphqlResponse } from '@ctw/shared/api/fqs/queries/data-indicator';
import { twx } from '@ctw/shared/utils/tailwind';

export type ResourceHasData = {
  className?: string;
  resource: ZAPTabName;
  text?: string;
  isBeta?: boolean;
};

export const DisplayCorrectTabLabel = ({ resource, text, isBeta }: ResourceHasData) => {
  const recordsMap = useHasDataRecordsByResource();
  const hasData = recordsMap.get(resource);

  return (
    <>
      <span
        className={twx(`capitalize text-black`, {
          'text-content-icon': !hasData,
        })}
      >
        {text}
      </span>
      {isBeta && <PrototypeLabel stage="beta" deemphasized={!hasData} />}
    </>
  );
};

// Maps whether each ZAP resource has any unread notifications
export function useHasDataRecordsByResource(resources?: ZAPTabName[]): Map<ZAPTabName, boolean> {
  const numRecordsToLookForData = 1;
  usePatientContext(); // Require patient context
  const { tabOrder } = useContext(ZapTabStateContext);
  const finalResources = resources || tabOrder;
  const query = useDataIndicators(
    numRecordsToLookForData,
    finalResources.length > 0,
    finalResources,
  );

  const map = new Map<ZAPTabName, boolean>();
  // Set all tabs to true by default so that when it is loading it will
  // prompt the user to think that there is data.
  // This is because we never want to incorrectly show that there is not data.
  finalResources.forEach((zapTabName) => {
    map.set(zapTabName, true);
  });

  if (!query.isLoading) {
    finalResources.forEach((resource) => {
      const data = handleResourceType(
        resource,
        query as DefinedQueryObserverResult<DataIndicatorGraphqlResponse, unknown>,
      );
      // Check if there data is available
      if (data && data.length < 1) {
        map.set(resource, false);
      }
    });
  }

  return map;
}

const handleResourceType = (
  resource: ZAPTabName,
  query:
    | DefinedQueryObserverResult<DataIndicatorGraphqlResponse, unknown>
    | QueryObserverLoadingErrorResult<DataIndicatorGraphqlResponse, unknown>,
) => {
  switch (resource) {
    case 'conditions-all':
      return query.data?.ConditionConnection.edges;
    case 'allergies':
      return query.data?.AllergyIntoleranceConnection.edges;
    case 'diagnostic-reports':
      return query.data?.DiagnosticReportConnection.edges;
    case 'documents':
      return query.data?.DocumentReferenceConnection.edges;
    case 'demographics':
      return query.data?.PatientConnection.edges;
    case 'referrals':
      return query.data?.ServiceRequestConnection.edges;
    case 'encounters':
      return query.data?.EncounterConnection.edges;
    case 'immunizations':
      return query.data?.ImmunizationConnection.edges;
    // Only gotcha here is that we don't filter out MedicationAdministration
    case 'medications-all':
      return query.data?.MedicationStatementConnection.edges;
    case 'vitals':
      return query.data?.ObservationConnection.edges;
    case 'care-team':
      return query.data?.CareTeamConnection.edges;
    default:
      return [];
  }
};
