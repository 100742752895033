import { DiagnosticReportModel } from '@ctw/shared/api/fhir/models';
import { TableColumn } from '@ctw/shared/components/table/table';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '@ctw/shared/content/resource/helpers/resource-title-column';
import { CopyToClipboard } from '@ctw/shared/components/copy-to-clipboard';

export const patientDiagnosticReportsColumns = (
  builderId: string,
): TableColumn<DiagnosticReportModel>[] => [
  {
    title: 'Diagnostic',
    widthPercent: 40,
    minWidth: 200,
    render: (diagnostic) => (
      <ResourceTitleColumn
        title={<CopyToClipboard>{diagnostic.displayName}</CopyToClipboard>}
        renderIcon={RenderSyncedWithRecordIcon(diagnostic.ownedByBuilder(builderId))}
        capitalizeTitle={false}
      />
    ),
  },
  {
    title: 'Date',
    dataIndex: 'dateDisplay',
    widthPercent: 20,
    minWidth: 128,
  },
  {
    title: 'Details',
    dataIndex: 'details',
    widthPercent: 39,
    minWidth: 200,
    className: 'details-cell',
  },
];
