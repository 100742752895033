import { sleep } from './sleep';

export const retryWithExponentialBackoff = async <T>(
  fn: () => Promise<T>,
  retries: number,
  baseDelay: number,
  attempt = 1,
): Promise<T> => {
  try {
    return await fn();
  } catch (error) {
    if (attempt > retries) {
      throw error;
    }
    await sleep(baseDelay * 2 ** attempt);
    return retryWithExponentialBackoff(fn, retries, baseDelay, attempt + 1);
  }
};
