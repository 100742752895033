/* eslint-disable react/no-array-index-key */
import { twx } from '@ctw/shared/utils/tailwind';

type TruncatedListOrientation = 'vertical' | 'horizontal';

export interface TruncatedListProps {
  className?: string;
  items: string[];
  limit: number;
  orientation: TruncatedListOrientation;
}

export const TruncatedList = ({ className, items, limit, orientation }: TruncatedListProps) => {
  const total = items.length;
  const displayCount = Math.min(limit, items.length);
  const paginatedItems = items.slice(0, displayCount);

  return (
    <ul
      className={twx('block', { 'flex flex-wrap gap-1': orientation === 'horizontal' }, className)}
    >
      {paginatedItems.map((item, index) => (
        <li
          key={item + index}
          data-after={index + 1 < displayCount ? ', ' : ''}
          className={twx('block break-keep', {
            'relative whitespace-nowrap after:content-[attr(data-after)]':
              orientation === 'horizontal',
          })}
        >
          {item}
        </li>
      ))}
      {total > limit && (
        <li
          className={twx('block font-medium', {
            'min-w-full flex-1': orientation === 'horizontal',
          })}
        >
          <span aria-hidden="true">&#65291;</span> {total - displayCount} more
        </li>
      )}
    </ul>
  );
};
