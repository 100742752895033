import { useAddMedicationForm } from './helpers/add-new-med-drawer';
import { medicationFilters } from './helpers/filters';
import { PatientMedicationsBase } from './helpers/patient-medications-base';
import { useToggleDismiss } from '../hooks/use-toggle-dismiss';
import { getDateRangeView } from '../resource/helpers/view-date-range';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { useQueryAllPatientMedications } from '@ctw/shared/hooks/use-medications';
import { useBaseTranslations } from '@ctw/shared/utils/i18n';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { RowActionsConfigProp } from '@ctw/shared/components/table/table';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useMemo } from 'react';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export interface PatientMedicationsOutsideProps {
  className?: string;
  onOpenHistoryDrawer?: () => void;
  onAddToRecord?: (record: MedicationStatementModel) => Promise<void> | void;
}

export const PatientMedicationsOutside = withErrorBoundary({
  boundaryName: 'PatientMedicationsOutside',
  includeTelemetryBoundary: true,
  Component: ({
    className,
    onAddToRecord,
    onOpenHistoryDrawer,
  }: PatientMedicationsOutsideProps) => {
    const { otherProviderMedications, isLoading } = useQueryAllPatientMedications();
    const { isWritebackEnabled } = useCTW();
    const rowActions = useRowActions((record) => {
      if (onAddToRecord) {
        void onAddToRecord(record);
      }
    });
    const { viewOptions, past6Months } =
      getDateRangeView<MedicationStatementModel>('lastActivityDate');
    const outsideMedicationWritebackEnabled = useMemo(
      () => isWritebackEnabled('medications-outside'),
      [isWritebackEnabled],
    );

    return (
      <PatientMedicationsBase
        className={twx(className)}
        query={{ data: otherProviderMedications, isLoading }}
        filters={medicationFilters(otherProviderMedications, true)}
        rowActions={outsideMedicationWritebackEnabled ? rowActions : undefined}
        views={viewOptions}
        defaultView={past6Months}
        onOpenHistoryDrawer={onOpenHistoryDrawer}
      />
    );
  },
});

function useRowActions(onAddToRecord?: (record: MedicationStatementModel) => void) {
  const { t } = useBaseTranslations();
  const { trackInteraction } = useTelemetry();
  const showAddMedicationForm = useAddMedicationForm();
  const { isLoading, toggleDismiss: toggleArchive } = useToggleDismiss();

  return (record: MedicationStatementModel): RowActionsConfigProp<MedicationStatementModel> => {
    const archiveLabel =
      record.isDismissed ? t('resourceTable.restore') : t('resourceTable.dismiss');
    return [
      {
        text: archiveLabel,
        className:
          'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm border border-solid border-border-main bg-white capitalize text-content-subtle hover:bg-background-hover',
        disabled: isLoading,
        onClick: async () => {
          await toggleArchive(record);
        },
        render() {
          return (
            <div className={tw`flex`}>
              {isLoading && <LoadingSpinner className={tw`mx-4 align-middle`} />}
              {!isLoading && archiveLabel}
            </div>
          );
        },
      },
      {
        text: t('resourceTable.add'),
        className:
          'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm btn bg-primary-main text-white hover:bg-primary-text ml-1 capitalize',
        testId: 'add-to-record',
        disabled: isLoading,
        onClick: () => {
          if (onAddToRecord) {
            onAddToRecord(record);
          } else {
            showAddMedicationForm(record);
          }
          trackInteraction('add_to_record');
        },
      },
    ];
  };
}
