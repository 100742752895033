/* eslint-disable no-restricted-imports */

import clsx, { ClassValue } from 'clsx';
import { type Config, extendTailwindMerge, mergeConfigs, validators } from 'tailwind-merge';

export type ClassName = ClassValue;

// Vendor the merge plugin for tailwind-extended-shadows because the package is busted
// https://github.com/kaelansmith/tailwind-extended-shadows-merge/tree/main/src
type TwExtendedShadowsMergeGroupIds =
  | 'extendedShadows.offset-x'
  | 'extendedShadows.offset-y'
  | 'extendedShadows.blur'
  | 'extendedShadows.spread'
  | 'extendedShadows.opacity'
  | 'extendedShadows.shadows'
  | 'extendedShadows.shadows-scale'
  | 'extendedShadows.shadows-ease';

const withExtendedShadows = (
  prevConfig: Config<TwExtendedShadowsMergeGroupIds, string>,
): Config<TwExtendedShadowsMergeGroupIds, string> =>
  mergeConfigs<TwExtendedShadowsMergeGroupIds>(prevConfig, {
    extend: {
      classGroups: {
        // x-axis shadow offsets
        'extendedShadows.offset-x': [
          {
            'shadow-x': ['px', validators.isNumber, validators.isArbitraryLength],
          },
        ],
        // y-axis shadow offsets
        'extendedShadows.offset-y': [
          {
            'shadow-y': ['px', validators.isNumber, validators.isArbitraryLength],
          },
        ],
        // shadow blur
        'extendedShadows.blur': [
          {
            'shadow-blur': ['px', validators.isNumber, validators.isArbitraryLength],
          },
        ],
        // shadow spread
        'extendedShadows.spread': [
          {
            'shadow-spread': ['px', validators.isNumber, validators.isArbitraryLength],
          },
        ],
        // shadow opacity
        'extendedShadows.opacity': [
          {
            'shadow-opacity': [validators.isInteger, validators.isArbitraryNumber],
          },
        ],
        // shadows (layers)
        'extendedShadows.shadows': [
          {
            shadows: [validators.isInteger],
          },
        ],
        // shadows scale multiplier
        'extendedShadows.shadows-scale': [
          {
            'shadows-scale': [validators.isNumber],
          },
        ],
        // shadows easings
        'extendedShadows.shadows-ease': [
          {
            'shadows-ease': ['in', 'out'],
          },
        ],
      },
    },
  });

const twMerge = extendTailwindMerge(withExtendedShadows);

const identity = (strings: TemplateStringsArray, ...values: unknown[]): string =>
  String.raw({ raw: strings }, ...values);

export const tw = (strings: TemplateStringsArray, ...values: unknown[]): string =>
  twMerge(clsx(identity(strings, ...values)));

export const twx = (...classLists: ClassValue[]): string => twMerge(clsx(...classLists));
