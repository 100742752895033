import { OverviewCard } from '../../components/containers/overview-card';
import { PatientSubscriptionPackage } from './patient-subscription-package';
import { PatientHistoryWithAction } from '../patient-history/patient-history-with-action';
import { SupportFormLink } from '../support-form-link';
import ZusSVG from '@ctw/shared/assets/zus-logo.svg';
import { usePatientHistory } from '@ctw/shared/content/patient-history/use-patient-history';
import { tw } from '@ctw/shared/utils/tailwind';
import { Link } from '@ctw/shared/components/link';
import { withOverviewCardErrorBoundary } from '@ctw/shared/content/overview/with-overview-card-error-boundary';

export interface ZusInformationCardProps {
  hideRequestRecords?: boolean;
}

export const ZusInformationCard = withOverviewCardErrorBoundary({
  cardTitle: 'Records Provided by Zus Health',
  boundaryName: 'ZusInformationCardComponent',
  Component: ({ hideRequestRecords = false }: ZusInformationCardProps) => {
    const patientHistory = usePatientHistory();

    return (
      <OverviewCard
        headerIcon={
          <img
            src={typeof ZusSVG === 'string' ? ZusSVG : (ZusSVG as { src: string }).src}
            alt="Zus"
            className={tw`h-5`}
          />
        }
        title="Records Provided by Zus Health"
        loading={patientHistory.isLoading}
        testId="zus-information-card"
        empty={patientHistory.isError}
        emptyStateMessage="Unable to load patient history information"
      >
        <div className={tw`space-y-2`}>
          <div className={tw`space-y-1.5 px-3`}>
            <PatientSubscriptionPackage />
            <PatientHistoryWithAction hideRequestRecords={hideRequestRecords} />
          </div>

          <div className={tw`h-px bg-background-subtle`} />

          <div className={tw`space-y-1.5 px-3 font-medium`}>
            <div className={tw`flex justify-between`}>
              <div>Have feedback or need support?</div>
              <SupportFormLink
                buttonText="Let us know"
                className={tw`link block whitespace-nowrap text-sm`}
              />
            </div>

            <div className={tw`flex justify-between`}>
              <div>Learn more about the ZAP</div>
              <Link
                href="https://clinicalguide.zushealth.com/docs/getting-started"
                className={tw`link block whitespace-nowrap text-sm`}
                target="_blank"
              >
                Clinical Guide
              </Link>
            </div>
          </div>
        </div>
      </OverviewCard>
    );
  },
});
