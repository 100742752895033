import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import { DetailsCard, DetailsProps } from '@ctw/shared/content/resource/helpers/details-card';
import { useAdditionalResourceActions } from '@ctw/shared/content/resource/use-additional-resource-actions';
import { Drawer } from '@ctw/shared/components/drawer';
import { TrackingMetadata } from '@ctw/shared/context/telemetry/tracking-metadata';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { useDrawer } from '@ctw/shared/context/drawer-hooks';
import { usePatientContextIfAvailable } from '@ctw/shared/context/patient-provider';
import { useBinaryId } from '@ctw/shared/api/fhir/binaries';
import { DocumentModel, PatientModel } from '@ctw/shared/api/fhir/models';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';
import { faInbox } from '@fortawesome/pro-solid-svg-icons';
import { ContentError } from '@ctw/shared/components/errors/content-error';
import { SupportFormLink } from '@ctw/shared/content/support-form-link';
import { RowActionsConfigProp } from '@ctw/shared/components/table/table';
import { useBreakpointQuery } from '@ctw/shared/hooks/breakpoints';
import { useFetchResource } from '@ctw/shared/hooks/use-fetch-resource';
import { Resource } from 'fhir/r4';
import { MergeExclusive } from 'type-fest';
import { nextJsRouterSafePushState } from '@ctw/shared/utils/history';
import { urlWithParameters } from '@ctw/shared/utils/url';

type DeepLinkResourceType =
  | 'Condition'
  | 'Immunization'
  | 'AllergyIntolerance'
  | 'Encounter'
  | 'DiagnosticReport'
  | 'ServiceRequest'
  | 'MedicationStatement'
  | 'Practitioner'
  | 'DocumentReference'
  | 'Measure';

interface UseResourceDetailsDrawerProps<
  TResource extends Resource,
  TModel extends FHIRModel<TResource>,
> extends Pick<
    ResourceDetailsDrawerProps<TResource, TModel>,
    | 'header'
    | 'subHeader'
    | 'getSourceDocument'
    | 'details'
    | 'rowActions'
    | 'enableDismissAndReadActions'
    | 'RenderChild'
    | 'renderResourceActions'
    | 'onOpen'
    | 'onAfterClosed'
  > {
  deepLinkResourceType?: DeepLinkResourceType;
}

export interface OpenDrawerWithPrefetchedModelProps<
  TResource extends Resource,
  TModel extends FHIRModel<TResource>,
> {
  prefetchedModel: TModel;
  trackingMetadata?: TrackingMetadata;
  animateOpen?: boolean;
  animateClose?: boolean;
}

export interface OpenDrawerWithResourceTypeAndIdentifierProps {
  resourceId: string;
  resourceType: DeepLinkResourceType;
  trackingMetadata?: TrackingMetadata;
  animateOpen?: boolean;
  animateClose?: boolean;
}

export type OpenDrawerWithResourceProps<
  TResource extends Resource,
  TModel extends FHIRModel<TResource>,
> = MergeExclusive<
  OpenDrawerWithPrefetchedModelProps<TResource, TModel>,
  OpenDrawerWithResourceTypeAndIdentifierProps
>;

export function useResourceDetailsDrawer<
  TResource extends Resource,
  TModel extends FHIRModel<TResource>,
>({
  deepLinkResourceType,
  onOpen,
  onAfterClosed,
  ...hookProps
}: UseResourceDetailsDrawerProps<TResource, TModel>) {
  const { openDrawer, isOpen } = useDrawer();
  const [isOpening, setIsOpening] = useState(false);

  const openDrawerWithResource = useCallback(
    ({
      prefetchedModel,
      resourceId,
      resourceType,
      trackingMetadata,
      animateOpen,
      animateClose,
    }: OpenDrawerWithResourceProps<TResource, TModel>) => {
      setIsOpening(true);
      const canonicalResourceType = resourceType ?? prefetchedModel.resourceType;
      const canonicalResourceId = resourceId ?? prefetchedModel.id;

      const uniqueKey = `${canonicalResourceId}-${Date.now()}`;

      openDrawer({
        component: (drawerProps) => (
          <ResourceDetailsDrawer
            key={uniqueKey}
            prefetchedModel={prefetchedModel}
            resourceId={resourceId}
            resourceType={resourceType}
            onAfterClosed={onAfterClosed}
            {...hookProps}
            {...drawerProps}
            onOpen={(openModel) => {
              if (canonicalResourceType === deepLinkResourceType && canonicalResourceId) {
                setTimeout(
                  () =>
                    nextJsRouterSafePushState(
                      urlWithParameters(window.location.href, {
                        drawerResourceType: canonicalResourceType,
                        drawerResourceId: canonicalResourceId,
                      }),
                    ),
                  300,
                );
              }

              onOpen?.(openModel);
            }}
            onClose={() => {
              if (deepLinkResourceType) {
                setTimeout(
                  () =>
                    nextJsRouterSafePushState(
                      urlWithParameters(window.location.href, {
                        drawerResourceType: null,
                        drawerResourceId: null,
                      }),
                    ),
                  300,
                );
              }

              drawerProps.onClose();
              onAfterClosed?.();
            }}
          />
        ),
        animateOpen,
        animateClose,
        trackingMetadata: {
          resourceType: prefetchedModel?.resourceType,
          ...trackingMetadata,
        },
      });
    },
    [deepLinkResourceType, hookProps, onAfterClosed, onOpen, openDrawer],
  );

  useEffect(() => {
    if (!isOpen && !isOpening && deepLinkResourceType) {
      const initialUrl = new URL(window.location.href);
      const initialResourceType = initialUrl.searchParams.get(
        'drawerResourceType',
      ) as DeepLinkResourceType | null;
      const initialResourceId = initialUrl.searchParams.get('drawerResourceId');

      if (initialResourceType === deepLinkResourceType && initialResourceId !== null) {
        openDrawerWithResource({
          animateOpen: false,
          resourceType: initialResourceType,
          resourceId: initialResourceId,
        });
      }
    }
  }, [deepLinkResourceType, isOpen, isOpening, openDrawerWithResource]);

  return openDrawerWithResource;
}

type ResourceDetailsDrawerProps<TResource extends Resource, TModel extends FHIRModel<TResource>> = {
  className?: string;
  details: (model: TModel) => DetailsProps['details'];
  getSourceDocument?: boolean;
  header: (model: TModel) => ReactNode;
  isOpen: boolean;
  onOpen?: (model: TModel) => void;
  prefetchedModel?: TModel;
  onClose: () => void;
  onAfterClosed?: () => void;
  rowActions?: (model: TModel) => RowActionsConfigProp<TModel>;
  enableDismissAndReadActions?: boolean;
  subHeader?: (model: TModel) => ReactNode;
  RenderChild?: (props: { model: TModel }) => ReactNode;
  renderResourceActions?: (model: TModel) => ReactNode;
  resourceType?: DeepLinkResourceType;
  resourceId?: string;
};

function ResourceDetailsDrawer<TResource extends Resource, TModel extends FHIRModel<TResource>>({
  className,
  details,
  getSourceDocument,
  header,
  isOpen,
  prefetchedModel,
  onClose,
  onAfterClosed,
  rowActions,
  enableDismissAndReadActions,
  subHeader,
  RenderChild,
  onOpen,
  resourceId,
  resourceType,
  renderResourceActions,
}: ResourceDetailsDrawerProps<TResource, TModel>) {
  const isSmallScreen = useBreakpointQuery().breakpoints.isAtMost.sm;
  const patientContext = usePatientContextIfAvailable();

  const patient = useMemo(
    () =>
      patientContext?.patient ??
      (prefetchedModel as unknown as Record<'patient', PatientModel | undefined> | undefined)
        ?.patient,
    [prefetchedModel, patientContext?.patient],
  );

  if (!patient) {
    throw new Error('Unable to resolve patient');
  }

  const [model, setModel] = useState<TModel | undefined>(prefetchedModel);

  const fetchResourceQuery = useFetchResource<TResource, TModel>({
    resourceId: resourceId ?? '',
    resourceType: resourceType ?? '',
    patient,
    enabled: prefetchedModel === undefined && model === undefined,
  });
  const [loading, setLoading] = useState(model === undefined);

  const rowActionsWithAdditions = useAdditionalResourceActions({
    rowActions,
    enableDismissAndReadActions,
    isInFooter: true,
  });

  useEffect(() => {
    if (!fetchResourceQuery.isLoading && fetchResourceQuery.data && !model) {
      setModel(fetchResourceQuery.data);
      setLoading(false);
    }
  }, [fetchResourceQuery, model]);

  const actions = useMemo(() => {
    if (model) {
      // We call rowActions right away so we'll know if it returns null and thus we should
      // hide our footer.
      return rowActionsWithAdditions({
        record: model,
        onSuccess: onClose,
        stacked: isSmallScreen,
      });
    }
    return null;
  }, [isSmallScreen, onClose, model, rowActionsWithAdditions]);

  useEffect(() => {
    if (model) {
      onOpen?.(model);
    }
  }, [onOpen, model]);

  return (
    <Drawer
      className={twx(className)}
      isOpen={isOpen}
      onClose={onClose}
      onAfterClosed={onAfterClosed}
      renderHeader={() => model?.resourceTypeTitle ?? resourceType ?? ''}
      renderFooter={() => (
        <div className={tw`flex justify-between gap-4`}>
          <Button
            type="button"
            variant="link"
            className={tw`!px-4 !py-2`}
            onClick={() => onClose()}
          >
            Close
          </Button>
          {model && renderResourceActions && (
            <div className={tw`flex flex-1 items-center justify-end`}>
              {renderResourceActions(model)}
            </div>
          )}
          {actions}
        </div>
      )}
    >
      <ResourceDetailsDrawerBody
        model={model}
        isError={fetchResourceQuery.isError}
        getSourceDocument={getSourceDocument}
        isLoading={loading}
        details={details}
        header={header}
        subHeader={subHeader}
        RenderChild={RenderChild}
        onClose={onClose}
      />
    </Drawer>
  );
}

type ResourceDetailsDrawerBodyProps<T extends Resource, M extends FHIRModel<T>> = {
  model?: M;
  getSourceDocument?: boolean;
  isLoading: boolean;
  isError: boolean;
  details: (model: M) => DetailsProps['details'];
  header: (model: M) => ReactNode;
  subHeader?: (model: M) => ReactNode;
  RenderChild?: (props: { model: M }) => ReactNode;
  onClose: () => void;
};

function ResourceDetailsDrawerBody<T extends Resource, M extends FHIRModel<T>>({
  model,
  getSourceDocument,
  isLoading,
  isError,
  details,
  header,
  subHeader,
  RenderChild,
  onClose,
}: ResourceDetailsDrawerBodyProps<T, M>) {
  const shouldFetchBinaryDocument = !!getSourceDocument || !!model;
  const binaryFetch = useBinaryId(model, getSourceDocument, shouldFetchBinaryDocument);

  const renderedChild = useMemo(() => {
    if (model && RenderChild) {
      return <RenderChild model={model} />;
    }

    return undefined;
  }, [RenderChild, model]);

  if (isError) {
    return (
      <div className={tw`flex flex-col items-center space-y-6`}>
        <ContentError title="An unexpected error occurred">
          <span>If this problem persists </span>
          <SupportFormLink buttonText="contact support" className={tw`link text-base`} />.
        </ContentError>
        <Button type="button" variant="primary" className={tw`w-fit`} onClick={() => onClose()}>
          Close
        </Button>
      </div>
    );
  }

  if (isLoading) {
    return <LoadingSpinner message="Loading content..." centered iconClass="w-5 h-5" />;
  }

  if (!model) {
    return (
      <div className={tw`flex flex-col items-center space-y-6`}>
        <ContentError
          icon={faInbox}
          title="No record found"
          message="Check the link and try again."
        >
          <span>If this problem persists </span>
          <SupportFormLink buttonText="contact support" className={tw`link text-base`} />.
        </ContentError>
        <Button type="button" variant="primary" className={tw`w-fit`} onClick={() => onClose()}>
          Close
        </Button>
      </div>
    );
  }

  return (
    <div className={tw`space-y-6`}>
      <div className={tw`space-y-2`}>
        <div data-testid="details-drawer-header" className={tw`text-2xl sm:text-3xl`}>
          {header(model)}
        </div>
        {subHeader && <div>{subHeader(model)}</div>}
      </div>

      {binaryFetch.isLoading && !shouldFetchBinaryDocument ?
        <LoadingSpinner message="Loading content..." centered iconClass="w-5 h-5" />
      : <DetailsCard
          details={details(model)}
          documentButton={
            !(model instanceof DocumentModel) || !binaryFetch.data ?
              undefined
            : <ResponsiveSourceDocumentLink
                contentType={model instanceof DocumentModel ? model.contentType : ''}
                binaryId={binaryFetch.data}
                forceInModal={model instanceof DocumentModel && !model.contentType?.includes('xml')}
                documentTitle={model.resourceTypeTitle}
                telemetryTarget="resource_details_drawer"
              />
          }
        />
      }
      {renderedChild}
    </div>
  );
}
