import React from 'react';
import { tw } from '@ctw/shared/utils/tailwind';
import { CareGapModel, CareGapStatus } from '@ctw/shared/api/fhir/models/care-gap';
import { DiagnosticReportModel } from '@ctw/shared/api/fhir/models';
import { RenderSyncedWithRecordIcon } from '@ctw/shared/content/resource/helpers/resource-title-column';
import { Button } from '@ctw/shared/components/button';
import { useDiagnosticReportDetailsDrawer } from '@ctw/shared/content/diagnostic-reports/helpers/drawer';
import { useCTW } from '@ctw/shared/context/ctw-context';

type ActivityHistoryProps = {
  careGap: CareGapModel;
};

type ActivityHistoryListProps = {
  careGap: CareGapModel;
  evidence?: Partial<Record<string, DiagnosticReportModel[]>>;
  openDiagnosticReportDrawer: (options: { prefetchedModel: DiagnosticReportModel }) => void;
  builderId: string;
};

export function ActivityHistory({ careGap }: ActivityHistoryProps) {
  const openDiagnosticReportDrawer = useDiagnosticReportDetailsDrawer();
  const { requestContext } = useCTW();

  // Gather evidence for all measure reports
  const evidence = careGap.measureReports.reduce(
    (acc, measureReport) => {
      acc[measureReport.id as string] = careGap.getEvidenceForSpecificReport(
        measureReport.id as string,
      );
      return acc;
    },
    {} as Record<string, DiagnosticReportModel[]>,
  );

  return (
    <ActivityHistoryList
      careGap={careGap}
      evidence={evidence}
      openDiagnosticReportDrawer={openDiagnosticReportDrawer}
      builderId={requestContext.builderId}
    />
  );
}

export function ActivityHistoryList({
  careGap,
  evidence,
  openDiagnosticReportDrawer,
  builderId,
}: ActivityHistoryListProps) {
  const evidenceLink = (diagnostic: DiagnosticReportModel) => {
    const isSyncedWithEhr = diagnostic.ownedByBuilder(builderId);
    return (
      <div className={tw`flex flex-row gap-1 pt-1`}>
        {RenderSyncedWithRecordIcon(isSyncedWithEhr, false)}
        <Button
          type="button"
          variant="link"
          className={tw`text-background-reversed w-fit text-left text-xs hover:underline`}
          onClick={() => {
            openDiagnosticReportDrawer({
              prefetchedModel: diagnostic,
            });
          }}
        >
          Diagnostic: {diagnostic.displayName}
        </Button>
      </div>
    );
  };

  const activityStatus = (gap: CareGapModel, prevStatus: CareGapStatus | undefined): string => {
    const statusDate = gap.updatedDate || 'Unknown';
    switch (gap.status) {
      case prevStatus:
        // We want to avoid repeating the same status, if it hasn't changed.
        return `Updated ${statusDate}`;
      case 'closed':
        return `Met ${statusDate}`;
      case 'open':
        return `Opened ${statusDate}`;
      case 'excluded':
        return `Excluded ${statusDate}`;
      default:
        return `Updated ${statusDate}`;
    }
  };

  return (
    <div className={tw`space-y-2 rounded-lg bg-background-hover px-2 py-4 sm:px-4`}>
      <div className={tw`flex justify-between space-x-2 text-sm uppercase text-content-subtle`}>
        <div className={tw`title-container`}>Activity</div>
      </div>
      {/* 
      Why the double reverse in this map?  We want to  render the reports in reverse chronological 
      order.  However, the statusDescriptions require knowing the value of the previous report in 
      regular chronological order. 
      */}
      {careGap.measureReports.map((measureReport, i) => {
        const reportEvidence = evidence?.[measureReport.id as string] || [];
        const entry = new CareGapModel(careGap.resource, [measureReport]);
        let prevGapStatus: CareGapStatus | undefined;
        if (i < careGap.measureReports.length - 1) {
          prevGapStatus = new CareGapModel(careGap.resource, [careGap.measureReports[i + 1]])
            .status;
        }
        const statusDescription = activityStatus(entry, prevGapStatus);

        return (
          <div
            data-testid="activity"
            key={entry.latestMeasureReport?.id}
            className={tw`space-y-2 rounded-lg bg-white p-3 text-left outline outline-1 outline-divider-main`}
          >
            <div className={tw`text-xs font-normal`}>{statusDescription}</div>

            <div className={tw`w-full space-y-1 text-sm`}>
              {entry.numeratorReasons.map((reason) => (
                <div key={reason} className={tw`font-normal first:font-medium`}>
                  {reason}
                </div>
              ))}
              {entry.exclusionReasons.map((reason) => (
                <div key={reason} className={tw`font-normal first:font-medium`}>
                  {reason}
                </div>
              ))}

              {reportEvidence.map((report) => evidenceLink(report))}
            </div>
          </div>
        );
      })}
    </div>
  );
}
