import parse from 'html-react-parser';
import { cloneDeep } from 'lodash-es';
import React, { Fragment, ReactElement, ReactNode, useEffect, useState } from 'react';
import './note-style.scss';
import { tw, twx } from '@ctw/shared/utils/tailwind';

export type DetailEntry = {
  beginsNewSection?: boolean;
  label?: string;
  value: ReactNode;
  transposeTables?: boolean;
};

export type DetailsProps = {
  className?: string;
  details: DetailEntry[];
  documentButton?: ReactNode;
  hideEmpty?: boolean;
  hideHeader?: boolean;
};

const MIN_SOURCE_COLUMNS_IN_NOTE_TO_TRANSPOSE = 3;
const MAX_DEPTH_FIND_TABLE_TO_TRANSPOSE = 6;

export const recursivelyTransposeTables = (node: ReactNode, curDepth: number): ReactNode => {
  if (!node || curDepth >= MAX_DEPTH_FIND_TABLE_TO_TRANSPOSE) {
    return node;
  }

  //  if this doesn't have a props then skip it
  if (node instanceof Boolean || node instanceof String) {
    return node;
  }

  //  is this node a table?
  if ((node as ReactElement).type === 'table') {
    const tbl = node as ReactElement;
    return transposeTable(tbl as ReactElement<Record<string, unknown>>);
  }

  const props =
    Object.hasOwn(node as object, 'props') ?
      (node as { props: Record<string, unknown> }).props
    : {};

  //  if there are contents, then it's a big 'ol string of html we need to parse
  if (props.content) {
    //  try to render it
    const el = parse(props.content as string, {});
    return recursivelyTransposeTables(el, curDepth + 1);
  }

  if (!props.children) {
    return node;
  }

  const children =
    Array.isArray(props.children) ?
      (props.children as ReactElement[])
    : ([props.children] as ReactElement[]);

  const newChildren = children.map((child) => recursivelyTransposeTables(child, curDepth + 1));
  const newNode = cloneDeep(node) as { props: Record<string, unknown> };

  if (!(newNode as ReactElement).props) {
    return node;
  }

  //  react doesn't want arrays of one; rather, it wants either an object or
  //  an array of 2 or more children
  if (newChildren.length === 1 && newChildren[0]) {
    //  eslint-disable-next-line prefer-destructuring
    newNode.props.children = newChildren[0];
  } else {
    newNode.props.children = newChildren;
  }
  return newNode as ReactNode;
};

const transposeTable = (tbl: ReactElement<Record<string, unknown>>): ReactElement => {
  //  if there's no children, then return with original tbl
  if (!Array.isArray(tbl.props.children)) {
    return tbl;
  }

  //  find the thead, otherwise skip
  const theads = (tbl.props.children as ReactElement[]).filter((child) => child.type === 'thead');
  if (theads.length !== 1) {
    return tbl;
  }
  const thead = theads[0] as {
    props: Record<string, { type: string; props: Record<string, unknown> }>;
  };

  //  find the tbody, otherwise skip
  const tbodies = (tbl.props.children as ReactElement[]).filter((child) => child.type === 'tbody');
  if (tbodies.length !== 1) {
    return tbl;
  }
  const tbody = tbodies[0] as ReactElement<Record<string, unknown>>;

  //  if there's less than the max number of cols, return the original table
  let headerRows = [] as ReactElement[];
  if (Array.isArray(thead.props.children)) {
    headerRows = thead.props.children as ReactElement[];
  } else if (
    thead.props.children.type === 'tr' &&
    Array.isArray(thead.props.children.props.children)
  ) {
    headerRows = thead.props.children.props.children as ReactElement[];
  } else {
    headerRows = [thead.props.children.props.children] as ReactElement[];
  }

  if (headerRows.length < MIN_SOURCE_COLUMNS_IN_NOTE_TO_TRANSPOSE) {
    return tbl;
  }

  const dataRows =
    Array.isArray(tbody.props.children) ?
      (tbody.props.children as ReactElement<Record<string, unknown>>[])
    : ([tbody.props.children] as ReactElement<Record<string, unknown>>[]);
  if (!dataRows.length) {
    return tbl;
  }

  const newRows = dataRows.reduce((acc, dataRow, rowIdx) => {
    //  now for each cell in the data row
    const dataCells =
      Array.isArray(dataRow.props.children) ?
        (dataRow.props.children as ReactElement[])
      : ([dataRow.props.children] as ReactElement[]);

    const newDivs = dataCells.reduce((accRow, dataCell, cellIdx) => {
      const headerRow = headerRows[cellIdx] as ReactElement<
        Record<string, ReactElement<Record<string, unknown>>>
      >;

      return [
        ...accRow,
        //  eslint-disable-next-line react/no-array-index-key
        <div key={`cell-${rowIdx}-${cellIdx}`}>
          <p className={tw`font-medium`}>{headerRow.props.children}</p>
          <p>{(dataCell.props as Record<string, never>).children}</p>
        </div>,
      ];
    }, [] as ReactElement[]);

    //  add an extra div we need to separate data rows
    if (dataRows.length - 1 > rowIdx) {
      newDivs.push(
        <div
          //  eslint-disable-next-line react/no-array-index-key
          key={`transpose-separator-${rowIdx}`}
          className={tw`note-transposed-row-separator`}
        />,
      );
    }

    return [...acc, ...newDivs];
  }, [] as ReactElement[]);

  return <div className={tw`note-transposed`}>{newRows}</div>;
};

export const DetailsCard = ({
  details,
  documentButton,
  hideEmpty = true,
  hideHeader = false,
  className = 'rounded-lg bg-background-hover space-y-2 px-2 py-4 sm:px-4',
}: DetailsProps) => {
  const [transposedValues, setTransposedValues] = useState(
    details.map(() => <div>Loading Note...</div>) as ReactNode[],
  );

  //  transposing the tables can take 1-2 seconds, so wrapping in an useEffect to
  //  prevent the screen from freezing
  useEffect(() => {
    const newlyTransposedValues = details.map((detail) =>
      detail.transposeTables ? recursivelyTransposeTables(detail.value, 0) : detail.value,
    );
    setTransposedValues(newlyTransposedValues);
  }, [details]);

  return (
    <dl className={twx(className)}>
      {!hideHeader && (
        <div className={tw`flex justify-between space-x-2 text-sm uppercase text-content-subtle`}>
          <div className={tw`title-container`}>Details</div>
          <div className={tw`flex`}>{documentButton}</div>
        </div>
      )}
      {transposedValues.length > 0 &&
        details.map((entry, idx) => {
          //  if we want to hide empty rows and the value is falsy and the value
          //  is not zero, then hide it
          if (hideEmpty && !entry.value && entry.value !== 0) {
            //  eslint-disable-next-line react/no-array-index-key
            return <div key={idx} />;
          }

          const valueWithTransposedTables = transposedValues[idx];
          if (!entry.label) {
            return (
              //  eslint-disable-next-line react/no-array-index-key
              <div key={idx} className={tw`flex items-baseline text-content-main`}>
                <div className={tw`m-0 w-full`}>{valueWithTransposedTables}</div>
              </div>
            );
          }
          return (
            <Fragment key={entry.label}>
              {entry.beginsNewSection && <hr />}
              <div className={tw`flex items-baseline gap-2 text-content-main`}>
                <dt className={tw`w-1/3 flex-shrink-0 font-medium`}>{entry.label}</dt>
                <dd className={tw`m-0 w-full`}>{valueWithTransposedTables}</dd>
              </div>
            </Fragment>
          );
        })}
    </dl>
  );
};
