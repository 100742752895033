import { ResourceTypeString } from '@ctw/shared/api/fhir/types';

export type Citation = {
  id: number;
  source_resource_type: ResourceTypeString;
  source_id: string;
};

export type ChartReviewRequest = {
  upid: string;
  reasonForVisit: string;
  specialty: string;
};

export type ChartReviewResponse = {
  upid?: string;
  hpi: string;
  citations: Citation[];
  createdAt?: Date;
};

export type FeedbackRequest = {
  createdAt: string;
  userId: string;
  upid: string;
  content: string;
  citations: Citation[];
  usefulnessRating: number;
  usefulnessFeedback: string;
  otherFeedback: string;
};

interface NewBlankFeedbackRequestOptions {
  upid?: string;
}

export const newBlankFeedbackRequest = ({
  upid,
}: NewBlankFeedbackRequestOptions): FeedbackRequest => ({
  createdAt: new Date().toISOString(),
  userId: 'unknown',
  upid: upid ?? 'unknown',
  content: '',
  citations: [],
  usefulnessRating: 0,
  usefulnessFeedback: '',
  otherFeedback: '',
});

export type FeedbackResponse = {
  id: string;
};
