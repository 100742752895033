import { parsePhoneNumber } from 'awesome-phonenumber';

export function formatPhoneNumber(phoneNumber: string): string {
  const parsedNumber = parsePhoneNumber(phoneNumber, { regionCode: 'US' });

  if (!parsedNumber.number?.national) {
    return phoneNumber;
  }

  return parsedNumber.number.national;
}
