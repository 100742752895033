import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { SYSTEM_ZUS_UNIVERSAL_ID } from '@ctw/shared/api/fhir/system-urls';
import { PatientFormData } from '@ctw/shared/content/forms/actions/patients';
import { useTriggerBackfillRequest } from '@ctw/shared/hooks/use-trigger-backfill-request';
import { useTriggerLensOnDemandJob } from '@ctw/shared/hooks/use-trigger-lens-on-demand-job';
import { QUERY_KEY_PATIENT } from '@ctw/shared/utils/query-keys';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { SetOptional } from 'type-fest';
import { ContentError } from '@ctw/shared/components/errors/content-error';
import { faUserLock } from '@fortawesome/pro-solid-svg-icons';
import { SupportFormLink } from '@ctw/shared/content/support-form-link';
import { tw } from '@ctw/shared/utils/tailwind';
import { OverlayProvider } from '@ctw/shared/context/overlay-provider';
import {
  CustomQueryFnContext,
  useCtwQuery,
  UseCtwQueryFnContext,
  UseCtwQueryOptions,
} from '@ctw/shared/hooks/use-ctw-query';
import {
  getBuilderFhirPatientByIdentifier,
  getPatientByID,
} from '@ctw/shared/api/fhir/patient-helper';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export type ThirdPartyID = {
  patientResourceID?: never;
  patientUPID?: never;
  patientID: string;
  systemURL: string;
};

export type PatientUPIDSpecified = {
  patientResourceID?: never;
  patientUPID: string;
  patientID?: never;
  systemURL?: never;
};

export type PatientResourceID = {
  patientResourceID: string;
  patientUPID?: never;
  patientID?: never;
  systemURL?: never;
};

export interface PatientState {
  patientResourceID?: string;
  patientID?: string;
  systemURL?: string;
  patient: PatientModel;
  onPatientSave?: (data: PatientFormData) => void;
}

type PatientStateWithOptionalPatient = SetOptional<PatientState, 'patient'>;

const PatientContext = createContext<PatientState | undefined>(undefined);

type PatientProviderProps = {
  onPatientSave?: (data: PatientFormData) => void;
} & PropsWithChildren &
  (ThirdPartyID | PatientUPIDSpecified | PatientResourceID);

export const PatientProvider = ({
  children,
  patientResourceID,
  patientUPID,
  patientID,
  systemURL,
  onPatientSave,
}: PatientProviderProps) => {
  const patient = useFetchPatient({
    patientUPID,
    patientID,
    patientResourceID,
    systemURL,
  });
  const { requestContext } = useCTW();
  const telemetry = useTelemetry();
  const queryClient = useQueryClient();

  useTriggerBackfillRequest(systemURL, patientID);
  useTriggerLensOnDemandJob('encounters', patient.data?.UPID);
  useTriggerLensOnDemandJob('medications', patient.data?.UPID);
  useTriggerLensOnDemandJob('conditions', patient.data?.UPID);

  const providerState: PatientStateWithOptionalPatient = useMemo(
    () => ({
      patientResourceID,
      patientID: patientUPID ?? patientID,
      systemURL: patientUPID ? SYSTEM_ZUS_UNIVERSAL_ID : systemURL,
      patient: patient.data,
      onPatientSave,
    }),
    [patient, patientResourceID, patientID, patientUPID, systemURL, onPatientSave],
  );

  useEffect(() => {
    telemetry.setPatient({
      patientUPID: patientUPID ?? patient.data?.UPID,
      patientID,
      patientResourceID,
      systemURL,
    });
  }, [patient, patientID, patientUPID, patientResourceID, systemURL, telemetry, queryClient]);

  if (isPatientStateWithPatient(providerState)) {
    if (providerState.patient.active === false) {
      return (
        <ContentError
          title="Inactive patient"
          message="Follow your organization's guidelines to activate this patient in Zus."
          className={tw`flex h-full min-h-full w-full min-w-full items-center justify-center`}
        >
          <span>If this problem persists </span>
          <SupportFormLink buttonText="contact support" className={tw`link text-base`} />.
        </ContentError>
      );
    }

    return (
      <PatientContext.Provider value={providerState}>
        <OverlayProvider>{children}</OverlayProvider>
      </PatientContext.Provider>
    );
  }

  if (patient.isLoading) {
    return (
      <LoadingSpinner
        centered
        message={
          requestContext.service === 'standalone' ?
            'Loading patient...'
          : '"Loading patient. This may take a minute if patient is being synced from EHR for the first time."'
        }
      />
    );
  }

  const notAuthorized =
    patient.error &&
    typeof patient.error === 'object' &&
    (('statusCode' in patient.error && patient.error.statusCode === 401) ||
      ('status' in patient.error && patient.error.status === 401));

  if (notAuthorized) {
    telemetry.trackError({
      message: 'User not authorized to view patient',
      error: patient.error,
      context: { patientID, patientResourceID, systemURL },
    });

    return (
      <ContentError
        icon={faUserLock}
        title="Not authorized"
        message="Your account does not have access to this feature."
        className={tw`flex h-full min-h-full w-full min-w-full items-center justify-center`}
      >
        <span>If this problem persists </span>
        <SupportFormLink buttonText="contact support" className={tw`link text-base`} />.
      </ContentError>
    );
  }

  telemetry.trackError({
    message: 'User tried to view a patient that cannot be found',
    error: patient.error,
    context: { patientID, patientResourceID, systemURL },
  });

  return (
    <ContentError
      title="Patient not found"
      message="Try reloading the app."
      className={tw`flex h-full min-h-full w-full min-w-full items-center justify-center`}
    >
      <span>If this problem persists </span>
      <SupportFormLink buttonText="contact support" className={tw`link text-base`} />.
    </ContentError>
  );
};

export function usePatientContext() {
  const context = useContext(PatientContext);
  if (!context) {
    throw new Error('usePatientContext must be used within a PatientProvider');
  }

  return context;
}

export const usePatientContextIfAvailable = () => useContext(PatientContext);

function isPatientStateWithPatient(state: PatientStateWithOptionalPatient): state is PatientState {
  return Boolean(state.patient);
}

interface UseFetchPatientOptions {
  patientUPID?: string;
  patientID?: string;
  patientResourceID?: string;
  systemURL?: string;
}

const useFetchPatient = ({
  patientUPID,
  patientID,
  patientResourceID,
  systemURL,
}: UseFetchPatientOptions): UseQueryResult<PatientModel> =>
  useCtwQuery({
    queryId: QUERY_KEY_PATIENT,
    queryKey: [patientUPID, patientID, patientResourceID, systemURL],
    queryFn: async ({ requestContext, ctwFetch }) => {
      if (patientResourceID) {
        return getPatientByID(ctwFetch, requestContext, patientResourceID);
      }

      if (patientUPID) {
        return getBuilderFhirPatientByIdentifier(
          ctwFetch,
          requestContext,
          patientUPID,
          SYSTEM_ZUS_UNIVERSAL_ID,
        );
      }

      if (patientID && systemURL) {
        return getBuilderFhirPatientByIdentifier(ctwFetch, requestContext, patientID, systemURL);
      }

      throw new Error('No patient identifier provided');
    },
  });

interface UsePatientQueryFnContext<CustomContext extends CustomQueryFnContext>
  extends UseCtwQueryFnContext<CustomContext> {
  patient: PatientModel;
}

export interface UsePatientQueryOptions<TData, TContext extends CustomQueryFnContext>
  extends Omit<UseCtwQueryOptions<TData, TContext>, 'queryFn'> {
  queryFn: (context: UsePatientQueryFnContext<TContext>) => Promise<TData>;
}

export function usePatientQuery<
  TData,
  TContext extends CustomQueryFnContext = CustomQueryFnContext,
>(options: UsePatientQueryOptions<TData, TContext>) {
  const { patient, patientID, patientResourceID, systemURL } = usePatientContext();
  const patientQueryMeta = useMemo(
    () => ({
      ...(options.queryMeta ?? {}),
      patientID,
      patientResourceID,
      systemURL,
    }),
    [options.queryMeta, patientID, patientResourceID, systemURL],
  );

  const useQueryOptionsWithContextualQueryKeys: UseCtwQueryOptions<TData, TContext> = useMemo(
    () => ({
      ...options,
      queryMeta: patientQueryMeta,
      // Prefix the query ID with `patient.`
      queryId: `patient.${options.queryId}`,
      // Add the patient's UPID to the query key so we never cache across patients
      queryKey: [...(options.queryKey ?? []), patient.resource.id],
      // Add the patient into the query function context
      queryFn: (context: UseCtwQueryFnContext<TContext>) => {
        context.setScopedContextProperty('patientQueryMeta', patientQueryMeta);

        return options.queryFn({
          ...(options.queryFnContext ?? {}),
          ...context,
          patient,
        });
      },
    }),
    [options, patient, patientQueryMeta],
  );

  return useCtwQuery(useQueryOptionsWithContextualQueryKeys);
}
