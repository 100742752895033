import { isEmpty, orderBy } from 'lodash-es';
import { FHIRModel } from './fhir-model';
import { DiagnosticReportModel } from '@ctw/shared/api/fhir/models/diagnostic-report';

export type CareGapStatus = 'open' | 'closed' | 'excluded';
export type MeasureReportId = string;
export interface CareGapEvidence {
  [key: MeasureReportId]: DiagnosticReportModel[];
}

export class CareGapModel extends FHIRModel<fhir4.Measure> {
  kind = 'CareGap' as const;

  readonly measureReports: fhir4.MeasureReport[];

  private readonly allEvidence: CareGapEvidence;

  constructor(
    measure: fhir4.Measure = {
      resourceType: 'Measure',
      status: 'unknown',
    },
    measureReports: fhir4.MeasureReport[] = [],
    evidence?: CareGapEvidence,
  ) {
    super(measure);
    this.allEvidence = evidence || {};
    // Sort MeasureReport resource from latest to earliest by `date`
    this.measureReports = orderBy(
      measureReports,
      (report) => (report.date ? new Date(report.date).getTime() : 0),
      'desc',
    );
  }

  get latestMeasureReport(): fhir4.MeasureReport | undefined {
    return this.measureReports[0];
  }

  get resourceTypeTitle(): string {
    return 'Care Gap';
  }

  // This presumes that our MeasureReport resources are individual reports and  will just
  // have one group.  If that changes, this will need to be updated.
  get inDenominator(): boolean {
    const denomPop = this.latestMeasureReport?.group?.[0].population?.find((pop) =>
      pop.code?.coding?.some((c) => c.code === 'denominator'),
    );
    return (denomPop?.count ?? 0) > 0;
  }

  // This presumes that our MeasureReport resources are individual reports and  will just
  // have one group.  If that changes, this will need to be updated.
  get inNumerator(): boolean {
    const numPop = this.latestMeasureReport?.group?.[0].population?.find((pop) =>
      pop.code?.coding?.some((c) => c.code === 'numerator'),
    );
    return (numPop?.count ?? 0) > 0;
  }

  // The title of the measure that this gap belongs to (e.g. "Controlling Blood Pressure").  This value
  // comes from the attached Measure resource.
  get title(): string {
    return this.resource.title || 'Unnamed Measure';
  }

  // The description of the measure that this gap belongs to (e.g. "The percentage of patients 45 to
  // 75 years of age with appropriate screening for colorectal cancer").  Although the FHIR spec
  // supports this field as "Markdown", our UI is expecting it to be in plain text.
  get measureDescription(): string | undefined {
    return this.resource.description;
  }

  get updatedDate(): string {
    if (!this.latestMeasureReport?.date) return '';

    const updatedDate = new Date(this.latestMeasureReport.date);

    return updatedDate.toLocaleDateString('en-US', { timeZone: 'UTC' });
  }

  // Excluded indicates that the care gap is excluded from the measure calculation.
  get excluded(): boolean {
    const excludedPop = this.latestMeasureReport?.group?.[0].population?.find((pop) =>
      pop.code?.coding?.some((c) => c.code === 'denominator-exclusion'),
    );

    return !this.inDenominator && !this.inNumerator && (excludedPop?.count ?? 0) > 0;
  }

  // Closed indicates that the care gap is closed by having the patient in both the numerator and
  // the denominator (in other words the clinical criteria for closure has been met).
  get closed(): boolean {
    return this.inDenominator && this.inNumerator;
  }

  get status(): CareGapStatus {
    if (this.closed) return 'closed';
    if (this.excluded) return 'excluded';
    return 'open';
  }

  get exclusionReasons(): string[] {
    return (
      this.latestMeasureReport?.group?.[0].population
        // Grab the populations that are marked as denominator-exclusion
        ?.find((pop) => pop.code?.coding?.some((c) => c.code === 'denominator-exclusion'))
        // Extract the extentensions that are the population description
        ?.extension?.filter(
          (ext) =>
            ext.url ===
            'http://hl7.org/fhir/StructureDefinition/measurereport-populationDescription',
        )
        // Grab the exclusion reason from the markdown value
        .map((ext) => ext.valueMarkdown || '')
        // Get rid of empty strings
        .filter((reason) => !isEmpty(reason)) || []
    );
  }

  get windowEnd(): string | undefined {
    if (!this.latestMeasureReport?.period.end) return undefined;

    const endDate = new Date(this.latestMeasureReport.period.end);
    return endDate.toLocaleDateString('en-US', { timeZone: 'UTC' });
  }

  get windowStart(): string | undefined {
    if (!this.latestMeasureReport?.period.start) return undefined;

    const startDate = new Date(this.latestMeasureReport.period.start);
    return startDate.toLocaleDateString('en-US', { timeZone: 'UTC' });
  }

  get denominatorReasons(): string[] {
    return (
      this.latestMeasureReport?.group?.[0].population
        // Grab the populations that are marked as denominator
        ?.find((pop) => pop.code?.coding?.some((c) => c.code === 'denominator'))
        // Extract the extentensions that are the population description
        ?.extension?.filter(
          (ext) =>
            ext.url ===
            'http://hl7.org/fhir/StructureDefinition/measurereport-populationDescription',
        )
        // Grab the exclusion reason from the markdown value
        .map((ext) => ext.valueMarkdown || '')
        // Get rid of empty strings
        .filter((reason) => !isEmpty(reason)) || []
    );
  }

  get numeratorReasons(): string[] {
    return (
      this.latestMeasureReport?.group?.[0].population
        // Grab the populations that are marked as denominator
        ?.find((pop) => pop.code?.coding?.some((c) => c.code === 'numerator'))
        // Extract the extentensions that are the population description
        ?.extension?.filter(
          (ext) =>
            ext.url ===
            'http://hl7.org/fhir/StructureDefinition/measurereport-populationDescription',
        )
        // Grab the exclusion reason from the markdown value
        .map((ext) => ext.valueMarkdown || '')
        // Get rid of empty strings
        .filter((reason) => !isEmpty(reason)) || []
    );
  }

  // As of this writing, CareGap evidence is limited to DiagnosticReport resources.  In the future
  // we'll need to figure how to handle the fact that different measure reports will have different
  // types of evidence.
  get evidence(): DiagnosticReportModel[] {
    const latestMeasureReportId = this.latestMeasureReport?.id;
    if (!latestMeasureReportId) {
      return [];
    }
    return this.allEvidence[latestMeasureReportId] ?? [];
  }

  getEvidenceForSpecificReport(reportId: string): DiagnosticReportModel[] {
    return this.allEvidence[reportId] ?? [];
  }

  // latestReportReasons will return the first reason found in order of exclusion, numerator, denominator.
  get latestReportReasons() {
    const { numeratorReasons, denominatorReasons, exclusionReasons } = this;
    if (exclusionReasons.length > 0) {
      return exclusionReasons;
    }
    if (numeratorReasons.length > 0) {
      return numeratorReasons;
    }
    return denominatorReasons;
  }
}
