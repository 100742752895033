import { useState } from 'react';
import { ChartReviewResponse } from './types';
import { getAiEndpoint, translateUnixBreaksToHtml } from './utils';
import { CTWRequestContext, CTWState, useCTW } from '@ctw/shared/context/ctw-context';
import { usePatientContext, usePatientQuery } from '@ctw/shared/context/patient-provider';
import { RequestError } from '@ctw/shared/utils/error';
import { QUERY_KEY_AI_CHART_REVIEW_SUMMARY } from '@ctw/shared/utils/query-keys';
import { retryWithExponentialBackoff } from '@ctw/shared/utils/request';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

const MAX_RETRIES = 5;
const BASE_DELAY = 2000;

const generatePrecomputedSummary = async (
  ctwFetch: CTWState['ctwFetch'],
  requestContext: CTWRequestContext,
  patientId: string,
  summaryType: string,
): Promise<ChartReviewResponse> => {
  const endpoint = getAiEndpoint(
    requestContext.env,
    `/patients/${patientId}/summaries/${summaryType}`,
  );
  const resp = await ctwFetch<ChartReviewResponse>(endpoint, {
    headers: {
      Authorization: `Bearer ${requestContext.authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  });

  resp.data.hpi = translateUnixBreaksToHtml(resp.data.hpi);
  return resp.data;
};

const fetchPrecomputedSummary = async (
  ctwFetch: CTWState['ctwFetch'],
  requestContext: CTWRequestContext,
  patientId: string,
  summaryType: string,
): Promise<ChartReviewResponse> => {
  const endpoint = getAiEndpoint(
    requestContext.env,
    `/patients/${patientId}/summaries/${summaryType}`,
  );

  const response = await ctwFetch<ChartReviewResponse>(endpoint, {
    headers: {
      Authorization: `Bearer ${requestContext.authToken}`,
      'Content-Type': 'application/json',
      'Zus-Account': requestContext.builderId,
    },
    method: 'GET',
  });

  response.data.hpi = translateUnixBreaksToHtml(response.data.hpi);
  return response.data;
};

export const ErrorSummary: ChartReviewResponse = {
  hpi: 'An error occurred while fetching the summary',
  citations: [],
};

export const NotFoundSummary: ChartReviewResponse = {
  hpi: 'Summary not found',
  citations: [],
};

export const usePrecomputedNewPatientSummaryForPatient = (summaryType: string) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const telemetry = useTelemetry();

  const {
    data: document,
    isLoading,
    error,
    refetch,
  } = usePatientQuery({
    queryId: QUERY_KEY_AI_CHART_REVIEW_SUMMARY,
    queryKey: [summaryType],
    queryFn: async ({ requestContext, patient }) => {
      try {
        const resp = await fetchPrecomputedSummary(
          ctwFetch,
          requestContext,
          patient.UPID ?? '',
          summaryType,
        );
        resp.upid = patient.UPID;
        return resp;
      } catch (e: unknown) {
        if (e instanceof RequestError && e.statusCode === 404) {
          return NotFoundSummary;
        }
        return ErrorSummary;
      }
    },
  });

  const { requestContext, ctwFetch } = useCTW();
  const { patient } = usePatientContext();

  const generateDocument = async () => {
    setIsGenerating(true);
    try {
      const upid = patient.UPID || 'unknown-upid';
      await generatePrecomputedSummary(ctwFetch, requestContext, upid, summaryType);

      // Wait for the summary to be generated
      return retryWithExponentialBackoff(
        async () => {
          const resp = await refetch();
          if (resp.data === NotFoundSummary) {
            throw new Error('Summary not found');
          }
          return resp;
        },
        MAX_RETRIES,
        BASE_DELAY,
      );
    } catch (e) {
      telemetry.trackError({ message: 'Error generating patient summary', error: e });
      throw new Error('Error generating summary');
    } finally {
      setIsGenerating(false);
    }
  };

  return {
    document,
    isLoading,
    isGenerating,
    error,
    generateDocument,
  };
};
