import { CustomThemeConfig } from 'tailwindcss/types/config';

export const zIndex: Record<string, string> = {
  base: '0',
  navigation: '100',
  menu: '200',
  'drawer-scrim': '300',
  drawer: '400',
  'modal-scrim': '500',
  modal: '600',
  toast: '700',
  tooltip: '800',
} as const;

interface BreakpointDefinition {
  min: number;
  max: number;
}

export const breakpoints: Record<string, BreakpointDefinition> = {
  xxs: { min: 0, max: 319 },
  xs: { min: 320, max: 479 },
  sm: { min: 480, max: 767 },
  md: { min: 768, max: 1_023 },
  lg: { min: 1_024, max: 1_439 },
  xl: { min: 1_440, max: 99_999 },
} as const;

export type Breakpoint = keyof typeof breakpoints;

export interface ZuiTheme {
  fontFamily?: 'Inter' | 'Avenir' | 'Roboto';
}

interface FontDefinition {
  cssImport: string | null;
  cssFontFamily: string;
}

type FontDefinitions = {
  [key in NonNullable<ZuiThemeFont>]: FontDefinition;
};

type ZuiThemeFont = ZuiTheme['fontFamily'];

export const zuiThemeFonts: FontDefinitions = {
  Inter: {
    cssImport: null,
    cssFontFamily: "'Inter', sans-serif",
  },
  Roboto: {
    cssImport:
      "url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')",
    cssFontFamily: "'Roboto', sans-serif",
  },
  Avenir: {
    cssImport: "url('https://fonts.cdnfonts.com/css/avenir')",
    cssFontFamily: 'Avenir, sans-serif',
  },
};

export const screens = Object.fromEntries(
  Object.entries(breakpoints).map(([name, value]) => [name, `${value.min}px`]),
) satisfies CustomThemeConfig['screens'];

// https://www.figma.com/design/BVusDUgTlUyzS41QLsW4SR/Library---Core?node-id=2785-24352&t=vnZ0D34hphS4LuVb-0
export const colors = {
  transparent: 'transparent',
  white: '#ffffff',
  black: '#000000',
  logo: {
    blue: '#71b2c9',
    green: '#008051',
    background: '#1f2937',
  },
  primary: {
    main: '#008052',
    text: '#036240',
    hover: '#036240',
    background: '#ebfef4',
    'background-hover': '#d0fbe2',
  },
  content: {
    main: '#111827',
    subtle: '#6b7280',
    disabled: '#9ca3af',
    icon: '#9ca3af',
    inverse: '#ffffff',
    focal: '#f4f4f4',
  },
  background: {
    main: '#ffffff',
    hover: '#f9fafb',
    disabled: '#f3f4f6',
    subtle: '#e5e7eb',
    tooltip: '#4b5563',
    inverse: '#111827',
  },
  border: {
    main: '#d1d5db',
  },
  divider: {
    main: '#e5e7eb',
  },
  error: {
    background: '#fef2f2',
    badge: '#fee2e2',
    main: '#ef4444',
    text: '#991b1b',
  },
  success: {
    background: '#ecfdf5',
    badge: '#d1fae5',
    main: '#10b981',
    text: '#065f46',
  },
  caution: {
    background: '#fffbeb',
    badge: '#fef3c7',
    main: '#f59e0b',
    text: '#9d5326',
  },
  info: {
    background: '#f0f9ff',
    badge: '#e0f2fe',
    main: '#0ea5e9',
    text: '#075985',
  },
} as const satisfies CustomThemeConfig['colors'];
