import { createContext, type JSX } from 'react';
import { TrackingMetadata } from '@ctw/shared/context/telemetry/tracking-metadata';

export type OpenModalProps = {
  component: ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => JSX.Element;
  trackingMetadata?: TrackingMetadata;
};

export type ModalState = {
  openModal: (props: OpenModalProps) => void;
};

export const ModalContext = createContext<ModalState | undefined>(undefined);
