import { ClassName, twx } from '@ctw/shared/utils/tailwind';
import { PropsWithChildren } from 'react';
import { RouterOptions, useZusUiContext } from '@ctw/shared/context/zus-ui-provider';

const isExternalHref = (href: string): boolean => {
  try {
    const url = new URL(href);

    if (url.hostname !== window.location.hostname) {
      return true;
    }
  } catch (_error) {
    /* pass */
  }

  return false;
};

const anchorElementClassName =
  'rounded-md fill-primary-text text-primary-text no-underline hover:underline focus:no-underline cursor-pointer font-sans font-medium focus-visible:outline-4 focus-visible:outline-offset-[3px] focus-visible:outline-primary-main active:scale-[.98]';

export interface LinkProps extends PropsWithChildren {
  href: string;
  rel?: HTMLAnchorElement['target'];
  target?: '_self' | '_blank' | '_parent' | '_top' | '_unfencedTop';
  dataTestId?: string;
  routerOptions?: RouterOptions;
  className?: ClassName;
}

export const Link = ({
  href,
  target,
  routerOptions,
  className,
  dataTestId,
  children,
}: LinkProps) => {
  const { navigate } = useZusUiContext();
  const isExternal = isExternalHref(href);

  if (isExternal) {
    return (
      <a
        data-testid={dataTestId}
        href={href}
        target={target}
        rel="noreferrer noopener"
        className={twx(anchorElementClassName, className)}
      >
        {children}
      </a>
    );
  }

  return (
    <a
      data-testid={dataTestId}
      onClick={(event) => {
        if (target !== '_blank') {
          event.preventDefault();
          navigate(href, routerOptions);
        }
      }}
      href={href}
      target={target}
      className={twx(anchorElementClassName, className)}
    >
      {children}
    </a>
  );
};
