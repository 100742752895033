import { useEffect, useState } from 'react';
import { EncounterModel } from '../api/fhir/models';
import { getLensSummariesWithSyncedWithRecord } from '../api/fhir/summary';
import { usePatientQuery } from '../context/patient-provider';
import {
  QUERY_KEY_PATIENT_BUILDER_ENCOUNTERS,
  QUERY_KEY_PATIENT_SUMMARY_ENCOUNTERS,
} from '../utils/query-keys';
import { fqsRequestAll, MAX_OBJECTS_PER_REQUEST } from '@ctw/shared/api/fqs/client';
import { useLazyLoadedPatientDocuments } from '@ctw/shared/api/fhir/document';
import { EncounterGraphqlResponse, encountersQuery } from '@ctw/shared/api/fqs/queries/encounters';
import {
  SYSTEM_SUMMARY,
  SYSTEM_ZUS_OWNER,
  SYSTEM_ZUS_SUMMARY,
  SYSTEM_ZUS_THIRD_PARTY,
} from '@ctw/shared/api/fhir/system-urls';
import { getLensBuilderId } from '@ctw/shared/api/urls';
import { dedupeAndMergeEncounters } from '@ctw/shared/content/encounters/helpers/filters';
import { filterResourcesByBuilderId } from '@ctw/shared/api/common';

export function usePatientEncounters(limit = MAX_OBJECTS_PER_REQUEST, enabled = true) {
  const queryForSummary = usePatientQuery({
    gcTime: 0,
    staleTime: 0,
    queryId: QUERY_KEY_PATIENT_SUMMARY_ENCOUNTERS,
    queryKey: [limit],
    queryFn: async ({ requestContext, telemetry, graphqlClient, patient }) => {
      const { data } = await fqsRequestAll<EncounterGraphqlResponse>(
        telemetry,
        graphqlClient,
        encountersQuery,
        'EncounterConnection',
        {
          upid: patient.UPID,
          cursor: '',
          first: limit,
          filter: {
            tag: {
              allmatch: [
                `${SYSTEM_ZUS_SUMMARY}|Common`,
                `${SYSTEM_ZUS_OWNER}|builder/${getLensBuilderId(requestContext.env)}`,
              ],
            },
          },
        },
      );
      const nodes = data.EncounterConnection.edges.map((x) => x.node);
      const results = nodes.map(
        (c) => new EncounterModel(c, c.ProvenanceList, undefined, c.BasicList),
      );
      return dedupeAndMergeEncounters(results);
    },
    enabled,
  });
  const queryForBuilder = usePatientQuery({
    queryId: QUERY_KEY_PATIENT_BUILDER_ENCOUNTERS,
    queryKey: [limit],
    queryFn: async ({ requestContext, telemetry, graphqlClient, patient }) => {
      const { data } = await fqsRequestAll<EncounterGraphqlResponse>(
        telemetry,
        graphqlClient,
        encountersQuery,
        'EncounterConnection',
        {
          upid: patient.UPID,
          cursor: '',
          first: limit,
          filter: {
            tag: {
              nonematch: [SYSTEM_SUMMARY, SYSTEM_ZUS_THIRD_PARTY],
              // TODO: There's a bug in FQS that doesn't allow filtering with nonematch AND allmatch.
              // Uncomment the line below once https://zeushealth.atlassian.net/browse/DRT-249 is resolved.
              // allmatch: [`${SYSTEM_ZUS_OWNER}|builder/${requestContext.builderId}`],
            },
          },
        },
      );
      let nodes = data.EncounterConnection.edges.map((x) => x.node);
      nodes = filterResourcesByBuilderId(nodes, requestContext.builderId);
      const results = nodes.map(
        (c) => new EncounterModel(c, c.ProvenanceList, undefined, c.BasicList),
      );
      return dedupeAndMergeEncounters(results);
    },
    enabled,
  });
  const [encounters, setEncounters] = useState<EncounterModel[]>([]);

  useEffect(() => {
    setEncounters(
      getLensSummariesWithSyncedWithRecord(queryForSummary.data ?? [], queryForBuilder.data ?? []),
    );
  }, [
    queryForSummary.data,
    queryForBuilder.data,
    queryForSummary.isLoading,
    queryForBuilder.isLoading,
  ]);

  return {
    ...queryForSummary,
    isLoading: queryForSummary.isLoading || queryForBuilder.isLoading,
    data: encounters,
  };
}

// Gets patient encounters along with clinical notes from any documents associated with each encounter.
export function usePatientEncountersWithClinicalNotes(limit = MAX_OBJECTS_PER_REQUEST) {
  const encounterQuery = usePatientEncounters(limit);
  const documentsQuery = useLazyLoadedPatientDocuments();

  // Instead of returning `encounterQuery.data` directly from this hook, store the encounters in a
  // state variable we can use to force a re-render when the documents are loaded.
  const [encounters, setEncounters] = useState<EncounterModel[]>([]);

  // This `useEffect` should run when the `encounterQuery` changes, and then once when the
  // `documentsQuery.isLoading` state switches from `true` to `false`.
  useEffect(() => {
    // Exactly once, when the `encountersQuery` finishes loading the encounters, loop through the
    // encounters and set `isLoadingClinicalNotes` to the value of `documentsQuery.isLoading` and if
    // the documents have been loaded, set them on the encounters.
    if (!encounterQuery.isLoading && encounterQuery.data.length > 0 && encounters.length === 0) {
      if (documentsQuery.isLoading) {
        // Documents are still loading, set a loading state on the encounters.
        for (const encounter of encounterQuery.data) {
          encounter.isLoadingClinicalNotes = true;
        }
      } else {
        // Documents are loaded, set them immediatly on the encounters and remove the loading state.
        for (const encounter of encounterQuery.data) {
          encounter.setClinicalNotesFromDocumentPool(documentsQuery.data);
          encounter.isLoadingClinicalNotes = false;
        }
      }

      setEncounters(encounterQuery.data);
      return;
    }

    // If the documents finish loading before the encounters do, we set them in the above
    // conditional. When they finish loading after the encounters we set them here by looping
    // through the encounters, setting the documents, removing the loading state, and then setting
    // the local encounters state to a new array with the updated encounters to force a re-render.
    if (!documentsQuery.isLoading) {
      for (const encounter of encounterQuery.data) {
        encounter.setClinicalNotesFromDocumentPool(documentsQuery.data);
        encounter.isLoadingClinicalNotes = false;
      }

      setEncounters([...encounterQuery.data]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsQuery.isLoading, encounterQuery.data, encounterQuery.isLoading]);

  // Exclude documentsQuery state so hook returns before documents are loaded
  return {
    isLoading: encounterQuery.isLoading,
    isError: encounterQuery.isError,
    data: encounters,
  };
}
