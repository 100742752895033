import './zus-aggregated-profile.scss';

import ZusSVG from '@ctw/shared/assets/zus.svg';
import { Heading } from '@ctw/shared/components/heading';
import { AlertFeatureFlag } from '@ctw/shared/content/alert-feature-flag';
import { PatientAllergiesProps } from '@ctw/shared/content/allergies/patient-allergies';
import { PatientCareTeamProps } from '@ctw/shared/content/care-team/patient-careteam';
import { Title } from '@ctw/shared/content/ctw-box';
import { PatientDemographicsProps } from '@ctw/shared/content/demographics/patient-demographics';
import { PatientDocumentsProps } from '@ctw/shared/content/document/patient-documents';
import { PatientImmunizationsProps } from '@ctw/shared/content/immunizations/patient-immunizations';
import { ZAPTabGroup } from '@ctw/shared/content/zus-aggregated-profile/zap-tab-group/zap-tab-group';
import { zusAggregatedProfileTabs } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile-tabs';
import { ZapPropsStateContext } from '@ctw/shared/context/zap-props-state-context';
import { ZapTabStateContext } from '@ctw/shared/context/zap-tab-state-context';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { intersection } from 'lodash-es';
import { useContext, useEffect, useMemo } from 'react';
import { PatientConditionsAllGroupedProps } from '../conditions/patient-conditions-all-grouped';
import { PatientDiagnosticReportsProps } from '../diagnostic-reports/patient-diagnostic-reports';
import { PatientEncountersProps } from '../encounters/patient-encounters';
import { PatientMedicationsProps } from '../medications/patient-medications';
import { PatientMedicationsAllProps } from '../medications/patient-medications-all';
import { PatientMedicationsOutsideProps } from '../medications/patient-medications-outside';
import { PatientRecordSearchProps } from '../patient-record-search/patient-record-search';
import { PatientReferralsProps } from '../patient-referrals/patient-referrals';
import { PatientVitalsProps } from '../vitals/patient-vitals';
import { useFeatureFlag } from '@ctw/shared/context/feature-flag-provider';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { CareGapProps } from '@ctw/shared/content/care-gaps/patient-care-gaps';

export const ZAP_TAB_NAMES = [
  'allergies',
  'care-gaps',
  'care-team',
  'conditions-all',
  'diagnostic-reports',
  'documents',
  'encounters',
  'referrals',
  'immunizations',
  'medications',
  'medications-outside',
  'medications-all',
  'search',
  'overview',
  'demographics',
  'vitals',
] as const;

export type ZAPTabName = (typeof ZAP_TAB_NAMES)[number];

export const DEFAULT_ZAP_TABS: ZAPTabName[] = [
  'overview',
  'search',
  'demographics',
  'conditions-all',
  'medications-all',
  'diagnostic-reports',
  'vitals',
  'encounters',
  'referrals',
  'documents',
  'allergies',
  'immunizations',
  'care-team',
];

export type ZusAggregatedProfileProps = {
  resources?: ZAPTabName[]; // TODO - would prefer to rename this to tabs
  forceHorizontalTabs?: boolean;
  title?: string;
  hideTitle?: boolean;
  removeBranding?: boolean;
  removeRequestRecords?: boolean;
} & ZusAggregatedProfileSubComponentProps;

type HidableRecords<T> = {
  hideRequestRecords?: boolean;
} & T;

type MapOfHideableTypes<MapType> = {
  [Property in keyof MapType]: HidableRecords<MapType[Property]>;
};

export type ZusAggregatedProfileSubComponentProps = MapOfHideableTypes<
  Partial<{
    allergiesProps: PatientAllergiesProps;
    careTeamProps: PatientCareTeamProps;
    careGapProps: CareGapProps;
    conditionsAllProps: PatientConditionsAllGroupedProps;
    diagnosticReportsProps: PatientDiagnosticReportsProps;
    documentsProps: PatientDocumentsProps;
    immunizationsProps: PatientImmunizationsProps;
    referralsProps: PatientReferralsProps;
    medicationsProps: PatientMedicationsProps;
    medicationsOutsideProps: PatientMedicationsOutsideProps;
    medicationsAllProps: PatientMedicationsAllProps;
    encounterProps: PatientEncountersProps;
    searchProps: PatientRecordSearchProps;
    demographicsProps: PatientDemographicsProps;
    vitalsProps: PatientVitalsProps;
  }>
>;

export type ZusAggregatedProfileSubComponentPropsMap = Partial<{
  allergies: ZusAggregatedProfileSubComponentProps['allergiesProps'];
  'care-gaps': ZusAggregatedProfileSubComponentProps['careGapProps'];
  'care-team': ZusAggregatedProfileSubComponentProps['careTeamProps'];
  'conditions-all': ZusAggregatedProfileSubComponentProps['conditionsAllProps'];
  'diagnostic-reports': ZusAggregatedProfileSubComponentProps['diagnosticReportsProps'];
  documents: ZusAggregatedProfileSubComponentProps['documentsProps'];
  immunizations: ZusAggregatedProfileSubComponentProps['immunizationsProps'];
  medications: ZusAggregatedProfileSubComponentProps['medicationsProps'];
  'medications-outside': ZusAggregatedProfileSubComponentProps['medicationsOutsideProps'];
  referrals: ZusAggregatedProfileSubComponentProps['referralsProps'];
  'medications-all': ZusAggregatedProfileSubComponentProps['medicationsAllProps'];
  encounters: ZusAggregatedProfileSubComponentProps['encounterProps'];
  search: ZusAggregatedProfileSubComponentProps['searchProps'];
  demographics: ZusAggregatedProfileSubComponentProps['demographicsProps'];
  overview: undefined;
  vitals: ZusAggregatedProfileSubComponentProps['vitalsProps'];
}>;

export const ZusAggregatedProfile = withErrorBoundary({
  boundaryName: 'ZusAggregatedProfile',
  includeTelemetryBoundary: true,
  Component: ({
    forceHorizontalTabs = false,
    allergiesProps,
    careGapProps,
    careTeamProps,
    conditionsAllProps,
    diagnosticReportsProps,
    documentsProps,
    immunizationsProps,
    referralsProps,
    medicationsProps,
    medicationsOutsideProps,
    medicationsAllProps,
    encounterProps,
    searchProps,
    demographicsProps,
    vitalsProps,
    resources = DEFAULT_ZAP_TABS,
    hideTitle = false,
    title = 'Outside Records',
    removeBranding = false,
    removeRequestRecords = false,
  }: ZusAggregatedProfileProps) => {
    const hasReferralsFeature = useFeatureFlag('ctw-referrals');
    const hasSearchFeature = useFeatureFlag('ctw-patient-record-search');
    const hasChartReviewFeature = useFeatureFlag('ctw-hpi');
    const hasShowRequestRecordsFeature = useFeatureFlag('ctw-patient-history-form');
    const { tabOrder, setTabOrder } = useContext(ZapTabStateContext);
    const { setProps } = useContext(ZapPropsStateContext);

    // Get the configuration for each tab group by resource type
    const subcomponentProps: ZusAggregatedProfileSubComponentPropsMap = useMemo(
      () => ({
        allergies: allergiesProps,
        'care-gaps': careGapProps,
        'care-team': careTeamProps,
        'conditions-all': conditionsAllProps,
        'diagnostic-reports': diagnosticReportsProps,
        documents: documentsProps,
        encounters: encounterProps,
        immunizations: immunizationsProps,
        referrals: referralsProps,
        medications: medicationsProps,
        'medications-outside': medicationsOutsideProps,
        'medications-all': medicationsAllProps,
        overview: undefined,
        search: searchProps,
        demographics: demographicsProps,
        vitals: vitalsProps,
      }),
      [
        allergiesProps,
        careGapProps,
        careTeamProps,
        conditionsAllProps,
        diagnosticReportsProps,
        documentsProps,
        encounterProps,
        immunizationsProps,
        referralsProps,
        medicationsProps,
        medicationsOutsideProps,
        medicationsAllProps,
        searchProps,
        demographicsProps,
        vitalsProps,
      ],
    );

    // Set tab order here because resource tab order can be changed by the user
    useEffect(() => {
      setProps(subcomponentProps);

      // Filter out tabs that are not enabled.
      const tabs = resources.filter(
        (tabName) =>
          (tabName !== 'referrals' || hasReferralsFeature) &&
          (tabName !== 'search' || hasSearchFeature),
      );

      setTabOrder(tabs);
    }, [
      resources,
      setTabOrder,
      setProps,
      subcomponentProps,
      hasReferralsFeature,
      hasSearchFeature,
      hasChartReviewFeature,
    ]);

    const showRequestRecordsButton = useMemo(
      () => hasShowRequestRecordsFeature && !removeRequestRecords,
      [hasShowRequestRecordsFeature, removeRequestRecords],
    );

    const tabbedContent = useMemo(
      () =>
        // Return tab content filtered down to our set of enabled tabs via tabOrder.
        // This also ensures it is in the same order as tabOrder which ensures that
        // setSelectedTab by name works correctly (tabs and content line up!).
        intersection(tabOrder as ZAPTabName[], resources).map((tabName) => {
          const props = subcomponentProps[tabName] ?? {};
          props.hideRequestRecords = !showRequestRecordsButton;
          return zusAggregatedProfileTabs[tabName](props);
        }),
      [tabOrder, resources, subcomponentProps, showRequestRecordsButton],
    );

    return (
      <div className={tw`max-h-full space-y-2 overflow-auto`}>
        <AlertFeatureFlag featureFlag="ctw-zap-alert" />
        <div className={tw`ctw-zus-aggregated-profile scrollable-pass-through-height space-y-4`}>
          <ZAPTabGroup content={tabbedContent} forceHorizontalTabs={forceHorizontalTabs} />
          {!hideTitle && (
            <Title
              className={twx(
                `border-b-0 border-l-0 border-r-0 border-t-2 border-solid border-background-subtle bg-white px-4`,
              )}
            >
              <div className={tw`flex items-center space-x-1 py-3`}>
                <Heading level="h3" className={tw`m-0 inline-block p-0`}>
                  {title}
                </Heading>
                {!removeBranding && (
                  <div className={tw`flex items-center space-x-2`}>
                    <span className={tw`text-sm font-light italic text-content-subtle`}>
                      Powered by
                    </span>
                    <img
                      src={typeof ZusSVG === 'string' ? ZusSVG : (ZusSVG as { src: string }).src}
                      alt="Zus"
                    />
                  </div>
                )}
              </div>
            </Title>
          )}
        </div>
      </div>
    );
  },
});
