import { PatientModel } from '@ctw/shared/api/fhir/models';
import ZusSVG from '@ctw/shared/assets/zus-logo.svg';
import { Button } from '@ctw/shared/components/button';
import { Heading } from '@ctw/shared/components/heading';
import { navigateIntoView } from '@ctw/shared/content/CCDA/ccda-viewer/helpers/navigate-into-view';
import { DocumentOnlyProps, SectionType } from '@ctw/shared/content/CCDA/ccda-viewer/types';
import { DOCUMENT_HEADER_ID, TABLE_OF_CONTENT_ID } from '@ctw/shared/content/CCDA/constants';
import '@ctw/shared/content/CCDA/styles.scss';
import useHeaderOffset from '@ctw/shared/hooks/use-header-offset';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { faArrowUpRightFromSquare, faBars, faX, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type JSX, ReactNode, useRef, useState } from 'react';
import { getAuthor } from './data/getAuthor';
import { Link } from '@ctw/shared/components/link';
import { useContainerQuery } from '@ctw/shared/hooks/breakpoints';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { getTitle } from '@ctw/shared/content/CCDA/ccda-viewer/components/Header/data/getTitle';
import { getEncounter } from '@ctw/shared/content/CCDA/ccda-viewer/components/Header/data/getEncounter';

type HeaderProps = DocumentOnlyProps & {
  children?: ReactNode;
  sections: SectionType[];
  onClose?: () => void;
  patient: PatientModel;
};

export const Header = ({ document, children, sections, patient }: HeaderProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const headerOffset = useHeaderOffset(DOCUMENT_HEADER_ID);

  const tableOfContents = [
    ...sections.map(({ title, id, isEmpty }) => ({ title, id, isEmpty })),
    { title: 'Demographic Details', id: 'demographic-details', isEmpty: false },
  ];

  return (
    <>
      <div
        className={tw`sticky top-0 z-50 text-lg print:!top-0`}
        id={TABLE_OF_CONTENT_ID}
        ref={containerRef}
        // eslint-disable-next-line no-restricted-syntax
        style={{ top: headerOffset }}
      >
        <TableOfContents document={document} sections={tableOfContents} patient={patient} />
      </div>
      <div className={tw`ccda-header-container space-y-4`}>
        <div className={tw`flex w-full`}>
          <div className={tw`w-fit basis-1/2`}>{children}</div>
        </div>
      </div>
    </>
  );
};

export const DocumentHeader = ({
  onClose,
  actions,
  overridePatient,
  binaryId,
}: {
  onClose?: () => void;
  actions?: ReactNode;
  overridePatient?: PatientModel | undefined;
  binaryId?: string;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { breakpoints } = useContainerQuery({ containerRef });
  const { featureFlags } = useCTW();
  const newTabLink = `/ccda/${binaryId}`;
  // We don't want to show the open in new tab button if we are already in the new tab.
  const showOpenInNewTab =
    typeof window !== 'undefined' && new URL(window.location.href).pathname === newTabLink;

  return (
    <div
      className={tw`sticky top-0 z-50 flex items-center justify-between bg-background-inverse px-5 py-2 text-white`}
      ref={containerRef}
      id={DOCUMENT_HEADER_ID}
    >
      <div className={tw`flex space-x-4 overflow-hidden`}>
        <img
          src={typeof ZusSVG === 'string' ? ZusSVG : (ZusSVG as { src: string }).src}
          alt="Zus"
        />
        <div
          className={twx('flex items-center justify-center gap-1 font-medium', {
            'overflow-hidden text-ellipsis whitespace-nowrap text-sm': breakpoints.isAtLeast.sm,
          })}
        >
          <span>{overridePatient?.firstName}</span>
          <span>{overridePatient?.lastName}</span>
          <span className={tw`uppercase`}>{`(${overridePatient?.gender?.[0]}),`}</span>
          <span>{overridePatient?.dob}</span>
          <span>{`(${overridePatient?.age}yr)`}</span>
        </div>
      </div>
      <div className={tw`flex items-center space-x-2 print:hidden`}>
        {featureFlags.openCCDAInNewTab && !showOpenInNewTab && (
          <Link
            href={newTabLink}
            className={tw`flex items-center gap-2 rounded-full bg-content-main p-3 text-white hover:bg-content-subtle/25 active:bg-content-subtle/25 print:hidden`}
            target="_blank"
          >
            <FontAwesomeIcon className={tw`cursor-pointer`} icon={faArrowUpRightFromSquare} />
          </Link>
        )}
        {actions && <div className={tw`flex`}>{actions}</div>}
        {!showOpenInNewTab && (
          <CloseDocument onClose={onClose}>
            <span className={tw`text-sm font-medium tracking-wider text-white`}>Close</span>
            <FontAwesomeIcon icon={faX} className={tw`h-4 w-4 text-white`} aria-hidden="true" />
          </CloseDocument>
        )}
      </div>
    </div>
  );
};

const TableOfContents = ({
  document,
  sections,
  patient,
}: {
  document: Document;
  sections: SectionType[];
  patient: PatientModel;
}) => {
  const [isHidden, setIsHidden] = useState(false);
  const { trackInteraction } = useTelemetry();

  const handleClick = () => {
    setIsHidden(!isHidden);
    trackInteraction('toggle_document_section', {
      action: isHidden ? 'collapse_document_section' : 'expand_document_section',
    });
  };

  return (
    <div className={tw`flex w-full max-w-[100vw] flex-col overflow-hidden bg-background-hover`}>
      <div className={tw`flex items-center bg-logo-blue px-4 py-2 text-sm uppercase text-white`}>
        <Button
          type="button"
          variant="unstyled"
          className={tw`ccda-section-arrow section print:hidden`}
          onClick={handleClick}
        >
          <span className={tw`hidden`}>hide</span>
          <FontAwesomeIcon icon={isHidden ? faBars : faXmark} className={twx('ml-auto w-4')} />
        </Button>
        in this document
      </div>
      {!isHidden && (
        <div>
          <div className={tw`flex flex-col items-baseline gap-4 px-5 py-4 lg:flex-row`}>
            <TableOfContentsItems className={tw`flex-1`} sections={sections} />
            <PatientDocumentDetails
              className={tw`flex-1`}
              patient={patient}
              document={document}
            />{' '}
          </div>
        </div>
      )}
    </div>
  );
};

const TableOfContentsItems = ({
  className,
  sections,
}: {
  className?: string;
  sections: SectionType[];
}) => {
  const isOutsideModal =
    typeof window !== 'undefined' && new URL(window.location.href).pathname.includes('/ccda/');

  return (
    <div className={twx('flex flex-1 flex-col gap-2', className)}>
      <Heading level="h4" className={tw`text-sm font-semibold uppercase text-content-subtle`}>
        Contents Menu
      </Heading>
      <ol
        className={twx(
          'flex max-h-40 list-inside list-decimal flex-col flex-wrap items-baseline gap-x-4 gap-y-1 md:max-h-70 lg:max-h-full',
        )}
      >
        {sections.map(({ title, id, isEmpty }) => (
          <Button
            key={`el-text-${id}`}
            variant="unstyled"
            className={twx(
              'basis-1/2 text-sm font-medium hover:underline hover:opacity-80 lg:basis-full',
              {
                'text-content-subtle': isEmpty,
              },
            )}
            type="button"
            onClick={() => {
              navigateIntoView(id, isOutsideModal);
            }}
          >
            <li className={tw`list-item`}>
              {title} {isEmpty && ' (empty)'}
            </li>
          </Button>
        ))}
      </ol>
    </div>
  );
};

const PatientDocumentDetails = ({
  className,
  patient,
  document,
}: {
  className?: string;
  patient: PatientModel;
  document: Document;
}) => {
  const title = getTitle(document);
  const encounter = getEncounter(document);
  const encounterType = encounter?.find((item) => item.label === 'Type')?.value;
  const encounterDateTime = encounter?.find((item) => item.label === 'Date/Time')?.value;
  const author = getAuthor(document);
  const authorName = author?.author1?.find((item) => item.label === 'Organization:')?.value;

  return (
    <div className={twx('flex-1 text-sm', className)}>
      <div className={tw`font-semibold uppercase text-content-subtle`}>Details</div>

      <div className={tw`flex gap-4`}>
        <div className={tw`flex flex-col gap-1`}>
          <div className={tw`flex flex-col gap-1`}>
            <div className={tw`whitespace-keep font-medium capitalize`}>patient name:</div>
            <div className={tw`space-x-1`}>
              <span>{patient.firstName}</span>
              <span>{patient.lastName}</span>
              <span className={tw`whitespace-nowrap uppercase`}>{`(${patient.gender?.[0]})`}</span>
            </div>
          </div>
          <div className={tw`flex flex-col`}>
            <div className={tw`whitespace-nowrap font-medium capitalize`}>dob:</div>
            <div>{patient.dob}</div>
          </div>
          <div className={tw`flex flex-col`}>
            <div className={tw`whitespace-nowrap font-medium capitalize`}>phone:</div>
            <div>{patient.phoneNumber}</div>
          </div>
          <div className={tw`flex flex-col`}>
            <div className={tw`whitespace-nowrap font-medium capitalize`}>email:</div>
            <div>{patient.email}</div>
          </div>
        </div>

        <div className={tw`flex flex-col gap-4`}>
          <div className={tw`flex gap-1`}>
            <div className={tw`flex flex-col gap-1`}>
              {title && (
                <div className={tw`flex flex-col`}>
                  <div className={tw`whitespace-nowrap font-medium capitalize`}>title:</div>
                  <div>{title}</div>
                </div>
              )}
              {encounterType && (
                <div className={tw`flex flex-col`}>
                  <div className={tw`whitespace-nowrap font-medium capitalize`}>
                    encounter type:
                  </div>
                  <div>{encounterType}</div>
                </div>
              )}
              {encounterDateTime && (
                <div className={tw`flex flex-col`}>
                  <div className={tw`whitespace-nowrap font-medium capitalize`}>date:</div>
                  <div>{encounterDateTime}</div>
                </div>
              )}
              {authorName && (
                <div className={tw`flex flex-col`}>
                  <div className={tw`whitespace-nowrap font-medium capitalize`}>author:</div>
                  <div>{authorName}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CloseDocument = ({ onClose, children }: { onClose?: () => void; children?: ReactNode }) => {
  const { trackInteraction } = useTelemetry();

  return (
    <Button
      type="button"
      ariaLabel="close"
      variant="unstyled"
      onClick={() => {
        if (onClose) {
          onClose();
        }

        trackInteraction('close_document');
      }}
      className={tw`flex items-center gap-1 rounded-full bg-content-main p-2 hover:bg-content-subtle/25 active:scale-[.98] active:bg-content-subtle/25 print:hidden`}
    >
      {children}
    </Button>
  );
};
