import { isFunction } from 'lodash-es';
import { ReactElement } from 'react';
import { OverviewCard, OverviewCardProps } from '../../components/containers/overview-card';
import { useToggleRead } from '../hooks/use-toggle-read';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { MinRecordItem, RowActionsProp, TableProps } from '@ctw/shared/components/table/table';
import { ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import './overview.scss';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export interface ResourceOverviewCardProps<T extends MinRecordItem>
  extends Omit<OverviewCardProps, 'children' | 'empty'> {
  renderResource: (
    record: T,
    writebackInProgress?: boolean,
  ) => ReactElement<{ record: T; writeBackInProgress?: boolean }>;
  data: T[];
  onRowClick?: TableProps<T>['handleRowClick'];
  telemetryTargetName: string;
  rowClassName?: ClassName;
  RowActions?: RowActionsProp<T>;
}

export const ResourceOverviewCard = <T extends fhir4.Resource, M extends FHIRModel<T>>({
  renderResource,
  data = [],
  onRowClick,
  rowClassName,
  RowActions,
  footerCTA,
  telemetryTargetName,
  ...overviewCardProps
}: ResourceOverviewCardProps<M>) => {
  const { trackInteraction } = useTelemetry();
  const { requestContext } = useCTW();
  const { toggleRead } = useToggleRead();

  function handleRowClick(record: M) {
    if (onRowClick) {
      if (!record.isRead && !record.ownedByBuilder(requestContext.builderId)) {
        void toggleRead(record);
      }
      onRowClick(record);
      trackInteraction('click_row', { target: telemetryTargetName });
    }
  }

  return (
    <OverviewCard
      {...overviewCardProps}
      empty={data.length === 0}
      fullbleed
      footerCTA={
        footerCTA ?
          {
            label: footerCTA.label,
            onClick: () => {
              trackInteraction('see_all_of_resource', { target: telemetryTargetName });
              footerCTA.onClick();
            },
          }
        : undefined
      }
    >
      {data.map((record) => (
        <div
          data-testid="resource-overview-row"
          className={twx('resource-overview-row grouped group relative px-3 py-1.5', rowClassName, {
            'cursor-pointer hover:bg-background-hover': isFunction(onRowClick),
            'last:rounded-b-lg': !footerCTA,
            'text-content-icon': record.isDismissed,
          })}
          key={record.key}
          // Needed for accessibility (eslint and sonar rules).
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleRowClick(record);
            }
          }}
          onClick={() => handleRowClick(record)}
        >
          {renderResource(record)}
          {RowActions && (
            // Add onClick to prevent clicks from propagating to the row.
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
            <div
              aria-label="row-actions"
              className={tw`row-actions resource-overview-actions group invisible absolute group-hover:visible`}
              onClick={(event) => event.stopPropagation()}
            >
              <RowActions record={record} />
            </div>
          )}
        </div>
      ))}
    </OverviewCard>
  );
};
