import { useContext } from 'react';
import { OverviewResources, PatientOverview } from './patient-overview';
import { ZapTabStateContext } from '@ctw/shared/context/zap-tab-state-context';
import { tw } from '@ctw/shared/utils/tailwind';

export interface PatientOverviewZapProps {
  hideRequestRecords?: boolean;
}

export const PatientOverviewZap = ({ hideRequestRecords = false }: PatientOverviewZapProps) => {
  const { setSelectedTab } = useContext(ZapTabStateContext);

  function handleSeeAllResources(resource: OverviewResources) {
    switch (resource) {
      case 'conditions':
        setSelectedTab('conditions-all');
        break;
      case 'referrals':
        setSelectedTab('referrals');
        break;
      case 'medications':
        setSelectedTab('medications-all');
        break;
      case 'diagnostics':
        setSelectedTab('diagnostic-reports');
        break;
      case 'encounters':
        setSelectedTab('encounters');
        break;
      case 'vitals':
        setSelectedTab('vitals');
        break;
      case 'care-gaps':
        setSelectedTab('care-gaps');
        break;
      default:
    }
  }

  return (
    <PatientOverview
      className={tw`pt-5`}
      onSeeAllResources={handleSeeAllResources}
      hideRequestRecords={hideRequestRecords}
    />
  );
};
