import {
  faFaceFrown,
  faFaceGrinHearts,
  faFaceMeh,
  faFaceSmile,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './feedback-item.scss';
import { tw, twx } from '@ctw/shared/utils/tailwind';

export type FeedbackItemProps = {
  score: number;
  radioGroupName: string;
  itemText: string;
  selectedScore: boolean;
  onChange: (score: number) => void;
  className: string;
};

export const FeedbackItem: React.FC<FeedbackItemProps> = ({
  score,
  radioGroupName,
  selectedScore,
  onChange,
  itemText,
  className,
}) => {
  const emojis = [faFaceFrown, faFaceMeh, faFaceSmile, faFaceGrinHearts];

  const onClick = () => {
    onChange(score);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onChange(score);
    }
  };

  return (
    <div
      className={twx(className, 'ai-feedback-item', {
        'bg-primary-background-hover': selectedScore,
      })}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <input
        className={tw`ctw-custom-radio`}
        type="radio"
        name={radioGroupName}
        value={score}
        checked={selectedScore}
        onChange={() => onChange(score)}
      />
      <div>{itemText}</div>
      <FontAwesomeIcon icon={emojis[score - 1]} className={tw`h-5 w-5`} />
    </div>
  );
};
