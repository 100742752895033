import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { cloneDeep, entries, isError } from 'lodash-es';
import { RequestError } from '../../utils/error';
import { ReactQueryError } from '../../utils/react-query-error';
import { Session } from '../../utils/session';
import { getCTWBaseUrl } from '@ctw/shared/api/urls';
import { Env } from '@ctw/shared/context/types';
import { Diff, Intersection } from 'utility-types';
import { WithLimitedKeys } from '@ctw/shared/utils/types';
import { ZusService } from '@ctw/shared/context/zus-ui-provider';
import { CTWRequestContext, CTWState } from '@ctw/shared/context/ctw-context';
import { TelemetryUserInteraction } from '@ctw/shared/context/telemetry/user-interactions';
import { DelegatedLogger } from '@ctw/shared/utils/delegated-logger';

export interface WithTimerMetricOperationFnContext {
  setScopedContextProperties: (properties: Record<string, unknown>) => void;
  setScopedContextProperty: (name: string, value: unknown) => void;
}

interface StartTimerMetricContext {
  setScopedContextProperties: (properties: Record<string, unknown>) => void;
  setScopedContextProperty: (name: string, value: unknown) => void;
}

type TelemetryMetricType = 'query' | 'render_component' | 'render_page';
type TelemetryEnv = 'dev' | 'prod';

interface TelemetryInitArgs {
  requestContext: CTWRequestContext;
  ctwFetch: CTWState['ctwFetch'];
}

type TelemetryInterface = typeof Telemetry;
interface DeferredTelemetryEvents {
  setGlobalContextProperty: Parameters<TelemetryInterface['setGlobalContextProperty']>[];
  trackError: Parameters<TelemetryInterface['trackError']>[0][];
  trackInteraction: Parameters<TelemetryInterface['trackInteraction']>[];
  trackMetric: Parameters<TelemetryInterface['trackMetric']>[];
  logger: (
    | ['debug', ...Parameters<DelegatedLogger['debug']>]
    | ['info', ...Parameters<DelegatedLogger['info']>]
    | ['warn', ...Parameters<DelegatedLogger['warn']>]
    | ['error', ...Parameters<DelegatedLogger['error']>]
  )[];
}

type InternalDatadogLogsInitConfiguration = Parameters<typeof datadogLogs.init>[0];
type InternalDatadogRumInitConfiguration = Parameters<typeof datadogRum.init>[0];
type DatadogCommonInitConfiguration = Omit<
  WithLimitedKeys<
    Intersection<InternalDatadogLogsInitConfiguration, InternalDatadogRumInitConfiguration>
  >,
  'beforeSend'
>;
type DatadogLogsInitConfiguration = WithLimitedKeys<
  Diff<InternalDatadogLogsInitConfiguration, DatadogCommonInitConfiguration>
>;
type DatadogRumInitConfiguration = WithLimitedKeys<
  Diff<InternalDatadogRumInitConfiguration, DatadogCommonInitConfiguration>
>;

type DatadogTelemetryServiceConfig = {
  global: WithLimitedKeys<DatadogCommonInitConfiguration>;
  logs: WithLimitedKeys<DatadogLogsInitConfiguration>;
  rum: WithLimitedKeys<DatadogRumInitConfiguration>;
};

type CommonDatadogTelemetryServiceConfig = {
  global: Partial<DatadogTelemetryServiceConfig['global']>;
  logs: Partial<DatadogTelemetryServiceConfig['logs']>;
  rum: Partial<DatadogTelemetryServiceConfig['rum']>;
};

type TelemetryConfigs = {
  [env in TelemetryEnv]: {
    [service in ZusService]: {
      datadog: WithLimitedKeys<DatadogTelemetryServiceConfig>;
    };
  };
};

const telemetryEnvPatternMappings = {
  dev: [/^([a-z]+)\.(dev|phitest)\.(zushealth|zusapi)\.com$/i, /localhost/i, /127\.0\.0\.1/i],
  prod: [/^([a-z]+)\.(sandbox\.)?(zushealth|zusapi)\.com$/i],
} as const;

const datadogCommonEnvironmentConfig: CommonDatadogTelemetryServiceConfig = {
  global: {
    site: 'datadoghq.com',
    useSecureSessionCookie: true,
    usePartitionedCrossSiteSessionCookie: true,
    telemetrySampleRate: 100,
    telemetryConfigurationSampleRate: 100,
    telemetryUsageSampleRate: 100,
    sessionSampleRate: 100,
  },
  logs: {
    forwardConsoleLogs: ['error'],
    forwardErrorsToLogs: true,
  },
  rum: {
    allowedTracingUrls: [],
    defaultPrivacyLevel: 'mask',
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    sessionReplaySampleRate: 100,
  },
} as const;

const datadogDevEnvironmentConfig: DatadogTelemetryServiceConfig = {
  global: {
    clientToken: 'pub29b659b1cd402a88d57c4f8c923c1eea',
    ...datadogCommonEnvironmentConfig.global,
  },
  logs: {
    ...datadogCommonEnvironmentConfig.logs,
  },
  rum: {
    applicationId: '48fec1f8-b187-492a-afdd-e809cc6b3b82',
    ...datadogCommonEnvironmentConfig.rum,
  },
} as const;

const datadogProdEnvironmentConfig: DatadogTelemetryServiceConfig = {
  global: {
    clientToken: 'pub6c5b408646325f595789b3e8127211fd',
    ...datadogCommonEnvironmentConfig.global,
  },
  logs: {
    ...datadogCommonEnvironmentConfig.logs,
  },
  rum: {
    applicationId: '3dfeeabc-d289-4fe4-8118-06c9817f3c27',
    ...datadogCommonEnvironmentConfig.rum,
  },
} as const;

const telemetryConfigs: TelemetryConfigs = {
  dev: {
    standalone: {
      datadog: datadogDevEnvironmentConfig,
    },
    zap: {
      datadog: datadogDevEnvironmentConfig,
    },
    'smart-on-fhir': {
      datadog: datadogDevEnvironmentConfig,
    },
  },
  prod: {
    standalone: {
      datadog: datadogProdEnvironmentConfig,
    },
    zap: {
      datadog: datadogProdEnvironmentConfig,
    },
    'smart-on-fhir': {
      datadog: datadogProdEnvironmentConfig,
    },
  },
} as const;

const REDACTED_HEADERS = [
  'cookie',
  'set-cookie',
  'x-middleware-set-cookie',
  'authorization',
] as const;

/**
 * Telemetry via DataDog Logs, DataDog RUM, and Amplitude.
 */
export class Telemetry {
  static logger = new DelegatedLogger({
    beforeDebug: (tag, message, context) => {
      if (this.isInitialized) {
        datadogLogs.logger.debug(`[${tag}] ${message}`, context);
      } else {
        this.deferredEvents.logger.push(['debug', tag, message, context]);
      }

      return { logToConsole: this.telemetryEnv === 'dev' };
    },
    beforeInfo: (tag, message, context) => {
      if (this.isInitialized) {
        datadogLogs.logger.info(`[${tag}] ${message}`, context);
      } else {
        this.deferredEvents.logger.push(['info', tag, message, context]);
      }

      return { logToConsole: this.telemetryEnv === 'dev' };
    },
    beforeWarn: (tag, message, context) => {
      if (this.isInitialized) {
        datadogLogs.logger.warn(`[${tag}] ${message}`, context);
      } else {
        this.deferredEvents.logger.push(['warn', tag, message, context]);
      }

      return { logToConsole: this.telemetryEnv === 'dev' };
    },
    beforeError: (tag, message, context) => {
      if (this.isInitialized) {
        datadogLogs.logger.error(`[${tag}] ${isError(message) ? message.message : message}`, {
          ...context,
          ...(typeof message === 'string' ? {} : { error: message }),
        });
      } else {
        this.deferredEvents.logger.push(['error', tag, message, context]);
      }

      return { logToConsole: this.telemetryEnv === 'dev' };
    },
  });

  private static configHash?: number;

  private static requestContext: CTWRequestContext;

  private static telemetryEnv: TelemetryEnv;

  private static patientUpid: string | undefined;

  private static ctwFetch: CTWState['ctwFetch'];

  private static deferredEvents: DeferredTelemetryEvents = {
    setGlobalContextProperty: [],
    trackError: [],
    trackInteraction: [],
    trackMetric: [],
    logger: [],
  };

  private static scopedContextProperties: Record<string, Record<string, unknown> | undefined> = {};

  static isInitialized = false;

  static init({ requestContext, ctwFetch }: TelemetryInitArgs) {
    this.requestContext = cloneDeep(requestContext);
    // TODO: Remove ctwFetch here once we no longer need to proxy amplitude events through the backend
    this.ctwFetch = ctwFetch;
    this.requestContext.serviceEnv = this.normalizeServiceEnv(this.requestContext.serviceEnv);
    this.telemetryEnv = this.determineTelemetryEnv();

    this.initGlobalContextProperties();

    if (this.shouldSkipSending() && this.configHash === undefined) {
      this.logger.info('Telemetry', 'Telemetry data sending is disabled in this environment', {
        service: this.requestContext.service,
        serviceEnv: this.requestContext.serviceEnv,
        telemetryEnv: this.telemetryEnv,
      });
    }

    if (datadogLogs.getInternalContext() === undefined) {
      datadogLogs.init({
        ...telemetryConfigs[this.telemetryEnv][this.requestContext.service].datadog.global,
        ...telemetryConfigs[this.telemetryEnv][this.requestContext.service].datadog.logs,
        env: this.requestContext.serviceEnv,
        service: this.requestContext.service,
        version: this.requestContext.serviceVersion,
      });
    }

    if (!this.shouldSkipSending() && datadogRum.getInternalContext() === undefined) {
      datadogRum.init({
        ...telemetryConfigs[this.telemetryEnv][requestContext.service].datadog.global,
        ...telemetryConfigs[this.telemetryEnv][requestContext.service].datadog.rum,
        env: this.requestContext.serviceEnv,
        service: this.requestContext.service,
        version: this.requestContext.serviceVersion,
      });
    }

    const newConfigHash = this.getConfigHash(requestContext);
    if (this.isInitialized) {
      if (this.configHash !== newConfigHash) {
        this.configHash = newConfigHash;

        this.logger.info('Telemetry', 'Reinitializing telemetry with new configuration', {
          service: this.requestContext.service,
          serviceEnv: this.requestContext.serviceEnv,
          telemetryEnv: this.telemetryEnv,
        });
      }
    }

    this.isInitialized = true;
    this.flushDeferredEvents();
  }

  static setPatient({
    patientID,
    patientResourceID,
    systemURL,
    patientUPID,
  }: {
    patientID?: string;
    patientResourceID?: string;
    systemURL?: string;
    patientUPID?: string;
  }): void {
    this.patientUpid = patientUPID;
    this.setGlobalContextProperty('patientUPID', this.patientUpid);
    this.setGlobalContextProperty('patientContext', {
      patientUPID,
      patientID,
      patientResourceID,
      systemURL,
    });
  }

  static resetPatient(): void {
    this.patientUpid = undefined;
    this.setGlobalContextProperty('patientContext', undefined);
    this.setGlobalContextProperty('patientUPID', undefined);
  }

  static trackError({
    tag,
    message: maybeMessage,
    error,
    context = {},
  }: {
    tag: string;
    message?: string;
    error: Error | unknown;
    context?: Record<string, unknown>;
  }): void {
    if (!this.isInitialized) {
      this.deferredEvents.trackError.push({ tag, message: maybeMessage, error, context });
      return;
    }

    const serializedError = this.serializeError(error);
    const message = maybeMessage ?? serializedError.message;

    const errorContext: Record<string, unknown> = {
      serializedError,
      ...context,
    };

    if (error instanceof RequestError) {
      errorContext.statusCode = error.statusCode;
    }

    if (error instanceof ReactQueryError) {
      errorContext.query = {
        queryKeys: error.otherQueryKeys,
        identifier: error.identifier,
        requestErrors: error.requestErrors,
        responseErrors: error.responseErrors,
      };
    }

    this.logger.error(tag, message, {
      error: {
        ...errorContext,
      },
    });

    if (!this.shouldSkipSending()) {
      datadogRum.addError(error, errorContext);
    }
  }

  static trackInteraction(
    action: TelemetryUserInteraction,
    context: Record<string, unknown> = {},
  ): void {
    if (!this.isInitialized) {
      this.deferredEvents.trackInteraction.push([action, context]);
      return;
    }

    void this.reportActiveSession();

    if (!this.shouldSkipSending()) {
      datadogRum.addAction(action, context);
      void this.analyticsEvent(action, context);
    }
  }

  static trackMetric(name: string, context: Record<string, unknown> = {}): void {
    if (!this.isInitialized) {
      this.deferredEvents.trackMetric.push([name, context]);
      return;
    }

    if (!this.shouldSkipSending()) {
      datadogRum.addAction(`ctw.metric.${name}`, context);
    }
  }

  static withTimerMetric<TReturn, TContext extends Record<string, unknown>>(
    type: TelemetryMetricType,
    id: string,
    operationFn: (context: TContext & WithTimerMetricOperationFnContext) => Promise<TReturn>,
  ): (context: TContext) => Promise<TReturn> {
    return /* operationFnCurriedWithTimer */ async (context) => {
      this.scopedContextProperties = {};
      const vitalName = `ctw.${type}.${id}`;

      const setScopedContextProperties = (properties: Record<string, unknown>) => {
        Object.entries(properties).forEach(([name, value]) => {
          this.setScopedContextProperty(vitalName, name, value);
        });
      };

      const setScopedContextProperty = (name: string, value: unknown) => {
        this.setScopedContextProperty(vitalName, name, value);
      };

      let returnValue;
      try {
        this.startTimerMetric(type, id);
        returnValue = await operationFn({
          ...context,
          setScopedContextProperties,
          setScopedContextProperty,
        });
      } catch (e) {
        returnValue = undefined;
        throw e;
      } finally {
        this.stopTimerMetric(type, id);
      }

      return returnValue;
    };
  }

  static startTimerMetric(
    type: TelemetryMetricType,
    id: string,
    context?: Record<string, unknown>,
  ): StartTimerMetricContext {
    const vitalName = `ctw.${type}.${id}`;

    if (!this.scopedContextProperties[vitalName]) {
      this.scopedContextProperties[vitalName] = {};
    }

    const setScopedContextProperties = (properties: Record<string, unknown>) => {
      Object.entries(properties).forEach(([name, value]) => {
        this.setScopedContextProperty(vitalName, name, value);
      });
    };

    const setScopedContextProperty = (name: string, value: unknown) => {
      this.setScopedContextProperty(vitalName, name, value);
    };

    if (!this.shouldSkipSending()) {
      datadogRum.startDurationVital(vitalName, {
        context: context ?? {},
      });
    }

    return { setScopedContextProperties, setScopedContextProperty };
  }

  static stopTimerMetric(
    type: TelemetryMetricType,
    id: string,
    context?: Record<string, unknown>,
  ): void {
    const vitalName = `ctw.${type}.${id}`;

    if (this.shouldSkipSending() || !this.hasScopedContextProperties(vitalName)) {
      return;
    }

    datadogRum.stopDurationVital(vitalName, {
      context: { ...this.flushScopedContextProperties(vitalName), ...(context ?? {}) },
    });
  }

  static trackTimerMetric(
    type: TelemetryMetricType,
    id: string,
    startTime: number,
    endTime: number,
    context?: Record<string, unknown>,
  ): void {
    const vitalName = `ctw.${type}.${id}`;

    if (this.shouldSkipSending()) {
      return;
    }

    datadogRum.addDurationVital(vitalName, {
      startTime,
      duration: endTime - startTime,
      context,
    });
  }

  static redactSensitiveHttpHeaders(headers: HeadersInit | undefined): Record<string, unknown> {
    if (headers === undefined) {
      return {};
    }

    const headersRecord: Record<string, unknown> =
      Array.isArray(headers) ? Object.fromEntries(headers) : (headers as Record<string, unknown>);

    for (const [key, value] of Object.entries(headers)) {
      if (REDACTED_HEADERS.includes(key.toLowerCase() as never)) {
        headersRecord[key] = '[REDACTED]';
      } else {
        headersRecord[key] = value;
      }
    }

    return headersRecord;
  }

  private static setScopedContextProperty(scope: string, key: string, value: unknown): void {
    if (!this.scopedContextProperties[scope]) {
      this.scopedContextProperties[scope] = {};
    }

    this.scopedContextProperties[scope][key] = value;
  }

  private static hasScopedContextProperties(scope: string): boolean {
    return Boolean(this.scopedContextProperties[scope]);
  }

  private static flushScopedContextProperties(scope: string): Record<string, unknown> {
    const properties = { ...(this.scopedContextProperties[scope] ?? {}) };
    delete this.scopedContextProperties[scope];
    return properties;
  }

  private static flushDeferredEvents(): void {
    const deferredEvents = { ...this.deferredEvents };
    this.deferredEvents = {
      setGlobalContextProperty: [],
      trackError: [],
      trackInteraction: [],
      trackMetric: [],
      logger: [],
    };

    deferredEvents.setGlobalContextProperty.forEach(([key, value]) => {
      this.setGlobalContextProperty(key, value);
    });

    deferredEvents.trackError.forEach(({ tag, message, error, context }) => {
      this.trackError({
        tag,
        message,
        error,
        context: {
          ...context,
          deferred: true,
        },
      });
    });

    deferredEvents.trackInteraction.forEach(([action, context]) => {
      this.trackInteraction(action, { ...context, deferred: true });
    });

    deferredEvents.trackMetric.forEach(([name, context]) => {
      this.trackMetric(name, { ...context, deferred: true });
    });

    deferredEvents.logger.forEach(([level, tag, message, context]) => {
      switch (level) {
        case 'debug':
          this.logger.debug(tag, message, { ...context, deferred: true });
          break;
        case 'info':
          this.logger.info(tag, message, { ...context, deferred: true });
          break;
        case 'warn':
          this.logger.warn(tag, message, { ...context, deferred: true });
          break;
        case 'error':
          this.logger.error(tag, message, { ...context, deferred: true });
          break;
        default:
          this.logger.error('Telemetry', `Unknown log level: ${level}`, {
            level,
            tag,
            message,
            context,
            deferred: true,
          });
      }
    });
  }

  /* private */ static determineTelemetryEnv(): TelemetryEnv {
    const { hostname } = window.location;

    for (const [env, patterns] of entries(telemetryEnvPatternMappings)) {
      for (const pattern of patterns) {
        if (pattern.test(hostname)) {
          return env as TelemetryEnv;
        }
      }
    }

    return 'dev';
  }

  /**
   * Report User analytic events
   */
  private static async analyticsEvent(
    eventName: string,
    eventProperties: Record<string, unknown>,
  ): Promise<void> {
    if (this.shouldSkipSending()) {
      return;
    }

    try {
      const analyticEvent = {
        event: eventName,
        metadata: {
          ...eventProperties,
          ehr: this.requestContext.ehr,
          version: this.requestContext.serviceVersion,
          upid: this.patientUpid,
        },
      };

      await this.ctwFetch(`${getCTWBaseUrl(this.requestContext.serviceEnv)}/report/analytic`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.requestContext.authToken}`,
        },
        // Base64 encode the event name and user information
        body: JSON.stringify(analyticEvent),
        mode: 'cors',
      });
    } catch (error) {
      this.trackError({
        tag: 'Telemetry',
        message: 'Telemetry error tracking analytics event',
        error,
      });
    }
  }

  private static serializeError(error: Error | unknown): {
    name: string;
    message: string;
    [key: string]: unknown;
  } {
    const serializedError: Record<string, unknown> = {};

    if (typeof error === 'object') {
      Object.getOwnPropertyNames(error).forEach((propertyName) => {
        serializedError[propertyName] = (error as never)[propertyName];
      });
    }

    return {
      name: 'Unknown',
      message: 'Unknown',
      ...serializedError,
    };
  }

  private static normalizeServiceEnv(env: Env) {
    const loweredEnv = env.toLowerCase();
    switch (loweredEnv) {
      case 'dev':
      case 'development':
        return 'dev';
      case 'prod':
      case 'production':
        return 'prod';
      default:
        return loweredEnv;
    }
  }

  private static initGlobalContextProperties(): void {
    this.setGlobalContextProperty('ehr', this.requestContext.ehr);
    this.setGlobalContextProperty('builderId', this.requestContext.builderId);
    this.setGlobalContextProperty('service', this.requestContext.service);
    this.setGlobalContextProperty('serviceEnv', this.requestContext.serviceEnv);
    this.setGlobalContextProperty('serviceVersion', this.requestContext.serviceVersion);
    this.setGlobalContextProperty('serviceVariant', this.requestContext.serviceVariant);

    Session.setSessionUserId(this.requestContext.authTokenState.zusUserId);

    datadogLogs.setUser(this.requestContext.authTokenState);
    datadogRum.setUser(this.requestContext.authTokenState);
  }

  private static shouldSkipSending(): boolean {
    return this.telemetryEnv !== 'prod';
  }

  private static setGlobalContextProperty(key: string, value: unknown): void {
    datadogLogs.setGlobalContextProperty(key, value);
    datadogRum.setGlobalContextProperty(key, value);
  }

  private static async reportActiveSession(): Promise<void> {
    try {
      if (Session.isActive()) {
        return;
      }

      Session.setSessionUserId(this.requestContext.authTokenState.zusUserId);
      Session.setSessionLastActiveTimestamp();
    } catch (error) {
      this.trackError({
        tag: 'Telemetry',
        message: 'Telemetry error reporting active session',
        error,
      });
    }
  }

  private static getConfigHash(config: object) {
    return (
      JSON.stringify(config)
        .split('')
        // eslint-disable-next-line no-bitwise
        .reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0)
    );
  }
}
