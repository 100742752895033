import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { FilterItem } from '@ctw/shared/components/filter-bar/filter-bar-types';

export const vitalFilters: FilterItem[] = [
  {
    key: 'showHidden', // Special key for filtering. See utils/filters.ts.
    type: 'tag',
    display: 'show hospital results',
    icon: faEye,
    toggleDisplay: 'hide hospital results',
    toggleIcon: faEyeSlash,
  },
];
