import { canonicalizeContactInfo } from './helpers/canonicalization';
import { useMatchedPatients } from '@ctw/shared/api/fhir/patient-helper';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { Table } from '@ctw/shared/components/table/table';
import { canonicalContactColumns } from '@ctw/shared/content/demographics/helpers/columns';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';

export interface PatientContactInformationProps {
  className?: string;
}

export const PatientContactInformation = withErrorBoundary({
  boundaryName: 'PatientContactInformation',
  includeTelemetryBoundary: true,
  Component: ({ className }: PatientContactInformationProps) => {
    const { requestContext } = useCTW();
    const matchedPatients = useMatchedPatients({
      includeCurrentBuilder: true,
    });

    if (!matchedPatients.data) {
      return <LoadingSpinner centered message="Loading..." />;
    }

    const canonicalContactInfo = canonicalizeContactInfo(
      requestContext.builderId,
      matchedPatients.data,
    );

    return (
      <div className={twx(className, 'scrollable-pass-through-height pt-5')}>
        <div className={tw`flex flex-col justify-between gap-5`}>
          <div data-testid="phone-numbers">
            <Table
              className={tw`rounded-md !border border-background-subtle`}
              records={canonicalContactInfo.phones}
              columns={canonicalContactColumns('Phone Number')}
              emptyMessage="No phone numbers found for this patient."
            />
          </div>
          <div data-testid="email-addresses">
            <Table
              className={tw`rounded-md !border border-background-subtle`}
              records={canonicalContactInfo.emails}
              columns={canonicalContactColumns('Email Address')}
              emptyMessage="No email addresses found for this patient."
            />
          </div>
          <div data-testid="addresses">
            <Table
              className={tw`rounded-md !border border-background-subtle`}
              records={canonicalContactInfo.addresses}
              columns={canonicalContactColumns('Address')}
              emptyMessage="No addresses found for this patient."
            />
          </div>
        </div>
      </div>
    );
  },
});
