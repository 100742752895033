import { useState } from 'react';
import { DocumentModel } from '@ctw/shared/api/fhir/models';
import { FileIcon } from '@ctw/shared/components/icons/file';
import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import { DetailsCard } from '@ctw/shared/content/resource/helpers/details-card';
import { getNoteDisplay, NotesProps } from '@ctw/shared/content/resource/helpers/notes';
import { NoteSummary } from '@ctw/shared/content/resource/helpers/notes-entry';
import { tw, twx } from '@ctw/shared/utils/tailwind';

export const EncounterNotes = ({ entries }: NotesProps) => (
  <div className={tw`space-y-4`}>
    <div className={tw`text-lg font-semibold`}>Notes</div>
    {entries.map((entry) => (
      <DocumentCard doc={entry} key={entry.id} />
    ))}
  </div>
);

// Component for displaying a document with multiple notes
export const DocumentCard = ({ doc }: { doc: DocumentModel }) => {
  const { binaryId, title } = doc;

  return (
    <div
      className={tw`space-y-2 rounded-lg bg-white p-3 text-left outline outline-1 outline-border-main`}
      id={binaryId}
      key={binaryId}
    >
      <span
        className={twx(
          'flex justify-between',
          doc.sectionDocuments?.length && 'border-b border-solid border-divider-main pb-2',
        )}
      >
        <span className={tw`inline-flex items-center space-x-2`}>
          <span className={tw`mt-1 h-full`}>
            {binaryId && <FileIcon className={tw`h-5 fill-content-icon`} />}
          </span>
          <span className={tw`text-base font-medium text-background-inverse`}>{title}</span>
        </span>
        <span className={tw`space-y-2`}>
          <ResponsiveSourceDocumentLink
            binaryId={binaryId}
            text="Open"
            showContentTypeIcon={false}
          />
        </span>
      </span>
      {doc.sectionDocuments?.map((entry) => <DocumentSection section={entry} key={entry.id} />)}
    </div>
  );
};

const DocumentSection = ({ section }: { section: DocumentModel }) => {
  const [isDetailShown, setIsDetailShown] = useState(false);
  return (
    <div
      key={section.id}
      className={twx(
        isDetailShown && 'divide-y rounded-lg border-y-border-main bg-background-hover px-4',
      )}
    >
      <NoteSummary
        summary={
          <>
            {section.title && <div>{section.title}</div>}
            {!section.title && <div className={tw`text-content-icon`}>Unknown</div>}
          </>
        }
        isDetailShown={isDetailShown}
        setIsDetailShown={setIsDetailShown}
        className={twx(
          'section-note-expandable',
          isDetailShown ? 'rounded-t-lg bg-background-hover' : 'rounded-lg px-4',
        )}
      />
      {isDetailShown && (
        <DetailsCard
          details={[
            {
              value: getNoteDisplay(section.displayContent),
              transposeTables: true,
            },
          ]}
          hideEmpty={false}
          className={tw`py-2 text-sm`}
          hideHeader
        />
      )}
    </div>
  );
};
