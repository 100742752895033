import { CTW_MODAL_ID } from './constants';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { Modal, ModalProps } from '@ctw/shared/components/modal';
import { ViewDocument } from '@ctw/shared/content/CCDA/view-document';
import { useModal } from '@ctw/shared/context/modal-hooks';
import { tw } from '@ctw/shared/utils/tailwind';

export function useCCDAModal(patient?: PatientModel) {
  const { openModal } = useModal();

  return (binaryId: string, title: string) => {
    openModal({
      component: (props) => (
        <CCDAModal fileName={title} binaryId={binaryId} patient={patient} {...props} />
      ),
    });
  };
}

type CCDAModalProps = {
  binaryId: string;
  fileName: string | undefined;
  onClose: () => void;
  patient?: PatientModel;
} & Omit<ModalProps, 'title' | 'children'>;

const CCDAModal = ({ binaryId, fileName, onClose, patient, ...modalProps }: CCDAModalProps) => (
  <Modal
    {...modalProps}
    onClose={onClose}
    id={CTW_MODAL_ID}
    className={tw`min-w-full !max-w-3xl !p-0`}
  >
    <div className={tw`w-full items-center`}>
      <ViewDocument patient={patient} binaryId={binaryId} fileName={fileName} onClose={onClose} />
    </div>
  </Modal>
);
