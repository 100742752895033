import { useCallback, useState } from 'react';
import { toggleDismiss } from '@ctw/shared/api/fhir/basic';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { notify } from '@ctw/shared/components/toast';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

interface UseToggleDismissResult {
  /**
   * Function to call to toggle the archive status of the FHIR model
   */
  toggleDismiss: (model: FHIRModel<fhir4.Resource>) => Promise<void>;

  /**
   * True when `toggleArchive` is called
   */
  isLoading: boolean;
}

/**
 * This hook is toggles the dismiss status for the specified FHIR model.
 */
export function useToggleDismiss(): UseToggleDismissResult {
  const ctw = useCTW();
  const telemetry = useTelemetry();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleToggleDismiss = useCallback(
    async (model: FHIRModel<fhir4.Resource>) => {
      setIsLoading(true);
      await toggleDismiss(model, ctw, telemetry);
      notify({
        type: 'info',
        title: `${model.title} ${model.isDismissed ? 'dismissed' : 'restored'}.`,
      });
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    toggleDismiss: handleToggleDismiss,
    isLoading,
  };
}
