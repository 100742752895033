import { useState } from 'react';
import { PatientContactInformation } from '@ctw/shared/content/demographics/patient-contact-information';
import {
  DemographicsTabName,
  PatientInformationTabs,
} from '@ctw/shared/content/demographics/demographics-tabs';
import { PatientSources } from '@ctw/shared/content/patient-sources/patient-sources';
import { twx } from '@ctw/shared/utils/tailwind';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';

export interface PatientDemographicsProps {
  className?: string;
}

export const PatientDemographics = withErrorBoundary({
  boundaryName: 'PatientDemographics',
  includeTelemetryBoundary: true,
  Component: ({ className }: PatientDemographicsProps) => {
    const [selectedTab, setSelectedTab] = useState<DemographicsTabName>('Contact Information');

    return (
      <div className={twx(className, 'flex flex-col gap-3 py-3')}>
        <PatientInformationTabs selectedTab={selectedTab} onTabSelected={setSelectedTab} />
        {selectedTab === 'Contact Information' && <PatientContactInformation />}
        {selectedTab === 'Sources' && <PatientSources />}
      </div>
    );
  },
});
