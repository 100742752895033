import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFileImage, faFileLines, faFilePdf } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DocumentModel } from '@ctw/shared/api/fhir/models/document';
import { TableColumn } from '@ctw/shared/components/table/table';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '@ctw/shared/content/resource/helpers/resource-title-column';
import { tw } from '@ctw/shared/utils/tailwind';

export const patientDocumentColumns = (userBuilderId: string): TableColumn<DocumentModel>[] => [
  {
    widthPercent: 37,
    minWidth: 200,
    title: 'Title',
    render: (document) => (
      <ResourceTitleColumn
        title={document.title}
        renderIcon={RenderSyncedWithRecordIcon(document.ownedByBuilder(userBuilderId))}
      />
    ),
  },
  {
    widthPercent: 8,
    minWidth: 128,
    title: 'Type',
    render: (document) => renderDocumentTypeColumn(document),
  },
  {
    widthPercent: 15,
    minWidth: 128,
    title: 'Encounter Date',
    dataIndex: 'encounterDate',
  },
  {
    widthPercent: 16,
    minWidth: 128,
    title: 'Date Retrieved',
    dataIndex: 'dateCreated',
  },
  {
    widthPercent: 24,
    minWidth: 200,
    title: 'Author',
    dataIndex: 'custodian',
  },
];

const renderDocumentTypeColumn = (document: DocumentModel) => {
  let docTypeIcon: IconProp | undefined;
  let docTypeDescription = '';

  if (document.isCDA) {
    docTypeIcon = faFileLines;
    docTypeDescription = 'CDA';
  } else if (document.isImage) {
    docTypeIcon = faFileImage;
    docTypeDescription = document.contentType?.split('/')[1]?.toUpperCase() ?? '';
  } else if (document.isPdf) {
    docTypeIcon = faFilePdf;
    docTypeDescription = 'PDF';
  }

  if (docTypeIcon) {
    return (
      <div className={tw`flex items-center space-x-2`}>
        <FontAwesomeIcon
          className={tw`h-[14px] w-[14px] text-content-subtle`}
          icon={docTypeIcon}
          title={docTypeDescription}
        />
        <span>{docTypeDescription}</span>
      </div>
    );
  }

  return <></>;
};
