import { capitalize } from 'lodash-es';
import { AllergyModel } from '@ctw/shared/api/fhir/models/allergies';
import { TableColumn } from '@ctw/shared/components/table/table';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '@ctw/shared/content/resource/helpers/resource-title-column';
import { tw } from '@ctw/shared/utils/tailwind';
import { CopyToClipboard } from '@ctw/shared/components/copy-to-clipboard';

export const patientAllergiesColumns = (builderId: string) => {
  const allergyColumns: TableColumn<AllergyModel>[] = [
    {
      title: 'Name',
      render: (allergy) => (
        <ResourceTitleColumn
          title={<CopyToClipboard>{allergy.display}</CopyToClipboard>}
          renderIcon={RenderSyncedWithRecordIcon(allergy.ownedByBuilder(builderId))}
        />
      ),
    },
    {
      title: 'Last Updated',
      render: (allergy) => (
        <div>
          {allergy.recordedDate ?
            <>
              <div className={tw`text-sm font-normal`}>Last Updated: {allergy.recordedDate}</div>
              <div>{allergy.patientOrganizationName}</div>
            </>
          : <div>{allergy.patientOrganizationName}</div>}
        </div>
      ),
    },
    {
      title: 'Details',
      render: (allergy) => (
        <div className={tw`font-normal`}>
          {!!allergy.manifestations && allergy.manifestations !== 'unknown' && (
            <div>Reaction: {capitalize(allergy.manifestations)}</div>
          )}
        </div>
      ),
    },
  ];

  return allergyColumns;
};
