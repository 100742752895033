import { PatientModel } from './models';
import { SYSTEM_LOINC, SYSTEM_ZUS_OWNER } from '@ctw/shared/api/fhir/system-urls';
import { fqsRequest, MAX_OBJECTS_PER_REQUEST } from '@ctw/shared/api/fqs/client';
import {
  DataIndicatorGraphqlResponse,
  generateDataIndicatorQuery,
} from '@ctw/shared/api/fqs/queries/data-indicator';
import { vitalLoincCodes } from '@ctw/shared/content/vitals/vital-loinc-codes';
import { ZAPTabName } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import { QUERY_KEY_DATA_INDICATORS } from '@ctw/shared/utils/query-keys';
import { GraphQLClient } from 'graphql-request';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export function useDataIndicators(
  limit = MAX_OBJECTS_PER_REQUEST,
  enabled = true,
  resources: ZAPTabName[] = [],
) {
  return usePatientQuery({
    queryId: QUERY_KEY_DATA_INDICATORS,
    queryKey: [limit, resources],
    queryFn: async ({ requestContext, telemetry, graphqlClient, patient }) =>
      getDataIndicators(telemetry, graphqlClient, requestContext, patient, limit, resources),
    enabled,
  });
}

export async function getDataIndicators(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  requestContext: CTWRequestContext,
  patient: PatientModel,
  limit: number = MAX_OBJECTS_PER_REQUEST,
  resources: ZAPTabName[] = [],
): Promise<DataIndicatorGraphqlResponse> {
  const vitalCodes = vitalLoincCodes.map((loincCode) => `${SYSTEM_LOINC}|${loincCode}`);
  const dataIndicatorQuery = generateDataIndicatorQuery(resources);
  const { data } = await fqsRequest<DataIndicatorGraphqlResponse>(
    telemetry,
    graphqlClient,
    dataIndicatorQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: limit,
      PatientFirst: MAX_OBJECTS_PER_REQUEST,
      PatientFilter: {
        tag: {
          nonematch: [`${SYSTEM_ZUS_OWNER}|builder/${requestContext.builderId}`],
        },
      },
      ObservationFilter: {
        code: {
          anymatch: vitalCodes,
        },
      },
      ImmunizationFilter: {
        status: {
          eq: 'completed',
        },
      },
    },
  );

  return { ...data };
}
