import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { Theme as RadixUiTheme } from '@radix-ui/themes';
import { RouterConfig } from '@react-types/shared';
import { ZuiTheme, zuiThemeFonts } from '@ctw/config/zui';
import { Locals } from '@ctw/shared/utils/i18n';
import i18next from 'i18next';
import { tw } from '@ctw/shared/utils/tailwind';

export type RouterOptions = Parameters<ReturnType<typeof useZusUiContext>['navigate']>[1];

export type ZusService = 'standalone' | 'zap' | 'smart-on-fhir';

interface ZusUiState {
  service: string;
  navigate: ZusUiProviderProps['navigate'];
  pathname: string;
  setLocalizations: (localizations: Locals) => void;
  setTheme: (theme: ZuiTheme) => void;
}

export const ZusUiContext = createContext<ZusUiState | null>(null);

export const useZusUiContext = () => {
  const context = useContext(ZusUiContext);

  if (!context) {
    throw new Error('useZusUiContext must be used within a ZusUiProvider');
  }

  return context;
};

export const useZuiTheme = (): Pick<ZusUiState, 'setLocalizations' | 'setTheme'> => {
  const context = useContext(ZusUiContext);

  if (!context) {
    throw new Error('useZuiTheme must be used within a ZusUiProvider');
  }

  return { setLocalizations: context.setLocalizations, setTheme: context.setTheme };
};

interface ZusUiProviderProps extends PropsWithChildren {
  service: ZusService | 'storybook' | 'playwright';
  navigate: (path: string, routerOptions: RouterConfig['routerOptions'] | undefined) => void;
  pathname: () => string | { pathname: string };
}

export const ZusUiProvider = ({
  service,
  navigate,
  pathname: usePathname,
  children,
}: ZusUiProviderProps) => {
  const pathname = usePathname();
  const zusUiContext: ZusUiState = useMemo(
    () => ({
      service,
      navigate,
      pathname: typeof pathname === 'string' ? pathname : pathname.pathname,
      setLocalizations: (localizations) => applyLocalizations(localizations),
      setTheme: (theme) => applyTheme(theme),
    }),
    [pathname, service, navigate],
  );

  return (
    // https://www.radix-ui.com/themes/docs/components/theme
    <RadixUiTheme
      className={tw`h-full overflow-hidden`}
      hasBackground={false}
      appearance="light"
      panelBackground="translucent"
      radius="medium"
      scaling="100%"
      accentColor="green"
      grayColor="gray"
    >
      <ZusUiContext.Provider value={zusUiContext}>{children}</ZusUiContext.Provider>
    </RadixUiTheme>
  );
};

const applyLocalizations = (localizations: Locals) => {
  Object.entries(localizations).forEach(([lang, namespaces]) => {
    Object.entries(namespaces).forEach(([namespace, resources]) => {
      i18next.addResourceBundle(lang, namespace, resources);
    });
  });
};

const applyTheme = ({ fontFamily }: ZuiTheme) => {
  let style = '';
  const rules: string[] = [];

  if (fontFamily) {
    const { cssImport, cssFontFamily } = zuiThemeFonts[fontFamily];
    if (cssImport !== null) {
      style += `@import ${cssImport};\n\n`;
    }

    rules.push(`font-family: ${cssFontFamily};`);
  }
  style += `body {${rules.join(';')}}`;

  const styleElementId = 'zui-theme';

  const existingStyleElement = document.getElementById(styleElementId);
  const newStyleElement: HTMLStyleElement = document.createElement('style');

  newStyleElement.id = styleElementId;
  newStyleElement.innerHTML = style;

  if (existingStyleElement) {
    existingStyleElement.remove();
  }

  document.body.prepend(newStyleElement);
};
