import { medicationFilters } from './helpers/filters';
import { PatientMedicationsBase } from './helpers/patient-medications-base';
import { defaultMedicationView, medicationViews } from './helpers/views';
import { useQueryAllPatientMedications } from '@ctw/shared/hooks/use-medications';
import { twx } from '@ctw/shared/utils/tailwind';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';

export interface PatientMedicationsProps {
  className?: string;
  onOpenHistoryDrawer?: () => void;
}

export const PatientMedications = withErrorBoundary({
  boundaryName: 'PatientMedications',
  includeTelemetryBoundary: true,
  Component: ({ className, onOpenHistoryDrawer }: PatientMedicationsProps) => {
    const { builderMedications, isLoading } = useQueryAllPatientMedications();

    return (
      <PatientMedicationsBase
        className={twx(className)}
        query={{ data: builderMedications, isLoading }}
        filters={medicationFilters(builderMedications, false)}
        views={medicationViews}
        defaultView={defaultMedicationView}
        onOpenHistoryDrawer={onOpenHistoryDrawer}
      />
    );
  },
});
