import { compact } from 'lodash-es';
import { DiagnosticReportModel } from '@ctw/shared/api/fhir/models';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { usePatientObservationsWithTrends } from '@ctw/shared/api/fhir/observations';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { ContentError } from '@ctw/shared/components/errors/content-error';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { BubbleIcon } from '@ctw/shared/content/observations/helpers/bubble';
import { ObservationTrends } from '@ctw/shared/content/observations/helpers/trends';

export type ObservationEntriesComponentProps = {
  model: DiagnosticReportModel;
};

export const diagnosticReportData = (diagnosticReport: DiagnosticReportModel) => [
  {
    label: 'Effective Date',
    value: diagnosticReport.dateDisplay,
  },
  { label: 'Organization', value: diagnosticReport.performer },
];

export const DiagnosticReportObservationList = withErrorBoundary({
  boundaryName: 'ObservationDetails',
  includeTelemetryBoundary: true,
  Component: ({ model }: ObservationEntriesComponentProps) => {
    const query = usePatientObservationsWithTrends(
      compact(model.results.map((r) => r.reference?.split('/')[1])),
    );

    if (query.isLoadingObservations) {
      return <LoadingSpinner centered />;
    }

    if (query.isError) {
      return <ContentError title="Error loading observations" />;
    }

    const sortedData = [...query.data]
      .filter((observation) => observation.value)
      .sort((a, b) => ((a.display ?? '') > (b.display ?? '') ? 1 : -1));

    return (
      <div className={tw`space-y-2`}>
        {query.isLoadingTrends && (
          <LoadingSpinner centered message="Loading trends..." className={tw`mb-6`} />
        )}
        {sortedData.map((observation) => (
          <div key={observation.id} className={tw`border-gray-200 border-b pb-2`}>
            {observation.valueIsInNotes || observation.value.length > 20 ?
              <>
                {observation.display?.toLowerCase() !== 'unknown' && observation.display && (
                  <>
                    <span className={tw`text-base`}>{observation.display}</span>
                  </>
                )}
                <div className={tw`mb-1 flex`}>
                  <div className={tw`text-sm font-normal`}>{observation.value}</div>
                </div>
              </>
            : <div className={tw`mb-1 flex flex-wrap items-center`}>
                <div className={tw`mb-1 flex flex-wrap text-base`}>
                  {observation.display}
                  <span className={tw`mx-2`}>-</span>
                </div>
                <div className={tw`text-sm font-normal`}>
                  <BubbleIcon
                    tooltip={observation.notes}
                    interpretation={observation.interpretation}
                    result={observation.value}
                    className={twx(observation.acceptedInterpretations)}
                  />
                </div>
              </div>
            }

            <div className={tw`mb-1 text-sm font-normal`}>
              {observation.referenceRange && (
                <div className={tw`font-normal`}>
                  Reference Range: {observation.referenceRange} {observation.unit}
                </div>
              )}
            </div>

            <ObservationTrends model={observation} />
          </div>
        ))}
      </div>
    );
  },
});
