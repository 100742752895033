import './diagnostic-reports.scss';
import { patientDiagnosticReportsColumns } from './helpers/columns';
import { diagnosticReportFilter } from './helpers/filters';
import { defaultDiagnosticReportSort, diagnosticReportSortOptions } from './helpers/sorts';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { twx } from '@ctw/shared/utils/tailwind';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { usePatientDiagnosticReports } from '@ctw/shared/api/fhir/diagnostic-report';
import { useDiagnosticReportDetailsDrawer } from '@ctw/shared/content/diagnostic-reports/helpers/drawer';

export interface PatientDiagnosticReportsProps {
  className?: string;
}

export const PatientDiagnosticReports = withErrorBoundary({
  boundaryName: 'PatientDiagnosticReports',
  includeTelemetryBoundary: true,
  Component: ({ className }: PatientDiagnosticReportsProps) => {
    const query = usePatientDiagnosticReports();
    const { data, filters, setFilters, setSort, defaultSort } = useFilteredSortedData({
      cacheKey: 'patient-diagnostic-reports',
      defaultSort: defaultDiagnosticReportSort,
      records: query.data,
    });
    const { requestContext } = useCTW();
    const isEmptyQuery = !query.data || query.data.length === 0;
    const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

    const openDetails = useDiagnosticReportDetailsDrawer();

    return (
      <div className={twx(className, 'scrollable-pass-through-height', 'diagnostic-reports')}>
        <ResourceTableActions
          filterOptions={{
            onChange: setFilters,
            filters: diagnosticReportFilter(),
            selected: filters,
          }}
          sortOptions={{
            defaultSort,
            options: diagnosticReportSortOptions,
            onChange: setSort,
          }}
        />
        <ResourceTable
          showTableHead
          isLoading={query.isLoading}
          data={data}
          columns={patientDiagnosticReportsColumns(requestContext.builderId)}
          onRowClick={(record) =>
            openDetails({
              prefetchedModel: record,
              trackingMetadata: { action: 'observations_details' },
            })
          }
          enableDismissAndReadActions
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="diagnostics"
            />
          }
        />
      </div>
    );
  },
});
