import { useContext, useMemo, useState } from 'react';
import { DrawerContext, DrawerState, OpenDrawerProps } from './drawer-context';
import { useTelemetryIfAvailable } from '@ctw/shared/context/telemetry/telemetry-boundary';

// Define this outside of the rendered component to avoid eslint error.
const dummyChild = (_props: unknown) => <div />;

export function useDrawerState() {
  const [isOpen, setIsOpen] = useState(false);
  const [drawerProps, setDrawerProps] = useState<OpenDrawerProps>({
    // Create some dummy initial props for the drawer. These will get
    // overwritten when openDrawer() is used.
    component: dummyChild,
  });
  const telemetry = useTelemetryIfAvailable();

  const state = useMemo(
    () => ({
      isOpen,
      openDrawer: (props: OpenDrawerProps) => {
        setDrawerProps(props);

        if (props.animateOpen === false) {
          setIsOpen(true);
          telemetry?.trackInteraction('open_drawer', props.trackingMetadata);
        } else {
          // Ensure isOpen starts as false and then async set it to true.
          // This ensures the drawer is added first before isOpen is set to
          // true which fixes an issue around initial opening animation/transition.
          setIsOpen(false);
          setTimeout(() => {
            setIsOpen(true);
            telemetry?.trackInteraction('open_drawer', props.trackingMetadata);
          });
        }
      },
    }),

    [isOpen, telemetry],
  );

  const drawerComponent = useMemo(
    () =>
      drawerProps.component({
        isOpen,
        onClose: () => {
          if (drawerProps.animateClose === false) {
            setIsOpen(false);
            setDrawerProps({
              component: dummyChild,
            });
            telemetry?.trackInteraction('close_drawer', drawerProps.trackingMetadata);
          } else {
            // Allow the closing animation to finish before unmounting the drawer component
            setIsOpen(false);
            setTimeout(() => {
              setDrawerProps({
                component: dummyChild,
              });
              telemetry?.trackInteraction('close_drawer', drawerProps.trackingMetadata);
            }, 300);
          }
        },
      }),
    [telemetry, drawerProps, isOpen],
  );

  return { drawerComponent, drawerState: state };
}

export const useDrawer = (): DrawerState => {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }

  return context;
};
