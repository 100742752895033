import { useEffect, useState } from 'react';
import { vitalFilters } from './helpers/filters';
import { defaultVitalSort, vitalSortOptions } from './sorts';
import { defaultVitalView, vitalViewOptions } from './views';
import { getVitalRows } from './vital-rows';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { usePatientVitals } from '@ctw/shared/api/fhir/vitals';
import { RotatedTable } from '@ctw/shared/components/table/rotated-table';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';

export interface PatientVitalsProps {
  className?: string;
}

export const PatientVitals = withErrorBoundary({
  boundaryName: 'PatientVitals',
  includeTelemetryBoundary: true,
  Component: ({ className }: PatientVitalsProps) => {
    const vitals = usePatientVitals();
    const [rows, setRows] = useState<ReturnType<typeof getVitalRows>>([]);

    const { data, filters, setFilters, setSort, setViewOption, defaultSort, defaultView } =
      useFilteredSortedData({
        cacheKey: 'patient-vitals',
        viewOptions: vitalViewOptions,
        defaultSort: defaultVitalSort,
        records: vitals.data,
        defaultView: defaultVitalView.display,
      });

    useEffect(() => {
      if (!vitals.isLoading) {
        setRows(getVitalRows(vitals.data));
      }
    }, [vitals.data, vitals.isLoading]);

    return (
      <div className={tw`patient-vitals`}>
        <ResourceTableActions
          viewOptions={{
            onChange: setViewOption,
            options: vitalViewOptions,
            defaultView,
          }}
          filterOptions={{
            onChange: setFilters,
            filters: vitalFilters,
            selected: filters,
          }}
          sortOptions={{
            defaultSort,
            options: vitalSortOptions,
            onChange: setSort,
          }}
        />
        <RotatedTable
          className={twx(className)}
          records={data}
          rows={rows}
          isLoading={vitals.isLoading}
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={vitals.data ? vitals.data.length > 0 : false}
              resourceName="vitals"
            />
          }
        />
      </div>
    );
  },
});
