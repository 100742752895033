import { DocumentReference } from 'fhir/r4';
import { gql } from 'graphql-request';
import { GraphqlConnectionNode, GraphqlPageInfo } from '../client';
import { fragmentBasic, fragmentCoding, fragmentOrganization } from './fragments';

export interface DocumentReferenceWithBasicsConnection {
  pageInfo: GraphqlPageInfo;
  edges: GraphqlConnectionNode<DocumentReferenceWithBasics>[];
}

export interface DocumentReferenceWithBasicsGraphqlResponse {
  DocumentReferenceConnection: DocumentReferenceWithBasicsConnection;
}

export type DocumentReferenceWithBasics = DocumentReference & {
  BasicList: fhir4.Basic[];
};

export const documentReferencesWithBasicsQuery = gql`
  ${fragmentCoding}
  ${fragmentOrganization}
  ${fragmentBasic}
  query DocumentReferencesWithBasics(
    $upid: ID!
    $cursor: String!
    $sort: DocumentReferenceSortParams!
    $filter: DocumentReferenceFilterParams! = {}
    $first: Int!
  ) {
    DocumentReferenceConnection(
      upid: $upid
      after: $cursor
      sort: $sort
      filter: $filter
      first: $first
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          resourceType
          BasicList(_reference: "subject") {
            ...Basic
          }
          masterIdentifier {
            system
            use
            value
          }
          meta {
            extension {
              url
              valueInstant
            }
            tag {
              system
              code
            }
            versionId
          }
          extension {
            url
            valueString
          }
          status
          docStatus
          type {
            text
            coding {
              ...Coding
            }
          }
          text {
            id
            div
          }
          category {
            text
            coding {
              ...Coding
            }
          }
          date
          custodian {
            reference
            resource {
              ...Organization
            }
          }
          description
          content {
            attachment {
              contentType
              language
              data
              url
              size
              hash
              title
              creation
            }
            format {
              system
              version
              code
              display
            }
          }
          context {
            period {
              start
              end
            }
            encounter {
              reference
            }
          }
        }
      }
    }
  }
`;

export interface DocumentReferenceConnection {
  pageInfo: GraphqlPageInfo;
  edges: GraphqlConnectionNode<DocumentReference>[];
}

export interface DocumentReferenceGraphqlResponse {
  DocumentReferenceConnection: DocumentReferenceConnection;
}

export const documentReferencesQuery = gql`
  ${fragmentCoding}
  query DocumentReferences(
    $upid: ID!
    $cursor: String!
    $sort: DocumentReferenceSortParams!
    $filter: DocumentReferenceFilterParams! = {}
    $first: Int!
  ) {
    DocumentReferenceConnection(
      upid: $upid
      after: $cursor
      sort: $sort
      filter: $filter
      first: $first
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          resourceType
          masterIdentifier {
            system
            use
            value
          }
          meta {
            extension {
              url
              valueInstant
            }
            tag {
              system
              code
            }
            versionId
          }
          extension {
            url
            valueString
          }
          status
          docStatus
          type {
            text
            coding {
              ...Coding
            }
          }
          text {
            id
            div
          }
          category {
            text
            coding {
              ...Coding
            }
          }
          date
          custodian {
            reference
          }
          description
          content {
            attachment {
              contentType
              language
              data
              url
              size
              hash
              title
              creation
            }
            format {
              system
              version
              code
              display
            }
          }
          context {
            period {
              start
              end
            }
            encounter {
              reference
            }
          }
        }
      }
    }
  }
`;
