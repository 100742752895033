import { CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';
import { tw } from '@ctw/shared/utils/tailwind';

type CareGapStatusDescription = {
  gap: CareGapModel;
};

export const StatusDescription = ({ gap }: CareGapStatusDescription) => (
  <div className={tw`flex flex-col text-xs font-normal`}>
    {gap.latestReportReasons.map((reason) => (
      <div key={reason}>{reason}</div>
    ))}
  </div>
);
