import { Provenance } from 'fhir/r4';
import { uniq } from 'lodash-es';
import { FHIRModel } from './models/fhir-model';
import { searchProvenancesFQS } from '@ctw/shared/api/fqs/queries/provenances';
import { GraphQLClient } from 'graphql-request';

export async function searchProvenances<T extends fhir4.Resource>(
  graphqlClient: GraphQLClient,
  models: FHIRModel<T>[],
): Promise<Provenance[]> {
  if (models.length === 0) return [];

  const targets = uniq(models.map((m) => `${m.resourceType}/${m.id}`));

  // Builder-scoped queries will fix the issue of FQS not supporting Practitioner provenance search.
  if (models[0].resourceType === 'Practitioner') {
    return [];
  }

  return searchProvenancesFQS(graphqlClient, models[0].resourceType, targets);
}

export function getBinaryIDFromProvenance(provenance?: fhir4.Provenance[]) {
  if (provenance && provenance.length > 0) {
    let binaryIDReference = '';
    for (let i = 0; i < provenance.length; i += 1) {
      const entity = provenance[i].entity?.find((e) => e.what.reference !== undefined);
      if (entity && entity.what.reference) {
        binaryIDReference = entity.what.reference;
        break;
      }
    }
    if (binaryIDReference) {
      return binaryIDReference.split('/')[1];
    }
  }
  return undefined;
}
