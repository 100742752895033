import { ReactNode, useRef } from 'react';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { useContainerQuery } from '@ctw/shared/hooks/breakpoints';

type ContainerProps = {
  className?: string;
  children?: ReactNode;
};

type TitleContainerProps = ContainerProps & {
  title?: string;
};

type HeadingContainerProps = TitleContainerProps;

export function StackedWrapper({ className, children, ...htmlProps }: ContainerProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { breakpoints } = useContainerQuery({ containerRef });
  const classNames = twx(className, {
    stacked: breakpoints.isAtMost.sm,
  });

  return (
    <div className={twx(classNames)} ref={containerRef} {...htmlProps}>
      {children}
    </div>
  );
}

export function Heading({ className, title, children }: HeadingContainerProps) {
  return (
    <div className={twx('heading-container', className)}>
      {title && <div className={tw`title`}>{title}</div>}
      {children}
    </div>
  );
}

export function Title({ className, title, children }: TitleContainerProps) {
  return (
    <div className={twx('title-container', className)}>
      {title && <div className={tw`title`}>{title}</div>}
      {children}
    </div>
  );
}
