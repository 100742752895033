import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@ctw/shared/components/tooltip';
import { tw } from '@ctw/shared/utils/tailwind';

const fixedColumns = 'w-[27rem] @[1024px]:w-[30rem] text-sm hidden -ml-2 ';

export const conditionsColumnGroupedClassNames = {
  builderOwned: 'text-sm w-[1.5rem] max-w-[1.5rem] min-w-[1.5rem]',
  displayName: 'w-full flex-grow text-sm',
  latestDiagnosis: [fixedColumns, '@[640px]:block @[640px]:ml-0'],
  onset: [fixedColumns, '@[1024px]:block @[1024px]:ml-0'],
  type: [fixedColumns, 'text-ellipsis @[768px]:block @[768px]:ml-0'],
};

export const patientConditionsColumnsGrouped = [
  {
    className: conditionsColumnGroupedClassNames.builderOwned,
  },
  {
    className: conditionsColumnGroupedClassNames.displayName,
    title: 'Name',
  },
  {
    className: conditionsColumnGroupedClassNames.latestDiagnosis,
    title: 'Latest Diagnosis',
  },
  {
    className: conditionsColumnGroupedClassNames.onset,
    render: () => (
      <div className={tw`flex w-full justify-start space-x-2`}>
        <div>Onset Date</div>
        <div className={tw`flex-grow content-start`}>
          <Tooltip content="Earliest known onset or documented date">
            <FontAwesomeIcon icon={faInfoCircle} className={tw`h-4 w-4 text-content-icon`} />
          </Tooltip>
        </div>
      </div>
    ),
  },
  {
    className: conditionsColumnGroupedClassNames.type,
    title: 'Type',
  },
];
