'use client';

/* eslint-disable no-restricted-imports */
import { PropsWithChildren, useEffect, useState } from 'react';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { Telemetry } from '@ctw/shared/context/telemetry';
import { useCTW } from '@ctw/shared/context/ctw-context';

interface TelemetryProviderProps extends PropsWithChildren {}

export const TelemetryInitializer = ({ children }: TelemetryProviderProps) => {
  const { requestContext, ctwFetch } = useCTW();

  useEffect(() => {
    Telemetry.init({
      requestContext,
      ctwFetch,
    });
  }, [ctwFetch, requestContext]);

  // If the telemetry initialization takes too long, stop waiting and continue rendering
  const [initTimeoutExceeded, setInitTimeoutExceeded] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitTimeoutExceeded(true);
    }, 2000);

    return () => clearTimeout(timeout); // Clean up the timeout if the component unmounts
  }, []);

  if (!Telemetry.isInitialized && !initTimeoutExceeded) {
    return <LoadingSpinner centered message="Loading..." />;
  }

  return children;
};
