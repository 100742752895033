import { BinaryModel } from '@ctw/shared/api/fhir/models/binary';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { useCCDAModal } from '@ctw/shared/content/CCDA/modal-ccda';
import { format } from 'date-fns';
import { Button } from '@ctw/shared/components/button';
import { tw } from '@ctw/shared/utils/tailwind';
import { Badge } from '@ctw/shared/components/badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/pro-regular-svg-icons';

interface ViewCCDAButtonProps {
  ccdaTitle: string;
  binary: BinaryModel;
  patient: PatientModel;
  preferred: boolean;
  overrideDateDisplay?: string;
  onClick?: () => void;
}

export const ViewCCDAButton = ({
  onClick,
  ccdaTitle,
  binary,
  patient,
  preferred,
  overrideDateDisplay,
}: ViewCCDAButtonProps) => {
  const openCCDAModal = useCCDAModal(patient);

  const date =
    overrideDateDisplay ?? (binary.createdAt ? format(new Date(binary.createdAt), 'M/d/yyyy') : '');
  const time = binary.createdAt ? format(new Date(binary.createdAt), 'h:mma') : '';

  return (
    <Button
      type="button"
      variant="link"
      className={tw`group w-full`}
      onClick={() => {
        openCCDAModal(binary.id, ccdaTitle);
        onClick?.();
      }}
    >
      <div
        className={tw`flex items-center justify-between rounded-lg bg-white p-3 text-left text-background-inverse outline outline-1 outline-divider-main`}
      >
        <div className={tw`flex space-x-10`}>
          <div className={tw`w-24`}>
            <div className={tw`font-medium`}>{time}</div>
            <div>{date}</div>
          </div>
          <div>
            <div className={tw`font-medium group-hover:underline`}>{ccdaTitle}</div>
            {preferred && <Badge variant="muted" content="Notes" />}
          </div>
        </div>
        <FontAwesomeIcon
          icon={faFileLines}
          className={tw`h-5 w-5 justify-self-end text-content-subtle`}
        />
      </div>
    </Button>
  );
};
