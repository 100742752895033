import { AppointmentModel } from './appointment';
import { DocumentModel } from './document';
import { EncounterModel } from './encounter';
import { FHIRModel } from './fhir-model';
import { PatientModel } from './patient';
import { bestName, formatName } from '@ctw/shared/api/fhir/formatters/human-name';
import { formatFHIRDate } from '@ctw/shared/utils/dates';

type ReferralStatus = 'ordered' | 'scheduled' | 'fulfilled';

interface ReferredPractitioner {
  name: string | undefined;
  practiceName: string | undefined;
}

export class ReferralModel extends FHIRModel<fhir4.ServiceRequest> {
  kind = 'Referral' as const;

  private patientModel: PatientModel;

  private appointmentResource: fhir4.Appointment | undefined;

  private encounterResource: fhir4.Encounter | undefined;

  private documentPool: DocumentModel[] = [];

  private isLoadingDocuments = false;

  constructor(
    serviceRequestResource: fhir4.ServiceRequest,
    patientModel: PatientModel,
    appointmentResource?: fhir4.Appointment,
    encounterResource?: fhir4.Encounter,
    documentPool?: DocumentModel[],
  ) {
    super(serviceRequestResource);

    this.documentPool = documentPool || [];
    this.patientModel = patientModel;
    this.appointmentResource = appointmentResource;

    if (this.appointmentResource) {
      this.encounterResource = encounterResource;
    }
  }

  get patient(): PatientModel {
    return this.patientModel;
  }

  get appointment(): AppointmentModel | undefined {
    return this.appointmentResource ? new AppointmentModel(this.appointmentResource) : undefined;
  }

  getIsLoadingDocuments() {
    return this.isLoadingDocuments;
  }

  setIsLoadingDocuments(isLoading: boolean) {
    this.isLoadingDocuments = isLoading;
  }

  get encounter(): EncounterModel | undefined {
    const encounterModel =
      this.encounterResource ? new EncounterModel(this.encounterResource, []) : undefined;

    if (encounterModel) {
      encounterModel.setClinicalNotesFromDocumentPool(this.documentPool);
    }

    return encounterModel;
  }

  get resourceTypeTitle() {
    return 'Referral';
  }

  get title() {
    return this.reason;
  }

  get referringPracticeName(): string | undefined {
    return this.patient.organizationDisplayName;
  }

  get referringPractitionerName(): string | undefined {
    const practitioner = (this.resource.requester as Record<string, unknown> | undefined)
      ?.resource as fhir4.Practitioner | undefined;

    if (this.resource.requester?.display) {
      return this.resource.requester.display;
    }

    if (practitioner?.name !== undefined) {
      const name = bestName(practitioner.name);

      if (name) {
        return formatName(name);
      }
    }

    return undefined;
  }

  get referredAtDisplay() {
    const time = this.resource.occurrenceDateTime ?? this.resource.authoredOn;
    return time ? formatFHIRDate(time) : undefined;
  }

  get scheduledAtDisplay() {
    return this.appointmentResource?.start ?
        formatFHIRDate(this.appointmentResource.start)
      : undefined;
  }

  get referredPractitioners(): ReferredPractitioner[] {
    return (this.resource.performer || this.resource.locationReference || []).map(
      (performer, index) => ({
        name: performer.display,
        practiceName: this.resource.locationReference?.[index]?.display,
      }),
    );
  }

  get referredPractitionerName() {
    return this.referredPractitioners[0]?.name;
  }

  get referredPracticeName() {
    return this.referredPractitioners[0]?.practiceName;
  }

  get completedAtDisplay() {
    const time = this.appointmentResource?.end ?? this.appointmentResource?.start;
    return time ? formatFHIRDate(time) : undefined;
  }

  get status(): ReferralStatus {
    if (!this.appointment) {
      return 'ordered';
    }

    if (!this.encounter) {
      return 'scheduled';
    }

    return 'fulfilled';
  }

  get reason() {
    return (
      this.resource.reasonCode?.[0].text ??
      this.resource.category?.[0].text ??
      (this.referredPractitionerName || this.referredPracticeName ?
        `Referral to ${this.referredPractitionerName ?? this.referredPracticeName}`
      : 'Referral')
    );
  }
}
