import { faArrowTrendUp, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { BubbleIcon } from './bubble';
import { ObservationModel } from '@ctw/shared/api/fhir/models';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';
import { TelemetryBoundary, useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export type ObservationTrendsProps = {
  model: ObservationModel;
};

export const ObservationTrends = ({ model }: ObservationTrendsProps) => {
  const [isTrendsShown, setIsTrendsShown] = useState(false);
  const { trackInteraction } = useTelemetry();

  return (
    <TelemetryBoundary boundaryName="ObservationTrends">
      <div className={tw`text-sm font-normal`}>
        {!model.isIncorrectlyCodedGlucose && model.trends && model.trends.length > 1 && (
          <div className={tw`pt-2`}>
            <Button
              aria-label="trends"
              variant="link"
              className={tw`space-x-3`}
              type="button"
              onClick={() => {
                trackInteraction('toggle_trend', {
                  action: isTrendsShown ? 'collapse_trend' : 'expand_trend',
                });
                setIsTrendsShown(!isTrendsShown);
              }}
            >
              <FontAwesomeIcon icon={faArrowTrendUp} />
              <span>Trends</span>
              <FontAwesomeIcon
                icon={faChevronRight}
                className={twx('h-3 w-3 pr-1', {
                  'rotate-90': isTrendsShown,
                })}
              />
            </Button>
            {isTrendsShown &&
              [...model.trends]
                .sort((a, b) => {
                  if (!a.effectiveStartRaw || !b.effectiveStartRaw) return 0;
                  return (
                    new Date(b.effectiveStartRaw).getTime() -
                    new Date(a.effectiveStartRaw).getTime()
                  );
                })
                .map(
                  (trend) =>
                    !trend.isIncorrectlyCodedGlucose && (
                      <div key={trend.id} className={tw`grid grid-cols-2 py-1 pl-4`}>
                        <div
                          className={twx('relative w-24 text-sm', {
                            'font-semibold':
                              trend.id === model.id ||
                              (trend.effectiveStartRaw === model.effectiveStartRaw &&
                                trend.value === model.value),
                          })}
                        >
                          {trend.effectiveStart}
                        </div>
                        <BubbleIcon
                          tooltip={trend.notes}
                          result={trend.value}
                          interpretation={trend.interpretation}
                          className={twx(`${trend.acceptedInterpretations} w-fit`, {
                            '!font-semibold': trend.id === model.id,
                          })}
                        />
                      </div>
                    ),
                )}
          </div>
        )}
      </div>
    </TelemetryBoundary>
  );
};
