import { SYSTEM_SUMMARY } from './system-urls';
import { DiagnosticReportModel, PatientModel } from '@ctw/shared/api/fhir/models';
import { fqsRequestAll, MAX_OBJECTS_PER_REQUEST } from '@ctw/shared/api/fqs/client';
import {
  DiagnosticReportGraphqlResponse,
  getDiagnosticReportQuery,
} from '@ctw/shared/api/fqs/queries/diagnostic-reports-query';
import { QUERY_KEY_PATIENT_DIAGNOSTIC_REPORTS } from '@ctw/shared/utils/query-keys';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import { GraphQLClient } from 'graphql-request';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { uniqWith, isEqual } from 'lodash-es';

// Gets diagnostic reports for the patient.
export function usePatientDiagnosticReports(limit = MAX_OBJECTS_PER_REQUEST, enabled = true) {
  return usePatientQuery({
    gcTime: 0,
    staleTime: 0,
    queryId: QUERY_KEY_PATIENT_DIAGNOSTIC_REPORTS,
    queryKey: [limit], // Only use the IDs in our key (fixes issue with ciruclar references).
    queryFn: async ({ graphqlClient, telemetry, patient }) => {
      const data = await fetchDiagnosticReportsFromFQS(telemetry, graphqlClient, patient, limit);

      const diagnosticReports = data.DiagnosticReportConnection.edges.map(
        (x) => new DiagnosticReportModel(x.node, undefined, x.node.BasicList),
      );
      const valuesToDedupeOn = (dr: DiagnosticReportModel) => [dr.displayName, dr.dateDisplay];
      const uniqueDiagnosticReports = uniqWith(diagnosticReports, (a, b) =>
        isEqual(valuesToDedupeOn(a), valuesToDedupeOn(b)),
      );

      return uniqueDiagnosticReports;
    },
    enabled,
  });
}

async function fetchDiagnosticReportsFromFQS(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  limit: number,
) {
  const { data } = await fqsRequestAll<DiagnosticReportGraphqlResponse>(
    telemetry,
    graphqlClient,
    getDiagnosticReportQuery,
    'DiagnosticReportConnection',
    {
      upid: patient.UPID,
      cursor: 'DiagnosticReportConnection',
      filter: {
        tag: { nonematch: [SYSTEM_SUMMARY] },
      },
      first: limit,
    },
  );
  return data;
}

export async function fetchDiagnosticReportsFromFQSById(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  ids: string[] = [],
) {
  const { data } = await fqsRequestAll<DiagnosticReportGraphqlResponse>(
    telemetry,
    graphqlClient,
    getDiagnosticReportQuery,
    'DiagnosticReportConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: 1000,
      filter: {
        ids: { anymatch: ids },
        tag: { nonematch: [SYSTEM_SUMMARY] },
      },
    },
  );
  return data.DiagnosticReportConnection.edges.map(
    (x) => new DiagnosticReportModel(x.node, undefined, x.node.BasicList),
  );
}
