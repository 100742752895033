import { twx } from '@ctw/shared/utils/tailwind';

type PrototypeStage = 'beta' | 'pilot';

export type PrototypeLabelProps = {
  className?: string;
  stage: PrototypeStage;
  deemphasized?: boolean;
};

export const PrototypeLabel = ({ className, stage, deemphasized }: PrototypeLabelProps) => (
  <span
    className={twx(className, 'relative bottom-1 break-keep text-[10px] uppercase', 'text-black', {
      'text-content-icon': deemphasized,
    })}
  >
    {stage}
  </span>
);
