import { usePatientSubscription } from '@ctw/shared/api/subscriptions/subscriptions';
import { Alert } from '@ctw/shared/components/alert';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { tw } from '@ctw/shared/utils/tailwind';

export const PatientSubscriptionPackage = () => {
  const patientSubscription = usePatientSubscription();

  if (patientSubscription.isLoading) {
    return <LoadingSpinner message="Loading..." />;
  }

  if (patientSubscription.isError) {
    return <Alert type="error" header="Error loading data" />;
  }

  const patientPackage = patientSubscription.data?.package;

  if (!patientPackage) {
    return null;
  }

  return <div className={tw`font-semibold`}>Enrolled in {patientPackage.name}</div>;
};
