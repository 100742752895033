import { useRef } from 'react';
import { useCareGapDetailsDrawer } from '@ctw/shared/content/care-gaps/helpers/details';
import { ClassName, twx } from '@ctw/shared/utils/tailwind';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { usePatientCareGaps } from '@ctw/shared/api/fhir/care-gaps';
import { patientCareGapsColumns } from '@ctw/shared/content/care-gaps/helpers/columns';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { careGapFilters } from '@ctw/shared/content/care-gaps/helpers/filters';
import { ResourceTableActions } from '@ctw/shared/content/resource/resource-table-actions';
import {
  careGapSortOptions,
  defaultCareGapSort,
} from '@ctw/shared/content/care-gaps/helpers/sorts';

export type CareGapProps = {
  className?: ClassName;
};

export const PatientCareGaps = withErrorBoundary({
  boundaryName: 'PatientCareGaps',
  includeTelemetryBoundary: true,
  Component: ({ className }: CareGapProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const careGapsQuery = usePatientCareGaps();

    const { data, filters, setFilters, setSort, defaultSort } = useFilteredSortedData({
      cacheKey: 'patient-care-gaps',
      defaultSort: defaultCareGapSort,
      records: careGapsQuery.data,
    });

    const hasZeroFilteredRecords = !careGapsQuery.isLoading && data.length === 0;

    const openCareGapDetails = useCareGapDetailsDrawer();

    return (
      <div className={twx(className, 'scrollable-pass-through-height')} ref={containerRef}>
        <ResourceTableActions
          filterOptions={{
            onChange: setFilters,
            filters: careGapFilters(careGapsQuery.data),
            selected: filters,
          }}
          sortOptions={{
            defaultSort,
            options: careGapSortOptions,
            onChange: setSort,
          }}
        />
        <ResourceTable
          showTableHead
          isLoading={careGapsQuery.isLoading}
          columns={patientCareGapsColumns()}
          data={data}
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="care-gaps"
            />
          }
          onRowClick={(e) => {
            openCareGapDetails({
              prefetchedModel: e,
              trackingMetadata: { target: 'care-gap' },
            });
          }}
        />
      </div>
    );
  },
});
