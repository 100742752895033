import { enrichedLoinc, type DiagnosticReportModel } from './diagnostic-report';
import { FHIRModel } from './fhir-model';
import { getBinaryIDFromProvenance } from '../provenance';
import { SYSTEM_CPT, SYSTEM_LOINC } from '../system-urls';
import { ResourceMap } from '../types';
import { codeableConceptLabel } from '@ctw/shared/api/fhir/codeable-concept';
import { formatPeriod, formatQuantity, formatRange } from '@ctw/shared/api/fhir/formatters';
import { formatFHIRDate } from '@ctw/shared/utils/dates';

export class ObservationModel extends FHIRModel<fhir4.Observation> {
  kind = 'Observation' as const;

  public binaryId?: string;

  public diagnosticReport?: DiagnosticReportModel;

  public provenance?: fhir4.Provenance[];

  public trends?: ObservationModel[];

  public isLoadingTrends = false;

  constructor(
    resource: fhir4.Observation,
    provenance?: fhir4.Provenance[],
    includedResources?: ResourceMap,
    basics?: fhir4.Basic[],
  ) {
    super(resource, includedResources, basics);
    this.provenance = provenance;
    this.binaryId = getBinaryIDFromProvenance(this.provenance);
  }

  get loincCode() {
    return enrichedLoinc(this.resource.code.coding)?.code;
  }

  get category() {
    return codeableConceptLabel(this.resource.category?.[0]);
  }

  get display() {
    const filteredCoding = this.resource.code.coding?.find(
      (coding) => coding.display !== 'unknown' && coding.display,
    );

    if (filteredCoding) {
      return filteredCoding.display || filteredCoding.code;
    }

    return codeableConceptLabel(this.resource.code);
  }

  get effectiveStart() {
    return this.effectiveStartRaw ? formatFHIRDate(this.effectiveStartRaw) : undefined;
  }

  get effectiveStartRaw() {
    return this.resource.effectivePeriod?.start || this.resource.effectiveDateTime;
  }

  get identifier() {
    return this.resource.identifier?.[0].value ?? '';
  }

  get performer() {
    return this.resource.performer?.[0].display;
  }

  get valueIsInNotes() {
    return this.resource.valueString?.toLowerCase() === 'see note';
  }

  get value(): string {
    if (this.resource.valueBoolean !== undefined) {
      return this.resource.valueBoolean ? 'true' : 'false';
    }

    if (this.resource.valueCodeableConcept) {
      return codeableConceptLabel(this.resource.valueCodeableConcept);
    }

    if (this.resource.valueDateTime) {
      return this.resource.valueDateTime;
    }

    if (this.resource.valueInteger !== undefined) {
      return String(this.resource.valueInteger);
    }

    if (this.resource.valuePeriod) {
      return formatPeriod(this.resource.valuePeriod) ?? '';
    }

    if (this.resource.valueQuantity) {
      return formatQuantity(this.resource.valueQuantity);
    }

    if (this.resource.valueRange) {
      return formatRange(this.resource.valueRange);
    }

    if (this.resource.valueString) {
      if ((this.valueIsInNotes || (this.resource.valueString ?? '') === '') && this.notes) {
        return this.notes;
      }
      return this.resource.valueString;
    }

    if (this.resource.valueTime) {
      return this.resource.valueTime;
    }

    return '';
  }

  get unit() {
    return this.resource.valueQuantity?.unit;
  }

  get interpretation() {
    const nonAcceptedValues = [
      'n',
      'noinformation',
      'normal',
      'na',
      '(normal)',
      'unknown',
      'not applicable',
      'temporarily unavailable',
      'nml',
      'norm',
      '*',
    ];

    const interpretation = codeableConceptLabel(this.resource.interpretation?.[0]).toLowerCase();
    if (nonAcceptedValues.includes(interpretation)) {
      return undefined;
    }

    return interpretation;
  }

  get notes() {
    return this.resource.note?.[0].text;
  }

  get referenceRange() {
    return this.resource.referenceRange?.[0].text;
  }

  get acceptedInterpretations(): string {
    switch (codeableConceptLabel(this.resource.interpretation?.[0]).toLowerCase()) {
      case 'high':
      case 'low':
      case 'hi':
      case 'abn':
      case '(high)':
      case '(low)':
      case 'abnormal':
      case 'a':
      case 'l':
      case 'h':
      case 'above high normal':
      case 'crit':
      case 'below low normal':
      case 'high alert':
      case '(abnormal)':
      case 'abnormal alert':
      case 'abnormal low':
      case 'abnormal high':
      case 'low alert':
      case 'pos':
      case 'no reference range':
      case 'positive':
      case '(positive)':
      case '(critical high)':
      case '(critical low)':
      case 'critical high':
      case 'critical low':
      case 'c':
        return 'text-caution-text bg-caution-badge inline-flex rounded-xl font-medium text-sm p-1 px-3';
      default:
        return 'text-background-inverse bg-background-disabled inline-flex rounded-xl leading-5 font-medium text-sm p-1 px-3';
    }
  }

  /**
   * Return true if the provided diagnostic and trend combination are incorrectly coded,
   * and therefore should not be displayed in the trends.
   *
   * https://zeushealth.atlassian.net/browse/CDEV-310
   */
  get isIncorrectlyCodedGlucose() {
    if (this.diagnosticReport) {
      const diagnosticFlagged = this.diagnosticReport.resource.code.coding?.some((coding) => {
        const a1cDisplay = coding.display?.toLowerCase().indexOf('a1c');
        return (
          (coding.system === SYSTEM_LOINC && coding.code === '4548-4') ||
          (coding.system === SYSTEM_CPT && coding.code === '83036') ||
          (a1cDisplay !== undefined && a1cDisplay > -1)
        );
      });
      const trendFlagged = this.resource.code.coding?.some(
        (coding) => coding.system === SYSTEM_LOINC && coding.code === '2345-7',
      );
      return diagnosticFlagged && trendFlagged;
    }
    return false;
  }

  get title() {
    return this.display ?? 'Unknown Observation';
  }
}
