/* eslint-disable no-underscore-dangle */
import { Coding } from 'fhir/r4';
import { find } from 'lodash-es';
import { FHIRModel } from './fhir-model';
import {
  codeableConceptLabel,
  findCodingByOrderOfPreference,
} from '@ctw/shared/api/fhir/codeable-concept';
import { findReference } from '@ctw/shared/api/fhir/resource-helper';
import {
  SYSTEM_DIAGNOSTIC_SERVICE_SECTION_ID,
  SYSTEM_ENRICHMENT,
  SYSTEM_SNOMED,
} from '@ctw/shared/api/fhir/system-urls';
import { formatFHIRDate } from '@ctw/shared/utils/dates';

export class DiagnosticReportModel extends FHIRModel<fhir4.DiagnosticReport> {
  kind = 'DiagnosticReport' as const;

  get category() {
    const category = codeableConceptLabel(this.resource.category?.[0]) || this.reportCategory;
    if (category) {
      return category;
    }

    const label = codeableConceptLabel(this.resource.category?.[0]);
    return (
      findReference('Observation', undefined, this.includedResources, this.resource.result?.[0])
        ?.category ?? `No display value found ${label ? ' for ' : ''}${label}`
    );
  }

  get coding() {
    return enrichedLoinc(this.resource.code.coding) || firstDisplay(this.resource.code.coding);
  }

  get displayName() {
    return (
      enrichedLoinc(this.resource.code.coding)?.display ||
      firstDisplay(this.resource.code.coding)?.display ||
      codeableConceptLabel(this.resource.code)
    );
  }

  get date() {
    return (
      this.resource.effectivePeriod?.start ||
      this.resource.effectiveDateTime ||
      this.resource.issued
    );
  }

  get dateDisplay() {
    return this.date ? formatFHIRDate(this.date) : undefined;
  }

  get identifier() {
    return this.resource.identifier?.[0].value ?? '';
  }

  get details() {
    if (!this.results.length) {
      return '';
    }
    const tResult = this.results.length > 1 ? 'results' : 'result';
    return `${this.results.length} ${tResult}`;
  }

  get performer() {
    return this.organization?.display || this.resource.performer?.[0].display;
  }

  get organization() {
    const performer = this.resource.performer ?? [];
    return find(performer, { type: 'Organization' });
  }

  get reportCategory() {
    return (
      findCodingByOrderOfPreference(
        [{ system: SYSTEM_DIAGNOSTIC_SERVICE_SECTION_ID }, { system: SYSTEM_SNOMED }],
        this.resource.code,
      )?.display ?? codeableConceptLabel(this.resource.code)
    );
  }

  get results() {
    return this.resource.result ?? [];
  }

  get title() {
    return this.displayName;
  }
}

export const enrichedLoinc = (coding?: Coding[]) => {
  if (!coding) {
    return undefined;
  }

  return coding.find(
    (x) =>
      x.extension &&
      x.extension.find(
        (ext) => ext.valueString === 'LOINC Standardization' && ext.url === SYSTEM_ENRICHMENT,
      ) &&
      x.display &&
      x.system !== 'http://terminology.hl7.org/CodeSystem/v3-NullFlavor',
  );
};

export const firstDisplay = (coding?: Coding[]): Coding | undefined => {
  if (!coding) {
    return undefined;
  }

  return (
    coding
      // filter out null flavor
      .filter((x) => x.system !== 'http://terminology.hl7.org/CodeSystem/v3-NullFlavor')
      // find the first one that has a truthy display
      .find((x) => x.display)
  );
};

export const filterOutFalsy = <T>(arr: (T | undefined)[] | undefined): T[] => {
  if (!arr) {
    return [];
  }

  // this makes me sad, but TypeScript isn't smart enough to figure this out
  return arr.filter((x) => x) as T[];
};
