import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { FilterItem } from '@ctw/shared/components/filter-bar/filter-bar-types';
import { dismissFilter } from '@ctw/shared/content/resource/filters';
import { uniqueValues } from '@ctw/shared/utils/filters';

export function medicationFilters(
  medications: MedicationStatementModel[],
  includeDismissed: boolean,
): FilterItem[] {
  const prescriberNames = uniqueValues(medications, 'lastPrescriber');
  const filters: FilterItem[] = [];

  if (includeDismissed) {
    filters.push(dismissFilter);
  }

  if (prescriberNames.length > 1) {
    filters.push({
      key: 'lastPrescriber',
      type: 'checkbox',
      icon: faUser,
      values: prescriberNames,
      display: 'prescriber',
    });
  }

  return filters;
}
