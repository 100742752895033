import { patientImmunizationsColumns } from './helpers/columns';
import { immunizationsFilter } from './helpers/filters';
import { defaultImmunizationSort, immunizationSortOptions } from './helpers/sort';
import { useResourceDetailsDrawer } from '../resource/resource-details-drawer';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { usePatientImmunizations } from '@ctw/shared/api/fhir/immunizations';
import { ImmunizationModel } from '@ctw/shared/api/fhir/models/immunization';
import { entryFromArray } from '@ctw/shared/utils/entry-from-array';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { twx } from '@ctw/shared/utils/tailwind';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';

export interface PatientImmunizationsProps {
  className?: string;
}

export const PatientImmunizations = withErrorBoundary({
  boundaryName: 'PatientImmunizations',
  includeTelemetryBoundary: true,
  Component: ({ className }: PatientImmunizationsProps) => {
    const { requestContext } = useCTW();
    const patientImmunizationsQuery = usePatientImmunizations();
    const { data, filters, setFilters, setSort, defaultSort } = useFilteredSortedData({
      cacheKey: 'patient-immunizations',
      defaultSort: defaultImmunizationSort,
      records: patientImmunizationsQuery.data,
    });

    const isEmptyQuery = patientImmunizationsQuery.data?.length === 0;
    const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

    const openDetails = useResourceDetailsDrawer({
      header: (m) => m.description,
      details: immunizationData,
      getSourceDocument: true,
      enableDismissAndReadActions: true,
      deepLinkResourceType: 'Immunization',
    });

    return (
      <div className={twx(className, 'scrollable-pass-through-height')}>
        <ResourceTableActions
          filterOptions={{
            onChange: setFilters,
            filters: immunizationsFilter(),
            selected: filters,
          }}
          sortOptions={{
            defaultSort,
            options: immunizationSortOptions,
            onChange: setSort,
          }}
        />
        <ResourceTable
          showTableHead
          isLoading={patientImmunizationsQuery.isLoading}
          data={data}
          columns={patientImmunizationsColumns(requestContext.builderId)}
          onRowClick={(model) => openDetails({ prefetchedModel: model })}
          enableDismissAndReadActions
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="immunizations"
            />
          }
        />
      </div>
    );
  },
});

const immunizationData = (immunization: ImmunizationModel) => [
  { label: 'Date Given', value: immunization.occurrence },
  { label: 'Recorded By', value: immunization.managingOrganization },
  { label: 'Status', value: immunization.status },
  { label: 'Dose Quantity', value: immunization.doseQuantity },
  { label: 'Route', value: immunization.route },
  { label: 'Site', value: immunization.site },
  { label: 'Lot Number', value: immunization.resource.lotNumber },
  ...entryFromArray('Note', immunization.notesDisplay),
];
