import { faCheck, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from '@headlessui/react';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { type JSX, ReactNode, useState } from 'react';
import { MenuItem } from '../_internal/menu-item';
import { ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import './action-dropdown.scss';

export type MenuItem = {
  action: () => void;
  className?: string;
  icon?: IconDefinition;
  name: string;
};

export type OptionsItem = {
  key: string;
  name: string;
  display?: ReactNode;
  isSelected?: boolean;
};
export type DropDownMenuItemType = 'checkbox' | 'select';

export type DropdownMenuProps = {
  buttonClassName?: ClassName;
  children: ReactNode;
  className?: ClassName;
  items: OptionsItem[];
  onItemSelect: (clickedItem: { key: string; name: string; value: boolean }) => void;
  type?: DropDownMenuItemType;
  customOptionRender?: (optionsItem: OptionsItem) => JSX.Element;
  pinnedActions?: MenuItem[];
  isOpen?: boolean;
  container?: HTMLDivElement | null;
};

export function ActionDropdown({
  children,
  className,
  items,
  onItemSelect,
  type,
  buttonClassName,
  pinnedActions = [],
  isOpen,
}: DropdownMenuProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(isOpen);
  return (
    <Menu>
      <RadixDropdownMenu.Root
        modal={false}
        open={isMenuOpen}
        onOpenChange={(e) => setIsMenuOpen(e)}
      >
        <RadixDropdownMenu.Trigger className={twx(buttonClassName)} aria-label="dropdown">
          {children}
        </RadixDropdownMenu.Trigger>
        <RadixDropdownMenu.Portal>
          <RadixDropdownMenu.Content
            align="start"
            // Prevent focus from closing menu, this fixes
            // an issue with interactive testing where a "click"
            // would fire twice, once for the mousedown and
            // again for focus on the button being clicked.
            onFocusOutside={(event) => event.preventDefault()}
            className={twx(
              className,
              'action-dropdown overview-x-hidden relative overflow-y-auto bg-white',
            )}
            collisionPadding={10}
          >
            {items.map((menuItem) => (
              <RadixDropdownMenu.Item
                key={menuItem.key}
                className={twx('action-dropdown-item bg-white')}
                onClick={(e) => {
                  if (type === 'checkbox') {
                    e.preventDefault();
                  }

                  onItemSelect({
                    key: menuItem.key,
                    name: menuItem.name,
                    value: !menuItem.isSelected,
                  });
                }}
              >
                <RenderCorrectFieldType
                  inputType={type}
                  menuItem={menuItem}
                  onClick={onItemSelect}
                />
              </RadixDropdownMenu.Item>
            ))}

            {pinnedActions.length > 0 && (
              <div className={tw`sticky bottom-0 bg-white`}>
                <RadixDropdownMenu.Separator className={tw`dropdown-separator`} />
                {pinnedActions.map((menuItem) => (
                  <RadixDropdownMenu.Item
                    onClick={() => menuItem.action()}
                    key={menuItem.name}
                    className={twx(menuItem.className, 'action-dropdown-item')}
                  >
                    <MenuItem icon={menuItem.icon}>{menuItem.name}</MenuItem>
                  </RadixDropdownMenu.Item>
                ))}
              </div>
            )}
          </RadixDropdownMenu.Content>
        </RadixDropdownMenu.Portal>
      </RadixDropdownMenu.Root>
    </Menu>
  );
}

export type RenderCorrectFieldTypeProps = {
  inputType?: DropDownMenuItemType;
  menuItem: OptionsItem;
  onClick: (clickedItem: { key: string; name: string; value: boolean }) => void;
};

const RenderCorrectFieldType = ({ inputType, menuItem, onClick }: RenderCorrectFieldTypeProps) => {
  switch (inputType) {
    case 'checkbox':
      return (
        <div>
          <label htmlFor={menuItem.name} className={tw`flex cursor-pointer items-center space-x-3`}>
            <input
              type="checkbox"
              className={tw`m-0 mb-px w-4`}
              name={menuItem.name}
              onClick={(e) => {
                onClick({
                  key: menuItem.key,
                  name: menuItem.name,
                  value: !menuItem.isSelected,
                });
                e.stopPropagation();
              }}
              checked={!!menuItem.isSelected}
              onChange={(e) => {
                e.stopPropagation();
              }}
            />
            {menuItem.display ? menuItem.display : <span>{menuItem.name}</span>}
          </label>
        </div>
      );
    case 'select':
      return (
        <div className={tw`flex w-full justify-between`}>
          <span className={twx({ 'font-semibold': menuItem.isSelected })}>
            {menuItem.display ? menuItem.display : menuItem.name}
          </span>
          {menuItem.isSelected && (
            <FontAwesomeIcon
              icon={faCheck}
              className={tw`inline-block h-4 stroke-0 align-middle text-primary-text`}
            />
          )}
        </div>
      );
    default:
      return <div>{menuItem.name}</div>;
  }
};
