import { Worker } from '@react-pdf-viewer/core';
import { QueryClientProvider } from '@tanstack/react-query';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BulkActionProvider } from './bulk-action-provider';
import { FeatureFlagProvider } from './feature-flag-provider';
import { ToastProvider } from './toast-provider';
import { Env } from './types';
import { ZapPropsProvider } from './zap-props-provider';
import { ZapTabStateProvider } from './zap-tab-provider';
import { OnResourceSaveCallback } from '@ctw/shared/api/fhir/action-helper';
import { QueryCache, QueryClient } from '@tanstack/query-core';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { TelemetryInitializer } from '@ctw/shared/context/telemetry/telemetry-initializer';
import Client from 'fhir-kit-client';
import { getFetchFromFqs } from '@ctw/shared/api/fqs/client';
import {
  getAuthTokenState,
  isAuthTokenExpired,
  isAuthTokenPastExpiryGracePeriod,
  ZusAuthTokenState,
} from '@ctw/shared/utils/auth';
import { toast } from 'react-toastify';
import { notify } from '@ctw/shared/components/toast';
import { getPageEnv } from '@ctw/shared/utils/get-page-env';
import { OverlayProvider } from '@ctw/shared/context/overlay-provider';
import { GraphQLClient } from 'graphql-request';
import { getEHRIntegrationServiceBaseUrl, getZusServiceUrl } from '@ctw/shared/api/urls';
import { assertResponseOK, getResponseContent } from '@ctw/shared/utils/http';
import { ZAPTabName } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';
import { ZusService } from '@ctw/shared/context/zus-ui-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { debounce } from 'lodash-es';

// We use an expiry padding to provide a buffer to prevent race conditions.
// A race condition could happen in that we check if the token is expired,
// it isn't, but by time we do our request(s) it has expired.
// We track the id of the toast that we open to avoid stacking.
const AUTH_ERROR_TOAST_ID = 'invalid-token' as const;

const CTW_REQUEST_HEADER = { 'Zus-Request-Source': 'component-library' } as const;

type CtwFetchFn = <TData>(
  url: Parameters<typeof fetch>[0],
  init: Parameters<typeof fetch>[1],
) => Promise<{
  data: TData;
  headers: Response['headers'];
}>;

export enum EHR {
  ATHENA = 'athena',
  CANVAS = 'canvas',
  ELATION = 'elation',
  FIREFLY = 'firefly',
  HEALTHIE = 'healthie',
  SALESFORCE = 'salesforce',
}

type EHRSupportedWritebacks = {
  [key in EHR]: ZAPTabName[];
};

const EHR_SUPPORTED_WRITEBACKS: EHRSupportedWritebacks = {
  [EHR.ATHENA]: ['conditions-all'],
  [EHR.CANVAS]: ['medications-all', 'medications-outside'],
  [EHR.ELATION]: ['conditions-all'],
  [EHR.FIREFLY]: ['conditions-all'],
  [EHR.HEALTHIE]: [],
  [EHR.SALESFORCE]: [],
} as const;

export interface CTWRequestContext {
  isInIframe: boolean;
  env: Env;
  headers?: HeadersInit;
  authToken: string;
  ehr: string;
  builderId: string;
  service: ZusService;
  serviceVersion: string;
  serviceVariant?: string;
  serviceEnv: Env;
  isAuthTokenExpired: () => boolean;
  authTokenState: ZusAuthTokenState;
  resourceSaveHeaders?: Record<string, string>;
}

export interface CTWState {
  requestContext: CTWRequestContext;
  headers?: HeadersInit;
  allowSmallBreakpointCCDAViewer: boolean;
  featureFlags: Record<string, boolean>;
  onResourceSave: OnResourceSaveCallback;
  resourceSaveHeaders?: Record<string, string>;
  updateBuilderId: (builderId: string) => void;
  isWritebackEnabled: (context: ZAPTabName) => boolean;
  fhirWriteBackClient: Client;
  graphqlClient: GraphQLClient;
  /*
   * @deprecated This method should not be used and will be replaced soon.
   */
  fetchFromFqs: (
    url: string,
    options: RequestInit,
  ) => Promise<{ data: unknown; headers: Response['headers'] }>;
  ctwFetch: CtwFetchFn;
}

export const CTWContext = createContext<CTWState | undefined>(undefined);

export type CTWProviderProps = {
  env: Env;
  service: ZusService;
  serviceVersion: string;
  serviceVariant?: string;
  builderId?: string;
  ehr: string;
  headers?: Record<string, string>;
  authToken: string;
  getRefreshedAuthToken?: (token: string) => Promise<string>;
  onAuthTokenExpiration?: () => void;
  featureFlags?: Record<string, boolean>;
  onResourceSave?: OnResourceSaveCallback;
  resourceSaveHeaders?: Record<string, string>;
  allowSmallBreakpointCCDAViewer?: boolean;
};

/**
 * CTWProvider is required for all apps in the monorepo. It should be loaded as early as possible
 * and provides global context and telemetry for the app.
 */
export const CTWProvider = ({
  children,
  env,
  builderId,
  ehr,
  service,
  serviceVersion,
  serviceVariant,
  featureFlags,
  headers,
  authToken: initialAuthToken,
  onResourceSave,
  getRefreshedAuthToken,
  onAuthTokenExpiration,
  resourceSaveHeaders,
  allowSmallBreakpointCCDAViewer = false,
}: PropsWithChildren<CTWProviderProps>) => {
  const telemetry = useTelemetry();
  const [authToken, setAuthToken] = useState(initialAuthToken);
  const [authTokenState, setAuthTokenState] = useState(getAuthTokenState(authToken));
  const [currentBuilderId, setCurrentBuilderId] = useState(builderId ?? authTokenState.builderId);
  const [showingAuthTokenError, setShowingAuthTokenError] = useState(false);
  const debouncedGetRefreshedAuthToken =
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(
      debounce(async (token) => getRefreshedAuthToken?.(token), 2000, { leading: true }),
      [getRefreshedAuthToken],
    );

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            gcTime: 1000 * 30, // garbage collect data every 30s
            staleTime: 1000 * 30, // data is valid for 30s, stale while revalidate after
            refetchOnWindowFocus: false,
          },
        },
        queryCache: new QueryCache({
          onError: (error, query) => {
            telemetry.trackError({
              message: 'Unhandled react query error',
              error,
              context: {
                query: {
                  key: query.queryKey,
                  status: query.state.status,
                },
                queryMeta: query.meta,
              },
            });
          },
        }),
      }),
    [telemetry],
  );

  const requestContext: CTWRequestContext = useMemo(
    () => ({
      isInIframe: window.location !== window.parent.location,
      authToken,
      isAuthTokenExpired: () => isAuthTokenPastExpiryGracePeriod(authToken),
      authTokenState,
      builderId: currentBuilderId,
      ehr,
      env,
      headers,
      service,
      serviceEnv: getPageEnv(),
      serviceVersion,
      serviceVariant,
    }),
    [
      authToken,
      authTokenState,
      currentBuilderId,
      ehr,
      env,
      headers,
      service,
      serviceVariant,
      serviceVersion,
    ],
  );

  const isWritebackEnabled = useCallback(
    (context: ZAPTabName): boolean => {
      const normalizedEhr = requestContext.ehr.toLocaleLowerCase();
      if (!(normalizedEhr in EHR_SUPPORTED_WRITEBACKS)) {
        return false;
      }

      return EHR_SUPPORTED_WRITEBACKS[normalizedEhr as EHR].includes(context);
    },
    [requestContext.ehr],
  );

  // fhirWriteBackClient uses write-back proxy to create new FHIR resources which could also have
  // configurations in ehr-data-integration service to additionally write out to an external source
  const fhirWriteBackClient = useMemo(() => {
    const customHeaders: Record<string, string> = { ...CTW_REQUEST_HEADER };
    if (builderId) {
      customHeaders['Zus-Account'] = builderId;
    }

    const writeBackCustomHeaders = { ...customHeaders };
    if (resourceSaveHeaders) {
      Object.assign(writeBackCustomHeaders, resourceSaveHeaders);
    }

    return new Client({
      baseUrl: `${getEHRIntegrationServiceBaseUrl(env)}/proxy/fhir`,
      bearerToken: requestContext.authToken,
      customHeaders: writeBackCustomHeaders,
    });
  }, [builderId, env, requestContext.authToken, resourceSaveHeaders]);

  const graphqlClient = useMemo(() => {
    const endpoint = `${getZusServiceUrl(requestContext.env, 'fqs')}/query`;

    return new GraphQLClient(endpoint, {
      errorPolicy: 'all',
      headers: {
        ...CTW_REQUEST_HEADER,
        authorization: `Bearer ${requestContext.authToken}`,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestContext.authToken, requestContext.env]);

  const ctwFetch: CtwFetchFn = useCallback(
    async (input, init = {}) => {
      const newInit: Parameters<typeof fetch>[1] = {
        ...init,
        headers: {
          ...init.headers,
          ...CTW_REQUEST_HEADER,
        },
      };

      try {
        const fetchResponse = await fetch(input, newInit);
        await assertResponseOK(fetchResponse);

        return {
          data: await getResponseContent(fetchResponse),
          headers: fetchResponse.headers,
        };
      } catch (error) {
        telemetry.trackError({
          error,
          context: {
            fetch: {
              url: input.toString(),
              init: {
                ...newInit,
                headers: telemetry.redactSensitiveHttpHeaders(newInit.headers),
              },
            },
          },
        });
        return {
          data: {},
          headers: new Headers(),
        };
      }
    },
    [telemetry],
  );

  const providerState: CTWState = useMemo(
    () => ({
      requestContext,
      allowSmallBreakpointCCDAViewer,
      featureFlags: featureFlags ?? {},
      headers,
      onResourceSave: (resource, action, error) => onResourceSave?.(resource, action, error),
      resourceSaveHeaders,
      updateBuilderId: setCurrentBuilderId,
      fetchFromFqs: getFetchFromFqs(ctwFetch, env, authToken),
      isWritebackEnabled,
      fhirWriteBackClient,
      graphqlClient,
      ctwFetch,
    }),
    [
      requestContext,
      allowSmallBreakpointCCDAViewer,
      featureFlags,
      headers,
      onResourceSave,
      resourceSaveHeaders,
      env,
      authToken,
      isWritebackEnabled,
      fhirWriteBackClient,
      graphqlClient,
      ctwFetch,
    ],
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const handleAuthTokenRefresh = async () => {
        if (isAuthTokenExpired(authToken)) {
          const refreshedAuthToken = await debouncedGetRefreshedAuthToken(authToken);
          const refreshedTokenState = getAuthTokenState(refreshedAuthToken ?? '');

          if (refreshedAuthToken && refreshedAuthToken !== authToken) {
            setAuthToken(refreshedAuthToken);
            setAuthTokenState(refreshedTokenState);
          } else if (
            !refreshedAuthToken ||
            !refreshedTokenState.email ||
            isAuthTokenPastExpiryGracePeriod(refreshedAuthToken)
          ) {
            if (!showingAuthTokenError) {
              onAuthTokenExpiration?.();
              sendInvalidTokenNotification(providerState.requestContext, AUTH_ERROR_TOAST_ID);
              setShowingAuthTokenError(true);
            }
          }
        }
      };

      void handleAuthTokenRefresh();
    }, 300);

    return () => {
      // Ensure the last enqueued execution of the interval finishes before clearing it
      setTimeout(() => {
        clearInterval(intervalId);
      }, 1000);
    };
  }, [
    authToken,
    debouncedGetRefreshedAuthToken,
    getRefreshedAuthToken,
    onAuthTokenExpiration,
    providerState.requestContext,
    showingAuthTokenError,
  ]);

  useEffect(() => {
    // We don't want to stack toast notifications. So we track if there is one already open with auth errors
    const unsubscribe = toast.onChange((t) => {
      if (t.id === AUTH_ERROR_TOAST_ID && t.status === 'removed') {
        setShowingAuthTokenError(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <CTWContext.Provider value={providerState}>
      <TelemetryInitializer>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <OverlayProvider>
            <ToastProvider>
              <FeatureFlagProvider env={env}>
                <ZapTabStateProvider>
                  <BulkActionProvider>
                    {/* https://react-pdf-viewer.dev/docs/basic-usage/ */}
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      <ZapPropsProvider>{children}</ZapPropsProvider>
                    </Worker>
                  </BulkActionProvider>
                </ZapTabStateProvider>
              </FeatureFlagProvider>
            </ToastProvider>
          </OverlayProvider>
        </QueryClientProvider>
      </TelemetryInitializer>
    </CTWContext.Provider>
  );
};

const sendInvalidTokenNotification = (requestContext: CTWRequestContext, toastId: string) => {
  const message =
    requestContext.isAuthTokenExpired() ? 'This token has expired' : 'Only user tokens are allowed';
  notify({
    type: 'error',
    title: 'Warning',
    body: `Invalid auth token: ${message}`,
    options: {
      toastId,
    },
  });
};

export const useCtwIfAvailable = (): CTWState | undefined => useContext(CTWContext);

export const useCTW = () => {
  const context = useContext(CTWContext);

  if (!context) {
    throw new Error('useCTW must be used within a CTWProvider');
  }

  return context;
};
