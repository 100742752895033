import { UseQueryResult } from '@tanstack/react-query';
import { merge } from 'lodash-es';
import { getCTWBaseUrl } from '@ctw/shared/api/urls';
import { QUERY_KEY_BUILDER_CONFIG } from '@ctw/shared/utils/query-keys';
import { useCtwQuery } from '@ctw/shared/hooks/use-ctw-query';

const DEFAULT_CONFIG = {
  overviewCards: {
    conditions: { enabled: true },
    referrals: { enabled: true },
    medications: { enabled: true },
    diagnostics: { enabled: true },
    encounters: { enabled: true },
    episodesOfCare: { enabled: false },
    vitals: { enabled: true },
    packages: { enabled: true },
    careGaps: { enabled: true },
  },
};

export type BuilderConfig = {
  builderId: string;
  overviewCards?: Record<string, { enabled: boolean } | undefined>;
  systemMRN?: string;
  hospitalizationExtensionFilters: { systemURL: string; displayName: string } | undefined;
};

export const useBuilderConfig = (): UseQueryResult<BuilderConfig> =>
  useCtwQuery<BuilderConfig>({
    queryId: QUERY_KEY_BUILDER_CONFIG,
    queryFn: async ({ requestContext, ctwFetch }) => {
      try {
        const { data: config } = await ctwFetch(
          `${getCTWBaseUrl(requestContext.env)}/api/builders/${requestContext.builderId}/config`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${requestContext.authToken}`,
            },
            mode: 'cors',
          },
        );
        return merge({}, DEFAULT_CONFIG, config) as unknown as BuilderConfig;
      } catch {
        return DEFAULT_CONFIG as unknown as BuilderConfig;
      }
    },
  });
