import { startCase } from 'lodash-es';
import type { InputHTMLAttributes, JSX } from 'react';
import { formatDateLocalToISO } from '@ctw/shared/api/fhir/formatters';
import { FormFieldOption } from '@ctw/shared/components/form/drawer-form-with-fields';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { faExclamationCircle, faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type FormFieldProps = {
  errors?: string[];
  options?: readonly FormFieldOption[];
  lines?: number;
  defaultValue?: string | string[];
  readonly?: boolean;
  render?: (
    readonly: boolean | undefined,
    props: InputHTMLAttributes<HTMLInputElement>,
  ) => JSX.Element;
} & InputHTMLAttributes<HTMLInputElement> & { name: string };

export const FormField = ({
  errors,
  options,
  lines,
  defaultValue,
  readonly,
  hidden,
  render,
  ...inputProps
}: FormFieldProps) => {
  // We display dates in MM/DD/YYYY format, but date input fields
  // expect it to be in YYYY-MM-DD format.

  const value =
    inputProps.type === 'date' ? formatDateLocalToISO(defaultValue as string) : defaultValue;

  const getFieldComponent = () => {
    if (render) {
      return render(readonly, { ...inputProps, defaultValue, hidden });
    }

    if (options) {
      return (
        <select
          data-testid={`form-field-${inputProps.name}`}
          className={twx({ error: errors }, 'listbox-button w-full')}
          name={inputProps.name}
          id={inputProps.name}
          disabled={inputProps.disabled}
          defaultValue={value}
        >
          {!options.some((option) => option.value === defaultValue) && (
            <option value={defaultValue} disabled>
              Select one
            </option>
          )}
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {startCase(option.label)}
            </option>
          ))}
        </select>
      );
    }
    if (lines) {
      return (
        <textarea
          data-testid={`form-field-${inputProps.name}`}
          rows={lines}
          className={twx({ error: errors }, 'listbox-textarea w-full whitespace-pre-wrap')}
          defaultValue={value}
          disabled={inputProps.disabled}
          readOnly={readonly}
          name={inputProps.name}
          id={inputProps.name}
        />
      );
    }
    return (
      <input
        data-testid={`form-field-${inputProps.name}`}
        {...inputProps}
        id={inputProps.name}
        type={inputProps.type}
        className={twx({ error: errors }, 'listbox-input w-full')}
        readOnly={readonly}
        // Only set defaultValue prop if there is a value.
        // This fixes an issue where partially filled dates
        // would get reset when saving and showing errors as the defaultValue could
        // be undefined and the input gets reset to that (empty).
        {...(value ? { defaultValue: value } : {})}
      />
    );
  };

  if (render && hidden) {
    return render(readonly, {
      ...inputProps,
      defaultValue,
      hidden,
    });
  }

  if (hidden) {
    return (
      <input
        data-testid={`form-field-${inputProps.name}`}
        {...inputProps}
        defaultValue={value}
        hidden={hidden}
      />
    );
  }

  return (
    <>
      <div className={tw`relative`}>
        {getFieldComponent()}
        {readonly && (
          <FontAwesomeIcon
            icon={faLock}
            className={tw`absolute right-3 top-1/2 h-4 w-4 -translate-y-1/2 transform fill-content-icon`}
          />
        )}
        {errors && (
          <div className={tw`pointer-events-none absolute right-0 top-2 pr-4`}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className={tw`h-5 w-5 text-error-main`}
              aria-hidden="true"
            />
          </div>
        )}
      </div>

      {errors && (
        <div className={tw`text-xs italic text-error-text`}>
          {errors.length > 1 ?
            errors.map((error) => <div key={error}>&bull; {error}</div>)
          : errors[0]}
        </div>
      )}
    </>
  );
};
