import { FHIRModel } from './fhir-model';
import {
  getIdentifyingRxNormCode,
  getMedicationDisplayName,
  getPerformingOrganization,
} from '@ctw/shared/api/fhir/medication';
import { PractitionerModel } from '@ctw/shared/api/fhir/models/practitioner';
import { findReference } from '@ctw/shared/api/fhir/resource-helper';
import { formatFHIRDate } from '@ctw/shared/utils/dates';

export class MedicationDispenseModel extends FHIRModel<fhir4.MedicationDispense> {
  kind = 'MedicationDispense' as const;

  get includedPerformer(): string | undefined {
    const reference = this.resource.performer?.[0]?.actor;

    const practitioner = findReference(
      'Practitioner',
      this.resource.contained,
      this.includedResources,
      reference,
    );

    if (practitioner) {
      return new PractitionerModel(practitioner).fullName;
    }
    return this.resource.performer?.[0]?.actor.display;
  }

  get performer(): fhir4.Organization | undefined {
    return getPerformingOrganization(this.resource, this.includedResources);
  }

  get performerDetails() {
    const { performer } = this;
    return {
      name: performer?.name ?? '',
      address: performer?.address?.[0].text ?? '',
      telecom: performer?.telecom?.[0].value ?? '',
    };
  }

  get status(): string {
    return this.resource.status;
  }

  get quantityDisplay(): string | undefined {
    const { value, unit = 'Unspecified' } = this.resource.quantity || {};
    return value !== undefined ? `${value} (${unit})` : undefined;
  }

  get rxNorm(): string | undefined {
    return getIdentifyingRxNormCode(this.resource, this.includedResources);
  }

  get supplied(): string | undefined {
    const { value, unit = 'days' } = this.resource.daysSupply || {};
    return value ? `${value} ${unit}` : undefined;
  }

  get medicationDisplayName() {
    return getMedicationDisplayName(this.resource, this.includedResources);
  }

  get PMARefillNotPickedUp(): string | undefined {
    return this.resource.extension?.find(
      (e) => e.url === 'http://surescripts.net/fhir/CodeSystem/patient-notification-types',
    )?.valueString;
  }

  get alertTimeStamp(): string | undefined {
    const time = this.resource.extension?.find(
      (e) => e.url === 'http://surescripts.net/fhir/CodeSystem/notification-generated-time',
    )?.valueInstant;

    return time ? formatFHIRDate(time) : undefined;
  }

  get title() {
    return this.medicationDisplayName;
  }

  get whenHandedOver() {
    return this.resource.whenHandedOver ? formatFHIRDate(this.resource.whenHandedOver) : undefined;
  }

  get whenPrepared() {
    return this.resource.whenPrepared ? formatFHIRDate(this.resource.whenPrepared) : undefined;
  }
}
