import { orderBy } from 'lodash-es';
import { useMemo } from 'react';
import { VitalsBucket } from '@ctw/shared/api/fhir/models/vitals-bucket';
import { usePatientVitals } from '@ctw/shared/api/fhir/vitals';

type OverviewVitals = {
  isLoading: boolean;
  data: OverviewVital[];
};

type OverviewVital = {
  vital: string;
  value?: string;
  date?: string;
  firstParty?: boolean;
};

const OVERVIEW_VITALS = [
  { title: 'BP', dataIndex: 'bloodPressure' },
  { title: 'HR', dataIndex: 'pulse' },
  { title: 'Temp', dataIndex: 'temperature' },
  { title: 'RR', dataIndex: 'respiratoryRate' },
  { title: 'SpO2', dataIndex: 'oxygenSaturation' },
  { title: 'Ht/Lt', dataIndex: 'height' },
  { title: 'Wt', dataIndex: 'weight' },
  { title: 'BMI', dataIndex: 'bmi' },
  { title: 'HC', dataIndex: 'headCircumference' },
  { title: 'LMP', dataIndex: 'lmp' },
  { title: 'Pain', dataIndex: 'pain' },
] as { title: string; dataIndex: keyof VitalsBucket }[];

const VITALS_TO_DROP_WHEN_BLANK = ['HC', 'LMP', 'Pain'];

export function usePatientVitalsOverview(): OverviewVitals {
  const { data, isLoading } = usePatientVitals();

  return useMemo(() => {
    if (isLoading || !data?.length) {
      return {
        isLoading,
        data: [],
      };
    }

    // Get newest encounters first, so that we grab most recent vitals first.
    const sortedBuckets = orderBy(data, (e) => e.dateTime, 'desc');

    // For each vital, find the most recent encounter that has that vital.
    const overviewVitals: OverviewVital[] = OVERVIEW_VITALS.map((vital) => {
      const vitalBucket = sortedBuckets.find((e) => e[vital.dataIndex]);
      const value = vitalBucket?.[vital.dataIndex] as
        | {
            display: string;
            firstParty: boolean;
          }
        | undefined;
      const date = vitalBucket?.date;

      return {
        vital: vital.title,
        date,
        value: value?.display,
        firstParty: value?.firstParty,
      };
    });

    // Filter out some of our empty vitals.
    return {
      isLoading: false,
      data: overviewVitals.filter(
        (v) => !VITALS_TO_DROP_WHEN_BLANK.includes(v.vital) || v.value !== undefined,
      ),
    };
  }, [data, isLoading]);
}
