import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';

export interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onAfterClosed?: () => void;
  children: ReactNode;
  className?: string;
  id?: string;
}

export const Modal = ({ isOpen, onAfterClosed, children, className, id, onClose }: ModalProps) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className={tw`relative z-[10100]`} onClose={() => onClose?.()}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={onAfterClosed}
      >
        <div className={tw`fixed inset-0 transition-opacity`}>
          <div className={tw`h-full w-full bg-content-subtle opacity-75`} />
        </div>
      </Transition.Child>

      <div className={tw`fixed inset-0 overflow-auto`} id={id}>
        <div className={tw`min-h-full items-center p-10 max-lg:p-4 max-sm:p-0`}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className={twx(
                'ml-auto mr-auto w-fit max-w-lg transform rounded-lg bg-white p-6 shadow-xl',
                className,
              )}
            >
              <Button
                type="button"
                ariaLabel="Close"
                variant="unstyled"
                className={twx(
                  'h-5 w-5',
                  'text-content-icon hover:text-content-subtle',
                  'flex items-center justify-center',
                  'absolute right-3 top-3',
                )}
                onClick={onClose}
              >
                <FontAwesomeIcon icon={faXmark} className={tw``} />
              </Button>
              <div className={tw`flex h-full flex-col space-y-6`} data-testid="modal">
                {children}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);
