import { isArrayLike } from 'lodash-es';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { fetchConditionsByIdFQS } from '@ctw/shared/api/fhir/conditions';
import { getImmunizationsFromFQS } from '@ctw/shared/api/fhir/immunizations';
import { getAllergyIntolerancesById } from '@ctw/shared/api/fhir/allergies';
import { getEncountersWithClinicalNotesById } from '@ctw/shared/api/fhir/encounters';
import { fetchDiagnosticReportsFromFQSById } from '@ctw/shared/api/fhir/diagnostic-report';
import { fetchReferralsByIdFQS } from '@ctw/shared/api/fhir/referrals';
import { getMedicationStatementsByIdFQS } from '@ctw/shared/api/fhir/medications';
import { getCareTeamMembersByIdFromFQS } from '@ctw/shared/api/fhir/care-team';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { useCtwQuery } from '@ctw/shared/hooks/use-ctw-query';
import { QUERY_KEY_BINARY_ID } from '@ctw/shared/utils/query-keys';
import { useEffect, useState } from 'react';
import { Resource } from 'fhir/r4';
import { getDocumentReferenceWithBasicsById } from '@ctw/shared/api/fhir/document';
import { getCareGapByMeasureIdFromFQS } from '@ctw/shared/api/fhir/care-gaps';

interface UseFetchResourceOptions {
  resourceId: string;
  resourceType: string;
  patient: PatientModel;
  enabled?: boolean;
}

export function useFetchResource<TResource extends Resource, TModel extends FHIRModel<TResource>>({
  resourceId,
  resourceType,
  patient,
  enabled = true,
}: UseFetchResourceOptions) {
  const resourceQuery = useCtwQuery({
    refetchOnMount: false,
    queryId: QUERY_KEY_BINARY_ID,
    queryKey: [resourceId, resourceType, patient.resource.id],
    queryFn: async ({ requestContext, telemetry, ctwFetch, graphqlClient }) => {
      switch (resourceType) {
        case 'Condition':
          return fetchConditionsByIdFQS(telemetry, graphqlClient, patient, [resourceId]);
        case 'Immunization':
          return getImmunizationsFromFQS(telemetry, graphqlClient, requestContext, patient, [
            resourceId,
          ]);
        case 'AllergyIntolerance':
          return getAllergyIntolerancesById(telemetry, graphqlClient, patient, [resourceId]);
        case 'Encounter':
          return getEncountersWithClinicalNotesById(telemetry, graphqlClient, patient, [
            resourceId,
          ]);
        case 'DiagnosticReport':
          return fetchDiagnosticReportsFromFQSById(telemetry, graphqlClient, patient, [resourceId]);
        case 'ServiceRequest':
          return fetchReferralsByIdFQS(telemetry, graphqlClient, patient, [resourceId]);
        case 'MedicationStatement':
          return getMedicationStatementsByIdFQS(telemetry, graphqlClient, patient, [resourceId]);
        case 'Practitioner':
          return getCareTeamMembersByIdFromFQS(telemetry, graphqlClient, patient, [resourceId]);
        case 'DocumentReference':
          return getDocumentReferenceWithBasicsById(telemetry, graphqlClient, patient, [
            resourceId,
          ]);
        case 'Measure':
          return getCareGapByMeasureIdFromFQS(ctwFetch, requestContext, patient, resourceId);
        default:
          return null;
      }
    },
    enabled: enabled && Boolean(resourceId) && Boolean(resourceType),
  });

  const [resource, setResource] = useState<TModel | null>(null);

  useEffect(() => {
    if (!resourceQuery.isLoading && resourceQuery.data && !resource) {
      if (isArrayLike(resourceQuery.data)) {
        setResource((resourceQuery.data[0] ?? null) as unknown as TModel | null);
      } else {
        setResource(resourceQuery.data as unknown as TModel | null);
      }
    }
  }, [resource, resourceQuery]);

  return { ...resourceQuery, data: resource };
}
