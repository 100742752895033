import { useState } from 'react';
import { DetailEntry, DetailsCard } from './details-card';
import { ResourceDetailSummary } from '../resource-detail-summary';
import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import { TrackingMetadata } from '@ctw/shared/context/telemetry/tracking-metadata';
import { tw } from '@ctw/shared/utils/tailwind';

export type HistoryEntryProps = {
  binaryId?: string;
  date?: string;
  details: DetailEntry[];
  hideEmpty?: boolean;
  id: string;
  subtitle?: string;
  title?: string;
  versionId?: string;
};

type Props = HistoryEntryProps & {
  resourceTypeTitle: string;
  trackingMetadata?: TrackingMetadata;
};

export const HistoryEntry = ({
  binaryId,
  date,
  details,
  hideEmpty,
  subtitle,
  title,
  resourceTypeTitle,
}: Props) => {
  const [isDetailShown, setIsDetailShown] = useState(false);

  return (
    <div className={tw`space-y-1`}>
      <ResourceDetailSummary
        date={date}
        title={title}
        subtitle={subtitle}
        isDetailShown={isDetailShown}
        setIsDetailShown={setIsDetailShown}
        hasDocument={!!binaryId}
        analyticsInteraction="toggle_history_entry"
        analyticsActionExpand="expand_history_entry"
        analyticsActionCollapse="collapse_history_entry"
      />
      {isDetailShown && (
        <DetailsCard
          details={details}
          hideEmpty={hideEmpty}
          documentButton={
            <ResponsiveSourceDocumentLink
              binaryId={binaryId}
              documentTitle={resourceTypeTitle}
              telemetryTarget="history_entry"
            />
          }
        />
      )}
    </div>
  );
};
