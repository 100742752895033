import { faCircleInfo, faTriangleExclamation, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import { z } from 'zod';
import { processVariantPayload, useFeatureVariant } from '@ctw/shared/hooks/use-feature-variant';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Link } from '@ctw/shared/components/link';

// TODO change the name of this once the alerts are off.
const DISMISSED_ALERTS_KEY = 'zap-alerts-dismissed';

const THEME_TYPES = {
  caution: {
    icon: faTriangleExclamation,
    iconColor: 'text-caution-main',
    iconHover: 'hover:bg-caution-badge',
    messageColor: 'text-caution-text',
    linkHover: 'hover:text-caution-main',
    bg: 'bg-caution-background',
  },
  info: {
    icon: faCircleInfo,
    iconColor: 'text-info-main',
    iconHover: 'hover:bg-info-badge',
    messageColor: 'text-info-text',
    linkHover: 'hover:text-info-hover',
    bg: 'bg-info-background',
  },
};

const PAYLOAD_SCHEMA = z.object({
  type: z.enum(['caution', 'info']),
  message: z.string(),
  link: z.string().optional(),
});

export type AlertFeatureFlag = {
  className?: string;
  featureFlag: string;
};

export function AlertFeatureFlag({ className, featureFlag }: AlertFeatureFlag) {
  const [dismissed, setDismissed] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const featureVariant = useFeatureVariant(featureFlag);
  const payload = processVariantPayload(featureVariant, PAYLOAD_SCHEMA);

  const dismissedAlerts = getDismissedAlerts();

  // Hide if there is no payload (meaning there's no alert to show).
  // Also hide if the alert has been dismissed.
  if (!payload || dismissedAlerts.includes(featureVariant.name) || dismissed) {
    return null;
  }

  const theme = THEME_TYPES[payload.type];

  function dismiss() {
    dismissedAlerts.push(featureVariant.name);
    localStorage.setItem(DISMISSED_ALERTS_KEY, JSON.stringify(dismissedAlerts));
    setDismissed(true);
  }

  return (
    <div ref={ref} className={twx(className, theme.bg, 'flex justify-between space-x-7 p-3')}>
      <div className={tw`flex flex-grow space-x-3`}>
        <FontAwesomeIcon icon={theme.icon} className={twx(theme.iconColor, 'h-5 flex-none')} />
        <div
          className={twx(
            theme.messageColor,
            'flex flex-grow flex-col justify-between space-y-2 text-sm font-medium md:flex-row md:space-x-3',
          )}
        >
          <div>{payload.message}</div>
          {payload.link && (
            <Link
              href={payload.link}
              className={twx(theme.linkHover, 'whitespace-nowrap underline')}
              target="_blank"
            >
              Learn More
            </Link>
          )}
        </div>
      </div>
      <FontAwesomeIcon
        onClick={dismiss}
        icon={faXmark}
        className={twx(
          theme.iconColor,
          theme.iconHover,
          '-mb-0.5 h-[22px] w-[22px] flex-none cursor-pointer', // Negative bottom margin is to prevent the height from increasing the height of the aler box.
        )}
      />
    </div>
  );
}

function getDismissedAlerts() {
  try {
    const dismissedAlerts = localStorage.getItem(DISMISSED_ALERTS_KEY);
    return dismissedAlerts ? JSON.parse(dismissedAlerts) : [];
  } catch (e) {
    return [];
  }
}
