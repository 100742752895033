import { ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';

export type SimplePaginationProps = {
  currentPage: number;
  isLoading?: boolean;
  setCurrentPage: (n: number) => void;
  hasNext?: boolean;
};

type PaginateButtonProps = {
  pageToNavigateTo: number;
  setCurrentPage: (n: number) => void;
  children: string;
  className?: ClassName;
};

export const SimplePagination = ({
  setCurrentPage,
  currentPage,
  hasNext,
}: SimplePaginationProps) => {
  if (hasNext || currentPage > 1) {
    return (
      <div className={tw`simple-pagination flex justify-end space-x-3 px-6 py-3`}>
        <PaginateButton
          pageToNavigateTo={currentPage - 1}
          setCurrentPage={setCurrentPage}
          className={twx({ invisible: currentPage === 1 })}
        >
          Prev
        </PaginateButton>

        <PaginateButton
          pageToNavigateTo={currentPage + 1}
          setCurrentPage={setCurrentPage}
          className={twx({ invisible: !hasNext })}
        >
          Next
        </PaginateButton>
      </div>
    );
  }
  return <></>;
};

const PaginateButton = ({
  pageToNavigateTo,
  setCurrentPage,
  children,
  className,
}: PaginateButtonProps) => (
  <Button
    type="button"
    variant="secondary"
    className={twx(className)}
    onClick={() => {
      setCurrentPage(pageToNavigateTo);
    }}
  >
    {children}
  </Button>
);
