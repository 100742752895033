import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';
import { Interweave } from 'interweave';
import { type JSX, ReactNode, useRef, useState } from 'react';
import '@ctw/shared/content/CCDA/styles.scss';
import { fixHtml } from '@ctw/shared/content/CCDA/ccda-viewer/helpers';
import { SectionType } from '@ctw/shared/content/CCDA/ccda-viewer/types';
import { DOCUMENT_HEADER_ID, TABLE_OF_CONTENT_ID } from '@ctw/shared/content/CCDA/constants';
import useHeaderOffset from '@ctw/shared/hooks/use-header-offset';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Heading } from '@ctw/shared/components/heading';
import { Button } from '@ctw/shared/components/button';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export const Section = ({ title, humanReadable, id, isEmpty }: SectionType): JSX.Element | null => {
  if (!humanReadable) return null;

  const sanitizedHtml = DOMPurify.sanitize(fixHtml(humanReadable));
  const cleanedHtml = sanitizedHtml.replace(/Â&nbsp;/g, ' ');

  return (
    <SectionContainer
      title={title}
      id={id}
      tableOfContentsId={TABLE_OF_CONTENT_ID}
      overrideDefaultHidden={isEmpty}
    >
      <Interweave content={cleanedHtml} />
    </SectionContainer>
  );
};

export type SectionContainerProps = {
  children: ReactNode;
  id: string;
  title: string;
  tableOfContentsId: string;
  overrideDefaultHidden?: boolean;
};

export const SectionContainer = ({
  children,
  id,
  title,
  tableOfContentsId,
  overrideDefaultHidden,
}: SectionContainerProps): JSX.Element => {
  const [isHidden, setIsHidden] = useState(overrideDefaultHidden);
  const containerRef = useRef<HTMLDivElement>(null);
  const headerOffset = useHeaderOffset(tableOfContentsId);
  const documentHeaderOffset = useHeaderOffset(DOCUMENT_HEADER_ID);

  const { trackInteraction } = useTelemetry();

  const handleClick = () => {
    setIsHidden(!isHidden);
    trackInteraction('toggle_document_section', {
      action: isHidden ? 'collapse_document_section' : 'expand_document_section',
    });
  };

  return (
    <div className={tw`ccda-section-container whitespace-pre-wrap`} id={id} ref={containerRef}>
      <div
        className={tw`ccda-section-header-wrapper`}
        // eslint-disable-next-line no-restricted-syntax
        style={{
          top: documentHeaderOffset + headerOffset,
        }}
      >
        <Button
          type="button"
          variant="unstyled"
          className={tw`ccda-section-arrow print:hidden`}
          onClick={handleClick}
        >
          <span className={tw`hidden`}>hide</span>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={twx('ml-auto w-4', {
              'rotate-[270deg] transform': isHidden,
            })}
          />
        </Button>
        <Heading level="h4" className={tw`ppercase`}>
          {title}
        </Heading>
      </div>

      {!isHidden && <div className={tw`section-display`}>{children}</div>}
    </div>
  );
};
