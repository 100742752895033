/* eslint-disable jsx-a11y/control-has-associated-label */
import { FunctionComponent, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { ExpandList } from '../pagination/expand-list';
import { ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { VoidValue } from '@ctw/shared/utils/types';
import { isFunction } from 'lodash-es';
import { Pagination } from '@ctw/shared/components/pagination/pagination';
import { SimplePagination } from '@ctw/shared/components/pagination/simple-pagination';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { useContainerQuery } from '@ctw/shared/hooks/breakpoints';

export interface MinRecordItem {
  key: string | number;
}

export type DataIndexSpecified<T> = { dataIndex: keyof T; render?: never };
export type RenderSpecified<T> = {
  dataIndex?: never;
  render: (row: T) => ReactNode;
};

export type TableColumn<T extends MinRecordItem> = {
  title?: string;
  className?: string;
  widthPercent?: number;
  minWidth?: number | string;
  width?: number | string;
} & (DataIndexSpecified<T> | RenderSpecified<T>);

export type RowActionsProps<T extends MinRecordItem> = {
  record: T;
  onSuccess?: () => void;
  stacked?: boolean;
};

export type RowActionsProp<T extends MinRecordItem> = FunctionComponent<RowActionsProps<T>>;

type TablePagination =
  | {
      type: 'show-more';
      pageSize: number;
    }
  | {
      type: 'next-previous';
      hasNext: boolean;
      onPageChange?: (page: number) => void;
    }
  | {
      type: 'pages';
      pageSize: number;
      total: number;
      onPageChange?: (page: number) => void;
    };

export type TableProps<T extends MinRecordItem> = {
  className?: ClassName;
  columns: TableColumn<T>[];
  emptyMessage?: string | ReactElement;
  pagination?: TablePagination | undefined;
  loading?: boolean;
  pageSize?: number;
  records: T[];
  showTableHead?: boolean;
  stacked?: boolean;
} & Pick<
  TableRowsProps<T>,
  'handleRowClick' | 'canClickRow' | 'RowActions' | 'getRowClassName' | 'onRightClick'
>;

export const Table = <T extends MinRecordItem>({
  className,
  columns,
  records,
  loading = false,
  emptyMessage = 'No records found',
  showTableHead = true,
  stacked = false,
  handleRowClick,
  canClickRow,
  onRightClick,
  RowActions,
  getRowClassName,
  pagination,
}: TableProps<T>) => {
  const containerRef = useRef<HTMLTableElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [internalCurrentPage, setInternalInternalCurrentPage] = useState(1);
  const [showLeftShadow, setShowLeftShadow] = useState(false);
  const [showRightShadow, setShowRightShadow] = useState(false);
  const [count, setCount] = useState(
    pagination?.type === 'show-more' || pagination?.type === 'pages' ?
      pagination.pageSize
    : records.length,
  );
  const { breakpoints } = useContainerQuery({ containerRef });
  const setCurrentPage = (page: number) => {
    if (pagination?.type === 'next-previous' || pagination?.type === 'pages') {
      pagination.onPageChange?.(page);
    }
    setInternalInternalCurrentPage(page);
  };

  const updateShadows = () => {
    const container = scrollContainerRef.current;
    const table = containerRef.current;
    if (container && table) {
      setShowLeftShadow(container.scrollLeft > 0);
      const rightSide = container.scrollLeft + container.clientWidth;
      setShowRightShadow(rightSide < table.clientWidth);
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;

    // Update right away.
    updateShadows();

    // Update on scroll or resize events.
    container?.addEventListener('scroll', updateShadows);
    window.addEventListener('resize', updateShadows);

    return () => {
      container?.removeEventListener('scroll', updateShadows);
      window.removeEventListener('resize', updateShadows);
    };
  }, [scrollContainerRef, loading]);

  const hasData = !loading && records.length > 0;

  useEffect(() => {
    if (pagination?.type === 'show-more' && hasData) {
      setCount(Math.min(Math.max(count, pagination.pageSize), records.length));
    } else {
      setCount(records.length);
    }
  }, [records, count, pagination, hasData]);

  return (
    <div
      className={twx('scrollable-pass-through-height space-y-4', {
        'table-stacked': stacked,
      })}
    >
      <div
        className={twx(
          'scrollable-pass-through-height relative overflow-hidden border-x-0 border-y border-r-0 border-solid border-background-subtle',
          className,
          {
            'before:pointer-events-none before:absolute before:left-0 before:top-0 before:z-30 before:block before:h-full before:w-1 before:rotate-180 before:bg-table-shadow before:content-[""]':
              showLeftShadow && !stacked,
            'after:pointer-events-none after:absolute after:right-0 after:top-0 after:z-30 after:block after:h-full after:w-1 after:bg-table-shadow after:content-[""]':
              showRightShadow && !stacked,
          },
        )}
      >
        <div
          className={twx('scrollable-content overflow-x-auto', { 'overflow-x-hidden': stacked })}
          ref={scrollContainerRef}
        >
          <table ref={containerRef} className={tw`w-full table-auto border-collapse`}>
            {hasData && (
              <colgroup className={twx({ hidden: stacked })}>
                {columns.map((column, index) => (
                  <col
                    key={column.title ?? index}
                    className={twx(column.className)}
                    width={column.widthPercent ? `${column.widthPercent}%` : undefined}
                    // eslint-disable-next-line no-restricted-syntax
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                    }}
                  />
                ))}
              </colgroup>
            )}
            {showTableHead && hasData && (
              <thead
                className={twx('border-0 border-b border-solid border-background-subtle', {
                  hidden: stacked,
                })}
              >
                <tr>
                  {columns.map((column, index) => (
                    <th
                      className={tw`group p-3 text-left text-xs font-medium uppercase tracking-wider text-content-subtle`}
                      key={column.title ?? index}
                      scope="col"
                    >
                      {column.title}
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody className={twx({ 'h-[7rem]': records.length === 0 })}>
              <TableRows
                getRowClassName={getRowClassName}
                records={records.slice(0, count)}
                handleRowClick={handleRowClick}
                canClickRow={canClickRow}
                onRightClick={onRightClick}
                RowActions={RowActions}
                columns={columns}
                loading={loading}
                emptyMessage={emptyMessage}
                stacked={stacked || breakpoints.isAtMost.xs}
              />
            </tbody>
          </table>
        </div>
      </div>
      {!loading && pagination?.type === 'show-more' && (
        <ExpandList
          className={tw`items-center justify-between gap-2.5 pl-4 pr-4`}
          total={records.length}
          count={count}
          pageSize={pagination.pageSize}
          changeCount={setCount}
        />
      )}
      {!loading && pagination?.type === 'next-previous' && (
        <SimplePagination
          currentPage={internalCurrentPage}
          setCurrentPage={setCurrentPage}
          hasNext={pagination.hasNext}
        />
      )}
      {!loading && pagination?.type === 'pages' && (
        <Pagination
          currentPage={internalCurrentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pagination.pageSize}
          total={pagination.total}
        />
      )}
    </div>
  );
};

export type RowActionsConfigProp<T extends MinRecordItem> =
  | {
      text: string;
      onClick: (record: T, onSuccess?: () => void) => VoidValue;
      className: ClassName;
      disabled?: boolean;
      testId?: string;
      render?: RowActionsProp<T>;
    }[]
  | undefined;

type TableRowsProps<T extends MinRecordItem> = {
  RowActions?: RowActionsProp<T>;
  columns: TableColumn<T>[];
  emptyMessage: string | ReactElement;
  getRowClassName?: (record: T) => ClassName;
  handleRowClick?: (record: T) => void;
  canClickRow?: (record: T) => boolean;
  onRightClick?: (record: T) => void;
  loading: boolean;
  records: T[];
  stacked: boolean;
};

const TableRows = <T extends MinRecordItem>({
  records,
  columns,
  loading,
  emptyMessage,
  handleRowClick,
  canClickRow = () => true,
  onRightClick,
  RowActions,
  getRowClassName,
  stacked,
}: TableRowsProps<T>) => {
  if (loading) {
    return (
      <tr
        className={twx(
          'flex flex-col items-center p-6 align-top text-background-inverse [&:not(:last-child)]:border-0 [&:not(:last-child)]:border-b [&:not(:last-child)]:border-solid [&:not(:last-child)]:border-background-subtle',
          { 'relative block p-3': stacked },
        )}
      >
        <td
          className={tw`whitespace-pre-wrap px-3 py-4 text-sm text-background-inverse`}
          colSpan={columns.length}
        >
          <LoadingSpinner centered message="Loading..." />
        </td>
      </tr>
    );
  }

  if (records.length === 0) {
    return (
      <tr
        className={twx(
          'flex flex-col items-center p-6 align-top text-background-inverse [&:not(:last-child)]:border-0 [&:not(:last-child)]:border-b [&:not(:last-child)]:border-solid [&:not(:last-child)]:border-background-subtle',
          { 'relative block p-3': stacked },
        )}
      >
        <td
          className={tw`whitespace-pre-wrap px-3 py-4 text-sm text-background-inverse`}
          colSpan={columns.length}
        >
          <span className={tw`empty-message -mt-3.5 sm:mt-0`}>{emptyMessage}</span>
        </td>
      </tr>
    );
  }

  return (
    <>
      {records.map((record) => (
        <tr
          // mx-px fixes bug where side borders disappear on hover when stacked.
          className={twx(
            'group relative mx-px table-row align-top [&:not(:last-child)]:border-0 [&:not(:last-child)]:border-b [&:not(:last-child)]:border-solid [&:not(:last-child)]:border-background-subtle',
            isFunction(getRowClassName) ? getRowClassName(record) : '',
            {
              'cursor-pointer hover:bg-background-hover':
                isFunction(handleRowClick) && canClickRow(record),
              'relative block p-3': stacked,
            },
          )}
          key={record.key}
          onClick={({ metaKey, ctrlKey }) => {
            // Case for cmd/ctrl + click
            if (isFunction(onRightClick) && (metaKey || ctrlKey)) {
              onRightClick(record);
              return;
            }

            if (handleRowClick) {
              handleRowClick(record);
            }
          }}
        >
          {columns.map((column, index) => (
            <td
              key={column.title ?? `unknown-${index}`}
              className={twx(
                'whitespace-pre-wrap px-3 py-4 text-sm text-background-inverse',
                column.className,
                'hyphens-auto break-words',
                {
                  'block w-full p-0 pr-7': stacked,
                },
              )}
            >
              {column.render ?
                column.render(record)
              : (record[column.dataIndex] as unknown as ReactNode)}
            </td>
          ))}
          {RowActions && (
            <td
              aria-label="row-actions"
              className={twx(
                'invisible absolute right-0 top-1 z-20 flex h-fit items-center space-x-2 whitespace-pre-wrap bg-table-actions-gradient px-4 text-sm text-background-inverse group-hover:visible',
                {
                  'mt-0 w-fit justify-end px-4 pr-7': stacked,
                },
              )}
            >
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <div onClick={(event) => event.stopPropagation()}>
                <RowActions record={record} stacked={stacked} />
              </div>
            </td>
          )}
        </tr>
      ))}
    </>
  );
};
