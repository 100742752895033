import { capitalize, compact } from 'lodash-es';
import { useMedicationHistory } from '@ctw/shared/api/fhir/medications';
import { MedicationModel } from '@ctw/shared/api/fhir/models/medication';
import { MedicationAdministrationModel } from '@ctw/shared/api/fhir/models/medication-administration';
import { MedicationDispenseModel } from '@ctw/shared/api/fhir/models/medication-dispense';
import { MedicationRequestModel } from '@ctw/shared/api/fhir/models/medication-request';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models/medication-statement';
import { HistoryEntryProps } from '@ctw/shared/content/resource/helpers/history-entry';
import { UseQueryResult } from '@tanstack/react-query';

export function useMedicationHistoryEntries(medication: MedicationStatementModel) {
  const medHistoryQuery = useMedicationHistory(medication);
  const { medications = [] } = medHistoryQuery.data ?? {};
  // Filter out medication statements from history unless they are the only entries we have to display.
  const filtered = medications.filter((med) => med.resourceType !== 'MedicationStatement');

  return {
    ...medHistoryQuery,
    data: (filtered.length > 0 ? filtered : medications).map(createMedicationDetailsCard),
  } as unknown as UseQueryResult<HistoryEntryProps[]>;
}

function createMedicationDetailsCard(medication: MedicationModel): HistoryEntryProps {
  if (medication.resourceType === 'MedicationStatement') {
    return createMedicationStatementCard(medication);
  }
  if (medication.resourceType === 'MedicationRequest') {
    return createMedicationRequestCard(medication);
  }

  if (medication.resourceType === 'MedicationDispense') {
    return createMedicationDispenseCard(medication);
  }

  if (medication.resourceType === 'MedicationAdministration') {
    return createMedicationAdminCard(medication);
  }

  throw new Error(`Unknown medication resource type "${medication.resourceType}"`);
}

function createMedicationStatementCard(medication: MedicationModel): HistoryEntryProps {
  const resource = medication.resource as fhir4.MedicationStatement;
  const medStatement = new MedicationStatementModel(resource, medication.includedResources);

  return {
    date: medication.dateDisplay,
    id: medication.id,
    title: 'Medication Reviewed',
    hideEmpty: false,
    subtitle: medication.medicationCodeableConceptText ?? medStatement.patient?.organization?.name,
    details: [
      {
        label: 'Status',
        value: capitalize(medStatement.displayStatus),
      },
      {
        label: 'Instructions',
        value: medStatement.dosage,
      },
    ],
  };
}

function createMedicationRequestCard(medication: MedicationModel): HistoryEntryProps {
  const resource = medication.resource as fhir4.MedicationRequest;
  const { prescriber } = medication;
  const { name, address, telecom } = new MedicationRequestModel(
    resource,
    medication.includedResources,
  ).pharmacy;
  const { numberOfRepeatsAllowed = '', initialFill } = resource.dispenseRequest || {};
  const { value = '', unit = '' } = initialFill?.quantity || {};

  return {
    date: medication.dateDisplay,
    id: medication.id,
    title: 'Prescription Ordered',
    subtitle: medication.medicationCodeableConceptText ?? prescriber,
    hideEmpty: false,
    details: [
      { label: 'Quantity', value: [value, unit].join(' ') },
      {
        label: 'Refills Allowed',
        value: numberOfRepeatsAllowed,
      },
      {
        label: 'Instructions',
        value: medication.dosage,
      },
      { label: 'Prescriber', value: prescriber },
      {
        label: 'Pharmacy',
        value: (
          <>
            {name && <div>{name}</div>}
            {address && <div>{address}</div>}
            {telecom && <div>T: {telecom}</div>}
          </>
        ),
      },
    ],
  };
}

function createMedicationDispenseCard(medication: MedicationModel): HistoryEntryProps {
  const resource = medication.resource as fhir4.MedicationDispense;
  const medDispense = new MedicationDispenseModel(resource, medication.includedResources);

  const { quantityDisplay, supplied, performerDetails } = medDispense;
  const { name, address, telecom } = performerDetails;

  return {
    date: medication.dateDisplay,
    hideEmpty: false,
    id: medication.id,
    title: 'Medication Filled',
    subtitle:
      medication.medicationCodeableConceptText ??
      compact([quantityDisplay, supplied ? `${supplied} supplied` : null]).join(', '),
    details: [
      { label: 'Quantity', value: quantityDisplay },
      {
        label: 'Days supply',
        value: supplied,
      },
      {
        label: 'Pharmacy',
        value: (
          <>
            {name && <div>{name}</div>}
            {address && <div>{address}</div>}
            {telecom && <div>T: {telecom}</div>}
          </>
        ),
      },
    ],
  };
}

function createMedicationAdminCard(medication: MedicationModel): HistoryEntryProps {
  const resource = medication.resource as fhir4.MedicationAdministration;
  const medAdmin = new MedicationAdministrationModel(resource, medication.includedResources);

  return {
    id: medication.id,
    date: medication.dateDisplay,
    hideEmpty: false,
    title: 'Medication Administered',
    subtitle:
      medication.medicationCodeableConceptText ??
      compact([medAdmin.dosageDisplay, medAdmin.dosageRoute]).join(', '),
    details: [
      { label: 'Dosage', value: medAdmin.dosageDisplay },
      {
        label: 'Route',
        value: medAdmin.dosageRoute,
      },
      {
        label: 'Start Date',
        value: medAdmin.effectivePeriod.start,
      },
      {
        label: 'End Date',
        value: medAdmin.effectivePeriod.end,
      },
    ],
  };
}
