import './patient-summary-drawer.scss';
import { tw } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';
import { faMagicWandSparkles } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrototypeLabel } from '@ctw/shared/components/prototype-label';

export type PatientSummaryDrawerHeaderProps = {
  onSummaryClick: () => void;
  onFeedbackClick: () => void;
  isFeedbackEnabled: boolean;
};

export const PatientSummaryDrawerHeader = ({
  onSummaryClick,
  onFeedbackClick,
  isFeedbackEnabled,
}: PatientSummaryDrawerHeaderProps) => (
  <div className={tw`ai-summary-drawer-header font-medium`}>
    <div className={tw`flex items-center space-x-1.5`}>
      <span className={tw`inline-block`}>
        <FontAwesomeIcon icon={faMagicWandSparkles} className={tw`h-5 w-5 text-primary-main`} />
      </span>
      <span className={tw`text-lg font-semibold`}>GPS</span>
      <PrototypeLabel deemphasized stage="pilot" />
    </div>
    <div className={tw`space-x-2`}>
      <Button
        type="button"
        variant="unstyled"
        className={tw`ai-summary-drawer-btn`}
        onClick={onSummaryClick}
      >
        Summary
      </Button>
      <Button
        type="button"
        variant="unstyled"
        className={tw`ai-summary-drawer-btn`}
        onClick={onFeedbackClick}
        disabled={isFeedbackEnabled}
      >
        Give Feedback
      </Button>
    </div>
  </div>
);
