import { useEffect, useState } from 'react';
import {
  BulkAddSelectBox,
  isSelectableWritebackStatus,
  WritebackAction,
  WritebackStatuses,
} from './helpers/resource-title-column';
import {
  ResourceOverviewCard,
  ResourceOverviewCardProps,
} from '../overview/resource-overview-card';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { MinRecordItem, RowActionsConfigProp } from '@ctw/shared/components/table/table';
import { useGroupedBy } from '@ctw/shared/content/hooks/use-grouped-by';
import { useAdditionalResourceActions } from '@ctw/shared/content/resource/use-additional-resource-actions';
import { ClassName, tw, twx } from '@ctw/shared/utils/tailwind';

type ResourceTableGroupedProps<T extends MinRecordItem> = Omit<
  ResourceOverviewCardProps<T> & {
    columnHeaders?: {
      className: ClassName;
      title?: string;
      render?: () => React.ReactNode;
    }[];
    enableDismissAndReadActions?: boolean;
    groupBy: (record: T) => string;
    rowActions?: (r: T) => RowActionsConfigProp<T>;
    selectedResources?: T[];
    setSelectedResources?: (resources: T[]) => void;
    writebackStatuses?: WritebackStatuses;
    onWritebackStart?: (action: WritebackAction, id: string) => void;
    onWritebackEnd?: (
      action: WritebackAction,
      id: string,
      isError: boolean,
      errorMessage?: string,
    ) => void;
  },
  'title'
>;

export const ResourceTableGrouped = <T extends fhir4.Resource, M extends FHIRModel<T>>({
  columnHeaders,
  data,
  enableDismissAndReadActions,
  groupBy,
  loading: isLoading,
  rowActions,
  selectedResources,
  setSelectedResources,
  writebackStatuses,
  onWritebackStart,
  onWritebackEnd,
  ...resourceTableProps
}: ResourceTableGroupedProps<M>) => {
  // Group the records using the groupBy function
  const { groupedRecords, sortedKeys } = useGroupedBy(groupBy, data);
  // Create the RowActions react node
  const RowActionsWithAdditions = useAdditionalResourceActions({
    onWritebackStart,
    onWritebackEnd,
    rowActions,
    enableDismissAndReadActions,
  });

  // This is hacky work around for a chrome bug causing
  // https://zeushealth.atlassian.net/browse/2129
  // We briefly toggle "inline-block" on/off when data changes (filters change).
  // This works by forcing the container to be redrawn in the browser.
  const [inlineBlock, setInlineBlock] = useState(false);
  useEffect(() => {
    setInlineBlock(true);
    setTimeout(() => setInlineBlock(false), 0);
  }, [groupedRecords]);

  return (
    <div className={twx('@container', { 'inline-block': inlineBlock })}>
      <div className={tw`space-y-2`}>
        {/* Do not show column names when loading or empty */}
        {!isLoading && (
          <div
            className={tw`@[640px]:flex mb-2 hidden justify-between space-x-1.5 border-b-[1px] border-b-content-icon px-3 text-left text-sm font-medium text-background-inverse`}
          >
            {columnHeaders?.map((header, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} className={twx(header.className)}>
                {header.render?.() || header.title}
              </div>
            ))}
          </div>
        )}
        {sortedKeys.map((title) => {
          const recordsInGroup = groupedRecords[title] ?? [];
          const selectableResources =
            groupedRecords[title]?.filter((r) =>
              isSelectableWritebackStatus(writebackStatuses?.[r.id]),
            ) ?? [];
          const isGroupSelectable = setSelectedResources && selectableResources.length > 0;
          const isGroupSelected =
            !!selectedResources &&
            selectableResources.reduce((acc, r) => acc && selectedResources.includes(r), true);
          return (
            <div key={title} data-testid="resource-table-grouped">
              <ResourceOverviewCard
                className={tw`!min-h-0 !min-w-0 !text-sm`}
                data={recordsInGroup}
                loading={isLoading}
                title={title}
                RowActions={RowActionsWithAdditions}
                headerIcon={
                  isGroupSelectable &&
                  selectedResources && (
                    <BulkAddSelectBox
                      isSelected={isGroupSelected}
                      changeState={() => {
                        if (isGroupSelected) {
                          setSelectedResources(
                            selectedResources.filter((r) => !groupedRecords[title]?.includes(r)),
                          );
                        } else {
                          setSelectedResources([
                            ...selectedResources,
                            ...selectableResources.filter((r) => !selectedResources.includes(r)),
                          ]);
                        }
                      }}
                    />
                  )
                }
                {...resourceTableProps}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
