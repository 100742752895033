import { useResourceDetailsDrawer } from '@ctw/shared/content/resource/resource-details-drawer';
import { DiagnosticReportModel } from '@ctw/shared/api/fhir/models';
import {
  diagnosticReportData,
  DiagnosticReportObservationList,
} from '@ctw/shared/content/diagnostic-reports/helpers/observation-list';

interface UseDiagnosticReportDetailsDrawerProps {
  enableDismissAndReadActions: boolean;
}

export const useDiagnosticReportDetailsDrawer = (
  { enableDismissAndReadActions }: UseDiagnosticReportDetailsDrawerProps = {
    enableDismissAndReadActions: true,
  },
) =>
  useResourceDetailsDrawer({
    header: (model) => model.displayName,
    enableDismissAndReadActions,
    getSourceDocument: true,
    deepLinkResourceType: 'DiagnosticReport',
    details: (model: DiagnosticReportModel) => diagnosticReportData(model),
    RenderChild: DiagnosticReportObservationList,
  });
