import { tw } from '@ctw/shared/utils/tailwind';
import { useState, useEffect } from 'react';

export interface SetTimeProgressBarProps {
  totalTimeSeconds: number;
  stages: Record<number, string>;
}

export const SetTimeProgressBar = ({ totalTimeSeconds, stages }: SetTimeProgressBarProps) => {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('');

  useEffect(() => {
    const startTime = Date.now();
    const duration = totalTimeSeconds * 1000;

    const getCurrentStatus = (currentProgress: number) =>
      Object.entries(stages)
        .reverse()
        .find(([threshold]) => currentProgress >= Number(threshold))?.[1] ?? '';

    const timer = setInterval(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      const newProgress = Math.min((elapsedTime / duration) * 100, 100);

      setProgress(newProgress);
      setStatus(getCurrentStatus(newProgress));

      if (elapsedTime >= duration) {
        clearInterval(timer);
      }
    }, 50);

    return () => clearInterval(timer);
  }, [totalTimeSeconds, stages]);

  return (
    <div className={tw`pl-2 pr-2`}>
      <div className={tw`mt-6 min-w-[400px] max-w-[400px]`}>
        <div className={tw`mb-2 justify-between text-center text-sm font-medium`}>
          <div>{status}</div>
        </div>
        <div className={tw`w-full overflow-hidden rounded-full border`}>
          <div
            style={{ width: `${progress}%` }}
            className={tw`duration-50 h-4 rounded-full bg-primary-main transition-all`}
          />
        </div>
      </div>
    </div>
  );
};

export default SetTimeProgressBar;
