import { useCallback, useState } from 'react';
import { getBinaryDocument } from '@ctw/shared/api/fhir/binaries';
import { base64toBlob } from '@ctw/shared/content/CCDA/base64toBlob';
import { useCTW } from '@ctw/shared/context/ctw-context';

type UseDownloadBinary = {
  downloadBinaryById: (binaryId: string, filename: string) => void;
  isDownloading: boolean;
  error?: string;
};

export function useDownloadBinary(): UseDownloadBinary {
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState<string>();
  const { fetchFromFqs } = useCTW();

  const downloadBinaryById = useCallback(
    (binaryId: string, filename: string) => {
      setIsDownloading(true);
      setError(undefined);

      const fetchAndDownloadBinary = async () => {
        let link: HTMLAnchorElement | null = null;
        try {
          const { binary } = await getBinaryDocument(fetchFromFqs, binaryId);
          // Create a new Blob from the decoded data
          let blob: Blob;
          try {
            // This method seems to work for pdfs but not for xml. So rather than
            // try to pre-determine which content types use which method, lets just
            // attempt to use the base64toBlob we took from FHIRPlace and fallback to
            // using the new Blob constructor directly.
            blob = base64toBlob(binary.data ?? '', binary.contentType);
          } catch (e) {
            blob = new Blob([binary.data ?? ''], { type: binary.contentType });
          }

          // Create a link element, use it to download the file and then remove it
          link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;

          // Append link to the body
          document.body.appendChild(link);

          // Trigger download with click
          link.click();
        } catch (e) {
          setError(e instanceof Error ? e.message : `${e}`);
          // eslint-disable-next-line no-console
          console.error(`Error downloading binary file: ${e}`);
        } finally {
          setIsDownloading(false);
          // Clean up and remove the link
          link?.parentNode?.removeChild(link);
        }
      };

      void fetchAndDownloadBinary();
    },
    [fetchFromFqs],
  );

  return { downloadBinaryById, isDownloading, error };
}
