import { QueryMeta, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { WithTimerMetricOperationFnContext } from '@ctw/shared/context/telemetry';
import { useMemo } from 'react';
import { CTWRequestContext, CTWState, useCTW } from '@ctw/shared/context/ctw-context';
import { QueryKey } from '@tanstack/query-core';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { useErrorBoundary } from '@ctw/shared/components/errors/error-boundary';

export type CustomQueryFnContext = Record<string, unknown>;

export type UseCtwQueryFnContext<CustomContext extends CustomQueryFnContext> = {
  [key in keyof CustomContext &
    keyof CTWRequestContext &
    keyof WithTimerMetricOperationFnContext]: CustomContext[key];
} & {
  requestContext: CTWState['requestContext'];
  graphqlClient: CTWState['graphqlClient'];
  fhirWriteBackClient: CTWState['fhirWriteBackClient'];
  fetchFromFqs: CTWState['fetchFromFqs'];
  ctwFetch: CTWState['ctwFetch'];
  telemetry: ReturnType<typeof useTelemetry>;
} & WithTimerMetricOperationFnContext;

export interface UseCtwQueryOptions<TData, TContext extends CustomQueryFnContext>
  extends Omit<UseQueryOptions<TData>, 'queryFn' | 'queryKey'> {
  cacheAcrossBuilders?: boolean;
  cacheAcrossUsers?: boolean;
  queryId: string;
  queryKey?: QueryKey;
  queryFn: (context: UseCtwQueryFnContext<TContext>) => Promise<TData>;
  queryFnContext?: TContext;
  queryMeta?: QueryMeta;
}

export const useCtwQuery = <TData, TContext extends CustomQueryFnContext = CustomQueryFnContext>(
  options: UseCtwQueryOptions<TData, TContext>,
) => {
  const {
    requestContext,
    graphqlClient,
    fhirWriteBackClient,
    fetchFromFqs,
    ctwFetch,
    isWritebackEnabled,
    featureFlags,
  } = useCTW();
  const telemetry = useTelemetry();
  const errorBoundary = useErrorBoundary();
  const ctwQueryMeta = useMemo(
    () => ({
      ...(errorBoundary ?? {}),
      ...(options.queryMeta ?? {}),
      fhirWritebackClientServiceUrl: fhirWriteBackClient.baseUrl,
      isWritebackEnabled,
      featureFlags,
      requestContext: {
        ...requestContext,
        authToken: '[REDACTED]',
        headers: telemetry.redactSensitiveHttpHeaders(requestContext.headers ?? {}),
      },
    }),
    [
      errorBoundary,
      featureFlags,
      fhirWriteBackClient.baseUrl,
      isWritebackEnabled,
      options.queryMeta,
      requestContext,
      telemetry,
    ],
  );

  const useQueryOptionsWithContextualQueryKeysAndTimedQueryFn: UseQueryOptions<TData> = useMemo(
    () => ({
      ...options,
      meta: ctwQueryMeta,
      /*
       * Use the consumer-provided query key, but also add in components of the request context
       * to ensure that queries never cache across builders or users (unless specifically requested).
       */
      queryKey: [
        options.queryId,
        ...(options.queryKey ?? []),
        options.cacheAcrossBuilders ? undefined : requestContext.builderId,
        options.cacheAcrossUsers ? undefined : requestContext.authTokenState.zusUserId,
      ].filter((key) => key !== null && key !== undefined),
      /* Wrap `queryFn` in a timer using `Telemetry.withTimerMetric`; only pass `options.queryKey`
       * to `withTimerMetric` so that that's what the query identifies itself as, even though its
       * _real_ query key will include components of the request context.
       */
      queryFn: (queryContext) => {
        if (requestContext.isAuthTokenExpired()) {
          const errorMessage = 'Skipping query because auth token is expired';
          const authTokenExpiredError = new Error(errorMessage);
          telemetry.logger.warn(errorMessage, {
            query: {
              queryId: options.queryId,
              queryKey: queryContext.queryKey,
            },
          });
          throw authTokenExpiredError;
        }

        const queryFnWithTimerMetric = telemetry.withTimerMetric(
          'query',
          options.queryId,
          (context) => {
            context.setScopedContextProperty('ctwQueryMeta', ctwQueryMeta);
            return options.queryFn({
              ...(options.queryFnContext ?? {}),
              ...context,
              requestContext,
              graphqlClient,
              fhirWriteBackClient,
              fetchFromFqs,
              ctwFetch,
              telemetry,
            });
          },
        );

        return queryFnWithTimerMetric(queryContext);
      },
    }),
    [
      options,
      ctwQueryMeta,
      requestContext,
      telemetry,
      graphqlClient,
      fhirWriteBackClient,
      fetchFromFqs,
      ctwFetch,
    ],
  );

  return useQuery(useQueryOptionsWithContextualQueryKeysAndTimedQueryFn);
};
