import '@ctw/shared/styles/preflight';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { App } from './App';

const Root = withErrorBoundary({
  boundaryName: 'Smart on FHIR Root',
  includeTelemetryBoundary: true,
  Component: () => (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ),
});

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(container!).render(<Root />);
