import { useContext, useMemo, useState } from 'react';
import { getZusServiceUrl } from '@ctw/shared/api/urls';
import { FeedbackContext } from '@ctw/shared/content/patient-record-search/helpers/feedback-provider';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

type FeedbackForm = {
  feedback: string;
  feedbackType: string;
};

type SubmitFeedback = {
  submit: (data: FeedbackForm) => void;
  isSubmitting: boolean;
  isSubmitted: boolean;
  isError: boolean;
  errorMessage?: string;
};

export const useSubmitFeedback = (name: string): SubmitFeedback => {
  const { id } = useContext(FeedbackContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { requestContext } = useCTW();
  const telemetry = useTelemetry();

  const submit = useMemo(
    () => async (data: FeedbackForm) => {
      setIsSubmitting(true);
      setIsError(false);
      setErrorMessage(undefined);

      try {
        const body = JSON.stringify({
          query_id: id,
          feedback: data.feedback,
          rating: data.feedbackType,
          target: name,
        });
        await fetch(`${getZusServiceUrl(requestContext.env, 'search-poc')}/feedback`, {
          method: 'POST',
          body,
          headers: {
            Authorization: `Bearer ${requestContext.authToken}`,
            'Content-Type': 'application/json',
            'Zus-Account': requestContext.builderId,
          },
        });
        // Form has been submitted to the search service
        setIsSubmitted(true);
      } catch (e) {
        const err = e as Error;
        setErrorMessage(err.message);
        setIsError(true);
      } finally {
        setIsSubmitting(false);
        // We don't care about the analytics event result
        void telemetry.trackInteraction('feedback', {
          value: 'ai_search',
          feedbackType: data.feedbackType,
          target: name,
        });
      }
    },
    [id, name, requestContext.authToken, requestContext.builderId, requestContext.env, telemetry],
  );

  return {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    submit,
    isSubmitting,
    isSubmitted,
    isError,
    errorMessage,
  };
};
