import { patientReferralsColumns } from './helpers/columns';
import { useReferralDetailsDrawer } from './helpers/details';
import { usePatientReferrals } from '@ctw/shared/hooks/use-patient-referrals';
import { twx } from '@ctw/shared/utils/tailwind';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';

export interface PatientReferralsProps {
  className?: string;
}

export const PatientReferrals = withErrorBoundary({
  boundaryName: 'PatientReferrals',
  includeTelemetryBoundary: true,
  Component: ({ className }: PatientReferralsProps) => {
    const { requestContext } = useCTW();
    const referralsQuery = usePatientReferrals();
    const openDetails = useReferralDetailsDrawer();

    return (
      <div
        data-testid="referrals-table"
        className={twx(className, 'scrollable-pass-through-height')}
      >
        <ResourceTable
          showTableHead
          isLoading={referralsQuery.isLoading}
          data={referralsQuery.data ?? []}
          columns={patientReferralsColumns(requestContext.builderId)}
          onRowClick={(model) => openDetails({ prefetchedModel: model })}
          enableDismissAndReadActions
          emptyMessage={
            <EmptyPatientTable hasZeroFilteredRecords={false} resourceName="referrals" />
          }
        />
      </div>
    );
  },
});
