import { Bundle } from 'fhir/r4';
import { SYSTEM_ZUS_THIRD_PARTY } from '@ctw/shared/api/fhir/system-urls';
import { ResourceType, ResourceTypeString } from '@ctw/shared/api/fhir/types';
import { getZusServiceUrl } from '@ctw/shared/api/urls';
import { CTWState } from '@ctw/shared/context/ctw-context';

export interface FQSRequestContext {
  env: string;
  builderId: string;
  authToken: string;
}

async function searchRecords<T extends ResourceTypeString>(
  ctwFetch: CTWState['ctwFetch'],
  context: FQSRequestContext,
  resourceString: T,
  params?: Record<string, string>,
  filterOutThirdParty = true,
): Promise<ResourceType<T>[]> {
  const baseUrl = `${getZusServiceUrl(context.env, 'fqs')}/rest`;
  const urlParams = new URLSearchParams(params);

  const { data } = await ctwFetch(`${baseUrl}/${resourceString}?${urlParams.toString()}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${context.authToken}`,
    },
  });

  const resources = ((data as Bundle).entry ?? []).map((e) => e.resource) as ResourceType<T>[];

  if (!filterOutThirdParty) {
    return resources;
  }

  // Filter out 3rd party resources (TODO: Can remove when fully post-kludge).
  return resources.filter(
    (resource) => !resource.meta?.tag?.some((t) => t.system === SYSTEM_ZUS_THIRD_PARTY),
  );
}

// SearchAllRecords uses the FQS REST API to search for ALL (first party and third party) records of
// a given resource type with the given parameters.
export async function searchAllRecords<T extends ResourceTypeString>(
  ctwFetch: CTWState['ctwFetch'],
  context: FQSRequestContext,
  resourceString: T,
  params?: Record<string, string>,
): Promise<ResourceType<T>[]> {
  return searchRecords(ctwFetch, context, resourceString, params, false);
}

// searchFirstPartyRecords uses the FQS REST API to search for first-party records of
// a given resource type with the given parameters.
export async function searchFirstPartyRecords<T extends ResourceTypeString>(
  ctwFetch: CTWState['ctwFetch'],
  context: FQSRequestContext,
  resourceString: T,
  params?: Record<string, string>,
): Promise<ResourceType<T>[]> {
  return searchRecords(ctwFetch, context, resourceString, params, true);
}

export async function searchBuilderRecords<T extends ResourceTypeString>(
  ctwFetch: CTWState['ctwFetch'],
  context: FQSRequestContext,
  resourceString: T,
  params?: Record<string, string>,
): Promise<ResourceType<T>[]> {
  return searchRecords(ctwFetch, context, resourceString, {
    ...params,
    builderID: context.builderId,
  });
}

export async function fetchResource<T extends ResourceTypeString>(
  ctwFetch: CTWState['ctwFetch'],
  context: FQSRequestContext,
  resourceString: T,
  id: string,
) {
  const baseUrl = `${getZusServiceUrl(context.env, 'fqs')}/rest`;
  const { data } = await ctwFetch(`${baseUrl}/${resourceString}/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${context.authToken}`,
    },
  });

  return data as ResourceType<T>;
}
