import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { CopyToClipboard } from '@ctw/shared/components/copy-to-clipboard';
import { TableColumn } from '@ctw/shared/components/table/table';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '@ctw/shared/content/resource/helpers/resource-title-column';
import { tw } from '@ctw/shared/utils/tailwind';

export const patientMedicationColumns: TableColumn<MedicationStatementModel>[] = [
  {
    title: 'Medication Name',
    render: (medication) => (
      <>
        <div className={tw`font-medium group-hover:underline`}>{medication.display}</div>
        <div className={tw`font-light`}>{medication.dosage}</div>
      </>
    ),
    widthPercent: 35,
    minWidth: 270,
  },
  {
    title: 'Dispensed',
    render: (medication) => (
      <div className={tw`stacked-concat`}>
        {medication.quantity && <div>{medication.quantity}</div>}
        {medication.refills && <div>{medication.refills} refills</div>}
      </div>
    ),
    widthPercent: 18,
    minWidth: 160,
  },
  {
    title: 'Last Filled',
    render: (medication) =>
      medication.lastFillDate && (
        <>
          <span>Last Filled: </span>
          <span className={tw`whitespace-nowrap`}>{medication.lastFillDate}</span>
        </>
      ),
    widthPercent: 18,
  },
  {
    title: 'Last Prescribed',
    render: (medication) => {
      const { lastPrescribedDate, lastPrescriber } = medication;

      if (!lastPrescribedDate && !lastPrescriber) return null;

      return (
        <div className={tw`stacked-concat`}>
          <span>Last Prescribed: </span>
          {lastPrescribedDate && <div>{lastPrescribedDate}</div>}
          {lastPrescriber && <div>{lastPrescriber}</div>}
        </div>
      );
    },
    widthPercent: 18,
    minWidth: 90,
  },
];

export const patientMedicationsAllColumns = (
  builderId: string,
): TableColumn<MedicationStatementModel>[] => [
  {
    title: 'Name',
    widthPercent: 40,
    minWidth: 320,
    render: (med) => (
      <ResourceTitleColumn
        title={<CopyToClipboard>{med.display}</CopyToClipboard>}
        capitalizeTitle={false}
        subTitle={med.dosage}
        renderIcon={RenderSyncedWithRecordIcon(med.ownedByBuilder(builderId))}
      />
    ),
  },
  {
    title: 'Dispensed',
    render: (medication) => (
      <div className={tw`stacked-concat`}>
        {medication.quantity && <div>{medication.quantity}</div>}
        {medication.refills && <div>{medication.refills} refills</div>}
      </div>
    ),
    widthPercent: 18,
    minWidth: 160,
  },
  {
    title: 'Last Filled',
    render: (medication) =>
      medication.lastFillDate && (
        <div>
          <span>Last Filled: </span>
          {medication.lastFillDate}
        </div>
      ),
    widthPercent: 18,
  },
  {
    title: 'Last Prescribed',
    render: (medication) => {
      const { lastPrescribedDate, lastPrescriber } = medication;

      if (!lastPrescribedDate && !lastPrescriber) return null;

      return (
        <div className={tw`stacked-concat`}>
          <span>Last Prescribed: </span>
          {lastPrescribedDate && <div>{lastPrescribedDate}</div>}
          {lastPrescriber && <div>{lastPrescriber}</div>}
        </div>
      );
    },
    widthPercent: 18,
    minWidth: 90,
  },
];
