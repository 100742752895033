/* eslint-disable no-await-in-loop */
import { get } from 'lodash-es';
import { sleep } from '@ctw/shared/utils/sleep';
import { GraphQLClient } from 'graphql-request';

// We'll keep retrying until we've gone over our timeout.
const TIMEOUT_MS = 20_000;

// Due to mutations going to ODS, there is a delay
// before FQS has the latest fresh resource.
// This function will poll FQS until the resource.meta.lastUpdated timestamp
// is greater or equal to the provided lastUpdated timestamp.
export async function longPollFQS(
  graphqlClient: GraphQLClient,
  resourceType: string,
  resourceId: string,
  lastUpdated: string,
): Promise<boolean> {
  const query = `query GetLastUpdated {
      ${resourceType}(id: "${resourceId}") {
        meta {
          lastUpdated
        }
      }
    }`;

  const targetTimestamp = Date.parse(lastUpdated);
  let retryCount = 0;
  let currentTimestamp = 0;
  const startTime = Date.now();
  do {
    // Exponential backoff how long we wait between retries.
    await sleep(100 * 2 ** retryCount);
    retryCount += 1;
    // Refetch the resource from FQS.
    try {
      const data = await graphqlClient.request(query);
      currentTimestamp = Date.parse(String(get(data, `${resourceType}.meta.lastUpdated`)));
    } catch {
      // Ignore errors and retry.
      // This can happen if we just created a new resource that isn't in FQS yet.
    }
  } while (
    (Number.isNaN(currentTimestamp) || currentTimestamp < targetTimestamp) &&
    Date.now() - startTime < TIMEOUT_MS
  );

  return currentTimestamp >= targetTimestamp;
}
