import { tw } from '@ctw/shared/utils/tailwind';

export type ErrorMessageProps = {
  message: string;
};

const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <div className={tw`flex h-full items-center justify-center`}>
    <div className={tw`text-xl`}>Error: {message}</div>
  </div>
);

export default ErrorMessage;
