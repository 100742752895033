import { faPills } from '@fortawesome/pro-solid-svg-icons';
import { useContext } from 'react';
import { useMedicationDetailsDrawer } from './helpers/details';
import { defaultMedicationSortOverview } from './helpers/sorts';
import { useMedicationRowActions } from './patient-medications-all';
import { ResourceOverviewCard } from '../overview/resource-overview-card';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '../resource/helpers/resource-title-column';
import { getDateRangeView } from '../resource/helpers/view-date-range';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { ZapPropsStateContext } from '@ctw/shared/context/zap-props-state-context';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { useQueryAllPatientMedications } from '@ctw/shared/hooks/use-medications';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { withOverviewCardErrorBoundary } from '@ctw/shared/content/overview/with-overview-card-error-boundary';

const TITLE = 'Medications';

export interface PatientMedicationOverviewProps {
  onSeeAllResources: () => void;
}

export const PatientMedicationOverview = withOverviewCardErrorBoundary({
  cardTitle: TITLE,
  boundaryName: 'PatientMedicationOverview',
  Component: ({ onSeeAllResources }: PatientMedicationOverviewProps) => {
    const { requestContext, isWritebackEnabled } = useCTW();
    const query = useQueryAllPatientMedications();
    const { props } = useContext(ZapPropsStateContext);
    const rowActions = useMedicationRowActions((record) => {
      if (props['medications-all']?.onAddToRecord) {
        void props['medications-all'].onAddToRecord(record);
      }
    });

    const openDetails = useMedicationDetailsDrawer({
      rowActions: isWritebackEnabled('medications-all') ? rowActions : undefined,
      enableDismissAndReadActions: false,
    });

    const { past6Months } = getDateRangeView<MedicationStatementModel>('lastActivityDate', false);

    const { data } = useFilteredSortedData({
      defaultSort: defaultMedicationSortOverview, // Alphabetical
      viewOptions: [past6Months],
      defaultView: past6Months.display,
      records: query.allMedications,
    });

    return (
      <ResourceOverviewCard
        onRowClick={(model) => openDetails({ prefetchedModel: model })}
        data={data}
        emptyStateMessage={
          query.allMedications.length === 0 ?
            "We didn't find any medication records for this patient."
          : 'There are no medications with prescriptions or fills within the past 6 months for this patient.'
        }
        footerCTA={{
          label: 'All Medications',
          onClick: onSeeAllResources,
        }}
        title={TITLE}
        helpText="Medications with prescriptions or fills within the last 6 months"
        loading={query.isLoading}
        headerIcon={faPills}
        telemetryTargetName="medications_overview"
        testId="medications-overview"
        renderResource={(record: MedicationStatementModel) => (
          <ResourceTitleColumn
            title={record.display}
            renderIcon={RenderSyncedWithRecordIcon(record.ownedByBuilder(requestContext.builderId))}
          />
        )}
      />
    );
  },
});
