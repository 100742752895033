import { compact, capitalize } from 'lodash-es';
import { ReferralModel } from '@ctw/shared/api/fhir/models/referral';
import { Badge } from '@ctw/shared/components/badge';
import { DetailEntry } from '@ctw/shared/content/resource/helpers/details-card';
import { useResourceDetailsDrawer } from '@ctw/shared/content/resource/resource-details-drawer';
import { tw } from '@ctw/shared/utils/tailwind';
import { Heading } from '@ctw/shared/components/heading';
import { useBinaries } from '@ctw/shared/api/fqs-rest/binaries';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { ViewCCDAButton } from '@ctw/shared/content/document/view-ccda-button';

interface RelatedDocumentsProps {
  model: ReferralModel;
}

const RelatedDocuments = ({ model }: RelatedDocumentsProps) => {
  const binariesQuery = useBinaries(
    compact(model.encounter?.docsAndNotes.map((doc) => doc.binaryId)),
  );

  if (binariesQuery.isLoading) {
    return (
      <div className={tw`flex w-full justify-center`}>
        <LoadingSpinner className={tw`text-content-icon`} message="Loading related documents..." />
      </div>
    );
  }

  if (binariesQuery.data === undefined || binariesQuery.data.length === 0) {
    return <></>;
  }

  return (
    <div className={tw`space-y-4`}>
      <Heading level="h3">Documents</Heading>
      {binariesQuery.data.map((binary) => (
        <ViewCCDAButton
          ccdaTitle={
            model.encounter?.docsAndNotes.find((doc) => doc.binaryId === binary.id)?.title ||
            'Related Encounter Document'
          }
          key={binary.id}
          binary={binary}
          patient={model.patient}
          preferred={false}
          overrideDateDisplay={model.completedAtDisplay}
        />
      ))}
    </div>
  );
};

export const useReferralDetailsDrawer = () =>
  useResourceDetailsDrawer({
    deepLinkResourceType: 'ServiceRequest',
    header: (referral) => referral.title,
    subHeader: (referral) => referral.referredPractitionerName ?? referral.referredPractitionerName,
    details: (referral: ReferralModel) =>
      compact<DetailEntry>([
        {
          label: 'Status',
          value: (
            <Badge
              variant={referral.status === 'fulfilled' ? 'muted' : 'primary'}
              content={capitalize(referral.status)}
            />
          ),
        },
        { label: 'Reason', value: referral.reason },
        {
          beginsNewSection: true,
          label: 'Referred On',
          value: referral.referredAtDisplay,
        },
        {
          label: 'Referred By',
          value: (
            <div className={tw`leading-tight`}>
              {referral.referringPractitionerName ?? ''}
              {referral.referringPracticeName && (
                <>
                  {referral.referringPractitionerName && <br />}
                  {referral.referringPracticeName}
                </>
              )}
            </div>
          ),
        },
        ...referral.referredPractitioners.map((referredPractitioner) => ({
          label: 'Referred To',
          value: (
            <div className={tw`leading-tight`}>
              {referredPractitioner.name ?? ''}
              {referredPractitioner.practiceName && (
                <>
                  {referredPractitioner.name && <br />}
                  {referredPractitioner.practiceName}
                </>
              )}
            </div>
          ),
        })),
        ['scheduled', 'fulfilled'].includes(referral.status) && {
          beginsNewSection: true,
          label: 'Scheduled On',
          value: referral.scheduledAtDisplay,
        },
        ['scheduled', 'fulfilled'].includes(referral.status) && {
          label: 'Scheduled By',
          value: (
            <div className={tw`leading-tight`}>{referral.referredPracticeName ?? 'Unknown'}</div>
          ),
        },
        ['fulfilled'].includes(referral.status) && {
          beginsNewSection: true,
          label: 'Completed On',
          value: referral.completedAtDisplay,
        },
        ['fulfilled'].includes(referral.status) && {
          label: 'Completed By',
          value: referral.referredPractitionerName ?? referral.referredPracticeName,
        },
      ]),
    RenderChild: ({ model }) => <RelatedDocuments model={model} />,
    getSourceDocument: false,
    enableDismissAndReadActions: false,
  });
