import { FlagProvider, UnleashClient } from '@unleash/proxy-client-react';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { Env } from './types';
import { LoadingSpinner } from '../components/loading-spinner';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useQueryClient } from '@tanstack/react-query';

type FeatureFlags = Record<string, boolean>;

interface UnleashEnvironmentConfig {
  url: string;
  clientKey: string;
}

/*
 * Map our env to the unleash environment, defaulting to "development".
 * NOTE: these keys are hardcoded in https://github.com/zeus-health/aws-base/blame/8eb18e694f5c94b70f15559254ce10027e74f4b4/unleash/scripts/create_secrets.sh#L33-L39
 * so they could change without notice. INFRA-729 will create these in AWS secrets for all
 * accounts but for now it is safe to store these in GitHub
 */
export const getUnleashEnvironmentConfig = (env: Env): UnleashEnvironmentConfig => {
  switch (env) {
    case 'prod':
    case 'production':
      return {
        url: 'https://unleash-proxy-prod.zusapi.com/proxy',
        clientKey: 'MDE0NDU5NTQtNEIyNC00RUVGLUI4NDUtRTE3QjYyMUQ3NTAzCg==',
      };
    case 'phitest':
    case 'sandbox':
      return {
        url: 'https://unleash-proxy.zusapi.com/proxy',
        clientKey: 'Q0QwNUIxODgtQkFEMC00MTA2LUIwRDEtRDgwQ0FFRDBBMzBCCg==',
      };
    case 'dev':
    case 'development':
    default:
      return {
        url: 'https://unleash-proxy-dev.zusapi.com/proxy',
        clientKey: 'NTk3QkM3RTItMTU4Qi00NDYwLTlFQjQtNjMyQ0ZFMENBNkY1Cg==',
      };
  }
};

export const FeatureFlagContext = createContext<FeatureFlags | undefined>(undefined);

interface FeatureFlagProviderProps extends PropsWithChildren {
  env: Env;
}

export function FeatureFlagProvider({ env, children }: FeatureFlagProviderProps) {
  const { requestContext } = useCTW();
  const [unleashClientStarted, setUnleashClientStarted] = useState<boolean>();
  const [unleashClientReady, setUnleashClientReady] = useState(false);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>();
  const queryClient = useQueryClient();
  const unleashClient = useMemo(
    () =>
      new UnleashClient({
        ...getUnleashEnvironmentConfig(env),
        refreshInterval: 15, // 15 seconds
        appName: 'component-library',
        context: {
          userId: requestContext.authTokenState.zusUserId,
          properties: {
            builderId: requestContext.authTokenState.builderId,
            builderName: requestContext.authTokenState.builderName,
            userType: requestContext.authTokenState.userType,
            email: requestContext.authTokenState.email,
            /* For some reason unleash proxy does not like localhost appearing
             * in the query params and will return 403's.
             * As a work around, we replace localhost with local.
             */
            hostname: window.location.hostname.replace('localhost', 'local'),
          },
        },
      }),
    [
      env,
      requestContext.authTokenState.builderId,
      requestContext.authTokenState.builderName,
      requestContext.authTokenState.email,
      requestContext.authTokenState.userType,
      requestContext.authTokenState.zusUserId,
    ],
  );

  useEffect(() => {
    if (!unleashClientStarted) {
      const startUnleashClient = async function run() {
        unleashClient.on('ready', () => {
          setUnleashClientReady(true);
          setFeatureFlags(
            Object.fromEntries(
              unleashClient.getAllToggles().map((toggle) => [toggle.name, toggle.enabled]),
            ),
          );
        });
        setUnleashClientStarted(true);
        await unleashClient.start();
      };

      void startUnleashClient();
    }
  }, [
    requestContext.authToken,
    unleashClient,
    unleashClientStarted,
    unleashClientReady,
    featureFlags,
    requestContext.authTokenState.zusUserId,
    requestContext.authTokenState.builderId,
    requestContext.authTokenState.builderName,
    requestContext.authTokenState.userType,
    requestContext.authTokenState.email,
  ]);

  useEffect(() => {
    void queryClient.invalidateQueries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags]);

  if (featureFlags === undefined) {
    return <LoadingSpinner centered message="Loading..." />;
  }

  return (
    <FlagProvider unleashClient={unleashClient} startClient={false}>
      <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>
    </FlagProvider>
  );
}

export function useFeatureFlag(name: string): boolean {
  const featureFlags = useContext(FeatureFlagContext);

  if (featureFlags === undefined) {
    throw new Error('useFeatureFlag must be used within a FeatureFlagProvider');
  }

  return featureFlags[name] ?? false;
}
