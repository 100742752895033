import { mostRecentGaps, usePatientCareGaps } from '@ctw/shared/api/fhir/care-gaps';
import { CareGapSummary } from '@ctw/shared/content/care-gaps/helpers/summary';
import { faHandHoldingMedical } from '@fortawesome/pro-solid-svg-icons';
import { withOverviewCardErrorBoundary } from '@ctw/shared/content/overview/with-overview-card-error-boundary';
import { ResourceOverviewCard } from '@ctw/shared/content/overview/resource-overview-card';
import { useCareGapDetailsDrawer } from '@ctw/shared/content/care-gaps/helpers/details';

interface CareGapsOverviewCardProps {
  onSeeAllResources: () => void;
}

export const CareGapsOverviewCard = withOverviewCardErrorBoundary({
  cardTitle: 'Care Gaps',
  boundaryName: 'CareGapsOverviewCard',
  Component: ({ onSeeAllResources }: CareGapsOverviewCardProps) => {
    const { data: allGaps, isLoading } = usePatientCareGaps();
    const openCareGapDetails = useCareGapDetailsDrawer();

    const uniqueGaps = mostRecentGaps(allGaps || []);

    return (
      <ResourceOverviewCard
        headerIcon={faHandHoldingMedical}
        title="Care Gaps"
        data={uniqueGaps}
        emptyStateMessage="This patient has no currently open gaps in care."
        loading={isLoading}
        helpText="Recently updated gaps in care."
        telemetryTargetName="caregaps_overview"
        testId="care-gaps-card"
        onRowClick={(gap) => openCareGapDetails({ prefetchedModel: gap })}
        footerCTA={{ label: 'All Care Gaps', onClick: onSeeAllResources }}
        renderResource={(gap) => <CareGapSummary gap={gap} />}
      />
    );
  },
});
