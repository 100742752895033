import { faCalendar, faFileLines, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReferralModel } from '@ctw/shared/api/fhir/models/referral';
import { ResourceOverviewCardGrouped } from '@ctw/shared/content/overview/resource-overview-card-grouped';
import { useReferralDetailsDrawer } from '@ctw/shared/content/patient-referrals/helpers/details';
import { usePatientReferrals } from '@ctw/shared/hooks/use-patient-referrals';
import { tw } from '@ctw/shared/utils/tailwind';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { withOverviewCardErrorBoundary } from '@ctw/shared/content/overview/with-overview-card-error-boundary';

const TITLE = 'Referrals';

const RelatedDocumentsSummary = ({ referral }: { referral: ReferralModel }) => {
  if (referral.getIsLoadingDocuments()) {
    return (
      <div className={tw`flex items-center gap-1`}>
        <LoadingSpinner className={tw`h-4 w-4`} />;
      </div>
    );
  }

  const relatedDocuments = referral.encounter?.docsAndNotes || [];

  if (relatedDocuments.length === 0 && referral.status === 'fulfilled') {
    return (
      <div className={tw`flex items-center gap-1`}>
        <FontAwesomeIcon className={tw`w-4 text-content-icon`} icon={faFileLines} />
        <div className={tw`text-content-icon`}>No documents available</div>
      </div>
    );
  }

  if (relatedDocuments.length > 0) {
    return (
      <div className={tw`ctems-center flex gap-1`}>
        <FontAwesomeIcon className={tw`w-4 text-content-icon`} icon={faFileLines} />
        <div>Documents available</div>
      </div>
    );
  }

  return <></>;
};

interface PatientReferralsOverviewProps {
  onSeeAllResources: () => void;
}

export const PatientReferralsOverview = withOverviewCardErrorBoundary({
  cardTitle: TITLE,
  boundaryName: 'PatientReferralsOverview',
  Component: ({ onSeeAllResources }: PatientReferralsOverviewProps) => {
    const referralsQuery = usePatientReferrals();
    const openDetails = useReferralDetailsDrawer();

    return (
      <ResourceOverviewCardGrouped
        groupBy={(referral) => referral.status}
        groupByKeyOrder={['open', 'closed']}
        footerCTA={{
          label: 'All Referrals',
          onClick: onSeeAllResources,
        }}
        onRowClick={(model) => openDetails({ prefetchedModel: model })}
        data={referralsQuery.data ?? []}
        emptyStateMessage="We didn't find any referrals for this patient."
        title={TITLE}
        helpText="Referrals for the patient"
        loading={referralsQuery.isLoading}
        headerIcon={faUsers}
        telemetryTargetName="referrals_overview"
        testId="referrals-overview"
        renderResource={(referral: ReferralModel) => (
          <div className={tw`border-t px-3 pt-2`}>
            <div className={tw`font-medium`}>{referral.title}</div>
            <div>{referral.referredPracticeName}</div>
            <hr className={tw`my-1`} />
            <div className={tw`flex flex-col gap-1.5`}>
              <div className={tw`flex gap-1`}>
                <FontAwesomeIcon className={tw`w-4 text-content-icon`} icon={faUser} />
                <div>
                  Ordered {referral.referredAtDisplay} by{' '}
                  {referral.referringPractitionerName || 'Unknown'}
                </div>
              </div>
              {['scheduled', 'pending-acknowledgement', 'acknowledged'].includes(
                referral.status,
              ) && (
                <div className={tw`flex gap-1`}>
                  <FontAwesomeIcon className={tw`w-4 text-content-icon`} icon={faCalendar} />
                  <div>
                    Scheduled {referral.scheduledAtDisplay} by{' '}
                    {referral.referredPracticeName || 'Unknown'}
                  </div>
                </div>
              )}
              {['pending-acknowledgement', 'acknowledged'].includes(referral.status) && (
                <div className={tw`flex gap-1`}>
                  <FontAwesomeIcon className={tw`w-4 text-content-icon`} icon={faCalendar} />
                  <div>
                    Completed {referral.completedAtDisplay} by{' '}
                    {referral.referredPracticeName || 'Unknown'}
                  </div>
                </div>
              )}
              <RelatedDocumentsSummary referral={referral} />
            </div>
          </div>
        )}
      />
    );
  },
});
