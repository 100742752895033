'use client';

import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import { useEffect, useState } from 'react';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useInfiniteQuery } from '@tanstack/react-query';
import { get } from 'lodash-es';
import {
  ALL_PAGES,
  fqsRequest,
  GenericConnection,
  MAX_OBJECTS_PER_REQUEST,
} from '@ctw/shared/api/fqs/client';
import { QueryKey } from '@tanstack/query-core';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export interface UseIncrementallyPaginatedFqsQueryForPatientOptions<
  M extends FHIRModel<F>,
  F extends fhir4.Resource,
> {
  gcTime?: number;
  staleTime?: number;
  queryId: string;
  queryKey?: QueryKey;
  namespace: string;
  graphqlQuery: string;
  pageLimit?: number;
  perPageLimit?: number;
  ModelClass: new (resource: F) => M;
}

export interface UseIncrementallyPaginatedQueryForPatientResult<
  M extends FHIRModel<F>,
  F extends fhir4.Resource,
> {
  data: M[];
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
}

export const useIncrementallyPaginatedFqsQueryForPatient = <
  M extends FHIRModel<F>,
  F extends fhir4.Resource,
>({
  gcTime,
  staleTime,
  queryId,
  queryKey,
  namespace,
  graphqlQuery,
  pageLimit = ALL_PAGES,
  perPageLimit = MAX_OBJECTS_PER_REQUEST,
  ModelClass,
}: UseIncrementallyPaginatedFqsQueryForPatientOptions<
  M,
  F
>): UseIncrementallyPaginatedQueryForPatientResult<M, F> => {
  const { graphqlClient } = useCTW();
  const { patient } = usePatientContext();
  const telemetry = useTelemetry();
  const [pagesFetched, setPagesFetched] = useState<string[]>([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [willFetchMore, setWillFetchMore] = useState(true);
  const [flattenedData, setFlattenedData] = useState<M[]>([]);

  const { isError, data, fetchNextPage } = useInfiniteQuery<{
    data: GenericConnection<never>;
  }>({
    gcTime,
    staleTime,
    enabled: willFetchMore,
    queryKey: [queryId, ...(queryKey ?? []), patient.UPID],
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      const endCursor = get(lastPage, ['data', namespace, 'pageInfo', 'endCursor'], '');
      return hasNextPage ? endCursor : undefined;
    },
    queryFn: async (context) => {
      const queryWithTimer = telemetry.withTimerMetric('query', queryId, async () => {
        const response = await fqsRequest<GenericConnection<never>>(
          telemetry,
          graphqlClient,
          graphqlQuery,
          {
            upid: patient.UPID,
            cursor: context.pageParam,
            first: perPageLimit,
            sort: {},
          },
        );

        setPagesFetched([...pagesFetched, context.pageParam as string]);
        setHasNextPage(get(response, ['data', namespace, 'pageInfo', 'hasNextPage'], false));

        return response;
      });

      return queryWithTimer(context);
    },
    maxPages: pageLimit,
  });

  useEffect(() => {
    if (willFetchMore) {
      if (pagesFetched.length === 0) {
        telemetry.startTimerMetric('query', queryId);
      }

      if (!hasNextPage || pagesFetched.length >= pageLimit) {
        telemetry.stopTimerMetric('query', queryId);
        setWillFetchMore(false);
      } else {
        void fetchNextPage();
      }

      setFlattenedData(
        data?.pages
          .map((page) =>
            get(page, ['data', namespace, 'edges'], []).map(
              (edge: Record<string, unknown>) => new ModelClass(edge.node as F),
            ),
          )
          .flat(1) ?? [],
      );
    }
  }, [
    ModelClass,
    data,
    fetchNextPage,
    hasNextPage,
    namespace,
    pageLimit,
    pagesFetched.length,
    queryId,
    telemetry,
    willFetchMore,
  ]);

  return {
    isLoading: willFetchMore,
    isFetching: willFetchMore,
    isError,
    data: flattenedData,
  };
};
