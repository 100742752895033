import { usePatientContextIfAvailable } from '../context/patient-provider';
import { twx } from '@ctw/shared/utils/tailwind';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { Link } from '@ctw/shared/components/link';

const SUPPORT_FORM_URL = 'https://share.hsforms.com/10NmAPiu7Qd2OXbxAHnseiwcjai8';

interface SupportFormLinkProps {
  buttonText: string;
  className?: string;
}

export const SupportFormLink = ({
  buttonText,
  className = 'link text-sm',
}: SupportFormLinkProps) => {
  const patientContext = usePatientContextIfAvailable();
  const { requestContext } = useCTW();

  // These property names exactly match the internal names of the HubSpot form fields
  const params = new URLSearchParams();
  if (patientContext?.patient.id && patientContext.patient.UPID) {
    params.append('patientid', patientContext.patient.id);
    params.append('upid', patientContext.patient.UPID);
  }

  params.append('builderid', requestContext.builderId);
  params.append('buildername', requestContext.authTokenState.builderName);
  params.append('email', requestContext.authTokenState.email);
  params.append('userid', requestContext.authTokenState.zusUserId);

  return (
    <Link
      href={`${SUPPORT_FORM_URL}?${params.toString()}`}
      className={twx(
        'focus:outline-4 focus:outline-offset-[3px] focus:outline-primary-main focus-visible:outline-4 focus-visible:outline-offset-[3px] focus-visible:outline-primary-main',
        className,
      )}
      target="_blank"
    >
      {buttonText}
    </Link>
  );
};
