import { useEffect, useState } from 'react';
import {
  InformationSource,
  dedupeMedicationsByActiveIngredient,
  splitMedications,
} from '@ctw/shared/api/fhir/medications';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models/medication-statement';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import {
  QUERY_KEY_PATIENT_BUILDER_MEDICATIONS,
  QUERY_KEY_PATIENT_SUMMARY_MEDICATIONS,
} from '@ctw/shared/utils/query-keys';
import { fqsRequest } from '@ctw/shared/api/fqs/client';
import {
  MedicationStatementGraphqlResponse,
  medicationStatementQuery,
} from '@ctw/shared/api/fqs/queries/medication-statements';
import {
  SYSTEM_SUMMARY,
  SYSTEM_ZUS_OWNER,
  SYSTEM_ZUS_SUMMARY,
  SYSTEM_ZUS_THIRD_PARTY,
} from '@ctw/shared/api/fhir/system-urls';
import { filterResourcesByBuilderId } from '@ctw/shared/api/common';
import { getLensBuilderId } from '@ctw/shared/api/urls';

type MedicationFilter = {
  status?: fhir4.MedicationStatement['status'];
  informationSource?: InformationSource;
  informationSourceNot?: InformationSource;
};

function applySearchFiltersToFQSResponse(
  medicationStatements: MedicationStatementModel[],
  searchFilters: MedicationFilter = {},
  removeMedsWithNoRxNorm = false,
) {
  let medications = medicationStatements;
  if (removeMedsWithNoRxNorm) {
    medications = medications.filter((medication) => medication.rxNorm !== undefined);
  }

  if (searchFilters.informationSource) {
    medications = medications.filter(
      (medication) => medication.informationSource?.type === searchFilters.informationSource,
    );
  }

  if (searchFilters.informationSourceNot) {
    medications = medications.filter(
      (medication) => medication.informationSource?.type !== searchFilters.informationSourceNot,
    );
  }

  return medications;
}

// Gets patient medications for the builder, excluding meds where the information source is patient.
export const usePatientBuilderMedications = (enabled = true) =>
  usePatientQuery({
    queryId: QUERY_KEY_PATIENT_BUILDER_MEDICATIONS,
    queryFn: async ({ requestContext, telemetry, graphqlClient, patient }) => {
      const searchFilters: MedicationFilter = {
        informationSourceNot: 'Patient', // exclude medication statements where the patient is the information source
      };
      const { data } = await fqsRequest<MedicationStatementGraphqlResponse>(
        telemetry,
        graphqlClient,
        medicationStatementQuery,
        {
          upid: patient.UPID,
          cursor: '',
          first: 1000,
          sort: {
            lastUpdated: 'DESC',
          },
          filter: {
            tag: {
              nonematch: [SYSTEM_SUMMARY, `${SYSTEM_ZUS_THIRD_PARTY}`],
              // TODO: There's a bug in FQS that doesn't allow filtering with nonematch AND allmatch.
              // Uncomment the line below once https://zeushealth.atlassian.net/browse/DRT-249 is resolved.
              // allmatch: [`${SYSTEM_ZUS_OWNER}|builder/${requestContext.builderId}`],
            },
          },
        },
      );
      let nodes = data.MedicationStatementConnection.edges.map((x) => x.node);
      nodes = filterResourcesByBuilderId(nodes, requestContext.builderId);
      const medStatements = nodes.map(
        (n) => new MedicationStatementModel(n, undefined, n.BasicList),
      );
      return applySearchFiltersToFQSResponse(medStatements, searchFilters, false);
    },
    enabled,
  });

export function usePatientSummaryMedications(enabled = true) {
  return usePatientQuery({
    queryId: QUERY_KEY_PATIENT_SUMMARY_MEDICATIONS,
    queryFn: async ({ requestContext, telemetry, graphqlClient, patient }) => {
      const { data } = await fqsRequest<MedicationStatementGraphqlResponse>(
        telemetry,
        graphqlClient,
        medicationStatementQuery,
        {
          upid: patient.UPID,
          cursor: '',
          first: 1000,
          sort: {
            lastUpdated: 'DESC',
          },
          filter: {
            tag: {
              allmatch: [
                `${SYSTEM_ZUS_SUMMARY}|Common`,
                `${SYSTEM_ZUS_OWNER}|builder/${getLensBuilderId(requestContext.env)}`,
              ],
            },
          },
        },
      );
      const nodes = data.MedicationStatementConnection.edges.map((x) => x.node);
      return nodes.map((n) => new MedicationStatementModel(n, undefined, n.BasicList));
    },
    enabled,
  });
}

/**
 * This hook provides all patient medication statements reconciled into two
 * categories ("Builder Medications" and "Other Provider Medications"). This is
 * useful when creating content such as the <PatientMedications /> component.
 */
export function useQueryAllPatientMedications(enabled = true) {
  const [customizedFirstPartyLenses, setCustomizedFirstPartyLenses] = useState<
    MedicationStatementModel[]
  >([]);
  const [otherProviderLenses, setOtherProviderLenses] = useState<MedicationStatementModel[]>([]);
  const [allMedications, setAllMedications] = useState<MedicationStatementModel[]>([]);

  const summaryMedicationsQuery = usePatientSummaryMedications(enabled);
  const builderMedicationsQuery = usePatientBuilderMedications(enabled);

  useEffect(() => {
    const builderMedications = builderMedicationsQuery.data ?? [];
    let summaryMedications = (summaryMedicationsQuery.data ?? []) as MedicationStatementModel[];

    // Filter out summary medications that are only aggregattedFrom MedicationAdministration
    summaryMedications = summaryMedications.filter((medication) =>
      medication.aggregatedFrom.some((agg) => agg.type !== 'MedicationAdministration'),
    );

    // Split the summarized medications into those known/unknown to the builder
    const splitData = splitMedications(summaryMedications, builderMedications);
    setCustomizedFirstPartyLenses(splitData.customizedFirstPartyLenses);
    setOtherProviderLenses(splitData.otherProviderMedications);
    setAllMedications(
      dedupeMedicationsByActiveIngredient([
        ...splitData.customizedFirstPartyLenses,
        ...splitData.otherProviderMedications,
      ]),
    );
  }, [builderMedicationsQuery.data, summaryMedicationsQuery.data]);

  const isLoading = builderMedicationsQuery.isLoading || summaryMedicationsQuery.isLoading;
  const isError = builderMedicationsQuery.isError || summaryMedicationsQuery.isError;
  const isFetching = builderMedicationsQuery.isFetching || summaryMedicationsQuery.isFetching;

  return {
    isLoading,
    isError,
    isFetching,
    builderMedications: customizedFirstPartyLenses,
    otherProviderMedications: otherProviderLenses,
    allMedications,
  };
}
