import { parseISO } from 'date-fns';
import { orderBy } from 'lodash-es';
import { usePatientEncounters } from './use-encounters';
import { fetchAppointments } from '@ctw/shared/api/fhir/appointments';
import { ReferralModel } from '@ctw/shared/api/fhir/models/referral';
import { fetchServiceRequests } from '@ctw/shared/api/fhir/service-requests';
import { usePatientContext, usePatientQuery } from '@ctw/shared/context/patient-provider';
import {
  QUERY_KEY_PATIENT_APPOINTMENTS,
  QUERY_KEY_PATIENT_SERVICE_REQUESTS,
} from '@ctw/shared/utils/query-keys';
import { UseQueryResult } from '@tanstack/react-query';
import { usePatientDocuments } from '@ctw/shared/api/fhir/document';

const isSameDay = (dateA: Date, dateB: Date): boolean =>
  dateA.getDate() === dateB.getDate() &&
  dateA.getMonth() === dateB.getMonth() &&
  dateA.getFullYear() === dateB.getFullYear();

export const findRelatedAppointment = (
  serviceRequest: fhir4.ServiceRequest,
  appointments: fhir4.Appointment[],
): fhir4.Appointment | undefined =>
  appointments.find((appointment) =>
    appointment.basedOn?.some(
      (basedOn) => basedOn.reference === `ServiceRequest/${serviceRequest.id}`,
    ),
  );

export const findRelatedEncounter = (
  appointment: fhir4.Appointment,
  encounters: fhir4.Encounter[],
): fhir4.Encounter | undefined =>
  encounters.find(
    (encounter) =>
      appointment.start &&
      encounter.period?.start &&
      isSameDay(
        parseISO(encounter.period.start.split('T')[0]),
        parseISO(appointment.start.split('T')[0]),
      ),
  );

export const usePatientReferrals = (): UseQueryResult<ReferralModel[]> => {
  const { patient } = usePatientContext();
  const serviceRequestsQuery = usePatientQuery({
    queryId: QUERY_KEY_PATIENT_SERVICE_REQUESTS,
    queryFn: async ({ graphqlClient, telemetry }) =>
      fetchServiceRequests(telemetry, graphqlClient, patient),
  });
  const appointmentsQuery = usePatientQuery({
    queryId: QUERY_KEY_PATIENT_APPOINTMENTS,
    queryFn: async ({ graphqlClient, telemetry }) =>
      fetchAppointments(telemetry, graphqlClient, patient),
  });
  const encountersQuery = usePatientEncounters();
  const documentsQuery = usePatientDocuments();

  if (serviceRequestsQuery.isLoading || appointmentsQuery.isLoading || encountersQuery.isLoading) {
    return {
      ...serviceRequestsQuery,
      isLoading: true,
    } as unknown as UseQueryResult<ReferralModel[]>;
  }

  const referrals = (serviceRequestsQuery.data || [])
    .filter((serviceRequest) => serviceRequest.status === 'active')
    .map((serviceRequest) => {
      const relatedAppointment = findRelatedAppointment(
        serviceRequest,
        appointmentsQuery.data || [],
      );
      const relatedEncounter =
        relatedAppointment ?
          findRelatedEncounter(
            relatedAppointment,
            encountersQuery.data.map((encounterModel) => encounterModel.resource),
          )
        : undefined;

      const referral = new ReferralModel(
        serviceRequest,
        patient,
        relatedAppointment,
        relatedEncounter,
        documentsQuery.data,
      );

      referral.setIsLoadingDocuments(documentsQuery.isLoading);

      return referral;
    });

  return {
    ...serviceRequestsQuery,
    isLoading: false,
    data: orderBy(
      referrals,
      [(referral) => referral.status, (referral) => referral.referredAtDisplay],
      ['desc', 'desc'],
    ),
  } as unknown as UseQueryResult<ReferralModel[]>;
};
