import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { DetailsCard, DetailsProps } from './details-card';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export type NotesEntryProps = {
  summary?: ReactNode;
  isDetailShownOnOpen?: boolean;
} & DetailsProps;

// Component for displaying a note with a summary and details directly
export const NotesEntry = ({
  summary,
  isDetailShownOnOpen = false,
  ...detailsCardProps
}: NotesEntryProps) => {
  const [isDetailShown, setIsDetailShown] = useState(isDetailShownOnOpen);

  return (
    <div className={tw`space-y-1`}>
      <NoteSummary
        summary={summary}
        isDetailShown={isDetailShown}
        setIsDetailShown={setIsDetailShown}
        className={tw`space-x-4 rounded-lg bg-white p-3 outline outline-1 outline-divider-main`}
      />
      {isDetailShown && <DetailsCard {...detailsCardProps} />}
    </div>
  );
};

export const NoteSummary = ({
  summary,
  isDetailShown,
  setIsDetailShown,
  className,
}: {
  summary?: ReactNode;
  isDetailShown: boolean;
  setIsDetailShown: Dispatch<SetStateAction<boolean>>;
  className?: string;
}) => {
  const { trackInteraction } = useTelemetry();
  return (
    <Button
      type="button"
      aria-label="details"
      variant="unstyled"
      className={twx(className, 'flex w-full items-center justify-between space-x-4 text-left')}
      onClick={() => {
        trackInteraction('toggle_note', {
          action: isDetailShown ? 'collapse_note' : 'expand_note',
        });
        return setIsDetailShown(!isDetailShown);
      }}
    >
      <div className={tw`flex grow space-x-3`}>{summary}</div>
      <div className={tw`flex items-center space-x-3`}>
        <div className={tw`justify-right flex`}>
          <FontAwesomeIcon
            icon={faChevronRight}
            className={twx('h-3 w-3 text-content-icon', {
              'rotate-90': isDetailShown,
            })}
          />
        </div>
      </div>
    </Button>
  );
};
