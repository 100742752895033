import { capitalize } from 'lodash-es';
import { allergyFilter } from './helpers/filters';
import { useAllergiesHistory } from './helpers/history';
import { allergySortOptions, defaultAllergySort } from './helpers/sort';
import { History } from '../resource/helpers/history';
import { useResourceDetailsDrawer } from '../resource/resource-details-drawer';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { usePatientAllergies } from '@ctw/shared/api/fhir/allergies';
import { AllergyModel } from '@ctw/shared/api/fhir/models/allergies';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { patientAllergiesColumns } from '@ctw/shared/content/allergies/helpers/column';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { twx } from '@ctw/shared/utils/tailwind';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';

export const allergyHistory = ({ model }: { model: AllergyModel }) => (
  <History getHistory={useAllergiesHistory} model={model} />
);

export interface PatientAllergiesProps {
  className?: string;
}

export const PatientAllergies = withErrorBoundary({
  boundaryName: 'PatientAllergies',
  includeTelemetryBoundary: true,
  Component: ({ className }: PatientAllergiesProps) => {
    const patientAllergiesQuery = usePatientAllergies();

    const { data, filters, setFilters, setSort, defaultSort } = useFilteredSortedData({
      cacheKey: 'patient-allergies',
      defaultSort: defaultAllergySort,
      records: patientAllergiesQuery.data,
    });

    const { requestContext } = useCTW();
    const isEmptyQuery = patientAllergiesQuery.data?.length === 0;
    const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

    const openDetails = useResourceDetailsDrawer({
      header: (m) => capitalize(m.display),
      details: allergyData,
      RenderChild: allergyHistory,
      getSourceDocument: true,
      enableDismissAndReadActions: true,
      deepLinkResourceType: 'AllergyIntolerance',
    });

    return (
      <div className={twx(className, 'scrollable-pass-through-height')}>
        <ResourceTableActions
          filterOptions={{
            onChange: setFilters,
            filters: allergyFilter(),
            selected: filters,
          }}
          sortOptions={{
            defaultSort,
            options: allergySortOptions,
            onChange: setSort,
          }}
        />
        <ResourceTable
          showTableHead
          isLoading={patientAllergiesQuery.isLoading}
          data={data}
          columns={patientAllergiesColumns(requestContext.builderId)}
          onRowClick={(model) => openDetails({ prefetchedModel: model })}
          enableDismissAndReadActions
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="allergies"
            />
          }
        />
      </div>
    );
  },
});

export const allergyData = (allergy: AllergyModel) => [
  { label: 'Recorded Date', value: allergy.recordedDate },
  { label: 'Recording Organization', value: allergy.patientOrganizationName },
  { label: 'Status', value: allergy.clinicalStatus },
  { label: 'Type', value: capitalize(allergy.type) },
  { label: 'Onset', value: allergy.onset },
  { label: 'Reaction', value: capitalize(allergy.manifestations) },
  { label: 'Severity', value: capitalize(allergy.severity) },
  { label: 'Note', value: allergy.note },
];
