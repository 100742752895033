import { faFileLines } from '@fortawesome/pro-regular-svg-icons';
import { faCircleH } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEpisodeOfCareDrawer } from './episode-of-care-drawer';
import { applyFilters, OverviewFilter } from '../overview/filters';
import { ResourceOverviewCard } from '../overview/resource-overview-card';
import { ResourceTitleColumn } from '../resource/helpers/resource-title-column';
import { datePredicate } from '../resource/helpers/view-date-range';
import { usePatientEpisodesOfCare } from '@ctw/shared/api/fhir/episode-of-care';
import { EpisodeOfCareModel } from '@ctw/shared/api/fhir/models';
import { applySorts } from '@ctw/shared/utils/sort';
import { tw } from '@ctw/shared/utils/tailwind';
import { withOverviewCardErrorBoundary } from '@ctw/shared/content/overview/with-overview-card-error-boundary';

const TITLE = 'ED & IP Visits';

interface EpisodeOfCaresOverviewCardProps {
  onSeeAllResources: () => void;
}

export const EpisodeOfCareOverviewCard = withOverviewCardErrorBoundary({
  cardTitle: TITLE,
  boundaryName: 'EpisodeOfCareOverviewCard',
  Component: ({ onSeeAllResources }: EpisodeOfCaresOverviewCardProps) => {
    const openEpisodeOfCareDetails = useEpisodeOfCareDrawer();

    const episodesOfCareQuery = usePatientEpisodesOfCare();
    const sortedData = applySorts(episodesOfCareQuery.data ?? [], [
      { key: 'periodStart', dir: 'desc', isDate: true }, // Newest first.
    ]);
    const filteredData = applyFilters(sortedData, getEpisodeOfCareOverviewFilters());

    return (
      <ResourceOverviewCard
        headerIcon={faCircleH}
        title={TITLE}
        data={filteredData}
        emptyStateMessage={
          filteredData.length === 0 ?
            "We didn't find any emergency or inpatient records for this patient."
          : 'No emergency or inpatient visits found within the past year.'
        }
        onRowClick={(episodeOfCare) => {
          openEpisodeOfCareDetails({ prefetchedModel: episodeOfCare });
        }}
        footerCTA={{
          label: "'All Encounters'",
          onClick: onSeeAllResources,
        }}
        loading={episodesOfCareQuery.isLoading}
        helpText="Emergency and inpatient visits from the past year."
        telemetryTargetName="episodes_of_care_overview"
        testId="episodes-of-care-overview"
        renderResource={(episodeOfCare: EpisodeOfCareModel) => {
          const documents = episodeOfCare.dischargeBinaryIds;

          return (
            <ResourceTitleColumn
              title={`${episodeOfCare.dateDisplay} - ${episodeOfCare.class}`}
              subTitle={
                <div className={tw`space-y-0.5`}>
                  {episodeOfCare.location && (
                    <div className={tw`font-normal`}>{episodeOfCare.location}</div>
                  )}
                  {documents.length > 0 ?
                    <div className={tw`flex items-center space-x-1.5 pt-1 text-sm font-normal`}>
                      <FontAwesomeIcon className={tw`w-4 text-content-icon`} icon={faFileLines} />
                      <div>
                        {documents.length} {documents.length === 1 ? 'document' : 'documents'}
                      </div>
                    </div>
                  : undefined}
                </div>
              }
            />
          );
        }}
      />
    );
  },
});

const getEpisodeOfCareOverviewFilters = (): OverviewFilter<EpisodeOfCareModel>[] => [
  {
    key: 'emergency',
    label: 'Filter for emergency episodes',
    description: 'Is an emergency or a hospitalization.',
    predicate: (episodeOfCare: EpisodeOfCareModel) =>
      ['EMER', 'IMP'].includes(episodeOfCare.classCode),
  },
  {
    key: 'recent',
    label: 'Filter for recent episodes',
    description: 'Began within the past year.',
    predicate: (episodeOfCare: EpisodeOfCareModel) =>
      datePredicate(episodeOfCare, 'periodStart', 365, false),
  },
];
