import { SortOption } from '@ctw/shared/content/resource/resource-table-actions';
import { CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';

export const defaultCareGapSort: SortOption<CareGapModel> = {
  display: 'Last Updated Date (New to Old)',
  sorts: [{ key: 'updatedDate', dir: 'desc', isDate: true }],
};

export const careGapSortOptions: SortOption<CareGapModel>[] = [
  defaultCareGapSort,
  {
    display: 'Last Updated Date (Old to New)',
    sorts: [{ key: 'updatedDate', dir: 'asc', isDate: true }],
  },
];
