import { Provenance } from 'fhir/r4';
import { gql, GraphQLClient } from 'graphql-request';
import { ResourceTypeString } from '@ctw/shared/api/fhir/types';

export async function searchProvenancesFQS(
  graphqlClient: GraphQLClient,
  resourceType: ResourceTypeString,
  targets: string[],
) {
  const targetIds = targets.map((target) => target.replace(`${resourceType}/`, ''));

  const data = (await graphqlClient.request(
    provenanceQueries(resourceType, targetIds),
  )) as ProvenanceResponse;

  return Object.values(data).reduce((acc, val) => {
    acc.push(...val.ProvenanceList);
    return acc;
  }, [] as Provenance[]);
}

type ProvenanceResponse = {
  [key: string]: {
    ProvenanceList: Provenance[];
  };
};

function provenanceQueries(resourceType: ResourceTypeString, targetIds: string[]) {
  return gql`
    query GetProvenances() {
      ${targetIds.map((id, index) => provenanceQuery(resourceType, id, index)).join('\n')}
    }
  `;
}

function provenanceQuery(resourceType: ResourceTypeString, targetId: string, index: number) {
  return `
    provenance${index}: ${resourceType}(id: "${targetId}") {
      ProvenanceList(_reference: "target") {
        id
        meta {
          tag {
            system
            code
          }
        }
        target {
          reference
        }
        entity {
          role
          what {
            reference
          }
        }
      }
    }
  `;
}
