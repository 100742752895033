import { useMemo } from 'react';
import {
  PatientHistoryJobDerivedProviderStatus,
  PatientHistoryRequestModel,
} from '@ctw/shared/api/fhir/models/patient-history';
import { PatientHistoryJobStatus } from '@ctw/shared/api/patient-history/patient-history-types';
import { usePatientHistoryDetails } from '@ctw/shared/content/patient-history/use-patient-history';
import { usePatientContext } from '@ctw/shared/context/patient-provider';

type UsePatientHistoryStatus = {
  isLoading: boolean;
  lastCompletedDate: Date | undefined;
  jobStatus: PatientHistoryJobStatus | undefined;
  derivedStatus: PatientHistoryJobDerivedProviderStatus | undefined;
};

export function usePatientHistoryStatus(): UsePatientHistoryStatus {
  const { isLoading, data = {} } = usePatientHistoryDetails();
  const { patient } = usePatientContext();

  return useMemo(() => {
    const { latestCompleted, latestInProgress, nextScheduledAt } = data;
    const hasJob = !!latestCompleted || !!latestInProgress || !!nextScheduledAt;

    const { jobStatus } = latestCompleted?.job.attributes || {};

    if (patient.isTestPatient && !hasJob) {
      // Not sure what this is needed for, test or otherwise, but it's in the original code
      return {
        jobStatus,
        isLoading: false,
        derivedStatus: 'complete',
        lastCompletedDate: new Date(),
      };
    }

    if (latestCompleted?.job.attributes.jobStatus) {
      return {
        jobStatus,
        isLoading: false,
        derivedStatus: PatientHistoryRequestModel.getDerivedProviderStatus(
          latestCompleted.job.attributes.providers,
        ),
        lastCompletedDate: latestCompleted.targetDate,
      };
    }

    return {
      jobStatus,
      isLoading,
      derivedStatus: undefined,
      lastCompletedDate: undefined,
    };
  }, [data, isLoading, patient.isTestPatient]);
}
