import { useRef } from 'react';
import { patientEncounterColumns } from './helpers/columns';
import { encounterFilterPredicates, encounterFilters } from './helpers/filters';
import { usePatientEncounterDetailsDrawer } from './helpers/modal-hooks';
import { defaultEncounterSort, encounterSortOptions } from './helpers/sorts';
import { getDateRangeView } from '../resource/helpers/view-date-range';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { EncounterModel } from '@ctw/shared/api/fhir/models/encounter';
import { usePatientEncountersWithClinicalNotes } from '@ctw/shared/hooks/use-encounters';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { ClassName, twx } from '@ctw/shared/utils/tailwind';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';

export interface PatientEncountersProps {
  className?: ClassName;
}

export const PatientEncounters = withErrorBoundary({
  boundaryName: 'PatientEncounters',
  includeTelemetryBoundary: true,
  Component: ({ className }: PatientEncountersProps) => {
    const { requestContext } = useCTW();
    const encountersQuery = usePatientEncountersWithClinicalNotes();

    const containerRef = useRef<HTMLDivElement>(null);
    const { viewOptions, allTime } = getDateRangeView<EncounterModel>('periodStart');
    const { data, filters, setFilters, setSort, setViewOption, defaultSort, defaultView } =
      useFilteredSortedData({
        cacheKey: 'patient-encounters',
        viewOptions,
        defaultView: allTime.display,
        filterPredicates: encounterFilterPredicates,
        defaultSort: defaultEncounterSort,
        records: encountersQuery.data,
      });

    const isEmptyQuery = encountersQuery.data.length === 0;
    const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

    const openEncounterDetails = usePatientEncounterDetailsDrawer();

    return (
      <div className={twx(className, 'scrollable-pass-through-height')} ref={containerRef}>
        <ResourceTableActions
          viewOptions={{
            onChange: setViewOption,
            options: viewOptions,
            defaultView,
          }}
          filterOptions={{
            onChange: setFilters,
            filters: encounterFilters(encountersQuery.data),
            selected: filters,
          }}
          sortOptions={{
            defaultSort,
            options: encounterSortOptions,
            onChange: setSort,
          }}
        />
        <ResourceTable
          showTableHead
          isLoading={encountersQuery.isLoading}
          columns={patientEncounterColumns(requestContext.builderId)}
          data={data}
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="encounters"
            />
          }
          enableDismissAndReadActions
          onRowClick={(e) => {
            openEncounterDetails({
              prefetchedModel: e,
              trackingMetadata: { target: 'encounters' },
            });
          }}
        />
      </div>
    );
  },
});
