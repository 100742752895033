import { isEqual, orderBy, uniqWith } from 'lodash-es';
import { HistoryEntryProps } from './helpers/history-entry';
import { getBinaryId } from '@ctw/shared/api/fhir/binaries';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { searchProvenances } from '@ctw/shared/api/fhir/provenance';
import { excludeTagsinPatientRecordSearch } from '@ctw/shared/api/fhir/search-helpers';
import { ResourceType, ResourceTypeString } from '@ctw/shared/api/fhir/types';
import { GenericConnection } from '@ctw/shared/api/fqs/client';
import { allergyQuery } from '@ctw/shared/api/fqs/queries/allergies';
import { conditionsQuery } from '@ctw/shared/api/fqs/queries/conditions';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import { GraphQLClient } from 'graphql-request';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export type UseHistoryProps<T extends ResourceTypeString, M extends FHIRModel<ResourceType<T>>> = {
  resourceType: T;
  model: M;
  queryKey: string;
  valuesToDedupeOn: (m: M) => unknown;
  getHistoryEntry: (m: M) => HistoryEntryProps;
  getFiltersFQS?: (m: M) => object | undefined;
  clientSideFiltersFQS?: (model: M, resources: ResourceType<T>[]) => ResourceType<T>[];
};

export const useHistory = <T extends ResourceTypeString, M extends FHIRModel<ResourceType<T>>>({
  resourceType,
  model,
  queryKey,
  valuesToDedupeOn,
  getHistoryEntry,
  getFiltersFQS,
  clientSideFiltersFQS,
}: UseHistoryProps<T, M>) =>
  usePatientQuery({
    queryId: queryKey,
    queryKey: [model],
    queryFn: async ({ graphqlClient, telemetry, patient }) =>
      fetchResourcesFQS(
        resourceType,
        model,
        graphqlClient,
        telemetry,
        patient,
        valuesToDedupeOn,
        getHistoryEntry,
        clientSideFiltersFQS,
        getFiltersFQS?.(model),
      ),
    enabled: true,
  });

export function dedupeHistory<T extends fhir4.Resource, M extends FHIRModel<T>>(
  resources: M[],
  valuesToDedupeOn: (m: M) => unknown,
) {
  // We sort by isEnriched because we want enriched records to be preferred in uniqWith function.
  const enrichedFirst = orderBy(resources, ['isEnriched'], 'desc');

  return uniqWith(enrichedFirst, (a, b) => isEqual(valuesToDedupeOn(a), valuesToDedupeOn(b)));
}

function getResourceFQSQuery(resourceType: ResourceTypeString) {
  switch (resourceType) {
    case 'Condition':
      return conditionsQuery;
    case 'AllergyIntolerance':
      return allergyQuery;
    default:
      throw new Error(`Resource type to FQS query not implemented yet for ${resourceType}`);
  }
}

function getResourceNodes<T extends ResourceTypeString>(response: object): ResourceType<T>[] {
  const values = Object.values(response) as GenericConnection<T>[];

  return values.map((x) => x.edges.map((y) => y.node)).flat();
}

async function fetchResourcesFQS<
  T extends ResourceTypeString,
  M extends FHIRModel<ResourceType<T>>,
>(
  resourceType: T,
  model: M,
  graphqlClient: GraphQLClient,
  telemetry: ReturnType<typeof useTelemetry>,
  patient: PatientModel,
  valuesToDedupeOn: (m: M) => unknown,
  getHistoryEntry: (m: M) => HistoryEntryProps,
  clientSideFiltersFQS?: (model: M, resources: ResourceType<T>[]) => ResourceType<T>[],
  filter?: object | undefined,
) {
  try {
    const resources =
      filter || clientSideFiltersFQS ?
        getResourceNodes<T>(
          await graphqlClient.request(getResourceFQSQuery(resourceType), {
            upid: patient.UPID,
            cursor: '',
            first: 1000,
            sort: {
              lastUpdated: 'DESC',
            },
            filter,
          }),
        )
      : [model.resource];

    let filteredResources = filterLensAndSummary(resources, resourceType);

    if (clientSideFiltersFQS) {
      filteredResources = clientSideFiltersFQS(model, filteredResources);
    }

    const constructor = model.constructor as new (r: ResourceType<T>) => M;
    const models = filteredResources.map((c) => new constructor(c));

    const entries = dedupeHistory(models, valuesToDedupeOn).map(getHistoryEntry);

    // Fetch provenances and add binaryId to each entry.
    const provenances = await searchProvenances(graphqlClient, models);
    entries.forEach((entry) => {
      // eslint-disable-next-line no-param-reassign
      entry.binaryId = getBinaryId(provenances, entry.id);
    });

    return entries;
  } catch (e) {
    telemetry.trackError({
      message: `Failed fetching ${resourceType} history for patient via FQS: ${patient.UPID}}`,
      error: e,
    });
    throw e;
  }
}

export function filterLensAndSummary<T extends ResourceTypeString>(
  resources: ResourceType<T>[],
  resourceType: T,
) {
  // filter out anything we don't want
  return resources.filter((resource) => {
    // no tags are allowed through (should be an edge case)
    if (!resource.meta || !resource.meta.tag) {
      return true;
    }

    const hasExcludableTag =
      resource.meta.tag.filter((tag) =>
        excludeTagsinPatientRecordSearch(resourceType).includes(`${tag.system}|${tag.code}`),
      ).length > 0;

    return !hasExcludableTag;
  });
}
