import { consola } from 'consola';
import { isEmpty } from 'lodash-es';

interface DelegateCallbackResponse {
  logToConsole: boolean;
  context?: Record<string, unknown>;
}

export interface DelegatedLoggerOptions {
  beforeDebug?: (
    tag: string,
    message: string,
    context: Record<string, unknown>,
  ) => DelegateCallbackResponse;
  beforeInfo?: (
    tag: string,
    message: string,
    context: Record<string, unknown>,
  ) => DelegateCallbackResponse;
  beforeWarn?: (
    tag: string,
    message: string,
    context: Record<string, unknown>,
  ) => DelegateCallbackResponse;
  beforeError?: (
    tag: string,
    message: string,
    context: Record<string, unknown>,
  ) => DelegateCallbackResponse;
}

export class DelegatedLogger {
  private readonly logger = consola;

  private readonly beforeDebug?: DelegatedLoggerOptions['beforeDebug'];

  private readonly beforeInfo?: DelegatedLoggerOptions['beforeInfo'];

  private readonly beforeWarn?: DelegatedLoggerOptions['beforeWarn'];

  private readonly beforeError?: DelegatedLoggerOptions['beforeError'];

  constructor({ beforeDebug, beforeInfo, beforeWarn, beforeError }: DelegatedLoggerOptions = {}) {
    this.beforeDebug = beforeDebug;
    this.beforeInfo = beforeInfo;
    this.beforeWarn = beforeWarn;
    this.beforeError = beforeError;
  }

  debug(tag: string, message: string, context: Record<string, unknown> = {}) {
    const delegateResponse = this.beforeDebug?.(tag, message, context);

    if (!this.beforeDebug || (delegateResponse && delegateResponse.logToConsole)) {
      if (isEmpty(context) && isEmpty(delegateResponse?.context)) {
        this.logger.withTag(tag).debug(message);
      } else {
        this.logger.withTag(tag).debug(message, {
          ...(delegateResponse?.context ?? {}),
          ...context,
        });
      }
    }
  }

  info(tag: string, message: string, context: Record<string, unknown> = {}) {
    const delegateResponse = this.beforeInfo?.(tag, message, context);

    if (!this.beforeInfo || (delegateResponse && delegateResponse.logToConsole)) {
      if (isEmpty(context) && isEmpty(delegateResponse?.context)) {
        this.logger.withTag(tag).info(message);
      } else {
        this.logger.withTag(tag).info(message, {
          ...(delegateResponse?.context ?? {}),
          ...context,
        });
      }
    }
  }

  warn(tag: string, message: string, context: Record<string, unknown> = {}) {
    const delegateResponse = this.beforeWarn?.(tag, message, context);

    if (!this.beforeWarn || (delegateResponse && delegateResponse.logToConsole)) {
      if (isEmpty(context) && isEmpty(delegateResponse?.context)) {
        this.logger.withTag(tag).warn(message);
      } else {
        this.logger.withTag(tag).warn(message, {
          ...(delegateResponse?.context ?? {}),
          ...context,
        });
      }
    }
  }

  error(tag: string, message: string, context: Record<string, unknown> = {}) {
    const delegateResponse = this.beforeError?.(tag, message, context);

    if (!this.beforeError || (delegateResponse && delegateResponse.logToConsole)) {
      if (isEmpty(context) && isEmpty(delegateResponse?.context)) {
        this.logger.withTag(tag).error(message);
      } else {
        this.logger.withTag(tag).error(message, {
          ...(delegateResponse?.context ?? {}),
          ...context,
        });
      }
    }
  }
}
