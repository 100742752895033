import jwt_decode from 'jwt-decode';

const AUTH_BUILDER_ID = 'https://zusapi.com/builder_id' as const;
const AUTH_BUILDER_NAME = 'https://zusapi.com/builder_name' as const;
const AUTH_PRACTITIONER_ID = 'https://zusapi.com/practitioner_id' as const;
const AUTH_IS_SUPER_ORG = 'https://zusapi.com/is_super_org' as const;
const AUTH_PERMISSION_TOKEN = 'https://zusapi.com/permissions_token' as const;
const AUTH_EMAIL = 'https://zusapi.com/email' as const;
const AUTH_USER_ID = 'https://zusapi.com/user_id' as const;
const AUTH_APP_CLIENT_ID = 'https://zusapi.com/app_client_id' as const;
const AUTH_USER_TYPE = 'https://zusapi.com/user_type' as const;
const AUTH_PATIENT_ID = 'https://zusapi.com/patient_id' as const;
const AUTH_AUTHENTICATED_BY = 'https://zusapi.com/authenticated_by' as const;

interface ZusJWTClaims {
  [AUTH_BUILDER_ID]: string;
  [AUTH_BUILDER_NAME]: string;
  [AUTH_PRACTITIONER_ID]: string;
  [AUTH_IS_SUPER_ORG]: string;
  [AUTH_PERMISSION_TOKEN]: string;
  [AUTH_EMAIL]: string;
  [AUTH_USER_ID]: string;
  [AUTH_APP_CLIENT_ID]: string;
  [AUTH_USER_TYPE]: string;
  [AUTH_PATIENT_ID]: string;
  [AUTH_AUTHENTICATED_BY]: string;
  kid: string;
  jti: string;
  nbf: string;
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
  [key: string]: unknown;
}

export const getAuthTokenClaims = (authToken: string): Partial<ZusJWTClaims> => {
  try {
    return jwt_decode(authToken);
  } catch {
    return {};
  }
};

export type ZusAuthTokenState = {
  zusUserId: string;
  builderId: string;
  userType: string;
  builderName: string;
  email: string;
  practitionerId: string;
  isSuperOrg: string;
  permissionsToken: string;
  appClientId: string;
  patientId: string;
  authenticatedBy: string;
  jti: string;
  nbf: string;
  kid: string;
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
};

export const getAuthTokenState = (authToken: string): ZusAuthTokenState => {
  const claims: Partial<ZusJWTClaims> = getAuthTokenClaims(authToken);

  return {
    zusUserId: claims[AUTH_USER_ID] ?? '',
    builderId: claims[AUTH_BUILDER_ID] ?? '',
    userType: claims[AUTH_USER_TYPE] ?? '',
    builderName: claims[AUTH_BUILDER_NAME] ?? '',
    email: claims[AUTH_EMAIL] ?? '',
    practitionerId: claims[AUTH_PRACTITIONER_ID] ?? '',
    isSuperOrg: claims[AUTH_IS_SUPER_ORG] ?? '',
    permissionsToken: claims[AUTH_PERMISSION_TOKEN] ?? '',
    appClientId: claims[AUTH_APP_CLIENT_ID] ?? '',
    patientId: claims[AUTH_PATIENT_ID] ?? '',
    authenticatedBy: claims[AUTH_AUTHENTICATED_BY] ?? '',
    kid: claims.kid ?? '',
    nbf: claims.jti ?? '',
    jti: claims.jti ?? '',
    iss: claims.iss ?? '',
    sub: claims.sub ?? '',
    aud: claims.aud ?? [],
    iat: claims.iat ?? 0,
    // Return 0s if the token does not contain an 'exp' claim because undefined
    // will cast to NaN which always will be false in comparisons.
    exp: !claims.exp || Number.isNaN(claims.exp) ? 0 : claims.exp,
    azp: claims.azp ?? '',
    scope: claims.scope ?? '',
  };
};

export const isAuthTokenExpired = (token: string) => {
  const tokenState = getAuthTokenState(token);

  // `Date.now()` is in milliseconds, `exp` is in seconds
  const nowMilliseconds = Date.now();
  const expiryMilliseconds = tokenState.exp * 1000;

  return nowMilliseconds >= expiryMilliseconds;
};

export const isAuthTokenPastExpiryGracePeriod = (token: string) => {
  const tokenState = getAuthTokenState(token);

  // `Date.now()` is in milliseconds, `exp` is in seconds
  const expiryMilliseconds = tokenState.exp * 1000;
  const nowMilliseconds = Date.now();
  const expiryGraceMilliseconds = 20 * 1000;

  return nowMilliseconds - expiryGraceMilliseconds >= expiryMilliseconds;
};
