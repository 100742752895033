import { faFileLines } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReferralModel } from '@ctw/shared/api/fhir/models/referral';
import { Badge } from '@ctw/shared/components/badge';
import { TableColumn } from '@ctw/shared/components/table/table';
import { tw } from '@ctw/shared/utils/tailwind';
import { capitalize } from 'lodash-es';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';

export const patientReferralsColumns = (_builderId: string) => {
  const referralColumns: TableColumn<ReferralModel>[] = [
    {
      title: 'Reason',
      render: (referral) => <div className={tw`leading-tight`}>{referral.reason}</div>,
    },
    {
      title: 'Referred On',
      render: (referral) => (
        <div className={tw`leading-tight`}>
          <span className={tw`whitespace-nowrap`}>{referral.referredAtDisplay ?? ''}</span>
          {referral.referringPractitionerName && (
            <>
              <br />
              <span className={tw`text-xs`}>by {referral.referringPractitionerName}</span>
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Referred Provider',
      render: (referral) => (
        <div className={tw`leading-tight`}>
          <span>{referral.referredPractitionerName ?? ''}</span>
          {referral.referredPracticeName && (
            <>
              <br />
              <span>{referral.referredPracticeName}</span>
            </>
          )}
          {referral.referredPractitioners.length > 1 && (
            <>
              <br />
              <span className={tw`text-xs`}>
                + {referral.referredPractitioners.length - 1} More
              </span>
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Status',
      render: (referral) => (
        <div className={tw`flex flex-col gap-1`}>
          <div className={tw`flex w-full items-center justify-between`}>
            <Badge
              variant={referral.status === 'fulfilled' ? 'muted' : 'primary'}
              content={capitalize(referral.status)}
            />
            {referral.getIsLoadingDocuments() && <LoadingSpinner className={tw`h-4 w-4`} />}
            {referral.encounter?.docsAndNotes && referral.encounter.docsAndNotes.length === 0 && (
              <FontAwesomeIcon className={tw`w-4 text-content-icon`} icon={faFileLines} />
            )}
          </div>
        </div>
      ),
    },
    {
      title: 'Referral Details',
      render: (referral) => (
        <div className={tw`flex flex-col gap-1`}>
          {['ordered', 'scheduled', 'fulfilled'].includes(referral.status) && (
            <div className={tw`leading-tight`}>
              {referral.referredAtDisplay && (
                <>
                  <span className={tw`whitespace-nowrap text-sm`}>
                    Referred {referral.referredAtDisplay}
                  </span>
                  {referral.referringPracticeName && (
                    <>
                      <br />
                      <span className={tw`text-content-light text-xs`}>
                        by {referral.referringPracticeName}
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          )}

          {['scheduled', 'fulfilled'].includes(referral.status) && (
            <div className={tw`leading-tight`}>
              {referral.scheduledAtDisplay && (
                <>
                  <span className={tw`whitespace-nowrap text-sm`}>
                    Scheduled {referral.scheduledAtDisplay}
                  </span>
                  {referral.referredPracticeName && (
                    <>
                      <br />
                      <span className={tw`text-content-light text-xs`}>
                        by {referral.referredPracticeName}
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {['fulfilled'].includes(referral.status) && (
            <div className={tw`leading-tight`}>
              {referral.completedAtDisplay && (
                <>
                  <span className={tw`whitespace-nowrap`}>
                    Completed {referral.completedAtDisplay}
                  </span>
                  {referral.referredPracticeName && (
                    <>
                      <br />
                      <span className={tw`text-content-light text-xs`}>
                        by {referral.referredPracticeName}
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      ),
    },
  ];

  return referralColumns;
};
