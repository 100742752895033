import { CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';
import { faStar, faBan, faClock } from '@fortawesome/pro-regular-svg-icons';
import { Badge } from '@ctw/shared/components/badge';

export const CareGapStatusBadge = ({ gap }: { gap: CareGapModel }) => {
  switch (gap.status) {
    case 'closed':
      return <Badge variant="primary" content="Met" icon={faStar} testId="care-gap-badge-closed" />;

    case 'excluded':
      return (
        <Badge variant="muted" content="Excluded" icon={faBan} testId="care-gap-badge-excluded" />
      );

    default: {
      return <Badge variant="info" content="Open" icon={faClock} testId="care-gap-badge-open" />;
    }
  }
};
