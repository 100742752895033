import { format } from 'date-fns';
import { PatientModel } from './patient';
import {
  PatientHistoryJobProvider,
  PatientHistoryJobProviderStatus,
  PatientHistoryJobResponseJobData,
} from '@ctw/shared/api/patient-history/patient-history-types';
import { formatFHIRDate } from '@ctw/shared/utils/dates';

export const completedProviderStatus: readonly PatientHistoryJobProviderStatus[] = [
  'done',
  'skipped',
];

export const failedProviderStatus: readonly PatientHistoryJobProviderStatus[] = [
  'error',
  'done_with_info',
];

export type PatientHistoryJobDerivedProviderStatus =
  | 'inProgress'
  | 'complete'
  | 'failed'
  | 'partiallyComplete' // Partially complete is a combination of in-progress and complete
  | 'completeWithErrors'; // Complete with errors is a combination of complete and failed

export class PatientHistoryRequestModel {
  kind = 'PatientHistory' as const;

  constructor(
    public patient: PatientModel,
    public historyInfo: PatientHistoryJobResponseJobData,
    // eslint-disable-next-line no-empty-function
  ) {}

  get key() {
    return this.historyInfo.id;
  }

  get providers() {
    return this.historyInfo.attributes.providers;
  }

  get targetDate() {
    // This is necessary because targetDate was not introduced until May 2023 and it was not backfilled
    return formatFHIRDate(
      this.historyInfo.attributes.targetDate ??
        Number(this.historyInfo.attributes.lastUpdatedAt) * 1000,
    );
  }

  get createdAt() {
    return format(new Date(Number(this.historyInfo.attributes.createdAt) * 1000), 'M/d/yy h:mm a');
  }

  get lastUpdatedAt() {
    return format(
      new Date(Number(this.historyInfo.attributes.lastUpdatedAt) * 1000),
      'M/d/yy h:mm a',
    );
  }

  static getDerivedProviderStatus(
    providers: readonly PatientHistoryJobProvider[],
  ): PatientHistoryJobDerivedProviderStatus {
    const statuses = providers.map((provider) => provider.status);

    if (statuses.every((status) => completedProviderStatus.includes(status))) {
      return 'complete';
    }

    if (statuses.every((status) => failedProviderStatus.includes(status))) {
      return 'failed';
    }

    if (
      statuses.every((status) =>
        [...completedProviderStatus, ...failedProviderStatus].includes(status),
      )
    ) {
      return 'completeWithErrors';
    }

    if (statuses.some((status) => completedProviderStatus.includes(status))) {
      return 'partiallyComplete';
    }

    return 'inProgress';
  }
}
