import { faNoteSticky } from '@fortawesome/pro-regular-svg-icons';
import { faCircleH } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { encounterClassPredicate } from './helpers/filters';
import { usePatientEncounterDetailsDrawer } from './helpers/modal-hooks';
import { defaultEncounterSort } from './helpers/sorts';
import { applyFilters, OverviewFilter } from '../overview/filters';
import { ResourceOverviewCard } from '../overview/resource-overview-card';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '../resource/helpers/resource-title-column';
import { datePredicate } from '../resource/helpers/view-date-range';
import { EncounterModel } from '@ctw/shared/api/fhir/models';
import { usePatientEncountersWithClinicalNotes } from '@ctw/shared/hooks/use-encounters';
import { applySorts } from '@ctw/shared/utils/sort';
import { tw } from '@ctw/shared/utils/tailwind';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { withOverviewCardErrorBoundary } from '@ctw/shared/content/overview/with-overview-card-error-boundary';

const TITLE = 'ED & IP Visits';

interface EncountersOverviewCardProps {
  onSeeAllResources: () => void;
}

export const EncountersOverviewCard = withOverviewCardErrorBoundary({
  cardTitle: TITLE,
  boundaryName: 'EncountersOverviewCard',
  Component: ({ onSeeAllResources }: EncountersOverviewCardProps) => {
    const { requestContext } = useCTW();
    const encountersQuery = usePatientEncountersWithClinicalNotes();
    const openEncounterDetails = usePatientEncounterDetailsDrawer();

    const sortedData = applySorts(encountersQuery.data, defaultEncounterSort.sorts);
    const filteredData = applyFilters(sortedData, getEncounterOverviewFilters());

    return (
      <ResourceOverviewCard
        headerIcon={faCircleH}
        title={TITLE}
        data={filteredData}
        emptyStateMessage={
          encountersQuery.data.length === 0 ?
            "We didn't find any encounter records for this patient."
          : 'There are no emergency or inpatient visits within the past year for this patient.'
        }
        onRowClick={(e) => {
          openEncounterDetails({ prefetchedModel: e, trackingMetadata: { target: 'overview' } });
        }}
        footerCTA={{
          label: 'All Encounters',
          onClick: onSeeAllResources,
        }}
        loading={encountersQuery.isLoading}
        helpText="Emergency and inpatient visits from the past year."
        telemetryTargetName="encounters_overview"
        testId="encounters-overview"
        renderResource={(e: EncounterModel) => (
          <ResourceTitleColumn
            title={`${e.dateDisplay} - ${e.typeDisplay}`}
            subTitle={
              <div className={tw`space-y-0.5`}>
                {e.location.length > 0 && (
                  <div className={tw`font-normal`}>{e.location.join(', ')}</div>
                )}
                {e.clinicalNotes.length > 0 ?
                  <div className={tw`flex items-center space-x-1.5 pt-1 text-sm font-normal`}>
                    <FontAwesomeIcon className={tw`w-4 text-content-icon`} icon={faNoteSticky} />
                    <div>
                      {e.clinicalNotes.length} {e.clinicalNotes.length === 1 ? 'note' : 'notes'}
                    </div>
                  </div>
                : undefined}
              </div>
            }
            renderIcon={RenderSyncedWithRecordIcon(
              e.ownedByBuilder(requestContext.builderId) || e.syncedWithRecord,
            )}
          />
        )}
      />
    );
  },
});

const getEncounterOverviewFilters = (): OverviewFilter<EncounterModel>[] => [
  {
    key: 'emergency',
    label: 'Filter for emergency encounters',
    description: 'Is an emergency or a hospitalization.',
    predicate: (e: EncounterModel) => encounterClassPredicate(['Emergency', 'Inpatient'], e),
  },
  {
    key: 'recent',
    label: 'Filter for recent encounters',
    description: 'Began within the past year.',
    predicate: (e: EncounterModel) => datePredicate(e, 'periodStart', 365, false),
  },
];
