import { gql } from 'graphql-request';
import { GraphqlConnectionNode } from '../client';
import { ZAPTabName } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';

// This is the current supported list of resources that can be queried for in the data indicator
const resourceNameMapping: Record<string, string> = {
  'conditions-all': 'ConditionConnection',
  allergies: 'AllergyIntoleranceConnection',
  'diagnostic-reports': 'DiagnosticReportConnection',
  documents: 'DocumentReferenceConnection',
  encounters: 'EncounterConnection',
  immunizations: 'ImmunizationConnection',
  referrals: 'ServiceRequestConnection',
  'medications-all': 'MedicationStatementConnection',
  vitals: 'ObservationConnection',
  demographics: 'PatientConnection',
  'care-team': 'CareTeamConnection',
};

export function generateDataIndicatorQuery(zapTabNames: ZAPTabName[]) {
  const activeFilters = zapTabNames
    .map((tabName) => filters.filter((filter) => filter.tabName === tabName))
    .filter(Boolean);

  // Filter out any zap tabs that are not in the resourceNameMapping
  const tabNames = zapTabNames.filter((resource) => resourceNameMapping[resource]);

  const dataIndicatorQuery = gql`
    query CombinedQuery(
      $upid: ID!
      $cursor: String!
      $first: Int!
      ${activeFilters
        .map((filter) => filter.map((f) => `$${f.name}: ${f.type}!`).join('\n'))
        .join('\n')}
      ) {
      ${tabNames
        .map((resource: ZAPTabName) => {
          const filterName = filters.find((filter) => filter.tabName === resource)?.name;
          return generateQueryForGenericResource(resourceNameMapping[resource], filterName);
        })
        .join('\n')}
    }
  `;

  return dataIndicatorQuery;
}

function generateQueryForGenericResource(resource: string, filter?: string) {
  return `
    ${resource}: ${resource}(
      upid: $upid
      after: $cursor
      first: $first
      ${filter ? `filter: $${filter}` : ''}
    ) {
      edges {
        node {
          id
          resourceType
        }
      }
    }
  `;
}

export type ResourceFilterKeys = Extract<ZAPTabName, 'sources' | 'vitals' | 'immunizations'>;

const filters: { tabName: ResourceFilterKeys; name: string; type: string }[] = [
  {
    tabName: 'vitals',
    name: 'ObservationFilter',
    type: 'ObservationFilterParams',
  },
  {
    tabName: 'immunizations',
    name: 'ImmunizationFilter',
    type: 'ImmunizationFilterParams',
  },
];

interface Connection<T> {
  edges: GraphqlConnectionNode<T>[];
}

export type MinimalResourceGraphqlResponse = {
  id: string;
  resourceType: string;
};

export interface DataIndicatorGraphqlResponse {
  DiagnosticReportConnection: Connection<MinimalResourceGraphqlResponse>;
  AllergyIntoleranceConnection: Connection<MinimalResourceGraphqlResponse>;
  DocumentReferenceConnection: Connection<MinimalResourceGraphqlResponse>;
  EncounterConnection: Connection<MinimalResourceGraphqlResponse>;
  PatientConnection: Connection<MinimalResourceGraphqlResponse & { meta: fhir4.Patient['meta'] }>;
  CareTeamConnection: Connection<MinimalResourceGraphqlResponse>;
  ServiceRequestConnection: Connection<MinimalResourceGraphqlResponse>;
  ObservationConnection: Connection<MinimalResourceGraphqlResponse>;
  ConditionConnection: Connection<MinimalResourceGraphqlResponse>;
  ImmunizationConnection: Connection<MinimalResourceGraphqlResponse>;
  MedicationStatementConnection: Connection<MinimalResourceGraphqlResponse>;
}
