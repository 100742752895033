export const urlWithParameters = (url: string, parameters: Record<string, unknown>): string => {
  const newUrl = new URL(url);

  Object.entries(parameters).forEach(([key, value]) => {
    if (value === null) {
      newUrl.searchParams.delete(key);
    } else {
      newUrl.searchParams.set(key, String(value));
    }
  });

  return newUrl.toString();
};
