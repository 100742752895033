import { Env } from '@ctw/shared/context/types';

// CanonicalUrlMap - maps a canonical URL to a measure ID
type CanonicalUrlMap = Record<string, string>;

const CARE_OF_OLDER_ADULTS_MEDICATION_REVIEW =
  'http://zusapi.com/quality/measure/ursa-hedis/COA/Meds';
const GLYCEMIC_STATUS_ASSESSMENT_DIABETES = 'http://zusapi.com/quality/measure/ursa-quality/GSD';
const CONTROLLING_HIGH_BLOOD_PRESSURE = 'http://zusapi.com/quality/measure/ursa-hedis/CBP';
const COLORECTAL_CANCER_SCREENING = 'http://zusapi.com/quality/measure/ursa-hedis/COL-E';

const canonicalUrlMaps: Record<Env, CanonicalUrlMap | undefined> = {
  dev: {
    [CONTROLLING_HIGH_BLOOD_PRESSURE]: '6a6a1f44-cf45-4e83-8043-f7519f63e3ce',
    [GLYCEMIC_STATUS_ASSESSMENT_DIABETES]: '73c1232d-d5d5-404f-a7c7-7d9a37111a90',
    [CARE_OF_OLDER_ADULTS_MEDICATION_REVIEW]: '6dc1c8b1-d911-4e58-afa0-47037cba56c7',
  },
  sandbox: {
    [CONTROLLING_HIGH_BLOOD_PRESSURE]: '4e6a7a32-4a28-4ba9-81c0-a5d9c3f360b8',
    [COLORECTAL_CANCER_SCREENING]: '10e675d1-5027-4f6f-bd39-a874e2fd0f3d',
    [CARE_OF_OLDER_ADULTS_MEDICATION_REVIEW]: 'aa62caa8-8240-4593-8944-fd53766232a0',
  },
  phitest: {},
  prod: {},
};

export const getMeasureIdFromCanonicalUrlMap = (measureIdOrUrl: string, env: Env) => {
  const canonicalUrlMap = canonicalUrlMaps[env] ?? {};
  return canonicalUrlMap[measureIdOrUrl] ?? measureIdOrUrl.split('/').pop();
};
