import { PatientModel } from '@ctw/shared/api/fhir/models';
import { fqsRequestAll, MAX_OBJECTS_PER_REQUEST } from '@ctw/shared/api/fqs/client';
import {
  ServiceRequestGraphqlResponse,
  ServiceRequestQuery,
} from '@ctw/shared/api/fqs/queries/service-requests';
import { GraphQLClient } from 'graphql-request';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export async function fetchServiceRequests(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
) {
  const { data } = await fqsRequestAll<ServiceRequestGraphqlResponse>(
    telemetry,
    graphqlClient,
    ServiceRequestQuery,
    'ServiceRequestConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
    },
  );

  return data.ServiceRequestConnection.edges.map(({ node }) => node);
}
