import { createOrEditFhirResource } from '@ctw/shared/api/fhir/action-helper';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models/medication-statement';
import { SYSTEM_RXNORM } from '@ctw/shared/api/fhir/system-urls';
import { dateToISO } from '@ctw/shared/utils/dates';
import {
  QUERY_KEY_PATIENT,
  QUERY_KEY_PATIENT_BUILDER_MEDICATIONS,
  QUERY_KEY_PATIENT_SUMMARY_MEDICATIONS,
} from '@ctw/shared/utils/query-keys';
import { useQueryClient } from '@tanstack/react-query';
import { CTWState } from '@ctw/shared/context/ctw-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

const QUERY_KEYS = [
  QUERY_KEY_PATIENT,
  QUERY_KEY_PATIENT_SUMMARY_MEDICATIONS,
  QUERY_KEY_PATIENT_BUILDER_MEDICATIONS,
];

export type CreateMedicationStatementFormData = {
  status: fhir4.MedicationStatement['status'];
  dateAsserted: Date;
  subjectID: string;
  medication: { display: string; code: string };
  dosage: string;
  note?: string;
};

export const useCreateMedicationStatement = () => {
  const queryClient = useQueryClient();
  const telemetry = useTelemetry();
  return async (data: CreateMedicationStatementFormData, ctw: CTWState): Promise<unknown> => {
    // Some fields will need to be set as they are required.
    const fhirMedicationStatement: fhir4.MedicationStatement = {
      resourceType: 'MedicationStatement',
      status: data.status,
      dateAsserted: dateToISO(data.dateAsserted),
      subject: { type: 'Patient', reference: `Patient/${data.subjectID}` },
      medicationCodeableConcept: {
        text: data.medication.display,
        coding: [
          {
            system: SYSTEM_RXNORM,
            code: data.medication.code,
          },
        ],
      },
      ...(data.dosage && {
        dosage: [
          {
            text: data.dosage,
          },
        ],
      }),
    };

    const resourceModel = new MedicationStatementModel(fhirMedicationStatement);

    try {
      const response = await createOrEditFhirResource(resourceModel.resource, ctw);

      await Promise.all(
        QUERY_KEYS.map(async (queryKey) => queryClient.invalidateQueries({ queryKey: [queryKey] })),
      );

      return response;
    } catch (error) {
      telemetry.trackError({ message: 'Error executing createOrEditFhirResource', error });
      throw error;
    }
  };
};
