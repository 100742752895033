import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Launch from './Launch';
import ZAP from './ZAP';
import { ZusUiProvider } from '@ctw/shared/context/zus-ui-provider';

const RouteDefinitions = () => {
  const navigate = useNavigate();

  return (
    <ZusUiProvider service="smart-on-fhir" navigate={navigate} pathname={useLocation}>
      <Routes>
        <Route path="/launch" element={<Launch />} />
        <Route path="/zap" element={<ZAP />} />
      </Routes>
    </ZusUiProvider>
  );
};

export function App() {
  return (
    <BrowserRouter>
      <RouteDefinitions />
    </BrowserRouter>
  );
}
