import { capitalize, compact, isEmpty } from 'lodash-es';

interface FormatAddressOptions {
  includeUse?: boolean;
}

export interface LenientAddress extends Omit<fhir4.Address, 'use' | 'type'> {
  use?: string;
  type?: string;
}

export const formatAddress = (input: LenientAddress, options: FormatAddressOptions = {}): string =>
  compact([
    `${options.includeUse && !isEmpty(input.use) ? `${capitalize(input.use)}: ` : ''}${compact(
      (input.line ?? []).map((line) => line.trim()),
    ).join(', ')}`,
    (input.city ?? input.district ?? '').trim(),
    `${(input.state ?? '').trim()} ${(input.postalCode ?? '').trim()}`.trim(),
    (input.country ?? 'US').trim(),
  ]).join(', ');
