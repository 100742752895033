import {
  demoPatientSubscriptionPackage,
  GetPackageAPIResponse,
  GetPatientSubscriptionAPIResponse,
  PatientSubscription,
  PatientSubscriptionData,
  PatientSubscriptionStatus,
} from './types';
import { useBuilderPatientsByUPID } from '@ctw/shared/api/fhir/patient-helper';
import { getZusServiceUrl } from '@ctw/shared/api/urls';
import { CTWRequestContext, CTWState } from '@ctw/shared/context/ctw-context';
import { isError } from '@ctw/shared/utils/errors';
import { QUERY_KEY_PATIENT_SUBSCRIPTION } from '@ctw/shared/utils/query-keys';
import { sort } from '@ctw/shared/utils/sort';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import { fhirErrorResponse, isFhirError } from '@ctw/shared/api/fhir/errors';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

async function getSubscriptionsByPatientIds(
  requestContext: CTWRequestContext,
  ctwFetch: CTWState['ctwFetch'],
  telemetry: ReturnType<typeof useTelemetry>,
  patientIds: string[],
  status?: PatientSubscriptionStatus,
): Promise<GetPatientSubscriptionAPIResponse> {
  try {
    const params = new URLSearchParams({
      'filter[patient-id]': patientIds.join(','),
    });
    if (status) {
      params.append('filter[status]', status);
    }

    const response = await ctwFetch(
      `${getZusServiceUrl(
        requestContext.env,
        'zap-data-subscriptions',
      )}/enrollment-statuses?${params}`,
      {
        headers: {
          Authorization: `Bearer ${requestContext.authToken}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return response.data as GetPatientSubscriptionAPIResponse;
  } catch (e) {
    const message = 'Failed fetching patient subscription';

    telemetry.trackError({
      message,
      error: e,
    });

    if (isFhirError(e)) {
      throw fhirErrorResponse(message, e);
    }

    if (isError(e)) {
      return { errors: { error: [{ title: message, status: e.message, detail: '' }] } };
    }

    return { errors: { error: [{ title: message, status: '', detail: '' }] } };
  }
}

export async function getPackage(
  ctwFetch: CTWState['ctwFetch'],
  requestContext: CTWRequestContext,
  packageId: string,
) {
  const response = await ctwFetch(
    `${getZusServiceUrl(requestContext.env, 'zap-data-subscriptions')}/packages/${packageId}`,
    {
      headers: {
        Authorization: `Bearer ${requestContext.authToken}`,
      },
    },
  );

  return response.data;
}

export function usePatientSubscription() {
  const patients = useBuilderPatientsByUPID();

  return usePatientQuery({
    queryId: QUERY_KEY_PATIENT_SUBSCRIPTION,
    queryKey: [patients.data],
    queryFn: async ({ requestContext, ctwFetch, telemetry, patient }) => {
      const patientSubscription: PatientSubscription = {
        patientId: patient.id,
      };

      const { data: activeSubscriptions, errors } = await getSubscriptionsByPatientIds(
        requestContext,
        ctwFetch,
        telemetry,
        (patients.data ?? [patient]).map(({ id }) => id),
        'active',
      );

      if (activeSubscriptions?.length === 0 || errors) {
        if (patient.isTestPatient) {
          // If a test/demo patient doesn't have a package then use this demo content.
          patientSubscription.package = demoPatientSubscriptionPackage;
        }
        return patientSubscription;
      }

      const subscription = sort(
        activeSubscriptions as PatientSubscriptionData[],
        'attributes.createdAt',
        'desc',
        true,
      )[0];
      const packageId = subscription.relationships.package.data.id;
      const zusPackage = (await getPackage(
        ctwFetch,
        requestContext,
        packageId,
      )) as GetPackageAPIResponse;
      const { intelligentRefreshProviders, recurringProvidersWithInterval } =
        zusPackage.data.attributes.meta;
      const hasIntelligentRefresh =
        intelligentRefreshProviders ? intelligentRefreshProviders.length > 0 : false;
      const hasRecurringInterval =
        recurringProvidersWithInterval ? recurringProvidersWithInterval.length > 0 : false;

      patientSubscription.package = {
        id: packageId,
        description: zusPackage.data.attributes.description,
        name: zusPackage.data.attributes.name,
        meta: zusPackage.data.attributes.meta,
        hasIntelligentRefresh,
        hasRecurringInterval,
        requiresManualRequests: requiresManualRequests({
          packageId,
          hasIntelligentRefresh,
          hasRecurringInterval,
        }),
      };

      return patientSubscription;
    },
    enabled: Boolean(patients.data),
  });
}

// Patient requires manaul requests if they don't belong to a package
// Or if their package has no intelligent refresh or recurring interval providers.
function requiresManualRequests(subscription: {
  packageId: string;
  hasIntelligentRefresh: boolean;
  hasRecurringInterval: boolean;
}) {
  if (!subscription.packageId) {
    return true;
  }

  if (!subscription.hasIntelligentRefresh && !subscription.hasRecurringInterval) {
    return true;
  }

  return false;
}
