import { InputHTMLAttributes, useState } from 'react';
import { getAutoCompleteMedications } from '@ctw/shared/api/autocomplete-medications';
import { ComboboxField, ComboxboxFieldOption } from '@ctw/shared/components/form/combobox-field';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { Coding } from 'fhir/r4';

export type AutoCompleteComboboxProps = {
  defaultCoding?: Coding;
  readonly: boolean | undefined;
} & InputHTMLAttributes<HTMLInputElement>;

export const MedicationsAutoComplete = ({
  defaultCoding,
  readonly,
  ...inputProps
}: AutoCompleteComboboxProps) => {
  const ctw = useCTW();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<ComboxboxFieldOption[]>();

  const handleSearchChange = async (query: string) => {
    setIsLoading(true);
    const medications = await getAutoCompleteMedications(ctw, query);

    if (medications) {
      setOptions(
        medications.map((item: Coding) => ({
          value: item,
          label: item.display,
        })) as ComboxboxFieldOption[],
      );
    }

    setIsLoading(false);
  };

  return (
    <ComboboxField
      options={options || []}
      isLoading={isLoading}
      name={`${inputProps.name}`}
      defaultSearchTerm={inputProps.defaultValue as string}
      readonly={readonly}
      onSearchChange={handleSearchChange}
      defaultValue={defaultCoding}
    />
  );
};
