import { useAddMedicationForm } from './helpers/add-new-med-drawer';
import { patientMedicationsAllColumns } from './helpers/columns';
import { useMedicationDetailsDrawer } from './helpers/details';
import { medicationFilters } from './helpers/filters';
import { defaultMedicationSort, medicationSortOptions } from './helpers/sorts';
import { useToggleRead } from '../hooks/use-toggle-read';
import { getDateRangeView } from '../resource/helpers/view-date-range';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { useQueryAllPatientMedications } from '@ctw/shared/hooks/use-medications';
import { useBaseTranslations } from '@ctw/shared/utils/i18n';
import { twx } from '@ctw/shared/utils/tailwind';
import { RowActionsConfigProp } from '@ctw/shared/components/table/table';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { useMemo } from 'react';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';

export interface PatientMedicationsAllProps {
  className?: string;
  onAddToRecord?: (record: MedicationStatementModel) => Promise<void> | void;
}

export const PatientMedicationsAll = withErrorBoundary({
  boundaryName: 'PatientMedicationsAll',
  includeTelemetryBoundary: true,
  Component: ({ className, onAddToRecord }: PatientMedicationsAllProps) => {
    const { requestContext, isWritebackEnabled } = useCTW();
    const query = useQueryAllPatientMedications();
    const medicationWritebackEnabled = useMemo(
      () => isWritebackEnabled('medications-all'),
      [isWritebackEnabled],
    );

    const { viewOptions, past6Months } =
      getDateRangeView<MedicationStatementModel>('lastActivityDate');

    const { data, filters, setFilters, setSort, setViewOption, defaultSort, defaultView } =
      useFilteredSortedData({
        cacheKey: 'patient-medications-all',
        defaultSort: defaultMedicationSort,
        viewOptions,
        defaultView: past6Months.display,
        records: query.allMedications,
      });

    const isEmptyQuery = query.allMedications.length === 0;
    const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;
    const empty = (
      <EmptyPatientTable
        hasZeroFilteredRecords={hasZeroFilteredRecords}
        resourceName="medications"
      />
    );

    const rowActions = useMedicationRowActions((record) => {
      if (onAddToRecord) {
        void onAddToRecord(record);
      }
    });

    const openDetails = useMedicationDetailsDrawer({
      rowActions: medicationWritebackEnabled ? rowActions : undefined,
      enableDismissAndReadActions: true,
    });

    return (
      <div className={twx(className, 'scrollable-pass-through-height')}>
        <ResourceTableActions
          filterOptions={{
            onChange: setFilters,
            filters: medicationFilters(query.allMedications, true),
            selected: filters,
          }}
          sortOptions={{
            defaultSort,
            options: medicationSortOptions,
            onChange: setSort,
          }}
          viewOptions={{
            onChange: setViewOption,
            options: viewOptions,
            defaultView,
          }}
        />
        <ResourceTable
          showTableHead
          isLoading={query.isLoading}
          data={data}
          columns={patientMedicationsAllColumns(requestContext.builderId)}
          onRowClick={(model) => openDetails({ prefetchedModel: model })}
          rowActions={medicationWritebackEnabled ? rowActions : undefined}
          enableDismissAndReadActions
          emptyMessage={empty}
        />
      </div>
    );
  },
});

export function useMedicationRowActions(
  onAddToRecord?: (record: MedicationStatementModel) => void,
) {
  const { t } = useBaseTranslations();
  const { requestContext } = useCTW();
  const showAddMedicationForm = useAddMedicationForm();
  const { toggleRead } = useToggleRead();

  return (record: MedicationStatementModel): RowActionsConfigProp<MedicationStatementModel> =>
    record.ownedByBuilder(requestContext.builderId) ?
      []
    : [
        {
          text: t('resourceTable.add'),
          className:
            'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm btn bg-primary-main text-white hover:bg-primary-text',
          onClick: () => {
            if (!record.isRead) {
              void toggleRead(record);
            }

            if (onAddToRecord) {
              onAddToRecord(record);
            } else {
              showAddMedicationForm(record);
            }
          },
        },
      ];
}
