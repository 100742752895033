import { formatFHIRDate } from '@ctw/shared/utils/dates';
import { formatISO, isValid, parse } from 'date-fns';
import { filter } from 'lodash-es';

export const matchDatePattern = (dateStr: string): Date | string => {
  const patterns = ['P', 'yyyyMMddHHmm'];

  // Going to try to parse all the patterns and if pattern is not recognized then will return date as is
  for (let i = 0; i < patterns.length; i += 1) {
    const parsedDate = parse(dateStr, patterns[i], new Date());
    if (isValid(parsedDate)) {
      return parsedDate;
    }
  }

  return dateStr;
};

// Formats a date from MM/DD/YYYY to YYYY-MM-DD.
export function formatDateLocalToISO(dateStr?: string): string | undefined {
  if (!dateStr) return undefined;

  const date = matchDatePattern(dateStr);
  if (date instanceof Date) {
    return formatISO(date, { representation: 'date' });
  }

  return date;
}

// Formats an age as value followed by unit, or whichever one is available.
export function formatAge(age: fhir4.Age): string {
  const { value, unit } = age;
  return compactToTruthyAndZero([value, unit]).join(' ');
}

export function formatPeriod(period: fhir4.Period) {
  const { start, end } = period;
  if (!start && !end) return '';

  if (!start || !end) {
    const oneExistingDate = start ?? end;
    return oneExistingDate ? formatFHIRDate(oneExistingDate) : '';
  }

  return `${formatFHIRDate(start)} - ${formatFHIRDate(end)}`;
}

// Returns the low and high value and units of a range or unknown if not available.
// Examples:
// "" # blank when both low.value and high.value don't exist
// "2.4 mmol/L" # Shows single value when low or high is missing
// "1.2 l/L - 2.4 mmol/L" # Shows both units if they both exist and are different
// "1.2 - 2.4 mmol/L" # Shows single unit at end when one unit isn't provided
// "1.2 - 2.4" # Drops units when both low.unit and high.unit are not provided
export function formatRange(range: fhir4.Range) {
  const { low, high } = range;

  // No values, show blank.
  if (low?.value === undefined && high?.value === undefined) return '';

  const unit = low?.unit ?? high?.unit;
  const unitStr = unit ? ` ${unit}` : '';

  // Only one value, show that value and the unit if it exists.
  if (low?.value === undefined || high?.value === undefined) {
    const value = low?.value ?? high?.value ?? '';
    return `${value}${unitStr}`;
  }

  // Both values exist, show both values and both units if units exist and are different.
  if (low.unit !== undefined && high.unit !== undefined && low.unit !== high.unit) {
    return `${low.value} ${low.unit} - ${high.value} ${high.unit}`;
  }

  return `${low.value} - ${high.value}${unitStr}`;
}

export function formatQuantity(quantity: fhir4.Quantity) {
  const { value, unit } = quantity;
  return compactToTruthyAndZero([value, unit]).join(' ');
}

export const compactToTruthyAndZero = <T>(arr: T[] | null | undefined) =>
  filter(arr, onlyTruthyAndZero);

export function onlyTruthyAndZero(val: unknown | undefined) {
  return val || val === 0;
}
