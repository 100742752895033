import { capitalize } from 'lodash-es';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { TableColumn } from '@ctw/shared/components/table/table';
import { tw } from '@ctw/shared/utils/tailwind';
import { formatPhoneNumber } from '@ctw/shared/utils/phone-number';
import { formatAddress } from '@ctw/shared/api/fhir/formatters/address';

export const patientSourcesColumns: TableColumn<PatientModel>[] = [
  {
    widthPercent: 10,
    minWidth: 128,
    title: 'Date',
    render: (matchedPatient) => <div>{matchedPatient.createdAtDisplay}</div>,
  },
  {
    widthPercent: 30,
    title: 'Source',
    render: (matchedPatient) => (
      <div className={tw`capitalize`}>{matchedPatient.organizationDisplayName}</div>
    ),
  },
  {
    widthPercent: 30,
    title: 'Patient Details',
    render: (matchedPatient) => (
      <div className={tw`stacked-concat`}>
        {matchedPatient.displayFull && <div>{matchedPatient.displayFull}</div>}
        {Boolean(
          matchedPatient.dob || matchedPatient.age !== undefined || matchedPatient.gender,
        ) && (
          <div>
            <span>
              {matchedPatient.dob} ({matchedPatient.age})
            </span>
            <span> {capitalize(matchedPatient.gender)}</span>
          </div>
        )}
      </div>
    ),
  },
  {
    widthPercent: 40,
    title: 'Contact Info',
    render: (matchedPatient) => (
      <div className={tw`stacked-concat`}>
        {matchedPatient.phoneNumber && <div>{formatPhoneNumber(matchedPatient.phoneNumber)}</div>}
        {matchedPatient.email && <div>{matchedPatient.email}</div>}
        {matchedPatient.homeAddress && formatAddress(matchedPatient.homeAddress)}
      </div>
    ),
  },
];
