import { Resource } from 'fhir/r4';
import { cloneDeep } from 'lodash-es';
import { isFhirError } from './errors';
import { isFHIRDomainResource } from './types';
import { longPollFQS } from '@ctw/shared/api/fqs/long-poll-fqs';
import { CTWState } from '@ctw/shared/context/ctw-context';
import { fixupFHIR } from '@ctw/shared/api/fhir/data-helper/fixup-fhir';

type ResourceSaveOperation = 'update' | 'create';
export type OnResourceSaveCallback = (
  resource: Resource,
  action: ResourceSaveOperation,
  error?: Error,
) => void;

export async function createOrEditFhirResource(
  resource: Resource,
  { fhirWriteBackClient, graphqlClient, onResourceSave }: CTWState,
) {
  const updating = !!resource.id;
  const resourceModified = cloneDeep(resource); // Clone to not modify the original resource.
  let action: ResourceSaveOperation = 'create';
  let response: Resource | undefined;

  if (updating) {
    action = 'update';
    response = await fhirWriteBackClient.update({
      resourceType: resource.resourceType,
      id: resource.id,
      body: fixupFHIR(resource),
      headers: {
        'ctw-write-provenance': 'true',
      },
    });
  } else {
    response = await fhirWriteBackClient.create({
      resourceType: resource.resourceType,
      body: fixupFHIR(resource),
      headers: {
        'ctw-write-provenance': 'true',
      },
    });

    if (!isFhirError(response)) {
      resourceModified.id = response.id;
    }
  }
  onResourceSave(resourceModified, action);

  // Read-Your-Writes!
  // Wait for FQS to have the latest version of the resource.
  // This way callers can safely refetch/invalidateQueries
  // and be sure to get fresh data from FQS.
  if (
    resource.resourceType !== 'Basic' &&
    isFHIRDomainResource(response) &&
    response.id &&
    response.meta?.lastUpdated
  ) {
    await longPollFQS(graphqlClient, resource.resourceType, response.id, response.meta.lastUpdated);
  }

  return response;
}
