import { patientMedicationColumns } from './columns';
import { useMedicationDetailsDrawer } from './details';
import { defaultMedicationSort, medicationSortOptions } from './sorts';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { FilterItem } from '@ctw/shared/components/filter-bar/filter-bar-types';
import './patient-medications.scss';
import { ViewOption } from '@ctw/shared/content/resource/helpers/view-button';
import {
  ResourceTableActions,
  ResourceTableActionsProps,
} from '@ctw/shared/content/resource/resource-table-actions';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { RowActionsConfigProp } from '@ctw/shared/components/table/table';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';

export type PatientMedicationsBaseProps = {
  action?: ResourceTableActionsProps<MedicationStatementModel>['action'];
  className?: string;
  query: { data?: MedicationStatementModel[]; isLoading: boolean };
  rowActions?: (record: MedicationStatementModel) => RowActionsConfigProp<MedicationStatementModel>;
  filters: FilterItem[];
  defaultView: ViewOption<MedicationStatementModel>;
  views: ViewOption<MedicationStatementModel>[];
  onOpenHistoryDrawer?: () => void;
};

export const PatientMedicationsBase = ({
  action,
  className,
  query,
  rowActions,
  filters,
  defaultView: defaultView2,
  views,
  onOpenHistoryDrawer,
}: PatientMedicationsBaseProps) => {
  const openDetailsDrawer = useMedicationDetailsDrawer({ rowActions });

  const {
    data,
    filters: selectedFilters,
    setFilters,
    setSort,
    setViewOption,
    defaultSort,
    defaultView,
  } = useFilteredSortedData({
    cacheKey: 'patient-medications-base',
    defaultSort: defaultMedicationSort,
    viewOptions: views,
    records: query.data,
    defaultView: defaultView2.display,
  });

  const isEmptyQuery = query.data?.length === 0;
  const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

  function handleRowClick(medication: MedicationStatementModel) {
    onOpenHistoryDrawer?.();
    openDetailsDrawer({ prefetchedModel: medication });
  }

  return (
    <div className={twx('scrollable-pass-through-height', className)}>
      <ResourceTableActions
        viewOptions={{
          onChange: setViewOption,
          defaultView,
          options: views,
        }}
        filterOptions={{
          onChange: setFilters,
          filters,
          selected: selectedFilters,
        }}
        sortOptions={{
          defaultSort,
          options: medicationSortOptions,
          onChange: setSort,
        }}
        action={action}
      />

      <ResourceTable
        className={tw`patient-medications`}
        columns={patientMedicationColumns}
        data={data}
        emptyMessage={
          <EmptyPatientTable
            hasZeroFilteredRecords={hasZeroFilteredRecords}
            resourceName="medications"
          />
        }
        isLoading={query.isLoading}
        onRowClick={handleRowClick}
        rowActions={rowActions}
      />
    </div>
  );
};
