import { fqsRequestAll, MAX_OBJECTS_PER_REQUEST } from '@ctw/shared/api/fqs/client';
import { PatientModel } from './models';
import {
  AppointmentGraphqlResponse,
  AppointmentQuery,
} from '@ctw/shared/api/fqs/queries/appointments';
import { GraphQLClient } from 'graphql-request';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export async function fetchAppointments(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
) {
  const { data } = await fqsRequestAll<AppointmentGraphqlResponse>(
    telemetry,
    graphqlClient,
    AppointmentQuery,
    'AppointmentConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
    },
  );

  return data.AppointmentConnection.edges.map(({ node }) => node);
}
