import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';

export type PatientSummaryDrawerFooterProps = {
  feedbackEnabled: boolean;
  onFeedbackClick: () => void;
  onClose: () => void;
};

export const PatientSummaryDrawerFooter = ({
  feedbackEnabled,
  onFeedbackClick,
  onClose,
}: PatientSummaryDrawerFooterProps) => {
  const feedbackBtnClass =
    feedbackEnabled ?
      'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm btn bg-primary-main text-white hover:bg-primary-text'
    : 'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm border border-solid border-border-main bg-white capitalize text-content-subtle hover:bg-background-hover';

  return (
    <div className={tw`flex items-center justify-between gap-5 px-4`}>
      <Button type="button" variant="link" className={tw`hover:underline`} onClick={onClose}>
        Close
      </Button>
      <Button
        type="button"
        variant="unstyled"
        className={twx(feedbackBtnClass)}
        disabled={!feedbackEnabled}
        onClick={onFeedbackClick}
      >
        Submit Feedback
      </Button>
    </div>
  );
};
