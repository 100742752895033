import { ReactNode } from 'react';
import { compact } from 'lodash-es';
import { CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';
import { CareGapStatusBadge } from '@ctw/shared/content/care-gaps/helpers/status-badge';
import { useResourceDetailsDrawer } from '@ctw/shared/content/resource/resource-details-drawer';
import { tw } from '@ctw/shared/utils/tailwind';
import { DetailEntry } from '@ctw/shared/content/resource/helpers/details-card';
import { ActivityHistory } from '@ctw/shared/content/care-gaps/helpers/activity-history';

export function useCareGapDetailsDrawer() {
  const createHeader = (gap: CareGapModel) => (
    <>
      <div className={tw`flex justify-between pb-2`}>
        <div className={tw`text-sm font-medium uppercase tracking-wide`}>
          Last Updated {gap.updatedDate}
        </div>
        <CareGapStatusBadge gap={gap} />
      </div>
      <div>{gap.title}</div>
    </>
  );

  return useResourceDetailsDrawer({
    header: createHeader,
    details: createCareGapsData,
    RenderChild: ({ model }) => <ActivityHistory careGap={model} />,
  });
}

const detail = (label: string | null, value: ReactNode): DetailEntry => ({
  label: label ?? '',
  value,
});

const createCareGapsData = (gap: CareGapModel) =>
  compact([
    gap.denominatorReasons.length > 0 ?
      detail('Inclusion logic', gap.denominatorReasons.join(' '))
    : null,
    gap.measureDescription ? detail('Description', gap.measureDescription) : null,
    gap.windowStart && gap.windowEnd ?
      detail('Treatment window', `${gap.windowStart} - ${gap.windowEnd}`)
    : null,
    gap.excluded ? detail('Exclusion', gap.exclusionReasons.join(', ')) : null,
  ]);
