import { useCallback, useState } from 'react';
import { toggleRead } from '@ctw/shared/api/fhir/basic';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

interface UseToggleReadResult {
  /**
   * Function to call to toggle the read status of the FHIR model
   */
  toggleRead: (model: FHIRModel<fhir4.Resource>) => Promise<void>;

  /**
   * True when `toggleRead` is called
   */
  isLoading: boolean;
}

/**
 * This hook toggles the read status for the specified FHIR model.
 */
export function useToggleRead(): UseToggleReadResult {
  const ctw = useCTW();
  const telemetry = useTelemetry();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleToggleRead = useCallback(
    async (model: FHIRModel<fhir4.Resource>) => {
      // In production we want to avoid non-builder users from inadvertantly marking records as read
      // In lower environments this is allowed for demos/testing
      if (
        ctw.requestContext.env === 'production' &&
        ctw.requestContext.authTokenState.userType !== 'builder'
      ) {
        return;
      }
      setIsLoading(true);
      await toggleRead(model, ctw, telemetry);
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    toggleRead: handleToggleRead,
    isLoading,
  };
}
