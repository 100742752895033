import { getFormsMedicationsUrl } from './urls';
import { CTWState } from '@ctw/shared/context/ctw-context';
import { Coding } from 'fhir/r4';

interface AutoCompleteMedicationsResponseData {
  data: Coding[] | undefined;
}

export const getAutoCompleteMedications = async (ctw: CTWState, searchTerm: string) => {
  const { authToken, builderId } = ctw.requestContext;
  const { data } = await ctw.ctwFetch<AutoCompleteMedicationsResponseData>(
    `${getFormsMedicationsUrl(ctw.requestContext.env)}?display=${searchTerm}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        ...(builderId && { 'Zus-Account': builderId }),
      },
    },
  );
  return data.data;
};
