/* eslint-disable no-restricted-imports, react/no-unstable-nested-components */
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';
import { ComponentType, createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import { ContentError } from '@ctw/shared/components/errors/content-error';
import {
  TelemetryBoundary,
  useTelemetryIfAvailable,
} from '@ctw/shared/context/telemetry/telemetry-boundary';
import { isError, pick } from 'lodash-es';

interface OnErrorArgs {
  error: unknown;
  handled: boolean;
  bubbled: boolean;
  errorContext: Record<string, unknown>;
}
type OnErrorFn = (args: OnErrorArgs) => void;

interface HandleErrorArgs {
  error: unknown;
  bubbled: boolean;
  errorContext: Record<string, unknown>;
}
type HandleErrorFn = (args: HandleErrorArgs) => boolean;

interface ErrorMessageArgs {
  error: unknown;
}

type ErrorMessageFn = (args: ErrorMessageArgs) => string;

interface RenderErrorArgs {
  error: unknown;
  handled: boolean;
  bubbled: boolean;
  errorMessage: string;
  errorContext: Record<string, unknown>;
  resetErrorBoundary: FallbackProps['resetErrorBoundary'];
}
type RenderErrorFn = (args: RenderErrorArgs) => ReactNode;

interface ErrorBoundaryState {
  ancestralBoundaryNames: string[];
  boundaryName: string;
  errorContext: Record<string, unknown>;
}

interface InternalErrorBoundaryState extends ErrorBoundaryState {
  errorMessage: ErrorMessageFn;
  handleError: HandleErrorFn;
  renderError: RenderErrorFn;
  onError: OnErrorFn;
}

interface ErrorFlags {
  bubbled: boolean;
  handled: boolean;
  tracked: boolean;
}

interface FlaggedError extends Error, ErrorFlags {}

interface BubbledError extends FlaggedError {
  bubbled: true;
}

interface HandledError extends FlaggedError {
  handled: true;
}

interface TrackedError extends FlaggedError {
  tracked: true;
}

const isBubbledError = (error: unknown): error is BubbledError =>
  isError(error) && 'bubbled' in error && error.bubbled === true;

const isHandledError = (error: unknown): error is HandledError =>
  isError(error) && 'handled' in error && error.handled === true;

const isTrackedError = (error: unknown): error is TrackedError =>
  isError(error) && 'tracked' in error && error.tracked === true;

interface FlagErrorOptions extends ErrorFlags {}

const flagError = (
  error: Error | FlaggedError,
  { bubbled, handled, tracked }: FlagErrorOptions,
): FlaggedError => {
  (error as FlaggedError).bubbled = bubbled;
  (error as FlaggedError).handled = handled;
  (error as FlaggedError).tracked = tracked;
  return error as FlaggedError;
};

const ErrorBoundaryState = createContext<InternalErrorBoundaryState | null>(null);

const useInternalErrorBoundary = (): InternalErrorBoundaryState | null =>
  useContext(ErrorBoundaryState);

export const useErrorBoundary = (): ErrorBoundaryState | null => {
  const errorBoundaryState = useContext(ErrorBoundaryState);

  return errorBoundaryState ?
      pick(errorBoundaryState, ['ancestralBoundaryNames', 'boundaryName', 'errorContext'])
    : null;
};

interface BaseErrorBoundaryProps {
  boundaryName: string;
  errorMessage?: ErrorMessageFn;
  handleError?: HandleErrorFn;
  renderError?: RenderErrorFn;
  onError?: OnErrorFn;
}

export interface ErrorBoundaryProps extends BaseErrorBoundaryProps {
  children: ReactNode;
}

export const ErrorBoundary = ({
  children,
  boundaryName: localBoundaryName,
  errorMessage: localErrorMessage,
  handleError: localHandleError,
  renderError: localRenderError,
  onError: localOnError,
}: ErrorBoundaryProps) => {
  const telemetry = useTelemetryIfAvailable();
  const parentBoundary = useInternalErrorBoundary();

  const localErrorContext = useMemo(
    () => ({
      ...(parentBoundary?.errorContext ?? {}),
      ...telemetry?.getScopedContextProperties(),
      telemetryAncestralBoundaryNames: telemetry?.ancestralBoundaryNames,
      telemetryBoundaryName: telemetry?.boundaryName,
    }),
    [parentBoundary, telemetry],
  );

  const errorBoundaryState: InternalErrorBoundaryState = useMemo(
    () => ({
      ancestralBoundaryNames:
        parentBoundary ?
          [...parentBoundary.ancestralBoundaryNames, parentBoundary.boundaryName]
        : [],
      boundaryName: localBoundaryName,
      errorContext: localErrorContext,
      errorMessage: ({ error }) => {
        if (localErrorMessage) {
          return localErrorMessage({ error });
        }

        if (parentBoundary?.errorMessage) {
          return parentBoundary.errorMessage({
            error,
          });
        }

        return 'Please try reloading the app.';
      },
      handleError: ({ error, bubbled, errorContext }) => {
        const handledByConsumer = localHandleError?.({ error, bubbled, errorContext }) ?? false;

        const handledByParentBoundary =
          parentBoundary?.handleError({
            error,
            bubbled,
            errorContext,
          }) ?? false;

        if (handledByConsumer || !handledByParentBoundary) {
          return true;
        }

        return false;
      },
      renderError: ({
        errorMessage,
        error,
        handled,
        bubbled,
        errorContext,
        resetErrorBoundary,
      }) => {
        if (localRenderError) {
          return localRenderError({
            errorMessage,
            error,
            handled,
            bubbled,
            errorContext,
            resetErrorBoundary,
          });
        }

        if (parentBoundary?.renderError) {
          return parentBoundary.renderError({
            errorMessage,
            error,
            handled,
            bubbled,
            errorContext,
            resetErrorBoundary,
          });
        }

        return <ContentError title={errorMessage} message="Please try reloading the app." />;
      },
      onError: ({ error, handled, bubbled, errorContext }) => {
        localOnError?.({ error, handled, bubbled, errorContext });

        parentBoundary?.onError({
          error,
          handled,
          bubbled,
          errorContext,
        });
      },
    }),
    [
      localBoundaryName,
      localErrorContext,
      localErrorMessage,
      localHandleError,
      localOnError,
      localRenderError,
      parentBoundary,
    ],
  );

  const forwardedErrorContext = useMemo(
    () => ({
      ancestralBoundaryNames: errorBoundaryState.ancestralBoundaryNames,
      boundaryName: localBoundaryName,
      context: localErrorContext,
    }),
    [errorBoundaryState.ancestralBoundaryNames, localBoundaryName, localErrorContext],
  );

  return (
    <ErrorBoundaryState.Provider value={errorBoundaryState}>
      <ReactErrorBoundary
        onError={(error) => {
          const previouslyHandled = isHandledError(error);
          const handled =
            previouslyHandled ||
            errorBoundaryState.handleError({
              error,
              bubbled: isBubbledError(error),
              errorContext: forwardedErrorContext,
            });

          const previouslyTracked = isTrackedError(error);
          if (!previouslyTracked) {
            telemetry?.trackError({
              message: 'Unhandled error caught by error boundary',
              error,
              context: {
                handled,
                bubbled: isBubbledError(error),
                errorContext: forwardedErrorContext,
              },
            });

            // Make sure error boundary errors are always logged to the console, even when
            // telemetry is not available, even in production (note: telemetry.trackError
            // will not by default log to the console in production)
            // eslint-disable-next-line no-console
            console.error(error, {
              handled,
              errorContext: forwardedErrorContext,
            });
          }

          flagError(error, {
            bubbled: isBubbledError(error),
            tracked: telemetry !== null,
            handled,
          });

          errorBoundaryState.onError({
            error,
            handled,
            bubbled: isBubbledError(error),
            errorContext: forwardedErrorContext,
          });

          // If we are not rendering the error, we should rethrow it so that it can be
          // caught and rendered by the parent boundary
          if (!previouslyHandled && !localRenderError) {
            flagError(error, {
              bubbled: true,
              tracked: isTrackedError(error),
              handled: isHandledError(error),
            });

            throw error;
          }
        }}
        fallbackRender={({ error, resetErrorBoundary }) => (
          <ReactErrorBoundaryFallbackRender
            error={error}
            errorMessage={localErrorMessage}
            renderError={errorBoundaryState.renderError}
            errorContext={forwardedErrorContext}
            resetErrorBoundary={resetErrorBoundary}
          />
        )}
      >
        {children}
      </ReactErrorBoundary>
    </ErrorBoundaryState.Provider>
  );
};

export interface WithErrorBoundaryProps<T> extends BaseErrorBoundaryProps {
  Component: ComponentType<T>;
  includeTelemetryBoundary?: boolean;
}

export const withErrorBoundary =
  <TChildProps extends object>({
    Component,
    includeTelemetryBoundary,
    boundaryName,
    errorMessage,
    renderError,
    handleError,
  }: WithErrorBoundaryProps<TChildProps>) =>
  (props: TChildProps) => {
    const renderErrorBoundary = useCallback(
      () => (
        <ErrorBoundary
          boundaryName={boundaryName}
          errorMessage={errorMessage}
          handleError={handleError}
          renderError={renderError}
        >
          <Component {...props} />
        </ErrorBoundary>
      ),
      [props],
    );

    if (includeTelemetryBoundary) {
      return (
        <TelemetryBoundary boundaryName={boundaryName}>{renderErrorBoundary()}</TelemetryBoundary>
      );
    }

    return renderErrorBoundary();
  };

interface ErrorBoundaryFallbackProps {
  error: unknown;
  errorMessage?: ErrorMessageFn;
  renderError?: RenderErrorFn;
  errorContext: Record<string, unknown>;
  resetErrorBoundary: FallbackProps['resetErrorBoundary'];
}

const ReactErrorBoundaryFallbackRender = ({
  error,
  errorMessage: errorMessageFn,
  renderError,
  errorContext,
  resetErrorBoundary,
}: ErrorBoundaryFallbackProps) => {
  const parentBoundary = useInternalErrorBoundary();

  const handled = isHandledError(error);
  const errorMessage = errorMessageFn?.({ error }) ?? 'An unexpected error occurred.';

  if (renderError) {
    return renderError({
      error,
      handled,
      bubbled: isBubbledError(error),
      errorMessage,
      errorContext,
      resetErrorBoundary,
    });
  }

  if (parentBoundary) {
    flagError(error as Error, {
      handled,
      bubbled: true,
      tracked: isTrackedError(error),
    });

    return parentBoundary.renderError({
      error,
      handled,
      bubbled: true,
      errorMessage,
      errorContext,
      resetErrorBoundary,
    });
  }

  return <ContentError title={errorMessage} message="Please try reloading the app." />;
};
