import {
  PatientConditionsAllGrouped,
  PatientConditionsAllGroupedProps,
} from '../conditions/patient-conditions-all-grouped';
import { DisplayCorrectTabLabel } from '../data-indicator';
import {
  PatientDiagnosticReports,
  PatientDiagnosticReportsProps,
} from '../diagnostic-reports/patient-diagnostic-reports';
import { PatientEncounters, PatientEncountersProps } from '../encounters/patient-encounters';
import { PatientMedications, PatientMedicationsProps } from '../medications/patient-medications';
import {
  PatientMedicationsAll,
  PatientMedicationsAllProps,
} from '../medications/patient-medications-all';
import {
  PatientMedicationsOutside,
  PatientMedicationsOutsideProps,
} from '../medications/patient-medications-outside';
import { PatientOverviewZap, PatientOverviewZapProps } from '../overview/patient-overview-zap';
import {
  PatientRecordSearch,
  PatientRecordSearchProps,
} from '../patient-record-search/patient-record-search';
import { PatientReferrals, PatientReferralsProps } from '../patient-referrals/patient-referrals';
import { PatientVitals, PatientVitalsProps } from '../vitals/patient-vitals';
import { PrototypeLabel } from '@ctw/shared/components/prototype-label';
import {
  PatientAllergies,
  PatientAllergiesProps,
} from '@ctw/shared/content/allergies/patient-allergies';
import {
  PatientCareTeam,
  PatientCareTeamProps,
} from '@ctw/shared/content/care-team/patient-careteam';
import {
  PatientDocuments,
  PatientDocumentsProps,
} from '@ctw/shared/content/document/patient-documents';
import {
  PatientImmunizations,
  PatientImmunizationsProps,
} from '@ctw/shared/content/immunizations/patient-immunizations';
import { PatientDemographics } from '@ctw/shared/content/demographics/patient-demographics';
import { TabGroupItem } from '@ctw/shared/content/zus-aggregated-profile/zap-tab-group/zap-tab-group';
import { ZAPTabName } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';
import i18next from '@ctw/shared/utils/i18n';
import { tw } from '@ctw/shared/utils/tailwind';
import { CareGapProps, PatientCareGaps } from '@ctw/shared/content/care-gaps/patient-care-gaps';

export type ZusAggregatedProfileTabs = Record<ZAPTabName, (props: object) => TabGroupItem>;

export const zusAggregatedProfileTabs: ZusAggregatedProfileTabs = {
  overview: (props: PatientOverviewZapProps) => ({
    key: 'overview',
    display: () => <TabHelper name="overview" />,
    render: () => <PatientOverviewZap {...props} />,
  }),

  demographics: (props = {}) => ({
    key: 'patient-information',
    display: () => <TabHelper resource="demographics" name="demographics" />,
    render: () => <PatientDemographics {...props} />,
  }),

  search: (props: PatientRecordSearchProps) => ({
    key: 'search',
    display: () => <TabHelper name="search" beta />,
    render: () => <PatientRecordSearch className={tw`pt-2`} {...props} />,
  }),

  'conditions-all': (props: PatientConditionsAllGroupedProps = {}) => ({
    key: 'conditions-all',
    display: () => (
      <TabHelper resource="conditions-all" name={i18next.t('zap.tabs.conditionsAll')} />
    ),
    render: () => <PatientConditionsAllGrouped {...props} />,
  }),

  medications: (props: PatientMedicationsProps = {}) => ({
    key: 'medications',
    display: () => 'medication list',
    render: () => <PatientMedications {...props} />,
  }),

  'medications-outside': (props: PatientMedicationsOutsideProps = {}) => ({
    key: 'medications-outside',
    display: () => <TabHelper name={i18next.t('zap.tabs.medicationsOutside')} />,
    render: () => <PatientMedicationsOutside {...props} />,
  }),

  'medications-all': (props: PatientMedicationsAllProps = {}) => ({
    key: 'medications-all',
    display: () => <TabHelper resource="medications-all" name="Medications" />,
    render: () => <PatientMedicationsAll {...props} />,
  }),

  'diagnostic-reports': (props: PatientDiagnosticReportsProps = {}) => ({
    key: 'diagnostic-reports',
    display: () => <TabHelper resource="diagnostic-reports" name="diagnostics" />,
    render: () => <PatientDiagnosticReports {...props} />,
  }),

  encounters: (props: PatientEncountersProps = {}) => ({
    key: 'encounters',
    display: () => <TabHelper resource="encounters" name="Encounters & Notes" />,
    render: () => <PatientEncounters {...props} />,
  }),

  referrals: (props: PatientReferralsProps = {}) => ({
    key: 'referrals',
    display: () => <TabHelper resource="referrals" name="referrals" beta />,
    render: () => <PatientReferrals {...props} />,
  }),

  documents: (props: PatientDocumentsProps = {}) => ({
    key: 'documents',
    display: () => <TabHelper resource="documents" name="documents" />,
    render: () => <PatientDocuments {...props} />,
  }),

  allergies: (props: PatientAllergiesProps = {}) => ({
    key: 'allergies',
    display: () => <TabHelper resource="allergies" name="allergies" />,
    render: () => <PatientAllergies {...props} />,
  }),

  immunizations: (props: PatientImmunizationsProps = {}) => ({
    key: 'immunizations',
    display: () => <TabHelper resource="immunizations" name="immunizations" />,
    render: () => <PatientImmunizations {...props} />,
  }),

  'care-team': (props: PatientCareTeamProps = {}) => ({
    key: 'care-team',
    display: () => <TabHelper resource="care-team" name="care team" />,
    render: () => <PatientCareTeam {...props} />,
  }),

  vitals: (props: PatientVitalsProps = {}) => ({
    key: 'vitals',
    display: () => <TabHelper resource="vitals" name="vitals" />,
    render: () => <PatientVitals {...props} />,
  }),
  'care-gaps': (props: CareGapProps) => ({
    key: 'care-gaps',
    display: () => <TabHelper resource="care-gaps" name="care gaps" />,
    render: () => <PatientCareGaps {...props} />,
  }),
};

type TabHelperProps = {
  beta?: boolean;
  name: string;
  resource?: ZAPTabName;
};

function TabHelper({ beta, name, resource }: TabHelperProps) {
  return (
    <div className={tw`flex items-center space-x-3 pr-3`}>
      <div className={tw`flex items-center space-x-1 text-black`}>
        {resource ?
          <DisplayCorrectTabLabel text={name} resource={resource} isBeta={beta} />
        : <>
            <span className={tw`capitalize`}>{name}</span>
            {beta && <PrototypeLabel stage="beta" />}
          </>
        }
      </div>
    </div>
  );
}
