import { faFlask } from '@fortawesome/pro-solid-svg-icons';
import { tw } from '@ctw/shared/utils/tailwind';
import { ContentError } from '@ctw/shared/components/errors/content-error';
import { SupportFormLink } from '@ctw/shared/content/support-form-link';
import {
  withErrorBoundary,
  WithErrorBoundaryProps,
} from '@ctw/shared/components/errors/error-boundary';
import { OverviewCard } from '@ctw/shared/components/containers/overview-card';

interface RenderOverviewCardErrorProps {
  cardTitle: string;
  errorTitle?: string;
  errorMessage?: string;
}

const renderOverviewCardError =
  ({
    cardTitle,
    errorTitle = 'An unexpected error occurred loading this content',
    errorMessage = 'Please reload the app to try again',
  }: RenderOverviewCardErrorProps) =>
  () => (
    <OverviewCard headerIcon={faFlask} title={cardTitle}>
      <div className={tw`py-4`}>
        <ContentError title={errorTitle} message={errorMessage}>
          <span>If this problem persists </span>
          <SupportFormLink buttonText="contact support" className={tw`link text-base`} />.
        </ContentError>
      </div>
    </OverviewCard>
  );

interface OverviewCardErrorBoundaryProps<T>
  extends Omit<WithErrorBoundaryProps<T>, 'renderError' | 'errorMessage'> {
  cardTitle: string;
  errorTitle?: string;
  errorMessage?: string;
}

export const withOverviewCardErrorBoundary = <T extends object>({
  cardTitle,
  errorTitle,
  errorMessage,
  ...boundaryProps
}: OverviewCardErrorBoundaryProps<T>) =>
  withErrorBoundary({
    ...boundaryProps,
    handleError: () => true,
    renderError: renderOverviewCardError({ cardTitle, errorTitle, errorMessage }),
  });
