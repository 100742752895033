import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PatientInfoLine } from '../patients/patient-info-line';
import { DetailEntry } from '../resource/helpers/details-card';
import { useResourceDetailsDrawer } from '../resource/resource-details-drawer';
import { EpisodeOfCareModel, PatientModel } from '@ctw/shared/api/fhir/models';
import { useBinaries } from '@ctw/shared/api/fqs-rest/binaries';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { tw } from '@ctw/shared/utils/tailwind';
import { Heading } from '@ctw/shared/components/heading';
import { ViewCCDAButton } from '@ctw/shared/content/document/view-ccda-button';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { Link } from '@ctw/shared/components/link';

/*
Details drawer for episode of care. Drawer will show heading based on
patient if goToPatient is provided (Transitions use case), otherwise
drawer header will be based on the episode of care.
*/
export function useEpisodeOfCareDrawer(linkToPatient = false) {
  return useResourceDetailsDrawer({
    header: (episodeOfCare: EpisodeOfCareModel) =>
      linkToPatient && episodeOfCare.patient && episodeOfCare.patientUPID ?
        <PatientHeader
          patient={episodeOfCare.patient}
          patientUPID={episodeOfCare.patientUPID}
          linkToPatient={linkToPatient}
        />
      : <EpisodeOfCareHeader episodeOfCare={episodeOfCare} />,
    subHeader: (episodeOfCare: EpisodeOfCareModel) =>
      linkToPatient ? <PatientInfoLine patient={episodeOfCare.patient} /> : episodeOfCare.location,
    getSourceDocument: false,
    details: episodeOfCareData,
    RenderChild: ({ model }: { model: EpisodeOfCareModel }) => <EncountersAndNotes model={model} />,
    enableDismissAndReadActions: true,
  });
}

const episodeOfCareData = (toc: EpisodeOfCareModel): DetailEntry[] => {
  const admittingDiagnosis = toc.diagnosesForRole('AD');
  const dischargeDiagnosis = toc.diagnosesForRole('DD');

  return [
    { label: 'Start Date', value: toc.periodStart },
    { label: 'End Date', value: toc.periodEnd },
    { label: 'Type', value: toc.class },
    { label: 'Location', value: toc.location },
    {
      label: 'Admitting Diagnosis',
      value:
        admittingDiagnosis.length > 0 ?
          <DiagnosisDetails diagnosis={admittingDiagnosis} />
        : undefined,
    },
    {
      label: 'Discharge Diagnosis',
      value:
        dischargeDiagnosis.length > 0 ?
          <DiagnosisDetails diagnosis={dischargeDiagnosis} />
        : undefined,
    },

    { label: 'Discharge Disposition', value: toc.dischargeDisposition },
  ];
};

function EncountersAndNotes({ model }: { model: EpisodeOfCareModel }) {
  const query = useBinaries(model.dischargeBinaryIds);
  const { trackInteraction } = useTelemetry();

  if (model.dischargeBinaryIds.length === 0) {
    return null;
  }

  if (query.isLoading) {
    return <LoadingSpinner message="Loading documents" />;
  }

  const preferredBinaryIds = model.preferredDischargeBinaryIds;

  return (
    <div className={tw`space-y-4`}>
      <Heading level="h3">Documents</Heading>
      {query.data?.map((binary) => (
        <ViewCCDAButton
          onClick={() => {
            trackInteraction('view_adt_discharge_summary', {
              resourceType: 'EpisodeOfCare',
            });
          }}
          ccdaTitle="Encounter Summary"
          key={binary.id}
          binary={binary}
          patient={model.patient as PatientModel}
          preferred={preferredBinaryIds.includes(binary.id)}
        />
      ))}
    </div>
  );
}

type PatientHeaderProps = {
  patient: PatientModel;
  patientUPID: string;
  linkToPatient: boolean;
};

function PatientHeader({ patient, patientUPID, linkToPatient }: PatientHeaderProps) {
  const { display, gender } = patient;

  return (
    <div className={tw`flex justify-between`}>
      {display}
      {gender ? ` (${gender[0].toUpperCase()})` : ''}
      {linkToPatient && (
        <Link
          href={`/patients/${patientUPID}`}
          className={tw`link flex cursor-pointer items-center space-x-2 border-0 bg-transparent p-0 leading-none text-primary-text outline-none hover:text-primary-main`}
          target="_blank"
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} className={tw`h-3`} />
          <span className={tw`normal-case`}>Go To Profile</span>
        </Link>
      )}
    </div>
  );
}

function EpisodeOfCareHeader({ episodeOfCare }: { episodeOfCare: EpisodeOfCareModel }) {
  return <div className={tw`flex justify-between`}>{episodeOfCare.title}</div>;
}

type DiagnosisDetailsProps = {
  diagnosis: string[];
};
function DiagnosisDetails({ diagnosis }: DiagnosisDetailsProps) {
  if (diagnosis.length === 1) {
    return <span>{diagnosis[0]}</span>;
  }

  return (
    <ul className={tw`list-inside list-disc`}>
      {diagnosis.map((d) => (
        <li key={d}>{d}</li>
      ))}
    </ul>
  );
}
