import { createContext, type JSX } from 'react';
import { TrackingMetadata } from './telemetry/tracking-metadata';
import { DrawerProps } from '@ctw/shared/components/drawer';

export type OpenDrawerProps = {
  animateOpen?: boolean;
  animateClose?: boolean;
  trackingMetadata?: TrackingMetadata;
  component: ({
    isOpen,
    onClose,
    onOpen,
    onAfterOpened,
  }: Pick<DrawerProps, 'isOpen' | 'onClose' | 'onOpen' | 'onAfterOpened'>) =>
    | JSX.Element
    | undefined;
};

export type DrawerState = {
  isOpen: boolean;
  openDrawer: (props: OpenDrawerProps) => void;
};

export const DrawerContext = createContext<DrawerState | undefined>(undefined);
