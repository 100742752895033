import { TableColumn } from '@ctw/shared/components/table/table';
import { CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';
import { CareGapStatusBadge } from '@ctw/shared/content/care-gaps/helpers/status-badge';

export const patientCareGapsColumns = (): TableColumn<CareGapModel>[] => [
  {
    title: 'Measure',
    minWidth: 120,
    dataIndex: 'title',
  },
  {
    title: 'Status',
    render: (careGap) => <CareGapStatusBadge gap={careGap} />,
    minWidth: 80,
  },
  {
    title: 'Details',
    render: (careGap) => careGap.latestReportReasons.join(', '),
  },
];
