import type { PatientRecordSearchResult } from '@ctw/shared/api/patient-record-search/patient-record-search';
import { capitalize } from 'lodash-es';
import { SearchResult } from './search-result';
import {
  AllergyModel,
  ConditionModel,
  DiagnosticReportModel,
  DocumentModel,
  MedicationStatementModel,
} from '@ctw/shared/api/fhir/models';
import { allergyData, allergyHistory } from '@ctw/shared/content/allergies/patient-allergies';
import { useConditionDetailsDrawer } from '@ctw/shared/content/conditions/helpers/details';
import { useDocumentDetailsDrawer } from '@ctw/shared/content/document/helpers/details';
import { useMedicationDetailsDrawer } from '@ctw/shared/content/medications/helpers/details';
import { useResourceDetailsDrawer } from '@ctw/shared/content/resource/resource-details-drawer';
import { useDiagnosticReportDetailsDrawer } from '@ctw/shared/content/diagnostic-reports/helpers/drawer';

type ResourceRowProps = {
  document: PatientRecordSearchResult['document'];
};

export function SearchResultRow(props: ResourceRowProps) {
  const { document } = props;
  const { resource, ...result } = document;
  const resourceType = result.metadata.resource_type;

  const openConditionDetails = useConditionDetailsDrawer({});
  const openAllergyDetails = useResourceDetailsDrawer({
    header: (m) => capitalize(m.display),
    details: allergyData,
    RenderChild: allergyHistory,
    getSourceDocument: true,
  });
  const openDocumentDetails = useDocumentDetailsDrawer({});
  const openMedicationDetails = useMedicationDetailsDrawer({});
  const openDiagnosticReportDetails = useDiagnosticReportDetailsDrawer();

  switch (resourceType) {
    case 'AllergyIntolerance': {
      const allergy = resource as AllergyModel;
      return (
        <SearchResult
          heading={allergy.display}
          label="Allergy"
          resource={allergy}
          openDetails={openAllergyDetails}
          details={[
            { label: 'Last Updated', value: allergy.recordedDate },
            { label: 'Reaction', value: allergy.manifestations },
          ]}
        />
      );
    }
    case 'Condition': {
      const condition = resource as ConditionModel;
      return (
        <SearchResult
          heading={condition.display}
          label="Condition"
          resource={condition}
          openDetails={openConditionDetails}
          details={[
            {
              label: 'Last Updated',
              value:
                condition.recordedDate ?
                  `${condition.recordedDate} ${condition.recorder ?? ''}`
                : 'Unknown',
            },
            {
              label: 'Earliest Known Onset',
              value: condition.onset,
            },
          ]}
        />
      );
    }

    case 'DocumentReference': {
      const doc = resource as DocumentModel;
      return (
        <SearchResult
          heading={doc.title}
          label="Document"
          resource={doc}
          openDetails={openDocumentDetails}
          text={result.page_content}
          details={[
            {
              label: 'Encounter',
              value: doc.encounterDate,
            },
            {
              label: 'Retrieved',
              value: doc.dateCreated,
            },
            {
              label: 'Author',
              value: doc.custodian,
            },
          ]}
        />
      );
    }

    case 'MedicationStatement': {
      const medication = resource as MedicationStatementModel;
      return (
        <SearchResult
          heading={medication.display}
          label="Medication"
          resource={medication}
          openDetails={openMedicationDetails}
          details={[
            {
              label: 'Dosage',
              value: medication.dosage,
            },
            {
              label: 'Last Filled',
              value: medication.lastFillDate,
            },
            {
              label: 'Last Prescribed',
              value:
                medication.lastPrescribedDate ?
                  `${medication.lastPrescribedDate} ${medication.lastPrescriber}`
                : undefined,
            },
          ]}
        />
      );
    }

    case 'DiagnosticReport': {
      const diagnosticReport = resource as DiagnosticReportModel;
      return (
        <SearchResult
          heading={diagnosticReport.displayName}
          label="Diagnostic Report"
          resource={diagnosticReport}
          openDetails={openDiagnosticReportDetails}
          details={[
            {
              label: 'Date',
              value: diagnosticReport.dateDisplay,
            },
            {
              label: '',
              value: diagnosticReport.details,
            },
          ]}
        />
      );
    }

    default:
      // Unknown resource type
      return null;
  }
}
