import { fetchAppointments } from '@ctw/shared/api/fhir/appointments';
import { getDocumentReferences } from '@ctw/shared/api/fhir/document';
import { getEncountersWithClinicalNotes } from '@ctw/shared/api/fhir/encounters';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { ReferralModel } from '@ctw/shared/api/fhir/models/referral';
import { fqsRequestAll, MAX_OBJECTS_PER_REQUEST } from '@ctw/shared/api/fqs/client';
import {
  ServiceRequestGraphqlResponse,
  ServiceRequestQuery,
} from '@ctw/shared/api/fqs/queries/service-requests';
import {
  findRelatedAppointment,
  findRelatedEncounter,
} from '@ctw/shared/hooks/use-patient-referrals';
import { GraphQLClient } from 'graphql-request';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export async function fetchReferralsByIdFQS(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  ids: string[],
) {
  const { data } = await fqsRequestAll<ServiceRequestGraphqlResponse>(
    telemetry,
    graphqlClient,
    ServiceRequestQuery,
    'ServiceRequestConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
      sort: {},
      filter: {
        ids: {
          anymatch: ids,
        },
      },
    },
  );

  const appointments = await fetchAppointments(telemetry, graphqlClient, patient);
  const encounters = await getEncountersWithClinicalNotes(telemetry, graphqlClient, patient);
  const documents = await getDocumentReferences(telemetry, graphqlClient, patient, [
    MAX_OBJECTS_PER_REQUEST,
  ]);

  return data.ServiceRequestConnection.edges
    .map((x) => x.node)
    .map((serviceRequest) => {
      const relatedAppointment = findRelatedAppointment(serviceRequest, appointments);
      const relatedEncounter =
        relatedAppointment ?
          findRelatedEncounter(
            relatedAppointment,
            encounters.map((encounterModel) => encounterModel.resource),
          )
        : undefined;

      return new ReferralModel(
        serviceRequest,
        patient,
        relatedAppointment,
        relatedEncounter,
        documents,
      );
    });
}
