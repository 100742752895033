import { ChangeEvent, FormEvent, useCallback, useRef, useState } from 'react';
import { SearchResultRow } from './helpers/search-result-row';
import {
  EMPTY_SEARCH_RESULTS,
  PatientRecordSearchResult,
  usePatientRecordSearch,
} from '@ctw/shared/api/patient-record-search/patient-record-search';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { DEFAULT_PAGE_SIZE, ExpandList } from '@ctw/shared/components/pagination/expand-list';
import { FeedbackProvider } from '@ctw/shared/content/patient-record-search/helpers/feedback-provider';
import { ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import './helpers/style.scss';
import { Alert } from '@ctw/shared/components/alert';
import { Button } from '@ctw/shared/components/button';
import { faSearch, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { useContainerQuery } from '@ctw/shared/hooks/breakpoints';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';

export interface PatientRecordSearchProps {
  className?: ClassName;
}

export const PatientRecordSearch = withErrorBoundary({
  boundaryName: 'PatientRecordSearch',
  includeTelemetryBoundary: true,
  Component: ({ className }: PatientRecordSearchProps) => {
    const [count, setCount] = useState(DEFAULT_PAGE_SIZE);
    const [searchTextInputValue, setSearchTextInputValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const ref = useRef<HTMLInputElement>(null);
    const { breakpoints } = useContainerQuery({ containerRef: ref });
    const {
      data = EMPTY_SEARCH_RESULTS,
      isFetching,
      isLoading,
      isError,
    } = usePatientRecordSearch(searchValue);
    const { trackInteraction } = useTelemetry();

    const handleSubmitForm = useCallback(
      (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const query = searchTextInputValue.trim();
        setSearchValue(query);
        setCount(DEFAULT_PAGE_SIZE);
        trackInteraction('search', { action: 'patient-record-search' });
      },
      [searchTextInputValue, trackInteraction],
    );

    const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
      setSearchTextInputValue(event.target.value);
    };

    const clearSearch = useCallback(() => {
      trackInteraction('clear_search', { action: 'patient-record-search' });
      setSearchTextInputValue('');
      setSearchValue('');
    }, [trackInteraction]);

    const userHasSearched = !!searchValue;
    const hasResults = data.results.length > 0;

    return (
      <div
        className={twx(
          className,
          'patient-record-search scrollable-pass-through-height space-x-0 space-y-2 text-base',
        )}
      >
        <form className={tw`patient-record-search-input relative`} onSubmit={handleSubmitForm}>
          <div className={tw`search-icon-wrapper`}>
            <FontAwesomeIcon icon={faSearch} className={tw`search-icon`} />
          </div>
          <input
            ref={ref}
            type="text"
            className={tw`w-full overflow-hidden overflow-ellipsis whitespace-nowrap rounded-md border border-solid border-content-icon bg-white px-3 py-2 pl-10 pr-8 text-sm shadow-sm`}
            placeholder={
              breakpoints.isAtMost.xs ?
                'Search patient records.'
              : 'Search patient conditions, medications, diagnostics, documents and allergies.'
            }
            name="patientRecordSearch"
            value={searchTextInputValue}
            onChange={handleChangeInput}
          />
          <Button
            aria-label="clear search"
            type="button"
            onClick={clearSearch}
            variant="unstyled"
            className={tw`clear-search-icon-wrapper`}
          >
            <FontAwesomeIcon icon={faX} className={tw`clear-search-icon`} />
          </Button>
        </form>

        {isFetching || isLoading ?
          <LoadingSpinner centered message="Loading..." />
        : <div className={tw`patient-record-search-results-list scrollable-pass-through-height`}>
            <div className={tw`patient-record-search-results align-left ml-0`}>
              {/* FeedbackProvider will allow all the feedback forms to get the id of the query */}
              <FeedbackProvider id={data.id}>
                {isError && (
                  <div className={tw`w-full`}>
                    <Alert type="error" header="Error">
                      There was an error running your search.
                    </Alert>
                  </div>
                )}
                {userHasSearched && !hasResults && !isError && (
                  <span className={tw`text-1xl text-content-dark block text-left font-medium`}>
                    Search did not find results.
                  </span>
                )}
                {userHasSearched && hasResults && (
                  <>
                    <span className={tw`text-1xl text-content-dark block text-left font-medium`}>
                      Found {data.results.length} results.
                    </span>
                    {data.results.slice(0, count).map((result: PatientRecordSearchResult, idx) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <SearchResultRow key={`${result.upid}_${idx}`} document={result.document} />
                    ))}
                    <div className={tw`mt-5`}>
                      <ExpandList
                        total={data.results.length}
                        count={count}
                        changeCount={setCount}
                      />
                    </div>
                  </>
                )}
              </FeedbackProvider>
            </div>
          </div>
        }
      </div>
    );
  },
});
