import { CareTeamPractitionerModel } from '@ctw/shared/api/fhir/models/careteam-practitioner';
import { CopyToClipboard } from '@ctw/shared/components/copy-to-clipboard';
import { TableColumn } from '@ctw/shared/components/table/table';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '@ctw/shared/content/resource/helpers/resource-title-column';

export const patientCareTeamColumns = (
  builderId: string,
): TableColumn<CareTeamPractitionerModel>[] => [
  {
    widthPercent: 30,
    minWidth: 150,
    title: 'Provider',
    render: (careTeam) => (
      <ResourceTitleColumn
        title={<CopyToClipboard>{careTeam.practitionerName}</CopyToClipboard>}
        subTitle={careTeam.managingOrganization}
        renderIcon={RenderSyncedWithRecordIcon(careTeam.ownedByBuilder(builderId))}
      />
    ),
  },
  {
    widthPercent: 20,
    minWidth: 150,
    title: 'Role',
    render: (careTeam) => <div>{careTeam.role}</div>,
  },
  {
    widthPercent: 30,
    minWidth: 150,
    title: 'Specialty',
    render: (careTeam) => <div>{careTeam.specialty}</div>,
  },
  {
    widthPercent: 20,
    minWidth: 150,
    title: 'Last Updated',
    render: (careTeam) => <div>{careTeam.effectiveStartDate}</div>,
  },
];
