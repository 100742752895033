import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toggleDismiss } from '@ctw/shared/api/fhir/basic';
import { ConditionModel } from '@ctw/shared/api/fhir/models';
import { TableColumn } from '@ctw/shared/components/table/table';
import { createOrEditCondition } from '@ctw/shared/content/forms/actions/conditions';
import { ConditionType } from '@ctw/shared/content/forms/types';
import { BulkAction } from '@ctw/shared/content/hooks/use-bulk-action-modal';
import { ZAPSelectedResources } from '@ctw/shared/context/bulk-action-context';
import { CTWState } from '@ctw/shared/context/ctw-context';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';
import {
  QUERY_KEY_PATIENT_BUILDER_CONDITIONS,
  QUERY_KEY_PATIENT_SUMMARY_CONDITIONS,
} from '@ctw/shared/utils/query-keys';
import { useQueryClient } from '@tanstack/react-query';
import { PatientState } from '@ctw/shared/context/patient-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

export const bulkDismissConditions: BulkAction<fhir4.Condition, ConditionModel> = {
  type: 'dismiss-bulk',
  action: async (
    patient: PatientState,
    ctw: CTWState,
    telemetry: ReturnType<typeof useTelemetry>,
    selectedConditions: ConditionModel[],
  ) => {
    await Promise.all(
      selectedConditions.map(async (condition) => {
        if (condition.preferredCoding && patient.patient.id) {
          await toggleDismiss(condition, ctw, telemetry);
        }
      }),
    );
  },
};

export const useBulkAddConditions = (): BulkAction<fhir4.Condition, ConditionModel> => {
  const queryClient = useQueryClient();

  return {
    type: 'add',
    action: async (
      patient: PatientState,
      ctw: CTWState,
      telemetry: ReturnType<typeof useTelemetry>,
      selected: ConditionModel[],
    ) => {
      await Promise.all(
        selected.map(async (condition) => {
          if (!condition.preferredCoding || !patient.patient.id) {
            return;
          }

          const conditionData = {
            status: condition.displayStatus,
            type: condition.type as ConditionType,
            condition: condition.preferredCoding,
            abatement: condition.abatement ? new Date(condition.abatement) : undefined,
            onset: condition.onset ? new Date(condition.onset) : undefined,
            note: condition.notes.join(''),
          };

          try {
            await createOrEditCondition(condition, patient.patient.id, conditionData, ctw);
          } catch (error) {
            telemetry.trackError({ message: 'Error executing createOrEditFhirResource', error });
            throw error;
          }
          await queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_PATIENT_SUMMARY_CONDITIONS, QUERY_KEY_PATIENT_BUILDER_CONDITIONS],
          });
        }),
      );
    },
  };
};

export const conditionsBulkActionColumns = (
  selectedResources: ZAPSelectedResources,
  setSelectedResources: (resources: ZAPSelectedResources) => void,
): TableColumn<ConditionModel>[] => [
  {
    title: 'Condition',
    render: (condition) => <div>{condition.display}</div>,
    widthPercent: 35,
  },
  {
    title: 'Onset Date',
    render: (condition) => <div>{condition.onset}</div>,
    widthPercent: 18,
  },
  {
    title: 'Status',
    render: (condition) => <div>{condition.displayStatus}</div>,
    widthPercent: 18,
  },
  {
    title: 'Type',
    render: (condition) => <div>{condition.type}</div>,
    widthPercent: 18,
  },
  {
    title: '',
    render: (condition) => (
      <Button
        type="button"
        variant="link"
        disabled={selectedResources['conditions-all'].length === 1}
        className={twx('di space-x-1', {
          'text-content-icon hover:text-content-icon':
            selectedResources['conditions-all'].length === 1,
        })}
        onClick={() => {
          const newSelectedResources = {
            ...selectedResources,
            'conditions-all': selectedResources['conditions-all'].filter(
              (c) => c.id !== condition.id,
            ),
          };
          setSelectedResources(newSelectedResources);
        }}
      >
        <span>
          <FontAwesomeIcon icon={faTrash} className={tw`w-4`} />
        </span>
        <span>Remove</span>
      </Button>
    ),
    widthPercent: 18,
  },
];
