import { useCallback, useState } from 'react';
import { FeedbackRequest, FeedbackResponse } from './types';
import { getAiEndpoint } from './utils';
import { CTWRequestContext, CTWState } from '@ctw/shared/context/ctw-context';
import { QUERY_KEY_AI_FEEDBACK } from '@ctw/shared/utils/query-keys';
import { useCtwQuery } from '@ctw/shared/hooks/use-ctw-query';

const submitFeedback = async (
  ctwFetch: CTWState['ctwFetch'],
  requestContext: CTWRequestContext,
  requestData: FeedbackRequest,
) => {
  const endpoint = getAiEndpoint(requestContext.env, `/feedback`);
  const createdAt = new Date().toISOString();
  const userId = requestContext.authTokenState.email;

  const finalRequestData = {
    ...requestData,
    createdAt,
    userId,
  };

  const response = await ctwFetch(endpoint, {
    headers: {
      Authorization: `Bearer ${requestContext.authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(finalRequestData),
  });
  return response.data as FeedbackResponse;
};

export const useFeedback = (req: FeedbackRequest) => {
  const [isEnabled, setIsEnabled] = useState(false);

  const query = useCtwQuery({
    queryId: QUERY_KEY_AI_FEEDBACK,
    queryKey: [JSON.stringify(req)],
    queryFn: async ({ requestContext, ctwFetch }) => {
      try {
        return await submitFeedback(ctwFetch, requestContext, req);
      } catch (e) {
        throw new Error(`Failed to fetch feedback: ${e}`);
      }
    },
    enabled: isEnabled,
  });

  const triggerFeedback = useCallback(() => {
    setIsEnabled(true);
    return query.refetch();
  }, [query]);

  return { ...query, triggerFeedback };
};
