import { compact } from 'lodash-es';
import { FHIRModel } from './fhir-model';
import { getMedicationDisplayName } from '../medication';
import { formatFHIRDate } from '@ctw/shared/utils/dates';

export class MedicationAdministrationModel extends FHIRModel<fhir4.MedicationAdministration> {
  kind = 'MedicationAdministration' as const;

  get dosageDisplay(): string {
    const { text, dose } = this.resource.dosage || {};
    if (text) {
      return text;
    }

    return compact([dose?.value, dose?.unit]).join(' ');
  }

  get dosageRoute(): string | undefined {
    const { route } = this.resource.dosage || {};
    return route?.text;
  }

  get effectivePeriod() {
    const { start, end } = this.resource.effectivePeriod || {};

    return {
      start: start ? formatFHIRDate(start) : '',
      end: end ? formatFHIRDate(end) : '',
    };
  }

  get title() {
    return getMedicationDisplayName(this.resource, this.includedResources);
  }
}
