import { ZusAggregatedProfile } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';
import { CTWProvider, EHR } from '@ctw/shared/context/ctw-context';
import { PatientProvider } from '@ctw/shared/context/patient-provider';
import { useEffect, useState } from 'react';
import { EhrFhirClientProvider, useEhrFhirClient } from './context/ehr-fhir-client-context';
import { useZusToken, ZusTokenProvider } from './context/zus-token-context';
import ErrorMessage from './ErrorMessage';
import { useEnvironment } from './hooks/use-environment';
import { usePatient } from './hooks/use-patient';
import LoadingMessage from './LoadingMessage';
import { getAuthTokenState, ZusAuthTokenState } from '@ctw/shared/utils/auth';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';

const { VITE_GIT_SHA } = import.meta.env;

const ZAP = () => {
  const environment = useEnvironment();
  const zusToken = useZusToken();
  const patient = usePatient();
  const fhirClient = useEhrFhirClient();
  const telemetry = useTelemetry();
  const [authTokenState, setAuthTokenState] = useState<ZusAuthTokenState | undefined>(undefined);

  useEffect(() => {
    if (zusToken.token) {
      setAuthTokenState(getAuthTokenState(zusToken.token));
    }
  }, [zusToken]);

  useEffect(() => {
    telemetry.logger.info('Mounting smart-on-fhir zap');
    return () => telemetry.logger.info('Unmounting smart-on-fhir zap');
  }, [telemetry]);

  // For Athena we want to refresh the chart after saving a resource
  // so that the user can see the effect of their action. Though we do
  // not want to refresh the chart after saving a Basic resource since
  // those are not reflected in Athena.
  const onResourceSave =
    environment.ehr === EHR.ATHENA ?
      async (resource: fhir4.Resource) => {
        if (resource.resourceType !== 'Basic') {
          window.parent.postMessage(
            {
              type: 'embeddedAppAPIMessage',
              method: 'notifyPatientDataChange',
              methodVersion: '1.0.0',
            },
            environment.env === 'production' ?
              'https://athenanet.athenahealth.com'
            : 'https://preview.athenahealth.com',
          );
        }
      }
    : undefined;

  // For Athena we need to pass the department ID in the request headers
  // so that we can use it when saving resources.
  let resourceSaveHeaders: Record<string, string> | undefined;
  if (environment.ehr === EHR.ATHENA) {
    const department = fhirClient.client?.state.tokenResponse?.ah_department;
    if (!department) {
      throw Error('Missing department ID in Athena token response');
    }
    resourceSaveHeaders = { ah_department: department };
  }

  if (patient.error) {
    return <ErrorMessage message={patient.error.message} />;
  }

  if (zusToken.token && authTokenState && patient.uniqueIdentifier) {
    return (
      <CTWProvider
        env={environment.env}
        authToken={zusToken.token}
        ehr={environment.ehr ?? 'unknown'}
        builderId={authTokenState.builderId}
        service="smart-on-fhir"
        serviceVersion={VITE_GIT_SHA}
        allowSmallBreakpointCCDAViewer
        onResourceSave={(resource) => {
          if (onResourceSave) {
            void onResourceSave(resource);
          }
        }}
        resourceSaveHeaders={resourceSaveHeaders}
      >
        <PatientProvider
          systemURL={patient.uniqueIdentifier.system}
          patientID={patient.uniqueIdentifier.value}
        >
          <ZusAggregatedProfile hideTitle />
        </PatientProvider>
      </CTWProvider>
    );
  }

  return <LoadingMessage />;
};

const ZAPWrapper = () => (
  <EhrFhirClientProvider>
    <ZusTokenProvider>
      <ZAP />
    </ZusTokenProvider>
  </EhrFhirClientProvider>
);

export default ZAPWrapper;
