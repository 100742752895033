import { capitalize } from 'lodash-es';
import { useConditionHistory } from './history';
import { History } from '../../resource/helpers/history';
import { ConditionModel } from '@ctw/shared/api/fhir/models';
import { CodingList } from '@ctw/shared/content/coding-list';
import { useResourceDetailsDrawer } from '@ctw/shared/content/resource/resource-details-drawer';
import { RowActionsConfigProp } from '@ctw/shared/components/table/table';
import { tw } from '@ctw/shared/utils/tailwind';

export type ConditionDetailsDrawerProps = {
  rowActions?: (c: ConditionModel) => RowActionsConfigProp<ConditionModel>;
  enableDismissAndReadActions?: boolean;
  onOpen?: (c: ConditionModel) => void;
  onAfterClosed?: () => void;
  conditionId?: string;
};

const conditionHistory = ({ model }: { model: ConditionModel }) => (
  <History getHistory={useConditionHistory} model={model} />
);

export const useConditionDetailsDrawer = ({
  rowActions,
  enableDismissAndReadActions,
}: ConditionDetailsDrawerProps = {}) =>
  useResourceDetailsDrawer({
    header: (condition: ConditionModel) => condition.display,
    subHeader: (condition: ConditionModel) => condition.ccsChapter,
    getSourceDocument: true,
    details: (condition: ConditionModel) => [
      { label: 'Latest Diagnosis', value: condition.recordedDate },
      { label: 'Recorder', value: condition.recorder },
      {
        label: 'Provider Organization',
        value: condition.patientOrganizationName,
      },
      { label: 'Onset Date', value: condition.onset },
      { label: 'Type', value: capitalize(condition.type) },
      { label: 'Status', value: capitalize(condition.displayStatus) },
      { label: 'Abatement Date', value: condition.abatement },
      {
        label: 'ICD-10',
        value:
          condition.icd10CMEnrichments.length ?
            <CodingList codings={condition.icd10CMEnrichments} />
          : null,
      },
      {
        label: 'HCC',
        value:
          condition.hccEnrichments.length ?
            <CodingList codings={condition.hccEnrichments} />
          : null,
      },
      {
        label: 'Note',
        value: condition.notes.length !== 0 && (
          <div className={tw`flex flex-col space-y-4`}>
            {condition.notes.map((note, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={note + i}>
                <div>{note}</div>
              </div>
            ))}
          </div>
        ),
      },
    ],
    rowActions,
    RenderChild: conditionHistory,
    enableDismissAndReadActions,
    deepLinkResourceType: 'Condition',
  });
