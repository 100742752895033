import './rotated-table.scss';

import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { DataIndexSpecified, MinRecordItem, RenderSpecified } from './table';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';

export type RotatedTableRows<T extends MinRecordItem> = {
  title: string;
  shortTitle?: string;
  greyed?: boolean;
} & (DataIndexSpecified<T> | RenderSpecified<T>);

export type RotatedTableProps<T extends MinRecordItem> = {
  className?: string;
  rows: RotatedTableRows<T>[];
  records: T[];
  isLoading?: boolean;
  emptyMessage?: string | ReactElement;
};

export const RotatedTable = <T extends MinRecordItem>({
  className,
  rows,
  records,
  isLoading = false,
  emptyMessage: message = 'No records found',
}: RotatedTableProps<T>) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const firstColCellRef = useRef<HTMLTableCellElement>(null);
  const [scrollContainerRef, setScrollContainerRef] = useState<HTMLDivElement | null>(null);
  const [showLeftShadow, setShowLeftShadow] = useState(false);
  const [showRightShadow, setShowRightShadow] = useState(false);
  const [leftShadowOffset, setLeftShadowOffset] = useState(0);

  const updateShadows = useCallback(() => {
    if (firstColCellRef.current) {
      setLeftShadowOffset(firstColCellRef.current.clientWidth);
    }

    const container = scrollContainerRef;
    const table = tableRef.current;
    if (container && table) {
      setShowLeftShadow(container.scrollLeft > 0);
      const rightSide = container.scrollLeft + container.clientWidth;
      setShowRightShadow(rightSide < table.clientWidth);
    }
  }, [scrollContainerRef, tableRef]);

  useEffect(() => {
    const container = scrollContainerRef;

    // Update right away.
    updateShadows();

    // Update on scroll or resize events.
    container?.addEventListener('scroll', updateShadows);
    window.addEventListener('resize', updateShadows);

    return () => {
      container?.removeEventListener('scroll', updateShadows);
      window.removeEventListener('resize', updateShadows);
    };
  }, [tableRef, firstColCellRef, scrollContainerRef, updateShadows]);

  if (isLoading) {
    return <LoadingSpinner centered message="Loading..." />;
  }

  if (Object.entries(records).length === 0) {
    return <div className={tw`flex justify-center`}>{message}</div>;
  }

  return (
    <div className={twx('scrollable-pass-through-height relative space-y-4')}>
      <div
        className={twx(
          'rotated-table-container scrollable-pass-through-height md:rotated-table-small',
          className,
        )}
      >
        <div
          style={{ left: `${leftShadowOffset}px !important` }}
          className={twx('scrollbar scrollable-content overflow-x-auto', {
            'before:z-5 before:pointer-events-none before:absolute before:top-0 before:block before:h-full before:w-1 before:rotate-180 before:bg-table-shadow before:content-[""]':
              showLeftShadow,
            'after:z-5 after:pointer-events-none after:absolute after:right-0 after:top-0 after:block after:h-full after:w-1 after:bg-table-shadow after:content-[""]':
              showRightShadow,
          })}
          ref={(ref) => {
            setScrollContainerRef(ref);
          }}
        >
          <table ref={tableRef}>
            <colgroup>
              <col />
              {records.map((record) => (
                <col key={record.key} />
              ))}
            </colgroup>
            <tbody>
              {rows.map((row, index) => (
                <tr key={row.title} className={twx(row.greyed && `bg-background-hover`)}>
                  <td
                    ref={index === 0 ? firstColCellRef : undefined}
                    className={twx(`title-column z-10`, {
                      'bg-background-hover': row.greyed,
                    })}
                  >
                    <span className={tw`block md:hidden`}>{row.shortTitle ?? row.title}</span>
                    <span className={tw`hidden md:block`}>{row.title}</span>
                  </td>
                  {records.map((record) => {
                    if (row.dataIndex) {
                      const value = record[row.dataIndex] as string | undefined;
                      return (
                        <td key={record.key}>
                          <div className={tw`flex h-full items-center`}>
                            <div title={value} className={tw`rotated-table-truncate`}>
                              {value}
                            </div>
                          </div>
                        </td>
                      );
                    }

                    return (
                      <td key={record.key}>
                        <div className={tw`flex h-full items-center`}>
                          <div className={tw`rotated-table-truncate`}>{row.render?.(record)}</div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
