import { ImmunizationModel } from '@ctw/shared/api/fhir/models/immunization';
import { CopyToClipboard } from '@ctw/shared/components/copy-to-clipboard';
import { TableColumn } from '@ctw/shared/components/table/table';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '@ctw/shared/content/resource/helpers/resource-title-column';

export const patientImmunizationsColumns = (builderId: string) => {
  const immunizationColumns: TableColumn<ImmunizationModel>[] = [
    {
      title: 'Immunization',
      widthPercent: 60,
      minWidth: 320,
      render: (immunization) => (
        <ResourceTitleColumn
          title={<CopyToClipboard>{immunization.description}</CopyToClipboard>}
          renderIcon={RenderSyncedWithRecordIcon(immunization.ownedByBuilder(builderId))}
        />
      ),
    },
    {
      title: 'Date Given',
      dataIndex: 'occurrence',
    },
  ];

  return immunizationColumns;
};
