import { ChangeEvent, useEffect, useState } from 'react';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { useSubmitFeedback } from '@ctw/shared/content/patient-record-search/helpers/use-submit-feedback';
import { tw } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';
import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FeedbackForm = ({ name }: { name: string }) => {
  const [showTextInput, setShowTextInput] = useState(false);
  const [showFinalMessage, setShowFinalMessage] = useState(false);
  const [feedbackType, setFeedbackType] = useState('');
  const [feedback, setFeedback] = useState('');
  const { submit, isSubmitting, isSubmitted, isError, errorMessage } = useSubmitFeedback(name);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isSubmitted) {
      // We only need to show the final message for a few seconds and then hide it
      setShowFinalMessage(true);
      timeoutId = setTimeout(() => {
        setShowFinalMessage(false);
      }, 2500);
    }
    return () => clearTimeout(timeoutId);
  }, [isSubmitted]);

  const handleThumbsDownClick = () => {
    setFeedbackType('negative');
    setShowTextInput(true);
  };

  const handleThumbsUpClick = () => {
    setFeedbackType('positive');
    setShowTextInput(false);
    submit({
      feedback: '',
      feedbackType: 'positive',
    });
  };

  const handleTextInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = () => {
    submit({
      feedback,
      feedbackType,
    });
  };

  const handleClear = () => {
    setShowTextInput(false);
    setFeedback('');
    setFeedbackType('');
    setShowFinalMessage(false);
  };

  if (isSubmitting) {
    return (
      <div>
        Submitting feedback... <LoadingSpinner message="Loading..." />
      </div>
    );
  }

  if (isSubmitted) {
    return showFinalMessage ?
        <div className={tw`patient-record-search-feedback`}>
          <span>
            {isError ?
              `There was an error submitting your feedback; ${errorMessage}`
            : 'Thank you for your feedback!'}
          </span>
        </div>
      : null;
  }

  return (
    <div className={tw`patient-record-search-feedback`}>
      {!showTextInput && (
        <>
          <Button type="button" variant="link" className={tw`mr-0.5`} onClick={handleThumbsUpClick}>
            <span className={tw`inline-block h-4 w-4`}>
              {' '}
              <FontAwesomeIcon
                icon={faThumbsUp}
                className={tw`stroke-black hover:fill-primary-background-hover hover:stroke-primary-text`}
              />
            </span>
          </Button>
          <Button
            type="button"
            variant="link"
            className={tw`mr-0.5`}
            onClick={handleThumbsDownClick}
          >
            {' '}
            <span className={tw`inline-block h-4 w-4`}>
              <FontAwesomeIcon
                icon={faThumbsDown}
                className={tw`stroke-black hover:fill-error-badge hover:stroke-error-text`}
              />
            </span>
          </Button>
        </>
      )}

      {showTextInput && (
        <>
          <input
            type="text"
            placeholder="Please provide more feedback..."
            className={tw`rounded-md border border-solid border-content-icon bg-white px-3 py-2 pl-1.5 pr-1.5 text-sm shadow-sm`}
            value={feedback}
            onChange={handleTextInputChange}
            onKeyUp={(event) => {
              if (!event.metaKey && event.key === 'Enter') handleSubmit();
              if (event.key === 'Escape') {
                handleClear();
              }
            }}
          />
          <Button type="button" variant="primary" className={tw`ml-3`} onClick={handleSubmit}>
            Submit
          </Button>
        </>
      )}
    </div>
  );
};
