import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Telemetry } from '@ctw/shared/context/telemetry/index';
import { SetReturnType } from 'type-fest';
import { IScopedDelegatedLogger } from '@ctw/shared/utils/scoped-delegated-logger';

type ScopedContextState = {
  getProperties: () => Record<string, unknown>;
  getProperty: (key: string) => unknown;
  setProperties: (properties: Record<string, unknown>) => void;
  setProperty: (key: string, value: unknown) => void;
};

const ScopedContext = createContext<ScopedContextState>({
  getProperties: () => ({}),
  getProperty: () => undefined,
  setProperties: () => {
    throw new Error('ScopedContext.setProperties must be used within a ScopedContextProvider');
  },
  setProperty: () => {
    throw new Error('ScopedContext.setProperty must be used within a ScopedContextProvider');
  },
});

interface ScopedContextProviderProps extends PropsWithChildren {}

const ScopedContextProvider = ({ children }: ScopedContextProviderProps) => {
  const parentScope = useContext(ScopedContext);
  // Intentionally do not expose a setter, and when we set a scoped context property, set it directly
  // on the object because we DO NOT want to trigger a re-render.
  const [scopedContextProperties] = useState<Record<string, unknown>>({
    ...parentScope.getProperties(),
  });

  const scopedContextState: ScopedContextState = useMemo(
    () => ({
      getProperties: () => scopedContextProperties,
      getProperty: (key) => scopedContextProperties[key],
      setProperties: (properties) => {
        Object.entries(properties).forEach(([key, value]) => {
          scopedContextProperties[key] = value;
        });
      },
      setProperty: (key, value) => {
        scopedContextProperties[key] = value;
      },
    }),
    [scopedContextProperties],
  );

  return <ScopedContext.Provider value={scopedContextState}>{children}</ScopedContext.Provider>;
};

const useScopedContext = (): ScopedContextState => useContext(ScopedContext);

type TelemetryInterface = typeof Telemetry;

interface TelemetryBoundaryState {
  ancestralBoundaryNames: string[];
  boundaryName: string;
  [key: string]: unknown;
}

interface CurriedTelemetryInterfaceState {
  logger: IScopedDelegatedLogger;
  setPatient: TelemetryInterface['setPatient'];
  resetPatient: TelemetryInterface['resetPatient'];
  trackError: (args: Omit<Parameters<TelemetryInterface['trackError']>[0], 'tag'>) => void;
  trackInteraction: TelemetryInterface['trackInteraction'];
  trackMetric: TelemetryInterface['trackMetric'];
  withTimerMetric: TelemetryInterface['withTimerMetric'];
  startTimerMetric: TelemetryInterface['startTimerMetric'];
  stopTimerMetric: TelemetryInterface['stopTimerMetric'];
  trackTimerMetric: TelemetryInterface['trackTimerMetric'];
  getScopedContextProperty: ScopedContextState['getProperty'];
  getScopedContextProperties: ScopedContextState['getProperties'];
  setScopedContextProperty: ScopedContextState['setProperty'];
  setScopedContextProperties: ScopedContextState['setProperties'];
  redactSensitiveHttpHeaders: TelemetryInterface['redactSensitiveHttpHeaders'];
}

type TelemetryState = TelemetryBoundaryState & CurriedTelemetryInterfaceState;

const TelemetryContext = createContext<TelemetryState | null>(null);

interface TelemetryProviderProps extends PropsWithChildren {
  boundaryName: string;
}

const TelemetryProvider = ({ children, boundaryName }: TelemetryProviderProps) => {
  const scopedContext = useScopedContext();
  const ancestralTelemetry = useTelemetryIfAvailable();

  const telemetryBoundaryState: TelemetryBoundaryState = useMemo(
    () => ({
      ancestralBoundaryNames:
        ancestralTelemetry ?
          [...ancestralTelemetry.ancestralBoundaryNames, ancestralTelemetry.boundaryName]
        : [],
      boundaryName,
    }),
    [ancestralTelemetry, boundaryName],
  );

  const telemetryInterfaceState: CurriedTelemetryInterfaceState = useMemo(
    () => ({
      logger: {
        debug: (message, context) =>
          Telemetry.logger.debug(boundaryName, message, {
            ...telemetryBoundaryState,
            ...scopedContext.getProperties(),
            ...context,
          }),
        info: (message, context) =>
          Telemetry.logger.info(boundaryName, message, {
            ...telemetryBoundaryState,
            ...scopedContext.getProperties(),
            ...context,
          }),
        warn: (message, context) =>
          Telemetry.logger.warn(boundaryName, message, {
            ...telemetryBoundaryState,
            ...scopedContext.getProperties(),
            ...context,
          }),
        error: (message, context) =>
          Telemetry.logger.error(boundaryName, message, {
            ...telemetryBoundaryState,
            ...scopedContext.getProperties(),
            ...context,
          }),
      },
      setPatient: (context) => Telemetry.setPatient(context),
      resetPatient: () => Telemetry.resetPatient(),
      trackError: ({ message, error, context }) =>
        Telemetry.trackError({
          tag: boundaryName,
          message,
          error,
          context: {
            ...scopedContext.getProperties(),
            ...context,
          },
        }),
      trackInteraction: (action, context) =>
        Telemetry.trackInteraction(action, {
          ...telemetryBoundaryState,
          ...scopedContext.getProperties(),
          ...context,
        }),
      trackMetric: (name, context) =>
        Telemetry.trackMetric(name, {
          ...telemetryBoundaryState,
          ...scopedContext.getProperties(),
          ...context,
        }),
      withTimerMetric: ((
        type: Parameters<TelemetryInterface['withTimerMetric']>[0],
        id: Parameters<TelemetryInterface['withTimerMetric']>[1],
        operationFn: SetReturnType<
          Parameters<TelemetryInterface['withTimerMetric']>[2],
          Promise<unknown>
        >,
      ) =>
        Telemetry.withTimerMetric(type, id, (operationContext): Promise<unknown> => {
          operationContext.setScopedContextProperties(telemetryBoundaryState);
          operationContext.setScopedContextProperties(scopedContext.getProperties());
          return operationFn(operationContext);
        })) as TelemetryInterface['withTimerMetric'],
      startTimerMetric: (type, id, context) =>
        Telemetry.startTimerMetric(type, id, {
          ...telemetryBoundaryState,
          ...scopedContext.getProperties(),
          ...context,
        }),
      stopTimerMetric: (type, id, context) =>
        Telemetry.stopTimerMetric(type, id, {
          ...telemetryBoundaryState,
          ...scopedContext.getProperties(),
          ...context,
        }),
      trackTimerMetric: (type, id, startTime, endTime, context) =>
        Telemetry.trackTimerMetric(type, id, startTime, endTime, {
          ...telemetryBoundaryState,
          ...scopedContext.getProperties(),
          ...context,
        }),
      getScopedContextProperty: (key) => scopedContext.getProperty(key),
      getScopedContextProperties: () => scopedContext.getProperties(),
      setScopedContextProperty: (key, value) => scopedContext.setProperty(key, value),
      setScopedContextProperties: (properties) => scopedContext.setProperties(properties),
      redactSensitiveHttpHeaders: Telemetry.redactSensitiveHttpHeaders,
    }),
    [boundaryName, scopedContext, telemetryBoundaryState],
  );

  const telemetryState: TelemetryState = useMemo(
    () => ({
      ...telemetryBoundaryState,
      ...telemetryInterfaceState,
    }),
    [telemetryBoundaryState, telemetryInterfaceState],
  );

  return (
    <ScopedContextProvider>
      <TelemetryContext.Provider value={telemetryState}>{children}</TelemetryContext.Provider>
    </ScopedContextProvider>
  );
};

interface TelemetryBoundaryProps extends PropsWithChildren {
  boundaryName: string;
}

export const TelemetryBoundary = ({ children, boundaryName }: TelemetryBoundaryProps) => (
  <ScopedContextProvider>
    <TelemetryProvider boundaryName={boundaryName}>{children}</TelemetryProvider>
  </ScopedContextProvider>
);

export const useTelemetryIfAvailable = (): TelemetryState | null => useContext(TelemetryContext);

export const useTelemetry = (): TelemetryState => {
  const context = useContext(TelemetryContext);

  if (!context) {
    throw new Error(`useTelemetry must be used within a TelemetryProvider`);
  }

  return context;
};
