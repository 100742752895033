import { uniqBy } from 'lodash-es';
import { useRef } from 'react';
import { patientSourcesColumns } from './helpers/columns';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { useMatchedPatients } from '@ctw/shared/api/fhir/patient-helper';
import { sort } from '@ctw/shared/utils/sort';
import { twx } from '@ctw/shared/utils/tailwind';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';

export interface PatientSourcesProps {
  className?: string;
}

export const PatientSources = withErrorBoundary({
  boundaryName: 'PatientSources',
  includeTelemetryBoundary: true,
  Component: ({ className }: PatientSourcesProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const patientSourcesQuery = useMatchedPatients();

    const hasNoData = patientSourcesQuery.data?.length === 0;

    const dedupedData = uniqBy(
      patientSourcesQuery.data ?? [],
      (p: PatientModel) =>
        [
          p.createdAtDisplay,
          p.organizationDisplayName,
          p.display,
          p.dob,
          p.age,
          p.gender,
          p.phoneNumber,
          p.email,
          p.homeAddress,
        ].join('|'), // Stringify the array to compare its contents
    );
    const sortedData = sort(dedupedData, 'createdAt', 'desc');

    return (
      <div ref={containerRef} className={twx(className, 'scrollable-pass-through-height')}>
        <ResourceTable
          isLoading={patientSourcesQuery.isLoading}
          data={sortedData}
          emptyMessage={
            <EmptyPatientTable hasZeroFilteredRecords={hasNoData} resourceName="patients" />
          }
          columns={patientSourcesColumns}
        />
      </div>
    );
  },
});
