import { sortBy } from 'lodash-es';
import { isEmptyClinicalNote, isSectionDocument } from './filters';
import { usePatientDocuments } from '@ctw/shared/api/fhir/document';
import { DocumentModel } from '@ctw/shared/api/fhir/models';
import { getNoteDisplay } from '@ctw/shared/content/resource/helpers/notes';
import { NotesEntry } from '@ctw/shared/content/resource/helpers/notes-entry';
import { useResourceDetailsDrawer } from '@ctw/shared/content/resource/resource-details-drawer';
import { tw } from '@ctw/shared/utils/tailwind';
import { RowActionsConfigProp } from '@ctw/shared/components/table/table';

export const DocumentNotes = ({ model: document }: { model: DocumentModel }) => {
  const allDocuments = usePatientDocuments();

  // If we're showing the detail drawer for a section document, we only want to show content from that document
  // Otherwise show content from all clinical notes
  const { binaryId, id } = document;

  const clinicalNotes =
    isSectionDocument(document) ?
      [document]
    : sortBy(
        (allDocuments.data ?? []).filter(
          (d) => d.binaryId === binaryId && !isEmptyClinicalNote(d) && isSectionDocument(d),
        ),
        'title',
      );

  return (
    <div className={tw`space-y-4`}>
      <div className={tw`text-lg font-semibold`}>Notes</div>
      {clinicalNotes.map((entry, idx) => (
        <NotesEntry
          // eslint-disable-next-line react/no-array-index-key
          key={`${entry.id}-${idx}`}
          summary={
            entry.title ?
              <div className={tw`font-semibold text-background-inverse`}>{entry.title}</div>
            : <div className={tw`text-content-icon`}>Unknown</div>
          }
          hideEmpty={false}
          details={[
            {
              value: getNoteDisplay(entry.displayContent),
              transposeTables: true,
            },
          ]}
          isDetailShownOnOpen={entry.id === id}
        />
      ))}
    </div>
  );
};

export const useDocumentDetailsDrawer = ({
  rowActions,
}: {
  rowActions?: (c: DocumentModel) => RowActionsConfigProp<DocumentModel>;
} = {}) =>
  useResourceDetailsDrawer({
    header: (m) => m.title,
    details: documentData,
    rowActions,
    enableDismissAndReadActions: true,
    deepLinkResourceType: 'DocumentReference',
    RenderChild: DocumentNotes,
  });

const documentData = (document: DocumentModel) => [
  { label: 'Encounter Date', value: document.encounterDate },
  { label: 'Date Retrieved', value: document.dateCreated },
  { label: 'Author', value: document.custodian },
];
