import { Tooltip as ReactTooltip } from 'react-tooltip';
import { type ReactNode, useMemo } from 'react';
import { uniqueId } from 'lodash-es';
import { twx } from '@ctw/shared/utils/tailwind';

export interface TooltipProps {
  className?: string;
  tooltipClassName?: string;
  children: ReactNode;
  content: string;
}

export function Tooltip({ className, tooltipClassName, children, content }: TooltipProps) {
  const id = useMemo(() => `tooltip-${uniqueId()}`, []);

  return (
    <>
      <div data-tooltip-id={id} className={twx('z-tooltip', className)}>
        {children}
      </div>
      <ReactTooltip
        positionStrategy="absolute"
        place="bottom"
        id={id}
        html={content}
        className={twx('z-[10000]', tooltipClassName)}
      />
    </>
  );
}
